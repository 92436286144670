import {Button, Typography, useTheme} from '@mui/material';
import {CustomModalComponent, Translation, AlertTriangle} from 'palipali-panel-common-web';
import {FC} from 'react';

interface IActiveJobDeleteModalProps {
    handleConfirm: () => void;
}

const ActiveJobDeleteModal: FC<IActiveJobDeleteModalProps> = ({handleConfirm}) => {
    const theme = useTheme();
    return (
        <CustomModalComponent
            theme={theme}
            title={
                <Typography color="text.primary" variant="h6" component="h2">
                    <Translation text={`jobs.deleteJob.title`} />
                </Typography>
            }
            content={
                <>
                    <AlertTriangle className="triangle" />
                    <Typography variant="body1" component="p" color="text.primary">
                        <Translation text="jobs.deleteJob.subtitle" />
                    </Typography>
                    <Typography variant="body2" component="p" sx={{color: 'error.dark'}}>
                        <Translation text="jobs.deleteJob.warning" />
                    </Typography>
                </>
            }
            footer={
                <Button
                    className="jobs-job-delete-modal-submit-button"
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => handleConfirm()}>
                    <Translation text={`jobs.deleteJob.buttons.deleteJobConfirm`} />
                </Button>
            }
        />
    );
};

export default ActiveJobDeleteModal;
