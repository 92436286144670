import {Button, Typography, useTheme} from '@mui/material';
import {CustomModalComponent, Translation, AlertTriangle} from 'palipali-panel-common-web';
import {FC} from 'react';

interface DeleteAccountDialogProps {
    handleConfirm: () => void;
}

const DeleteProfileModal: FC<DeleteAccountDialogProps> = ({handleConfirm}) => {
    const theme = useTheme();

    return (
        <CustomModalComponent
            theme={theme}
            title={
                <Typography color="text.primary" variant="h6" component="h2">
                    <Translation text={`account.deleteProfile.title`} />
                </Typography>
            }
            content={
                <>
                    <AlertTriangle className="triangle" />
                    <Typography>
                        <Translation text="account.deleteProfile.subtitle" />
                    </Typography>
                    <Typography>
                        <Translation text="account.deleteProfile.warning" />
                    </Typography>
                </>
            }
            footer={
                <Button type="submit" variant="contained" color="primary" fullWidth onClick={() => handleConfirm()}>
                    <Translation text={`account.deleteProfile.buttons.deleteAccount`} />
                </Button>
            }
        />
    );
};

export default DeleteProfileModal;
