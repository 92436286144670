import {
    Translation,
    addAlert,
    AlertType,
    ITeamViewOutput,
    renewAuthToken,
    authTokenSelector,
    refreshTokenSelector,
    handleApiError,
} from 'palipali-panel-common-web';
import React, {useEffect, useState} from 'react';
import {Button, Stack, Box} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {PaymentElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {getTeam, setLoading} from '../../../../../../store/reducers/teamViewSlice';

interface IStripeCardProps {
    teamView: ITeamViewOutput | null;
}

const StripeCard: React.FC<IStripeCardProps> = ({teamView}) => {
    const dispatch = useDispatch(),
        stripe = useStripe(),
        [shouldRefreshTeam, setShouldRefreshTeam] = useState<boolean>(false),
        elements = useElements(),
        authToken = useSelector(authTokenSelector),
        refreshToken = useSelector(refreshTokenSelector);

    const confirmPaymentMethod = async () => {
        if (!stripe || !elements) {
            return;
        }

        dispatch(setLoading(true));

        const result = await stripe.confirmSetup({
            elements,
            confirmParams: {
                return_url: window.location.toString(),
            },
            redirect: 'if_required',
        });

        if (result.error) {
            if (result.error.code === 'setup_intent_authentication_failure') {
                dispatch(addAlert(handleApiError(`app.exception.setup_intent_authentication_failure`)));
            } else {
                dispatch(addAlert(handleApiError(`app.exception.${result.error.decline_code || 'general_error'}`)));
            }
            dispatch(setLoading(false));
        } else {
            if (result?.setupIntent?.client_secret) {
                handleSetupIntent(result.setupIntent.client_secret);
                dispatch(renewAuthToken(refreshToken, authToken, true));
                dispatch(getTeam(teamView?.id));
            }
        }
    };

    const handleSetupIntent = (clientSecret: string) => {
        if (!stripe || !elements) {
            return;
        }
        dispatch(setLoading(true));
        // Retrieve the SetupIntent
        stripe.retrieveSetupIntent(clientSecret).then(({setupIntent}) => {
            let message = null;
            let shouldExpectPaymentMethod = false;
            let alertType = AlertType.INFO;
            switch (setupIntent?.status) {
                case 'succeeded':
                    message = 'teams.teamView.alerts.paymentMethod.added';
                    shouldExpectPaymentMethod = true;
                    alertType = AlertType.SUCCESS;
                    break;

                case 'processing':
                    message = 'teams.teamView.alerts.paymentMethod.processing';
                    shouldExpectPaymentMethod = true;
                    break;

                case 'requires_payment_method':
                    message = 'teams.teamView.alerts.paymentMethod.failed';
                    alertType = AlertType.WARNING;
                    break;
            }
            dispatch(setLoading(false));
            dispatch(addAlert({message: message || '', type: alertType}));

            if (shouldExpectPaymentMethod && teamView?.id && !teamView.defaultPaymentMethod) {
                setShouldRefreshTeam(true);
            }
        });
    };

    useEffect(() => {
        if (!stripe) {
            return;
        }
        const clientSecret = new URLSearchParams(window.location.search).get('setup_intent_client_secret');

        if (!clientSecret) {
            return;
        }
        handleSetupIntent(clientSecret);
    }, [stripe]);

    useEffect(() => {
        if (!shouldRefreshTeam) {
            return;
        }
        const interval = setInterval(() => {
            if (teamView?.id && !teamView.defaultPaymentMethod) {
                dispatch(renewAuthToken(refreshToken, authToken, true));
                dispatch(getTeam(teamView?.id));
                setShouldRefreshTeam(true);
            } else {
                setShouldRefreshTeam(false);
            }
        }, 5000);

        return () => clearInterval(interval);
    }, [shouldRefreshTeam]);

    return (
        <Stack spacing={3} sx={{alignItems: 'flex-start'}}>
            <Box className="card-element">
                <PaymentElement />
            </Box>
            <Button color="primary" variant="outlined" className="add-payment-method-button" onClick={() => confirmPaymentMethod()}>
                <Translation text={`teams.teamView.teamDetails.buttons.addPaymentMethod`} />
            </Button>
        </Stack>
    );
};

export default StripeCard;
