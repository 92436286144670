import {Translation} from 'palipali-panel-common-web';
import React from 'react';
import {Box, Stack, Typography} from '@mui/material';

interface ITeamMemberRoleDescriptionProps {}

const TeamMemberRoleDescription: React.FC<ITeamMemberRoleDescriptionProps> = () => {
    return (
        <Stack gap={'1.6rem'}>
            <Box>
                <Typography variant="body1" component="p" color="text.primary" fontWeight={700}>
                    <Translation text={`teams.teamOperation.inviteUser.employee`} />
                </Typography>
                <Typography variant="body1" component="span" color="text.primary">
                    <Translation text={`teams.teamOperation.inviteUser.employeeDescription`} />
                </Typography>
            </Box>
            <Box>
                <Typography variant="body1" component="p" color="text.primary" fontWeight={700}>
                    <Translation text={`teams.teamOperation.inviteUser.manager`} />
                </Typography>
                <Typography variant="body1" component="span" color="text.primary">
                    <Translation text={`teams.teamOperation.inviteUser.managerDescription`} />
                </Typography>
            </Box>
        </Stack>
    );
};

export default TeamMemberRoleDescription;
