import React from 'react';
import {Box, Typography} from '@mui/material';

interface BoxSizeIconWithBackgroundProps {
    type: 's' | 'm' | 'l';
    customWrapperStyles?: string;
    customTextStyles?: string;
}

const BoxSizeIconWithBackground: React.FC<BoxSizeIconWithBackgroundProps> = ({type, customTextStyles, customWrapperStyles}) => {
    return (
        <Box>
            <Box className={`size-icon ${customWrapperStyles ? customWrapperStyles : ''} size-${type}`}>
                <Typography className={`size-text ${customTextStyles ? customTextStyles : ''} text-${type}`}>{type}</Typography>
            </Box>
        </Box>
    );
};

export default BoxSizeIconWithBackground;
