import {selectSystemParameter, SystemParameterCode} from 'palipali-panel-common-web';
import React from 'react';
import {useSelector} from 'react-redux';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe, StripeElementsOptions} from '@stripe/stripe-js';
import CreateJobSummaryAdditionalPaymentAuthorization from '../CreateJobSummaryAdditionalPaymentAuthorization';

const stripePromise = (key: string) => loadStripe(key);

export interface ICreateJobSummaryAdditionalPaymentAuthorizationStripeWrapperProps {
    additionalPaymentAuthorizationClientSecret: string;
}

const CreateJobSummaryAdditionalPaymentAuthorizationStripeWrapper: React.FC<
    ICreateJobSummaryAdditionalPaymentAuthorizationStripeWrapperProps
> = ({additionalPaymentAuthorizationClientSecret}) => {
    const stripeParameterKey = useSelector((state) => selectSystemParameter(state, SystemParameterCode.STRIPE_PUBLISHABLE_KEY));

    const elementOptions: StripeElementsOptions = {
        clientSecret: additionalPaymentAuthorizationClientSecret,
        appearance: {
            variables: {
                fontSizeBase: '16px',
                colorPrimary: '#32325D',
                colorDanger: '#F04438',
                colorWarning: '#F04438',
            },
        },
    };

    return (
        <Elements stripe={stripePromise(stripeParameterKey.value)} options={elementOptions}>
            <CreateJobSummaryAdditionalPaymentAuthorization
                additionalPaymentAuthorizationClientSecret={additionalPaymentAuthorizationClientSecret}
            />
        </Elements>
    );
};

export default CreateJobSummaryAdditionalPaymentAuthorizationStripeWrapper;
