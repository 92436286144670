import {createRoot} from 'react-dom/client';
import Moment from 'react-moment';
import './i18n';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import App from './App';

Moment.startPooledTimer(1000);
const container = document.getElementById('root');
const root = createRoot(container as any);

root.render(<App />);

serviceWorker.unregister();
