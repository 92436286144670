import {ILocationOutput, firstTeamFromOwnerOrManagerRoleSelector, isAuthTokenExpiredSelector} from 'palipali-panel-common-web';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Route, Routes} from 'react-router-dom';
import {ILocationFilters, changeLocationFilters} from 'src/store/reducers/locationListingSlice';
import {locationFiltersSelector, locationListingSelector} from 'src/store/selectors/locationListingSelectors';
import LocationCreateEdit, {LocationOperationType} from './LocationCreateEdit';
import LocationList from './LocationList';

const Locations: React.FC = () => {
    const dispatch = useDispatch(),
        locations: ILocationOutput[] | [] = useSelector(locationListingSelector),
        locationFilters: ILocationFilters = useSelector(locationFiltersSelector),
        firstTeamFromOwnerList: string | undefined = useSelector(firstTeamFromOwnerOrManagerRoleSelector);
    const isAuthTokenExpired: boolean = useSelector(isAuthTokenExpiredSelector);

    useEffect(() => {
        if (!locationFilters['team.id'] && !isAuthTokenExpired) {
            const payload = {
                ...locationFilters,
                ['team.id']: firstTeamFromOwnerList ? firstTeamFromOwnerList : null,
            };
            dispatch(changeLocationFilters(payload, 1));
        }
    }, [locationFilters, isAuthTokenExpired]);

    return (
        <div className="content-view">
            <Routes>
                <Route path="create" element={<LocationCreateEdit operationType={LocationOperationType.CREATE} />} key="location-create" />
                <Route path=":id" element={<LocationCreateEdit operationType={LocationOperationType.EDIT} />} key="location-view" />
                <Route path="/" element={<LocationList locations={locations} />} key="location-list" />
            </Routes>
        </div>
    );
};

export default Locations;
