import {addAlert, handleApiError, IPurchaserCurrentJobDetailsOutput} from 'palipali-panel-common-web';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useStripe} from '@stripe/react-stripe-js';
import {Stripe} from '@stripe/stripe-js';
import {
    resetToInitialCreateJobViewState,
    setAdditionalPaymentAuthorizationClientSecret,
    setCreateJobViewLoading,
} from '../../../../../store/reducers/createJobViewSlice';
import {useNavigate} from 'react-router-dom';
import {changeActiveJobsFilters, IJobFilters} from '../../../../../store/reducers/jobListingSlice';
import {jobActiveListingFiltersSelector} from '../../../../../store/selectors/jobListingSelector';
import {createdJobSelector} from '../../../../../store/selectors/createJobViewSelectors';

export interface ICreateJobSummaryAdditionalPaymentAuthorizationProps {
    additionalPaymentAuthorizationClientSecret: string;
}

const CreateJobSummaryAdditionalPaymentAuthorization: React.FC<ICreateJobSummaryAdditionalPaymentAuthorizationProps> = ({
    additionalPaymentAuthorizationClientSecret,
}) => {
    const dispatch = useDispatch(),
        jobListingFilters: IJobFilters = useSelector(jobActiveListingFiltersSelector),
        navigate = useNavigate(),
        createdJob: IPurchaserCurrentJobDetailsOutput | null = useSelector(createdJobSelector),
        stripe = useStripe();

    const confirmStripePayment = async (stripe: Stripe) => {
        const {error} = await stripe.confirmPayment({
            // The client secret of the PaymentIntent
            clientSecret: additionalPaymentAuthorizationClientSecret,
            confirmParams: {
                return_url: `${window.location.origin}/panel/active-orders/`,
            },
            redirect: 'if_required',
        });

        if (error) {
            if (error.code === 'payment_intent_authentication_failure') {
                dispatch(addAlert(handleApiError(`app.exception.payment_intent_authentication_failure`)));
            } else {
                dispatch(addAlert(handleApiError(`app.exception.${error.decline_code || 'general_error'}`)));
            }
            dispatch(setCreateJobViewLoading(false));
            dispatch(setAdditionalPaymentAuthorizationClientSecret(null));
        } else {
            const filters: IJobFilters = {...jobListingFilters, 'team.id': createdJob?.team?.id || ''};
            dispatch(changeActiveJobsFilters(filters));
            dispatch(setCreateJobViewLoading(false));
            dispatch(resetToInitialCreateJobViewState());
            navigate('/panel/active-orders');
        }
    };

    useEffect(() => {
        if (stripe) {
            confirmStripePayment(stripe);
        }
    }, [stripe]);

    return <></>;
};

export default CreateJobSummaryAdditionalPaymentAuthorization;
