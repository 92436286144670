import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import {FormikFieldConfig, FormikFieldTypes} from 'palipali-panel-common-web';

const additionalDataFormConfig = (): FormikFieldConfig[] => {
    const {t} = useTranslation();

    return [
        {
            name: 'orderNumber',
            label: t('jobs.createJob.additionalData.formControls.orderNumber'),
            validation: Yup.string()
                .notRequired()
                .max(30, `${t('formValidation.errors.orderNumberValidation', {maxChars: 30})}`),
            type: FormikFieldTypes.TEXT,
            characterLimit: 30,
        },
        {
            name: 'description',
            label: t('jobs.createJob.additionalData.formControls.additionalData'),
            validation: Yup.string().max(500, `${t('formValidation.errors.orderNumberValidation', {maxChars: 500})}`),
            type: FormikFieldTypes.TEXT,
            rows: 6,
            characterLimit: 500,
        },
    ];
};
export default additionalDataFormConfig;
