import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';

export const selectLocationListingSlice = (state: RootState) => {
    return state.locationListing;
};

export const locationListingSelector = createSelector([selectLocationListingSlice], (state: any) => state.locationList);
export const locationListingMetadataSelector = createSelector([selectLocationListingSlice], (state: any) => state.metadata);
export const locationListingPaginationSelector = createSelector([selectLocationListingSlice], (state: any) => state.pagination);
export const isLocationListingLoadingSelector = createSelector([selectLocationListingSlice], (state: any) => state.isLoading);
export const locationFiltersSelector = createSelector([selectLocationListingSlice], (state: any) => state.locationFilters);
export const locationListingInitializedSelector = createSelector([selectLocationListingSlice], (state: any) => state.isInitialized);
