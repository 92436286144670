import {FormikFieldConfig, FormikFieldTypes, IMultiselectOption, ValidationErrorType} from 'palipali-panel-common-web';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';

const createJobTeamSelectorFormConfig = (teamList: IMultiselectOption[]): FormikFieldConfig[] => {
    const {t} = useTranslation();

    return [
        {
            name: 'team',
            label: t('jobs.formControls.team'),
            className: 'job-listing-status-filter-select-team',
            validation: Yup.string().required(`${t(ValidationErrorType.IS_REQUIRED)}`),
            type: FormikFieldTypes.SELECT,
            options: teamList,
            placeholder: `${t('jobs.formControls.noActiveTeams')}`,
        },
    ];
};

export default createJobTeamSelectorFormConfig;
