import {
    FormikFieldTypes,
    FormikFormControlType,
    FormikGroupFieldConfig,
    IMultiselectOption,
    isPhoneNumberTypeMobile,
    selectPhoneCodeList,
    TextMaskType,
    Translation,
    ValidationErrorType,
} from 'palipali-panel-common-web';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import {Typography} from '@mui/material';

const locationOperationFormConfig = (locationTypes: IMultiselectOption[]): FormikGroupFieldConfig[] => {
    const {t} = useTranslation();

    return [
        {
            formikControlType: FormikFormControlType.GROUP_CONTROL,
            fields: [
                {
                    name: 'name',
                    label: t('locations.locationOperation.formControls.name.label'),
                    validation: Yup.string().required(`${t(ValidationErrorType.IS_REQUIRED)}`),
                    type: FormikFieldTypes.TEXT,
                    isRequired: true,
                },
            ],
        },
        {
            formikControlType: FormikFormControlType.GROUP_CONTROL,
            fields: [
                {
                    name: 'companyName',
                    label: t('locations.locationOperation.formControls.companyName.label'),
                    validation: Yup.string().notRequired(),
                    type: FormikFieldTypes.TEXT,
                },
            ],
        },
        {
            formikControlType: FormikFormControlType.GROUP_CONTROL,
            fields: [
                {
                    name: 'locationTypeId',
                    label: t('locations.locationOperation.formControls.locationTypeId.label'),
                    validation: Yup.string().required(`${t(ValidationErrorType.IS_REQUIRED)}`),
                    type: FormikFieldTypes.SELECT,
                    options: locationTypes,
                    isRequired: true,
                },
            ],
        },
        {
            formikControlType: FormikFormControlType.GROUP_CONTROL,
            controlsPerRow: 2,

            fields: [
                {
                    name: 'dialingCode',
                    label: `${t('auth.formControls.dialingCode')}`,
                    validation: Yup.string(),
                    type: FormikFieldTypes.SELECT,
                    className: `account-profile-phone-code`,
                    formControlClassName: 'phone-code-select',
                    flexSettings: '0',
                    options: selectPhoneCodeList,
                },
                {
                    name: 'phone',
                    label: t('auth.formControls.phone'),
                    validation: Yup.string().test('is-valid-phone', `${t(ValidationErrorType.IS_MOBILE_NUMBER)}`, function (value) {
                        const {dialingCode} = this.parent;

                        if (!value) return true;

                        try {
                            return isPhoneNumberTypeMobile(dialingCode, value);
                        } catch (error) {
                            return false;
                        }
                    }),
                    type: FormikFieldTypes.MASKED,
                    textMaskType: TextMaskType.PHONE,
                    flexSettings: '1',
                },
            ],
        },
        {
            formikControlType: FormikFormControlType.GROUP_CONTROL,
            fields: [
                {
                    name: 'phoneDetails',
                    label: t('locations.locationOperation.phoneDetails'),
                    validation: Yup.string().notRequired(),
                    type: FormikFieldTypes.CUSTOM,
                    customContent: (
                        <div>
                            <Typography color="text.secondary" component="p" sx={{paddingBottom: '2.4rem', fontSize: '1.2rem'}}>
                                <Translation text="locations.locationOperation.phoneDetails" />
                            </Typography>
                        </div>
                    ),
                },
            ],
        },
        {
            formikControlType: FormikFormControlType.GROUP_CONTROL,
            controlsPerRow: 2,
            fields: [
                {
                    name: 'addressLine',
                    label: t('jobs.createJob.senderData.formControls.address'),
                    noOptionText: t('jobs.createJob.senderData.noAutocompleteResults'),
                    validation: Yup.string()
                        .max(255)
                        .required(`${t(ValidationErrorType.IS_REQUIRED)}`),
                    type: FormikFieldTypes.ADDRESS_AUTOCOMPLETE,
                    controlFlexWidth: 2,
                    isRequired: true,
                },
                {
                    name: 'flatNumber',
                    label: t('locations.locationOperation.formControls.houseNo.label'),
                    validation: Yup.string().max(255).notRequired(),
                    type: FormikFieldTypes.TEXT,
                    controlFlexWidth: 4,
                },
            ],
        },
    ];
};

export default locationOperationFormConfig;
