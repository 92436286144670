import {
    FormikFieldConfig,
    FormikFieldTypes,
    FormikFormControlType,
    FormikGroupFieldConfig,
    ValidationErrorType,
} from 'palipali-panel-common-web';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';

const immediateOrderFormConfig = (isImmediateOrder: boolean | undefined): FormikGroupFieldConfig[] => {
    const {t} = useTranslation();

    const fields: FormikFieldConfig[] = [
        {
            name: 'isImmediateOrder',
            label: t('jobs.createJob.generalData.immediateCompletion'),
            type: FormikFieldTypes.SWITCH,
            switchLabelPlacement: 'end',
            flexSettings: '3',
            className: 'switch-form-control-wrapper job-create-general-data-form-immediate-completion-switch',
            validation: Yup.boolean(),
        },
    ];

    if (!isImmediateOrder) {
        fields.push({
            name: 'processAt',
            label: t('jobs.createJob.generalData.formControls.date'),
            disablePast: true,
            className: `job-create-general-data-form-order-date`,
            isRequired: true,
            minDateTime: new Date(),
            validation: Yup.date()
                .required(`${t(ValidationErrorType.IS_REQUIRED)}`)
                .min(new Date(), `${t('formValidation.errors.isProcessAtLaterThanNow')}`),
            flexSettings: '4',
            type: FormikFieldTypes.DATETIME,
        });
    }
    return [
        {
            formikControlType: FormikFormControlType.GROUP_CONTROL,
            controlsPerRow: isImmediateOrder ? 1 : 2,
            fields: fields,
        },
    ];
};
export default immediateOrderFormConfig;
