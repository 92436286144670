import React from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {
    BadgeType,
    ILocationOutput,
    locationTranslationSelector,
    ILocationTypeOutput,
    locationTypesSelector,
} from 'palipali-panel-common-web';
import {Badge, Divider, Grid, Stack, Typography} from '@mui/material';

interface ILocationSelectItemProps {
    item: ILocationOutput;
    onPress: (value: ILocationOutput) => void;
}

const LocationSelectItem: React.FC<ILocationSelectItemProps> = ({item, onPress}) => {
    const locationTypes = useSelector(locationTypesSelector),
        {t} = useTranslation();

    const getBadgeValue = (locationTypeId: string): string => {
        const translationName = useSelector((state) => locationTranslationSelector(state, locationTypeId));
        return translationName ? translationName : '';
    };

    const getBadgeType = (locationTypeId: string | undefined): BadgeType => {
        let type = BadgeType.LOCATION_TYPE_WAREHOUSE;
        if (locationTypes) {
            const result: ILocationTypeOutput[] = locationTypes.filter((type) => type.id === locationTypeId),
                typeName = result[0]?.code;
            type =
                typeName === BadgeType.LOCATION_TYPE_SHOP
                    ? BadgeType.LOCATION_TYPE_SHOP
                    : typeName === BadgeType.LOCATION_TYPE_WAREHOUSE
                    ? BadgeType.LOCATION_TYPE_WAREHOUSE
                    : BadgeType.LOCATION_TYPE_WHOLESALER;
        }
        return type;
    };

    return (
        <React.Fragment key={item.locationTypeId}>
            <Grid onClick={() => onPress(item)} className={`address-book-item create-job-address-book-location-list-item-${item?.id}`}>
                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                    <Typography variant="subtitle1" color="text.primary" noWrap sx={{paddingRight: '0.5rem'}}>
                        {item?.name}
                    </Typography>
                    <Badge className={`badge ${getBadgeType(item.locationTypeId)}`}>{getBadgeValue(item.locationTypeId)}</Badge>
                </Stack>
                <Stack>
                    <Typography variant="inherit" className="button-small-font-variant" color="text.secondary">
                        {item?.addressLine ? `${t('jobs.createJob.senderData.addressBookModal.street')} ${item?.addressLine}` : '-'}
                    </Typography>
                </Stack>
            </Grid>
            <Divider />
        </React.Fragment>
    );
};

export default LocationSelectItem;
