import {FormikFieldConfig, FormikFieldTypes, FormikRegexSettings, IMultiselectOption, ValidationErrorType} from 'palipali-panel-common-web';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';

export const teamMemberInviteFormConfig = (roles: IMultiselectOption[]): FormikFieldConfig[] => {
    const {t} = useTranslation();

    return [
        {
            name: 'email',
            label: t('teams.teamView.table.headers.email'),
            validation: Yup.string()
                .max(255)
                .matches(FormikRegexSettings.EMAIL, `${t(ValidationErrorType.IS_EMAIL)}`)
                .required(`${t(ValidationErrorType.IS_REQUIRED)}`),
            type: FormikFieldTypes.TEXT,
            isRequired: true,
        },
        {
            name: 'role',
            label: t('teams.teamView.table.headers.role'),
            validation: Yup.string()
                .max(255)
                .required(`${t(ValidationErrorType.IS_REQUIRED)}`),
            type: FormikFieldTypes.SELECT,
            isRequired: true,
            options: roles,
        },
    ];
};
