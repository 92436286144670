import {Stack, TextField, Typography} from '@mui/material';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {TimePicker} from '@mui/x-date-pickers/TimePicker';
import {IOpenHoursOutput, Translation} from 'palipali-panel-common-web';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

interface IHourControlProps {
    data: IOpenHoursOutput | null;
    selectedDay: string | null;
    onHourChange: (selectedDay: string, closingHour: string, openingHour: string, isDayAvailable: boolean) => void;
    isErrorChange: (isError: boolean) => void;
    isDayAvailable: boolean;
}

const HourControl: React.FC<IHourControlProps> = ({data, selectedDay, onHourChange, isDayAvailable, isErrorChange}) => {
    const {t} = useTranslation(),
        [defaultOpeningValue, setDefaultOpeningValue] = useState<string | null>(null),
        [defaultClosingValue, setDefaultClosingValue] = useState<string | null>(null),
        [isOpeningHourErrorPresent, setOpeningHourErrorPresent] = useState<string | null>(null),
        [isClosingHourErrorPresent, setClosingHourErrorPresent] = useState<string | null>(null);

    useEffect(() => {
        isOpeningHourErrorPresent || isClosingHourErrorPresent ? isErrorChange(true) : isErrorChange(false);
    }, [isOpeningHourErrorPresent, isClosingHourErrorPresent]);

    useEffect(() => {
        if (data && selectedDay) {
            const timeSlot = (data as IOpenHoursOutput)?.[selectedDay as keyof IOpenHoursOutput];
            if (timeSlot) {
                setDefaultOpeningValue(timeSlot.from);
                setDefaultClosingValue(timeSlot.to);
            }
        }
    }, [data, selectedDay]);

    useEffect(() => {
        if (selectedDay) {
            const timeSlot = (data as IOpenHoursOutput)?.[selectedDay as keyof IOpenHoursOutput];
            setDefaultOpeningValue(isDayAvailable && timeSlot ? timeSlot.from : null);
            setDefaultClosingValue(isDayAvailable && timeSlot ? timeSlot.to : null);
        }
    }, [isDayAvailable, selectedDay]);

    const convertHoursMinutesToValue = (time: string | null, adjustment: 'before' | 'after' | null = null) => {
        if (!time) {
            return null;
        }
        const [h, m] = time.split(':'),
            ms = new Date().setHours(Number(h), Number(m)),
            date = new Date(ms);

        if (adjustment === 'before') {
            date.setMinutes(date.getMinutes() - 1);
        } else if (adjustment === 'after') {
            date.setMinutes(date.getMinutes() + 1);
        }

        return date;
    };
    const formatTimeFromDate = (date: Date) => {
        const hours = date.getHours().toString().padStart(2, '0'),
            minutes = date.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    };

    const convertedDefaultOpeningValue = convertHoursMinutesToValue(defaultOpeningValue, 'after'),
        convertedDefaultClosingValue = convertHoursMinutesToValue(defaultClosingValue, 'before');
    const isValidTimeFormat = (timeStr: string) => {
        const timePattern = /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])$/;
        return timePattern.test(timeStr);
    };
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack direction={'row'} spacing={2}>
                {!isDayAvailable ? (
                    <Typography className="unavailable-day-label">
                        <Translation text="locations.locationOperation.openingHours.closed" />
                    </Typography>
                ) : (
                    <>
                        <TimePicker
                            label={t('locations.locationOperation.openingHours.openingHours')}
                            onChange={(value: Date | null, keyboardInputValue?: string | undefined) => {
                                if (
                                    (value && !keyboardInputValue) ||
                                    (value && keyboardInputValue && isValidTimeFormat(keyboardInputValue))
                                ) {
                                    setDefaultOpeningValue(formatTimeFromDate(value));
                                    if (selectedDay && defaultClosingValue) {
                                        onHourChange(selectedDay, formatTimeFromDate(value), defaultClosingValue, isDayAvailable);
                                    }
                                }
                            }}
                            ampm={false}
                            onError={setOpeningHourErrorPresent}
                            disabled={!isDayAvailable}
                            value={convertHoursMinutesToValue(defaultOpeningValue)}
                            inputFormat={'HH:mm'}
                            maxTime={convertedDefaultClosingValue ? convertedDefaultClosingValue : undefined}
                            renderInput={(props) => (
                                <TextField className={'time-input'} key={`${selectedDay}-render-input-opening-hours`} {...props} />
                            )}
                        />
                        <TimePicker
                            label={t('locations.locationOperation.openingHours.closingHours')}
                            onChange={(value: Date | null, keyboardInputValue?: string | undefined) => {
                                if (
                                    (value && !keyboardInputValue) ||
                                    (value && keyboardInputValue && isValidTimeFormat(keyboardInputValue))
                                ) {
                                    setDefaultClosingValue(formatTimeFromDate(value));
                                    if (selectedDay && defaultOpeningValue) {
                                        onHourChange(selectedDay, defaultOpeningValue, formatTimeFromDate(value), isDayAvailable);
                                    }
                                }
                            }}
                            ampm={false}
                            onError={setClosingHourErrorPresent}
                            value={convertHoursMinutesToValue(defaultClosingValue)}
                            minTime={convertedDefaultOpeningValue ? convertedDefaultOpeningValue : undefined}
                            inputFormat={'HH:mm'}
                            renderInput={(props) => (
                                <TextField key={`${selectedDay}-render-input-closing-hours`} className={'time-input'} {...props} />
                            )}
                        />
                    </>
                )}
            </Stack>
        </LocalizationProvider>
    );
};

export default HourControl;
