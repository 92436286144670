import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';
import {JobListingState} from '../reducers/jobListingSlice';

export const selectJobListingSlice = (state: RootState) => {
    return state.jobListing;
};

export const activeJobsListingSelector = createSelector([selectJobListingSlice], (state: JobListingState) => state.activeJobs);
export const historicalJobsListingSelector = createSelector([selectJobListingSlice], (state: JobListingState) => state.historicalJobs);
export const isJobListingLoadingSelector = createSelector([selectJobListingSlice], (state: JobListingState) => state.isLoading);
export const jobActiveListingPaginationSelector = createSelector(
    [selectJobListingSlice],
    (state: JobListingState) => state.activeJobsPagination
);
export const jobHistoricalListingPaginationSelector = createSelector(
    [selectJobListingSlice],
    (state: JobListingState) => state.historicalJobsPagination
);
export const jobActiveListingMetadataSelector = createSelector(
    [selectJobListingSlice],
    (state: JobListingState) => state.activeJobsMetadata
);
export const jobHistoricalListingMetadataSelector = createSelector(
    [selectJobListingSlice],
    (state: JobListingState) => state.historicalJobsMetadata
);
export const jobActiveListingFiltersSelector = createSelector([selectJobListingSlice], (state: JobListingState) => state.activeJobsFilters);
export const jobActiveCurrentlySelectedTeamSelector = createSelector([jobActiveListingFiltersSelector], (filters) => filters['team.id']);

export const jobHistoricalListingFiltersSelector = createSelector(
    [selectJobListingSlice],
    (state: JobListingState) => state.historicalJobsFilters
);
export const isJobListLoadingSelector = createSelector([selectJobListingSlice], (state: JobListingState) => state.isLoading);
export const activeJobListingInitializedSelector = createSelector(
    [selectJobListingSlice],
    (state: JobListingState) => state.isActiveJobsViewInitialized
);
export const historicalJobListingInitializedSelector = createSelector(
    [selectJobListingSlice],
    (state: JobListingState) => state.isHistoricalJobsViewInitialized
);
