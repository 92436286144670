import {Button, Stack, Typography} from '@mui/material';
import {
    MemberOperationType,
    Translation,
    openModal,
    HorizontalCard,
    ITeamViewOutput,
    isModalOpenSelector,
    currentlySelectedModalTypeSelector,
} from 'palipali-panel-common-web';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import DeleteTeamModal from './TeamDeleteModal';
import {useParams} from 'react-router-dom';
import {canDeleteTeamSelector} from 'src/store/selectors/teamViewSelectors';

interface ITeamDeleteCardProps {
    teamView: ITeamViewOutput;
    editMode?: boolean;
}

const TeamDeleteCard: React.FC<ITeamDeleteCardProps> = ({teamView, editMode}) => {
    const dispatch = useDispatch(),
        {id} = useParams(),
        isModalOpen = useSelector(isModalOpenSelector),
        modalType = useSelector(currentlySelectedModalTypeSelector),
        canDelete = useSelector(canDeleteTeamSelector);
    if (!id) return <></>;

    return (
        <>
            <HorizontalCard
                heading={<Translation text="teams.teamView.teamDetails.labels.removeTeam" />}
                content={
                    <Stack spacing={3} sx={{alignItems: 'flex-start'}}>
                        <Typography variant="subtitle1" component="h4" gutterBottom>
                            <Translation text="teams.teamView.teamDetails.removeDesription" />
                        </Typography>
                        <Button
                            color="error"
                            variant="outlined"
                            className="btn"
                            disabled={!canDelete || !editMode}
                            onClick={() => dispatch(openModal(MemberOperationType.DELETE_TEAM))}>
                            <Translation text="teams.teamView.teamDetails.buttons.removeTeam" />
                        </Button>
                    </Stack>
                }
            />

            {isModalOpen && modalType === MemberOperationType.DELETE_TEAM && <DeleteTeamModal id={id} companyName={teamView?.name} />}
        </>
    );
};

export default TeamDeleteCard;
