import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';
import {JobState} from '../reducers/jobSlice';

export const selectJobSlice = (state: RootState) => {
    return state.job;
};

export const jobSelector = createSelector([selectJobSlice], (state: JobState) => state.job);
export const canBeReportedSelector = createSelector([selectJobSlice], (state: JobState) => state.canBeReported);
export const canBeCancelledSelector = createSelector([selectJobSlice], (state: JobState) => state.canBeCancelled);
export const canBeDeletedSelector = createSelector([selectJobSlice], (state: JobState) => state.canBeDeleted);
export const canCreateOpinionSelector = createSelector([selectJobSlice], (state: JobState) => state.canCreateOpinion);
export const isJobLoadingSelector = createSelector([selectJobSlice], (state: JobState) => state.isLoading);
export const redirectToJobSelector = createSelector([selectJobSlice], (state: JobState) => state.redirectToJob);
export const redirectToActiveJobsListSelector = createSelector([selectJobSlice], (state: JobState) => state.redirectToActiveJobsList);
export const redirectToHistoricalJobViewSelector = createSelector([selectJobSlice], (state: JobState) => state.redirectToHistoricalJobView);
export const jobInitializedSelector = createSelector([selectJobSlice], (state: JobState) => state.isInitialized);
export const cancellationFeeSelector = createSelector([selectJobSlice], (state: JobState) => state.cancellationFee);
export const jobTeamMembersSelector = createSelector([selectJobSlice], (state: JobState) => state.jobTeamMembers);
export const jobTeamMemberFilterSelector = createSelector([selectJobSlice], (state: JobState) => state.jobTeamMembersFilters);
export const jobIdSelector = createSelector([selectJobSlice], (state: JobState) => state.job?.id);
