import {getAddressParts, IGooglePlaceDetail} from 'palipali-panel-common-web';
import {IGoogleAddressParts} from 'palipali-panel-common-web/dist/model/location';
import React, {useState} from 'react';
import {useMapEvents} from 'react-leaflet';

interface MapClickHandlerProps {
    onLocationChange: (coords: [number, number], address: string, addressParts: IGoogleAddressParts) => void;
}

const MapClickHandler: React.FC<MapClickHandlerProps> = ({onLocationChange}) => {
    const [isProcessing, setIsProcessing] = useState(false);

    useMapEvents({
        click: (e) => {
            if (isProcessing) {
                return;
            }
            const {lat, lng} = e.latlng;
            fetchAddressData([lat, lng]);
        },
    });

    const fetchAddressData = async (coords: [number, number] | null) => {
        if (!coords) {
            return;
        }
        setIsProcessing(true);

        try {
            const response = await fetch(
                `https://maps.googleapis.com/maps/api/geocode/json?latlng=${coords[0]},${coords[1]}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
            );
            const data = await response.json();
            if (data.results && data.results.length > 0) {
                setIsProcessing(false);
                const addressParts = getAddressParts(data.results[0] as unknown as IGooglePlaceDetail);
                const address = data.results[0].formatted_address;
                onLocationChange(coords, address, addressParts);
            } else {
                setIsProcessing(false);
            }
        } catch (error) {
            setIsProcessing(false);
        }
    };

    return null;
};

export default MapClickHandler;
