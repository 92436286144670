import {combineEpics} from 'redux-observable';
import {
    changePasswordEpic,
    loginEpic,
    reauthenticateEpic,
    renewAuthTokenEpic,
    authEpic,
    accountEpic,
    dictionaryDataEpic,
    teamListingEpic,
    systemParametersEpic,
    silentRefreshDictionariesAndParametersEpic,
} from 'palipali-panel-common-web';
import teamViewEpic from './teamViewEpic';
import teamOperationEpic from './teamOperationEpic';
import teamMemberEpic from './teamMemberEpic';
import locationListingEpic from './locationListingEpic';
import jobListingEpic from './jobListingEpic';
import jobEpic from './jobEpic';
import createJobViewEpic from './createJobViewEpic';
import locationViewEpic from './locationViewEpic';

export const rootEpic = combineEpics(
    loginEpic,
    renewAuthTokenEpic,
    authEpic,
    reauthenticateEpic,
    changePasswordEpic,
    dictionaryDataEpic,
    teamListingEpic,
    teamViewEpic,
    teamOperationEpic,
    teamMemberEpic,
    accountEpic,
    locationListingEpic,
    jobListingEpic,
    jobEpic,
    createJobViewEpic,
    locationViewEpic,
    systemParametersEpic,
    silentRefreshDictionariesAndParametersEpic
);
