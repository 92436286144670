import {Divider} from '@mui/material';
import {
    IPurchaserCurrentJobDetailsOutput,
    JobPrice,
    JobStatus,
    JobViewDetailsListButton,
    JobViewDetailsListItem,
    MemberOperationType,
    openModal,
    PhoneComponent,
    pinFormatter,
    isUserTeamOwnerSelector,
    isUserTeamManagerSelector,
} from 'palipali-panel-common-web';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {canBeReportedSelector} from '../../../../store/selectors/jobSelector';

interface IJobViewDetailsProps {
    job: IPurchaserCurrentJobDetailsOutput;
}

const JobViewDetails: React.FC<IJobViewDetailsProps> = ({job}) => {
    const canBeReported: boolean = useSelector(canBeReportedSelector),
        dispatch = useDispatch();

    if (!job) return <span />;
    const isTeamOwner = useSelector(isUserTeamOwnerSelector(job.team.id)),
        isTeamManager = useSelector(isUserTeamManagerSelector(job.team.id)),
        isEditEnabled = isTeamOwner || isTeamManager;

    return (
        <dl className="job-details-list">
            <JobViewDetailsListItem
                label={'jobs.orderDetails.cost'}
                value={<JobPrice price={job?.cost} isGrossPrice={true} />}
                valueClass={'price'}
            />
            <Divider />
            <JobViewDetailsListItem label={'jobs.orderDetails.team'} value={job.team.teamName} />
            <Divider />
            <JobViewDetailsListItem label={'jobs.orderDetails.customer'} value={`${job.creator.firstName} ${job.creator.lastName}`} />
            <Divider />
            <JobViewDetailsListItem label={'jobs.orderDetails.receiver'} value={`${job.recipient.firstName} ${job.recipient.lastName}`} />
            <Divider />
            <JobViewDetailsListItem label={'jobs.orderDetails.sendingPoint'} value={<PhoneComponent phone={job.fromLocation.phone} />} />
            <Divider />
            <JobViewDetailsListItem label={'jobs.orderDetails.innerOrderNumber'} value={job.orderNumber} />
            <Divider />
            {job.courier && (
                <>
                    <JobViewDetailsListButton
                        label={'jobs.jobView.courier'}
                        value={job.courier.firstName}
                        onClick={() => dispatch(openModal(MemberOperationType.ACTIVE_ORDERS_COURIER_DETAILS))}
                    />
                    <Divider />
                </>
            )}
            {canBeReported && (
                <>
                    <JobViewDetailsListButton
                        label={'jobs.jobView.report'}
                        onClick={() => dispatch(openModal(MemberOperationType.ACTIVE_ORDERS_ORDER_REPORT))}
                    />
                    <Divider />
                </>
            )}
            {job.deliveryPin && job.deliveryStatus !== JobStatus.SEARCHING && job.deliveryStatus !== JobStatus.PLANNED ? (
                <>
                    <JobViewDetailsListItem
                        label={'jobs.orderDetails.deliveryPin'}
                        value={pinFormatter(job?.deliveryPin)}
                        valueClass={'pin'}
                    />
                    <Divider />
                </>
            ) : null}
            {job.deliveryStatus === JobStatus.PLANNED && isEditEnabled && (
                <>
                    <JobViewDetailsListButton
                        label={'jobs.orderDetails.edit'}
                        onClick={() => dispatch(openModal(MemberOperationType.ACTIVE_ORDERS_ORDER_EDIT))}
                    />
                    <Divider />
                </>
            )}
        </dl>
    );
};

export default JobViewDetails;
