import {SvgIcon} from '@mui/material';
import type {ReactNode} from 'react';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {RouteIcon, HouseIcon, LocationsIcon, TeamsIcon} from 'palipali-panel-common-web';

export interface Item {
    disabled?: boolean;
    external?: boolean;
    icon?: ReactNode;
    items?: Item[];
    label?: ReactNode;
    path?: string;
    title: string;
}

export interface Section {
    items: Item[];
    subheader?: string;
}

export const useSections = () => {
    const {t} = useTranslation();

    return useMemo(() => {
        return [
            {
                items: [
                    {
                        title: t('menuItems.activeJobs'),
                        path: '/panel/active-orders/',
                        icon: (
                            <SvgIcon fontSize="large">
                                <HouseIcon />
                            </SvgIcon>
                        ),
                        class: 'active-jobs-menu-item',
                    },
                    {
                        title: t('menuItems.jobs'),
                        path: '/panel/historical-orders/',
                        icon: (
                            <SvgIcon fontSize="large">
                                <RouteIcon />
                            </SvgIcon>
                        ),
                        class: 'jobs-menu-item',
                    },
                    {
                        title: t('menuItems.teams'),
                        path: '/panel/teams',
                        icon: (
                            <SvgIcon fontSize="large">
                                <TeamsIcon />
                            </SvgIcon>
                        ),
                        class: 'teams-menu-item',
                    },
                    {
                        title: t('menuItems.locations'),
                        path: '/panel/locations/',
                        icon: (
                            <SvgIcon fontSize="large">
                                <LocationsIcon />
                            </SvgIcon>
                        ),
                        class: 'locations-menu-item',
                    },
                ],
            },
        ];
    }, [t]);
};
