import {Box, Button, Stack, Typography} from '@mui/material';
import {Elements} from '@stripe/react-stripe-js';
import {StripeElementsOptions, loadStripe} from '@stripe/stripe-js';
import {
    HorizontalCard,
    ITeamViewOutput,
    MemberOperationType,
    Translation,
    currentlySelectedModalTypeSelector,
    isModalOpenSelector,
    openModal,
    selectSystemParameter,
    SystemParameterCode,
    Loader,
    LoaderType,
} from 'palipali-panel-common-web';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {canChangePaymentMethodSelector, clientSecretSelector} from 'src/store/selectors/teamViewSelectors';
import cardIcon from '../../../../../assets/images/card-icon.svg';
import stripeIcon from '../../../../../assets/images/stripe-icon.svg';
import {getClientSecret} from '../../../../../store/reducers/teamViewSlice';
import DeletePaymentMethodModal from './DeletePaymentMethodModal';
import StripeCard from './StripeCard';
import {useSearchParams} from 'react-router-dom';
import {removeStripeQueryParams} from '../../../../../utils/stripeUtils';

interface IPaymentMethodCardProps {
    teamView: ITeamViewOutput;
    editMode?: boolean;
}

const stripePromise = (key: string) => loadStripe(key);

const PaymentMethodCard: React.FC<IPaymentMethodCardProps> = ({teamView, editMode}) => {
    const [updateStripeButtonEnabled, setUpdateStripeButtonEnabled] = React.useState<boolean>(false),
        dispatch = useDispatch(),
        stripeParameterKey = useSelector((state) => selectSystemParameter(state, SystemParameterCode.STRIPE_PUBLISHABLE_KEY)),
        isModalOpen = useSelector(isModalOpenSelector),
        modalType = useSelector(currentlySelectedModalTypeSelector),
        canChangePaymentMethod = useSelector(canChangePaymentMethodSelector),
        clientSecret = useSelector(clientSecretSelector);
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        setUpdateStripeButtonEnabled(editMode ? canChangePaymentMethod : false);
    }, [canChangePaymentMethod, editMode]);

    useEffect(() => {
        if (teamView && teamView.paymentAccountId && !teamView.defaultPaymentMethod) {
            dispatch(getClientSecret(teamView.paymentAccountId));
        }
    }, [canChangePaymentMethod, editMode, teamView.paymentAccountId, teamView.defaultPaymentMethod]);

    const removePaymentMethod = () => {
        dispatch(openModal(MemberOperationType.DELETE_PAYMENT_METHOD));
    };

    const maskCardNumber = (cardNumber: string | null | undefined) => {
        if (!cardNumber) {
            return null;
        }

        return '**** **** **** ' + cardNumber.slice(-4);
    };
    const renderPaymentInfoBox = () => {
        if (-1 !== window.location.search.indexOf('setup_intent_client_secret')) {
            removeStripeQueryParams(searchParams, setSearchParams);
        }

        return (
            <Stack className="card-preview-wrapper">
                <Box className="card-preview">
                    <img src={cardIcon} alt="Payment Card Icon" className="card-preview-icon" />
                    <Typography variant="body2" component="span">
                        {maskCardNumber(teamView.defaultPaymentMethod?.cardNumber)}
                    </Typography>
                </Box>
                <Button
                    color="error"
                    variant="outlined"
                    className={'delete-payment-method-button'}
                    onClick={() => removePaymentMethod()}
                    disabled={!updateStripeButtonEnabled}>
                    <Translation text={'teams.teamView.teamDetails.buttons.removePaymentMethod'} />
                </Button>
            </Stack>
        );
    };

    const renderPaymentCardBox = () => {
        if (!clientSecret || !stripePromise || !stripeParameterKey) {
            return <Loader showLoader={true} type={LoaderType.Local} />;
        }

        const elementOptions: StripeElementsOptions = {
            clientSecret: clientSecret,
            appearance: {
                variables: {
                    fontSizeBase: '16px',
                    colorPrimary: '#32325D',
                    colorDanger: '#F04438',
                    colorWarning: '#F04438',
                },
            },
        };
        return (
            <Elements stripe={stripePromise(stripeParameterKey.value)} options={elementOptions}>
                <StripeCard teamView={teamView} />
            </Elements>
        );
    };

    return (
        <>
            <HorizontalCard
                heading={<Translation text="teams.teamView.teamDetails.labels.paymentMethod" />}
                content={
                    <Stack spacing={3} className="payment-method-cart">
                        <Box className="payment-provider-info">
                            <Typography variant="subtitle1" component="h4">
                                <Translation text="teams.teamView.teamDetails.paymentDescription" />
                            </Typography>
                            <img className="stripe-icon" src={stripeIcon} alt="Stripe image" />
                        </Box>
                        {teamView.defaultPaymentMethod ? renderPaymentInfoBox() : renderPaymentCardBox()}
                    </Stack>
                }
            />
            {isModalOpen && modalType === MemberOperationType.DELETE_PAYMENT_METHOD && (
                <DeletePaymentMethodModal
                    paymentAccountId={teamView?.paymentAccountId || null}
                    systemPaymentMethodId={teamView?.defaultPaymentMethod?.id || null}
                />
            )}
        </>
    );
};

export default PaymentMethodCard;
