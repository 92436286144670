import {Button, Typography, useTheme} from '@mui/material';
import {CustomModalComponent, Translation, AlertTriangle} from 'palipali-panel-common-web';
import React from 'react';
import {useDispatch} from 'react-redux';
import {deletePaymentMethod} from '../../../../../../store/reducers/teamViewSlice';

interface IDeletePaymentMethodProps {
    paymentAccountId: string | null;
    systemPaymentMethodId: string | null;
}

const DeletePaymentMethod: React.FC<IDeletePaymentMethodProps> = ({paymentAccountId, systemPaymentMethodId}) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    return (
        <CustomModalComponent
            theme={theme}
            title={
                <Typography color="text.primary" variant="h6" component="h2">
                    <Translation text={`teams.teamOperation.title.removePaymentMethod`} />
                </Typography>
            }
            content={
                <>
                    <AlertTriangle />
                    <Typography className="delete-team-description">
                        <Translation text="teams.teamOperation.description.removePaymentMethod" />
                    </Typography>
                    <Typography className="delete-team-warning" sx={{color: 'error.dark'}}>
                        <Translation text="teams.teamOperation.description.warning" />
                    </Typography>
                </>
            }
            footer={
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => {
                        if (paymentAccountId && systemPaymentMethodId) {
                            dispatch(deletePaymentMethod(paymentAccountId, systemPaymentMethodId));
                        }
                    }}>
                    <Translation text={`teams.teamOperation.buttons.removePaymentMethod`} />
                </Button>
            }
        />
    );
};

export default DeletePaymentMethod;
