import {Avatar, Box, Button, Card, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, Typography} from '@mui/material';
import {
    IAccountBasicInfoOutput,
    ITeamMemberOutput,
    ITeamViewOutput,
    Loader,
    LoaderType,
    MemberOperationType,
    NoDataInfo,
    Translation,
    UserRole,
    currentlySelectedModalTypeSelector,
    getDisplayName,
    isModalOpenSelector,
    openModal,
    PhoneComponent,
    TrashIcon,
    DefaultAvatar,
    EditIcon,
    UserPlus,
} from 'palipali-panel-common-web';
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {isTeamMemberLoadingSelector} from '../../../../store/selectors/teamMemberSelectors';
import TeamMemberBadge from './TeamMemberBadge';
import TeamMemberDeleteModal from './TeamMemberDeleteModal';
import TeamMemberEditModal from './TeamMemberEditModal';
import TeamMemberInviteModal from './TeamMemberInviteModal';
import {getTeamAccessRole} from './utils/roleFilters';

interface ITeamMembersProps {
    id: string | undefined;
    team: ITeamViewOutput;
    token: string;
    account: IAccountBasicInfoOutput;
}

const TeamMembers: React.FC<ITeamMembersProps> = ({id, token, team, account}) => {
    const dispatch = useDispatch(),
        isLoading = useSelector(isTeamMemberLoadingSelector),
        isModalOpen = useSelector(isModalOpenSelector),
        modalType = useSelector(currentlySelectedModalTypeSelector),
        [selectedMember, setSelectedMember] = useState<ITeamMemberOutput | null>(null);
    const memberOperation = (operation: MemberOperationType | null, id: string | null | undefined, teamMember: ITeamMemberOutput) => {
        setSelectedMember(teamMember);
        dispatch(openModal(operation));
    };

    const renderTableBody = () => {
        if (isLoading) return;

        if (!team?.members || team?.members.length === 0) {
            return (
                <TableRow>
                    <TableCell colSpan={6}>
                        <NoDataInfo text={'teams.teamList.table.noTeamUsersResults'} />
                    </TableCell>
                </TableRow>
            );
        }

        return team?.members.map((member: ITeamMemberOutput, index: number) => (
            <TableRow hover key={member.id}>
                <TableCell>
                    <Box sx={{display: 'flex', alignItems: 'center', gap: '1.6rem'}}>
                        <span className="index-number-cell">
                            <Typography component="span" className="index-number">{`#${index + 1}`}</Typography>
                        </span>
                        <div className="details-box">
                            <div className="details-box-avatar">
                                {member.details.avatar?.fileUrls?.original ? (
                                    <Avatar src={member.details.avatar.fileUrls.original} />
                                ) : (
                                    <DefaultAvatar />
                                )}
                            </div>
                        </div>
                        <Typography>{getDisplayName(member.details)}</Typography>
                    </Box>
                </TableCell>
                <TableCell> {member.details.username}</TableCell>
                <TableCell>
                    <PhoneComponent phone={member?.details?.phone} />
                </TableCell>
                <TableCell>
                    <TeamMemberBadge role={member.invitation ? 'invited' : member.role} />
                </TableCell>
                <TableCell className="action-cell">
                    <div className="action-cell-container">
                        <div className="btn-action-wrapper">
                            <Button
                                disabled={
                                    getTeamAccessRole(token, id) !== UserRole.PURCHASER_TEAM_OWNER ||
                                    account.username === member.details.username
                                }
                                className="btn btn-action teams-users-edit-user-button"
                                onClick={() => memberOperation(MemberOperationType.TEAMS_EDIT_MEMBER, member.id, member)}
                                startIcon={<EditIcon />}>
                                <Translation text="teams.teamView.buttons.edit" />
                            </Button>
                        </div>
                        <div className="btn-action-wrapper">
                            <Button
                                disabled={!member.canBeDeleted}
                                onClick={() => memberOperation(MemberOperationType.TEAMS_REMOVE_MEMBER, member.id, member)}
                                className="btn btn-action teams-users-delete-user-button"
                                startIcon={<TrashIcon />}>
                                <Translation text="teams.teamList.table.buttons.deleteTeam" />
                            </Button>
                        </div>
                    </div>
                </TableCell>
            </TableRow>
        ));
    };
    const renderTeamMembersTable = () => {
        return (
            <TableContainer>
                <Table className="custom-list">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Box sx={{paddingLeft: '9.9rem'}}>
                                    <Translation text="teams.teamView.table.headers.name" />
                                </Box>
                            </TableCell>
                            <TableCell>
                                <Translation text="teams.teamView.table.headers.email" />
                            </TableCell>
                            <TableCell>
                                <Translation text="teams.teamView.table.headers.phone" />
                            </TableCell>
                            <TableCell>
                                <Translation text="teams.teamView.table.headers.role" />
                            </TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>{renderTableBody()}</TableBody>
                </Table>
            </TableContainer>
        );
    };

    return (
        <>
            <Box display={'flex'} justifyContent={'flex-end'} sx={{marginBottom: '3.2rem'}}>
                <Button
                    disabled={getTeamAccessRole(token, id) === UserRole.PURCHASER_TEAM_EMPLOYEE}
                    variant="contained"
                    color="primary"
                    size="medium"
                    className="header-button add-member-button"
                    onClick={() => {
                        dispatch(openModal(MemberOperationType.TEAMS_ADD_MEMBER));
                    }}
                    startIcon={<UserPlus size={20} />}>
                    <Translation text="teams.teamOperation.title.addUser" />
                </Button>
            </Box>

            <Card className="custom-card overflow-hidden">
                {renderTeamMembersTable()}
                <Loader showLoader={isLoading} type={LoaderType.Local} />
            </Card>
            {isModalOpen && modalType === MemberOperationType.TEAMS_REMOVE_MEMBER && (
                <TeamMemberDeleteModal id={selectedMember?.id} teamName={team?.name} />
            )}
            {selectedMember && isModalOpen && modalType === MemberOperationType.TEAMS_EDIT_MEMBER && (
                <TeamMemberEditModal teamMember={selectedMember} userRole={getTeamAccessRole(token, id)} />
            )}

            {isModalOpen && modalType === MemberOperationType.TEAMS_ADD_MEMBER && (
                <TeamMemberInviteModal teamId={id} role={getTeamAccessRole(token, id)} />
            )}
        </>
    );
};

export default TeamMembers;
