import {Button, Divider, Grid, Stack, Typography, useTheme} from '@mui/material';
import {useFormik} from 'formik';
import {LatLngExpression} from 'leaflet';
import 'leaflet-defaulticon-compatibility';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css';
import {
    AddressAutocomplete,
    ChevronLeftIcon,
    ChevronRight,
    FormikForm,
    IGeoPointInput,
    IGeoPointOutput,
    IJobLocationInput,
    ILocationOutput,
    MemberOperationType,
    PlusSquareIcon,
    Translation,
    closeModal,
    currentlySelectedModalTypeSelector,
    isModalOpenSelector,
    isSameValue,
    locationByCodeSelector,
    openModal,
} from 'palipali-panel-common-web';
import {IGoogleAddressParts} from 'palipali-panel-common-web/dist/model/location';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {MapContainer, Marker} from 'react-leaflet';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import {receiverMarkerIcon, senderMarkerIcon} from 'src/components/shared/mapIcons';
import {
    CreateJobWizardSteps,
    setCurrentStep,
    setIsOutsideNavigation,
    setJobReceiverData,
} from '../../../../store/reducers/createJobViewSlice';
import {
    createJobGeneralDataSelector,
    createJobReceiverDataSelector,
    createJobSenderDataSelector,
} from '../../../../store/selectors/createJobViewSelectors';
import CustomTileLayer from '../../../shared/CustomTileLayer';
import MapClickHandler from '../../../shared/MapClickHandler';
import {AddressBookType} from '../CreateJobSenderData';
import LocationsModal from '../CreateJobSenderData/LocationsModal';
import {LocationTypeCode} from '../CreateJobSenderData/LocationsModal/LocationsSelect';
import UpdateMapCenter from '../CreateJobSenderData/UpdateMapCenter';
import receiverDataFormConfig from './receiverDataFormConfig';

interface ReceiverDataForm {
    locationId: string | null;
    addressLine: string;
    point: IGeoPointOutput | null;
    companyName: string | null;
    flatNumber: string | null;
    street: string | null;
    houseNumber: string | null;
    zip: string | null;
    city: string | null;
    country: string | null;
}

interface ICreateJobReceiverDataProps {}

const CreateJobReceiverData: React.FC<ICreateJobReceiverDataProps> = () => {
    const theme = useTheme(),
        dispatch = useDispatch(),
        {t} = useTranslation(),
        navigate = useNavigate(),
        receiverData = useSelector(createJobReceiverDataSelector),
        senderData = useSelector(createJobSenderDataSelector),
        generalData = useSelector(createJobGeneralDataSelector),
        isModalOpen = useSelector(isModalOpenSelector),
        modalType = useSelector(currentlySelectedModalTypeSelector),
        selectedLocationType = useSelector((state) => locationByCodeSelector(state, LocationTypeCode.WHOLESALER)),
        [selectedLocation, setSelectedLocation] = useState<ILocationOutput | null>(null),
        [defaultCoordinates, setDefaultCoordinates] = useState<IGeoPointOutput | null>(null),
        initialValues: ReceiverDataForm = {
            locationId: receiverData?.locationId || null,
            addressLine: receiverData?.addressLine || '',
            point: receiverData?.point || defaultCoordinates,
            companyName: receiverData?.companyName || '',
            flatNumber: receiverData?.flatNumber || '',
            street: receiverData?.street || '',
            houseNumber: receiverData?.houseNumber || '',
            zip: receiverData?.zip || '',
            city: receiverData?.city || '',
            country: receiverData?.country || '',
        };

    useEffect(() => {
        if (receiverData) {
            setDefaultCoordinates(receiverData.point);
        }
    }, [receiverData]);

    const handleLocationSelection = (value: ILocationOutput) => {
        setSelectedLocation(value);
        const point: IGeoPointInput = {
            latitude: value?.point?.latitude,
            longitude: value?.point?.longitude,
        };
        formik.setValues({
            ...formik.values,
            locationId: value?.id,
            addressLine: value?.addressLine || '',
            point: point,
            companyName: value?.companyName || '',
            flatNumber: value?.flatNumber || '',
            street: value?.street || '',
            houseNumber: value?.houseNumber || '',
            zip: value?.zip || '',
            city: value?.city || '',
            country: value?.country || '',
        });
        if (value && value.point) {
            setDefaultCoordinates(point);
        }
        dispatch(closeModal());
    };

    const onAddressClear = () => {
        setSelectedLocation(null);
        formik.setValues({
            ...formik.values,
            locationId: null,
            addressLine: '',
            point: null,
            companyName: '',
            street: '',
            houseNumber: '',
            zip: '',
            city: '',
            country: '',
        });
        setDefaultCoordinates(null);
    };

    const saveAddress = () => {
        const location = {
            locationId: selectedLocationType?.id,
            companyName: formik.values?.companyName || null,
            phone: {
                country: '+48',
                phone: '',
            },
            addressLine: formik.values?.addressLine,
            flatNumber: formik.values?.flatNumber || null,
            point: {
                latitude: (formik.values?.point as IGeoPointInput)?.latitude,
                longitude: (formik.values?.point as IGeoPointInput)?.longitude,
            },
            street: formik.values?.street,
            houseNumber: formik.values?.houseNumber || '',
            zip: formik.values?.zip || '',
            city: formik.values?.city || '',
            country: formik.values?.country || '',
        };
        const locationId = selectedLocation?.id || null;
        const receiverDataInput: IJobLocationInput = {
            ...location,
            locationId: locationId,
        };

        dispatch(setJobReceiverData(receiverDataInput));
        dispatch(setIsOutsideNavigation(true));
        navigate('/panel/locations/create', {state: {location: location, teamId: generalData?.teamId}});
    };

    const handleSaveData = (values: ReceiverDataForm) => {
        if ((values.addressLine && values.point) || selectedLocation) {
            const locationId = selectedLocation?.id || null,
                isStepValueChanged = !isSameValue(initialValues, values, true),
                isLocationIdSame = initialValues.locationId === values.locationId,
                receiver: IJobLocationInput = {
                    locationId: isStepValueChanged && isLocationIdSame ? null : values?.locationId || locationId || null,
                    companyName: values?.companyName || '',
                    phone: null,
                    addressLine: values?.addressLine || '',
                    flatNumber: values?.flatNumber || '',
                    point: (values?.point as IGeoPointInput) || null,
                    street: values?.street ? values.street : '',
                    houseNumber: values?.houseNumber || '',
                    zip: values?.zip || '',
                    city: values?.city || '',
                    country: values?.country || '',
                };
            dispatch(setJobReceiverData(receiver));
            dispatch(setCurrentStep(CreateJobWizardSteps.RECIPIENT_DATA));
        }
    };

    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: handleSaveData,
    });

    const isValid = () => {
        return (
            formik.values.addressLine &&
            formik.values.point &&
            formik.values.zip &&
            formik.values.street &&
            formik.values.houseNumber &&
            formik.values.city &&
            formik.values.country
        );
    };

    const isAutocompleteAddressValid = () => {
        return formik.values.zip && formik.values.street && formik.values.houseNumber && formik.values.city && formik.values.country;
    };

    const setAddressAndCoordsFromMapAction = (coords: [number, number], address: string, addressParts: IGoogleAddressParts) => {
        setSelectedLocation(null);
        formik.setValues({
            ...formik.values,
            locationId: null,
            ...addressParts,
            addressLine: address,
            point: {
                latitude: coords[0],
                longitude: coords[1],
            },
        });
    };
    const coordLat = receiverData?.point?.latitude;
    const coordLng = receiverData?.point?.longitude;
    let centerCoords: [number, number] = [52.237049, 21.017532];

    if (typeof coordLat === 'number' && typeof coordLng === 'number') {
        centerCoords = [coordLat, coordLng];
    }

    let receiverLocation: LatLngExpression | null = null;
    if (formik.values.point && formik.values.point.latitude && formik.values.point.longitude) {
        receiverLocation = [formik.values.point.latitude, formik.values.point.longitude];
    }

    const isFieldDirty = (fieldName: keyof ReceiverDataForm) => {
        return formik.values[fieldName] !== formik.initialValues[fieldName];
    };

    return (
        <Stack className="sender-data-container">
            <Grid container>
                <Grid item xs={12} md={6} className="sender-data-wrapper">
                    <Typography className="sender-data-description">
                        <Translation text="jobs.createJob.receiverData.description" />
                    </Typography>

                    <AddressAutocomplete
                        label={t('jobs.createJob.senderData.formControls.address')}
                        noOptionText={t('jobs.createJob.senderData.noAutocompleteResults')}
                        isAddressBookShown={true}
                        isError={!isAutocompleteAddressValid() && isFieldDirty('addressLine')}
                        onAddressBookAction={() => dispatch(openModal(MemberOperationType.NEW_ORDER_ADDRESS_BOOK))}
                        defaultValue={formik.values.addressLine}
                        onAddressClear={onAddressClear}
                        isRequired={true}
                        onChange={(addressLine, point, addressParts: IGoogleAddressParts) => {
                            formik.setValues({
                                ...formik.values,
                                ...addressParts,
                                addressLine: addressLine,
                                point: point,
                            });
                            setDefaultCoordinates(point);
                        }}
                    />

                    <FormikForm
                        fields={receiverDataFormConfig()}
                        formId="job-receiver-data"
                        initialValues={initialValues}
                        updatedValues={formik.values}
                        theme={theme}
                        customEventChange={(formControl: string, value: string) => {
                            formik.setFieldValue(formControl, value);
                        }}
                        onSubmit={(values) => {
                            handleSaveData(values);
                        }}
                    />
                    <Stack alignItems={'flex-start'}>
                        <Button
                            fullWidth={false}
                            className="btn-action"
                            disabled={!isValid()}
                            onClick={() => saveAddress()}
                            startIcon={<PlusSquareIcon />}>
                            <Typography variant="body1" color="primary.text">
                                <Translation text="jobs.createJob.senderData.saveAddress" />
                            </Typography>
                        </Button>
                    </Stack>
                </Grid>
                <Grid item xs={12} md={6} className="map-container">
                    <MapContainer center={centerCoords} zoom={10} maxZoom={18} minZoom={2} scrollWheelZoom={false}>
                        <CustomTileLayer />
                        <MapClickHandler onLocationChange={setAddressAndCoordsFromMapAction} />

                        {receiverLocation ? (
                            <Marker key={'toLocation'} icon={receiverMarkerIcon} position={receiverLocation}></Marker>
                        ) : null}
                        {senderData?.point ? (
                            <Marker
                                key={'fromLocation'}
                                icon={senderMarkerIcon}
                                position={[senderData.point.latitude, senderData.point.longitude]}></Marker>
                        ) : null}
                        <UpdateMapCenter
                            latitude={senderData?.point?.latitude}
                            longitude={senderData?.point?.longitude}
                            additionalPosition={
                                defaultCoordinates && defaultCoordinates.latitude && defaultCoordinates.longitude
                                    ? {
                                          latitude: defaultCoordinates.latitude,
                                          longitude: defaultCoordinates.longitude,
                                      }
                                    : undefined
                            }
                        />
                    </MapContainer>
                </Grid>
            </Grid>

            <Divider />
            <Grid className="navigation-buttons-container">
                <Button
                    key="prev-button"
                    onClick={() => {
                        dispatch(setCurrentStep(CreateJobWizardSteps.SENDER_DATA));
                    }}
                    className="btn-action"
                    startIcon={<ChevronLeftIcon />}>
                    <Translation text="jobs.createJob.generalData.prevButton" />
                </Button>
                <Button
                    key="next-button"
                    disabled={!isValid()}
                    type="submit"
                    variant="contained"
                    color="primary"
                    form="job-receiver-data"
                    className="navigation-button next-button"
                    endIcon={<ChevronRight />}>
                    <Translation text="jobs.createJob.generalData.nextButton" />
                </Button>
            </Grid>

            {isModalOpen && modalType === MemberOperationType.NEW_ORDER_ADDRESS_BOOK && (
                <LocationsModal activeTab={AddressBookType.ADDRESS_BOOK} onSelectLocation={handleLocationSelection} />
            )}
        </Stack>
    );
};

export default CreateJobReceiverData;
