import {PayloadAction} from '@reduxjs/toolkit';
import {createBaseReducerSlice, ILocationInput, IBaseReducerState, ILocationOutput} from 'palipali-panel-common-web';

export interface ILocationViewState extends IBaseReducerState {
    location: ILocationOutput | null;
    redirectToLocationList: boolean;
}

export interface ISetLocation {
    location: ILocationOutput | null;
}

export interface ISetRedirectToLocationList {
    redirectToLocationList: boolean;
}

export interface IGetLocation {
    locationId: string;
}

const initialState: ILocationViewState = {
    location: null,
    isLoading: false,
    isInitialized: false,
    error: null,
    redirectToLocationList: false,
};

const locationsViewSlice = createBaseReducerSlice({
    name: 'locationView',
    initialState: initialState,
    reducers: {
        setLocation: {
            reducer: (state: ILocationViewState, action: PayloadAction<ISetLocation>) => {
                return {
                    ...state,
                    location: action.payload.location,
                };
            },
            prepare(location: boolean) {
                return {
                    payload: {location: location},
                };
            },
        },
        getLocation: {
            reducer: (state: ILocationViewState) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
            prepare(locationId: string) {
                return {
                    payload: {locationId: locationId},
                };
            },
        },
        createLocation: {
            reducer: (state: ILocationViewState) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
            prepare(location: ILocationInput) {
                return {
                    payload: {location: location},
                };
            },
        },
        createTeamLocation: {
            reducer: (state: ILocationViewState) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
            prepare(location: ILocationInput) {
                return {
                    payload: {location: location},
                };
            },
        },
        createJobLocation: {
            reducer: (state: ILocationViewState) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
            prepare(location: ILocationInput) {
                return {
                    payload: {location: location},
                };
            },
        },
        updateLocation: {
            reducer: (state: ILocationViewState) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
            prepare(id: string, location: ILocationInput) {
                return {
                    payload: {id: id, location: location},
                };
            },
        },
        setRedirectToLocationList: {
            reducer: (state: ILocationViewState, action: PayloadAction<ISetRedirectToLocationList>) => {
                return {
                    ...state,
                    redirectToLocationList: action.payload.redirectToLocationList,
                };
            },
            prepare(redirectToLocationList: boolean) {
                return {
                    payload: {redirectToLocationList: redirectToLocationList},
                };
            },
        },

        deleteLocation: {
            reducer: (state: ILocationViewState) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
            prepare(locationId: string) {
                return {
                    payload: {locationId},
                };
            },
        },
        setLoading: (state: ILocationViewState, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setError: (state: ILocationViewState, action: PayloadAction<string | null>) => {
            state.error = action.payload;
        },
    },
});

export const {
    setLocation,
    setLoading,
    setError,
    setInitialized,
    getLocation,
    updateLocation,
    createLocation,
    createTeamLocation,
    createJobLocation,
    setRedirectToLocationList,
    deleteLocation,
} = locationsViewSlice.actions;
export default locationsViewSlice.reducer;
