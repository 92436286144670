import React, {useEffect} from 'react';
import {Route, Routes, useSearchParams} from 'react-router-dom';
import ActiveJobList from './ActiveJobList';
import ActiveJobView from './ActiveJobView';
import ActiveJobCreate from './ActiveJobCreate';
import {useDispatch, useSelector} from 'react-redux';
import {
    Loader,
    isTeamListingLoadingSelector,
    ITeamViewOutput,
    getTeamListing,
    activeValidTeamListingSelector,
    setTeamListing,
    isAuthTokenExpiredSelector,
} from 'palipali-panel-common-web';
import NoTeamsInfoView from './NoTeamsInfoView';
import {removeStripeQueryParams} from '../../utils/stripeUtils';
import {setCreateJobViewLoading} from '../../store/reducers/createJobViewSlice';
import {setActiveJobsViewInitialized} from 'src/store/reducers/jobListingSlice';

const ActiveJobs: React.FC = () => {
    const teams: ITeamViewOutput[] | null = useSelector(activeValidTeamListingSelector),
        dispatch = useDispatch(),
        isAuthTokenExpired: boolean = useSelector(isAuthTokenExpiredSelector),
        [searchParams, setSearchParams] = useSearchParams(),
        isLoading: boolean = useSelector(isTeamListingLoadingSelector);

    useEffect(() => {
        if (!isAuthTokenExpired) {
            dispatch(getTeamListing());
        }

        if (-1 !== window.location.search.indexOf('payment_intent_client_secret')) {
            dispatch(setCreateJobViewLoading(false));
            removeStripeQueryParams(searchParams, setSearchParams);
        }
        return () => {
            dispatch(setTeamListing([]));
            dispatch(setActiveJobsViewInitialized(false));
        };
    }, [isAuthTokenExpired]);

    const renderView = () => {
        if (isLoading || !teams) return <Loader showLoader={isLoading} />;
        if (!isLoading && !teams.length) return <NoTeamsInfoView />;

        return (
            <Routes>
                <Route path="/create" element={<ActiveJobCreate />} key="active-job-create" />
                <Route path="/:id" element={<ActiveJobView />} key="active-job-view" />
                <Route path="/" element={<ActiveJobList />} key="active-job-list" />
            </Routes>
        );
    };

    return renderView();
};

export default ActiveJobs;
