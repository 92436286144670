import {DateRange, FormikFieldConfig, FormikFieldTypes, IMultiselectOption, ValidationErrorType} from 'palipali-panel-common-web';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';

export const activeJobListFiltersFormConfig = (initialDate: DateRange, teamList: IMultiselectOption[]): FormikFieldConfig[] => {
    const {t} = useTranslation();

    return [
        {
            name: 'team.id',
            label: t('jobs.formControls.team'),
            className: 'job-listing-status-filter-select-team',
            validation: Yup.string().required(`${t(ValidationErrorType.IS_REQUIRED)}`),
            type: FormikFieldTypes.SELECT,
            options: teamList,
        },
        {
            name: 'createdFilter',
            label: t('jobs.formControls.date'),
            validation: Yup.date(),
            dateFormat: 'DD.MM.YYYY',
            startFromTomorrow: true,
            initialDates: initialDate,
            className: 'vehicle-inspection-form-until-form-control',
            type: FormikFieldTypes.DATERANGE,
        },
    ];
};
