import {Button, Grid, Stack, Typography, useTheme} from '@mui/material';
import {CustomModalComponent, Loader, Translation} from 'palipali-panel-common-web';
import React from 'react';
import alertTriangleIcon from '../../../../../assets/images/alert-triangle.svg';

interface IDeleteLocationProps {
    isLoading: boolean;
    deleteLocation: () => void;
}

const DeleteLocationModal: React.FC<IDeleteLocationProps> = ({isLoading, deleteLocation}) => {
    const theme = useTheme();
    return (
        <CustomModalComponent
            theme={theme}
            title={
                <Typography color="text.primary" variant="h6" component="h2">
                    <Translation text="locations.locationOperation.deleteLocation.modal.title" />
                </Typography>
            }
            content={
                <Grid sx={{width: '100%'}}>
                    <Stack spacing={1} sx={{alignItems: 'center'}}>
                        <img className="card-icon" src={alertTriangleIcon} alt="Alert icon" />
                        <Typography className="description">
                            <Translation text="locations.locationOperation.deleteLocation.modal.description" />
                        </Typography>
                        <Typography color="error">
                            <Translation text="locations.locationOperation.deleteLocation.modal.reminder" />
                        </Typography>
                    </Stack>
                    <Loader showLoader={isLoading} />
                </Grid>
            }
            footer={
                <Button
                    className="jobs-job-delete-modal-submit-button"
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => deleteLocation()}>
                    <Translation text={'locations.locationOperation.deleteLocation.deleteButton'} />
                </Button>
            }
        />
    );
};

export default DeleteLocationModal;
