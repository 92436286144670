import {PayloadAction} from '@reduxjs/toolkit';
import {
    createBaseReducerSlice,
    IBaseReducerState,
    IModelApiResponseViewObject,
    initApiCall,
    isSameValue,
    ILocationOutput,
    PAGINATION_ITEMS_PER_PAGE,
} from 'palipali-panel-common-web';

export interface ILocationListingState extends IBaseReducerState {
    locationList: ILocationOutput[] | null;
    pagination: IPaginationParams;
    locationFilters: ILocationFilters;
    metadata: IModelApiResponseViewObject | null;
}

export interface ILocationFilters {
    name: string | null;
    locationType: {
        id: string | null;
    };
    ['team.id']: string | null;
}

export interface IChangeLocationFilters {
    locationFilters: ILocationFilters;
    page?: number;
}

export interface ISetLocationListing {
    locationList: ILocationOutput[] | null;
}

export interface IPaginationParams {
    itemsPerPage: number;
    page: number;
}

export interface ISetMetadata {
    metadata: IModelApiResponseViewObject | null;
}

export interface IChangePagination {
    pagination: IPaginationParams;
}

const initialState: ILocationListingState = {
    locationList: null,
    pagination: {
        page: 1,
        itemsPerPage: PAGINATION_ITEMS_PER_PAGE,
    },
    locationFilters: {
        name: null,
        'team.id': null,
        locationType: {
            id: null,
        },
    },
    metadata: null,
    isLoading: false,
    isInitialized: false,
    error: null,
};

const LocationListingSlice = createBaseReducerSlice({
    name: 'locationListing',
    initialState: initialState,
    reducers: {
        applyLocationFilters: (state: ILocationListingState) => {
            return {
                ...state,
                isLoading: true,
            };
        },
        changeLocationFilters: {
            reducer: (state: ILocationListingState, action: PayloadAction<IChangeLocationFilters>) => {
                if (isSameValue(action.payload.locationFilters, state.locationFilters)) {
                    return {
                        ...state,
                        isLoading: true,
                        pagination: {
                            page: action.payload.page || state.pagination.page,
                            itemsPerPage: PAGINATION_ITEMS_PER_PAGE,
                        },
                    };
                }

                return {
                    ...state,
                    isLoading: true,
                    locationFilters: action.payload.locationFilters,
                    pagination: {
                        page: action.payload.page || 1,
                        itemsPerPage: PAGINATION_ITEMS_PER_PAGE,
                    },
                };
            },
            prepare: (locationFilters: ILocationFilters, page: number) => {
                return {
                    payload: {
                        locationFilters: locationFilters,
                        page,
                    },
                };
            },
        },
        setLocationListing: (state: ILocationListingState, action: PayloadAction<ISetLocationListing>) => {
            return {
                ...state,
                locationList: action.payload.locationList,
                isLoading: false,
            };
        },
        setMetadata: (state: ILocationListingState, action: PayloadAction<ISetMetadata>) => {
            return {
                ...state,
                metadata: action.payload.metadata,
            };
        },
        changeLocationListingPagination: (state: ILocationListingState, action: PayloadAction<IPaginationParams>) => {
            return {
                ...state,
                pagination: action.payload,
                isLoading: true,
            };
        },
        setLoading: (state: ILocationListingState, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setError: (state: ILocationListingState, action: PayloadAction<string | null>) => {
            state.error = action.payload;
        },
        getLocationListing: (state: ILocationListingState) => initApiCall(state),
    },
});

export const {
    setLocationListing,
    setLoading,
    setError,
    applyLocationFilters,
    changeLocationFilters,
    setInitialized,
    getLocationListing,
    changeLocationListingPagination,
    setMetadata,
} = LocationListingSlice.actions;
export default LocationListingSlice.reducer;
