import {Box, FormControlLabel, Switch, Typography} from '@mui/material';
import {IDayOpenHoursOutput} from 'palipali-panel-common-web';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

interface IDayControlProps {
    day: string;
    data: IDayOpenHoursOutput | null;
    changeIsAvailableDay: (isDisabled: boolean) => void;
    setIsDataPresent: (isDataPresent: boolean) => void;
}

const DayControl: React.FC<IDayControlProps> = ({day, data, changeIsAvailableDay, setIsDataPresent}) => {
    const {t} = useTranslation(),
        [isDayAvailable, setIsDayAvailable] = useState(true);

    useEffect(() => {
        setIsDayAvailable(data !== null && isDayAvailable);
        changeIsAvailableDay(data !== null && isDayAvailable);
    }, [day]);

    const checkIsDataPresent = (value: boolean) => {
        const dayValue = data && data[day as keyof IDayOpenHoursOutput];

        if (!value) {
            return true;
        }

        if (value && dayValue != null && dayValue !== undefined) {
            return true;
        }

        return false;
    };

    return (
        <Box>
            <FormControlLabel
                className={'day-label'}
                control={
                    <Switch
                        checked={isDayAvailable}
                        onChange={(event, value) => {
                            changeIsAvailableDay(value);
                            setIsDayAvailable(value);
                            setIsDataPresent(checkIsDataPresent(value));
                        }}
                    />
                }
                label={
                    <Typography variant="inherit" color="text.primary" className="button-small-font-variant">
                        {t(`locations.locationOperation.openingHours.weekdays.${day}`)}
                    </Typography>
                }
            />
        </Box>
    );
};

export default DayControl;
