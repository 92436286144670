import {Button, Typography, Stack} from '@mui/material';
import {ILocationOutput, Translation, LocationsIcon02} from 'palipali-panel-common-web';
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import LocationListCard from './LocationListCard';
import {useDispatch} from 'react-redux';
import {setIsOutsideNavigation} from '../../../store/reducers/createJobViewSlice';

interface ILocationListProps {
    locations: ILocationOutput[] | undefined;
}

const LocationList: React.FC<ILocationListProps> = ({locations}) => {
    const navigate = useNavigate(),
        dispatch = useDispatch(),
        [selectedTeamId, setSelectedTeamId] = useState<string | null>(null);

    const handleCreateLocation = () => {
        dispatch(setIsOutsideNavigation(false));
        navigate(`create`, {state: {teamId: selectedTeamId}});
    };

    return (
        <Stack>
            <div className="header">
                <div className="title">
                    <Typography variant="h4" component="h2" color="text.primary">
                        <Translation text="locations.title" />
                    </Typography>
                </div>
                <Button
                    onClick={handleCreateLocation}
                    variant="contained"
                    color="primary"
                    size="medium"
                    disabled={!selectedTeamId}
                    startIcon={<LocationsIcon02 />}>
                    <Translation text="locations.locationList.createLocation" />
                </Button>
            </div>
            <LocationListCard onTeamChange={(teamId: string | null) => setSelectedTeamId(teamId)} locations={locations} />
        </Stack>
    );
};

export default LocationList;
