import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';
import {ITeamMemberOutput} from 'palipali-panel-common-web';
import {ICreateJobViewState, IGeneralData} from '../reducers/createJobViewSlice';

export const selectCreateJobViewSlice = (state: RootState) => {
    return state.createJobView;
};

export const createJobGeneralDataSelector = createSelector([selectCreateJobViewSlice], (state: ICreateJobViewState) => state.generalData);
export const createJobSenderDataSelector = createSelector([selectCreateJobViewSlice], (state: ICreateJobViewState) => state.senderData);
export const createJobReceiverDataSelector = createSelector([selectCreateJobViewSlice], (state: ICreateJobViewState) => state.receiverData);
export const createJobRecipientDataSelector = createSelector(
    [selectCreateJobViewSlice],
    (state: ICreateJobViewState) => state.recipientData
);
export const createJobAdditionalDataSelector = createSelector(
    [selectCreateJobViewSlice],
    (state: ICreateJobViewState) => state.additionalData
);
export const isCreateJobViewLoadingSelector = createSelector([selectCreateJobViewSlice], (state: ICreateJobViewState) => state.isLoading);
export const createJobViewErrorSelector = createSelector([selectCreateJobViewSlice], (state: ICreateJobViewState) => state.error);
export const showBoxSizesModalSelector = createSelector(
    [selectCreateJobViewSlice],
    (state: ICreateJobViewState) => state.showBoxSizesModal
);
export const createJobLocationsSelector = createSelector([selectCreateJobViewSlice], (state: ICreateJobViewState) => state.locations);
export const createJobLocationsTotalResultSelector = createSelector(
    [selectCreateJobViewSlice],
    (state: ICreateJobViewState) => state.locationsMetadata?.totalResults
);
export const createJobLocationsPageSelector = createSelector(
    [selectCreateJobViewSlice],
    (state: ICreateJobViewState) => state.locationsPagination?.page
);

export const createJobLocationFiltersSelector = createSelector(
    [selectCreateJobViewSlice],
    (state: ICreateJobViewState) => state.locationsFilters
);
export const createJobLocationPaginationSelector = createSelector(
    [selectCreateJobViewSlice],
    (state: ICreateJobViewState) => state.locationsPagination
);
export const createJobLocationMetadataSelector = createSelector(
    [selectCreateJobViewSlice],
    (state: ICreateJobViewState) => state.locationsMetadata
);

export const createJobRecipientsListSelector = createSelector(
    [selectCreateJobViewSlice],
    (state: ICreateJobViewState) => state.recipientsList
);

export const isLocationsLoadingSelector = createSelector(
    [selectCreateJobViewSlice],
    (state: ICreateJobViewState) => state.isLocationsLoading
);

export const isDimensionsModalLoadingSelector = createSelector(
    [selectCreateJobViewSlice],
    (state: ICreateJobViewState) => state.isDimensionsModalLoading
);

export const createJobRecipientByIdSelector = createSelector([createJobRecipientsListSelector, (state, id) => id], (teamMembers, id) => {
    return teamMembers?.find((teamMember: ITeamMemberOutput) => teamMember.id === id);
});

export const createJobPackageDimensions = createSelector(
    [selectCreateJobViewSlice],
    (state: ICreateJobViewState) => state.packageDimensions
);

export const createdJobSelector = createSelector([selectCreateJobViewSlice], (state: ICreateJobViewState) => state.createdOrder);

export const shouldRecalculateJobDetailsSelector = createSelector(
    [selectCreateJobViewSlice],
    (state: ICreateJobViewState) => state.shouldRecalculateJobDetails
);

export const currentWizardStepSelector = createSelector([selectCreateJobViewSlice], (state: ICreateJobViewState) => state.currentStep);

export const insuranceParametersSelector = createSelector([selectCreateJobViewSlice], (state: ICreateJobViewState) => state.jobParameters);

export const isImmediateOrderSelector =
    createSelector([createJobGeneralDataSelector], (state: IGeneralData) => state?.isImmediateOrder) ?? true;

export const isProcessAtSelector = createSelector([createJobGeneralDataSelector], (state: IGeneralData) => state?.processAt ?? null);

export const additionalPaymentAuthorizationClientSecretSelector = createSelector(
    [selectCreateJobViewSlice],
    (state: ICreateJobViewState) => state.clientSecret
);

export const isNavigationOutsideViewSelector = createSelector(
    [selectCreateJobViewSlice],
    (state: ICreateJobViewState) => state.isNavigationOutsideView
);
