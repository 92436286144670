import {Button, Unstable_Grid2 as Grid, Typography, useTheme} from '@mui/material';
import {useFormik} from 'formik';
import {
    CustomModalComponent,
    FormikForm,
    IJobRecipientInput,
    IJobRecipientOutput,
    IMultiselectOption,
    IPhoneInput,
    IPurchaserCurrentJobDetailsOutput,
    ITeamMemberBasicOutput,
    IUpdateJobInput,
    Translation,
    closeModal,
    formatPhoneNumber,
    isNullOrUndefined,
} from 'palipali-panel-common-web';
import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {setJobTeamMembersFilter, updateJob} from '../../../../store/reducers/jobSlice';
import {jobTeamMembersSelector} from '../../../../store/selectors/jobSelector';
import recipientDataFormConfig from './recipmentDataFormConfig';

interface IActiveJobEditModalProps {
    job: IPurchaserCurrentJobDetailsOutput;
}

export interface IEditOrderFormValues {
    editRecipientData: boolean;
    teamMemberId: string | null;
    recipientId: string | null;
    firstName: string | null;
    lastName: string | null;
    country: string | null;
    phone: string | null;
    orderNumber: string | null;
    description: string | null;
}

const ActiveJobEditModal: React.FC<IActiveJobEditModalProps> = ({job}) => {
    const getFormattedPhoneFromRecipient = (
        firstName?: string | null,
        lastName?: string | null,
        phoneNumber?: IPhoneInput | null
    ): string => {
        const name = `${firstName || ''} ${lastName || ''} `,
            phone = phoneNumber ? `(${formatPhoneNumber(phoneNumber)})` : '';
        return name + phone;
    };
    const transformRecipientToSelectValues = (recipient: IJobRecipientOutput): IMultiselectOption[] => {
        const details = getFormattedPhoneFromRecipient(recipient.firstName, recipient.lastName, recipient.phone);
        if (!recipient.teamMemberId && !recipient.id) {
            return [];
        }
        return [
            {
                value: recipient.teamMemberId ? recipient.teamMemberId : (recipient.id as string),
                label: details,
            },
        ];
    };
    const recipientDataPresent = job.recipient && isNullOrUndefined(job.recipient.teamMemberId),
        [isValid, setIsValid] = useState<boolean>(false),
        [recipientsArray, setRecipientsArray] = useState<IMultiselectOption[]>(transformRecipientToSelectValues(job.recipient)),
        initialValues: IEditOrderFormValues = {
            editRecipientData: recipientDataPresent || false,
            recipientId: job.recipient ? job.recipient.id : null,
            teamMemberId: job.recipient?.teamMemberId || '',
            firstName: job.recipient?.teamMemberId ? '' : job.recipient?.firstName ?? '',
            lastName: job.recipient?.teamMemberId ? '' : job.recipient?.lastName ?? '',
            country: job.recipient?.teamMemberId ? '+48' : job.recipient?.phone?.country ?? '',
            phone: job.recipient?.teamMemberId ? '' : job.recipient?.phone?.phone ?? '',
            orderNumber: job.orderNumber ? job.orderNumber : '',
            description: job.description ? job.description : '',
        },
        dispatch = useDispatch();
    const teamMembers = useSelector(jobTeamMembersSelector);

    const handleSubmit = (values: IEditOrderFormValues) => {
        let recipientData: IJobRecipientInput = {
            teamMemberId: null,
            firstName: null,
            lastName: null,
            phone: null,
        };
        if (values.editRecipientData) {
            recipientData = {
                ...recipientData,
                firstName: values.firstName,
                lastName: values.lastName,
                phone: {
                    country: values.country ? values.country : '',
                    phone: values.phone ? values.phone : '',
                },
            };
        } else {
            recipientData = {
                ...recipientData,
                teamMemberId: values.teamMemberId ? values.teamMemberId : '',
            };
        }
        const updateOrderPayload: IUpdateJobInput = {
            recipient: recipientData,
            orderNumber: values.orderNumber,
            description: values.description,
        };
        dispatch(updateJob(job.id, updateOrderPayload));
        dispatch(closeModal());
    };

    useEffect(() => {
        if (teamMembers) {
            const teamMembersOptions = teamMembers.map((teamMember: ITeamMemberBasicOutput) => {
                const details = getFormattedPhoneFromRecipient(
                    teamMember.details.firstName,
                    teamMember.details.lastName,
                    teamMember.details.phone
                );
                return {
                    value: teamMember.id,
                    label: details,
                };
            });
            setRecipientsArray(teamMembersOptions);
        }
    }, [teamMembers]);

    const theme = useTheme();
    const teamId = job.team.id;

    useEffect(() => {
        if (teamId) {
            dispatch(setJobTeamMembersFilter(teamId));
        }
    }, [teamId]);

    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: handleSubmit,
    });

    const prevCountryRef = useRef(formik.values.country);

    useEffect(() => {
        if (formik.values.country !== prevCountryRef.current) {
            formik.setFieldTouched('phone', true, false);
            formik.validateField('phone');
            prevCountryRef.current = formik.values.country;
        }
    }, [formik.values.country]);

    return (
        <CustomModalComponent
            theme={theme}
            title={
                <Typography color="text.primary" variant="h6" component="h2">
                    <Translation text={`jobs.editOrder.title`} />
                </Typography>
            }
            content={
                <Grid sx={{width: '100%'}}>
                    <FormikForm
                        fields={recipientDataFormConfig(formik.values.editRecipientData, recipientsArray)}
                        formId="update-order-form"
                        initialValues={initialValues}
                        updatedValues={formik.values}
                        submitAllowed={setIsValid}
                        customEventChange={(formControlName, value) => {
                            formik.setFieldValue(formControlName, value);
                        }}
                        theme={theme}
                        onSubmit={handleSubmit}
                    />
                </Grid>
            }
            footer={
                <Button type="submit" disabled={!isValid} fullWidth form="update-order-form" variant="contained" color="primary">
                    <Translation text={`jobs.editOrder.buttons.update`} />
                </Button>
            }
        />
    );
};

export default ActiveJobEditModal;
