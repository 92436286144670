import {
    AlertType,
    ITeamMemberOutput,
    StateSuccessActionsFunction,
    addAlert,
    closeModal,
    createActionPayloadEpic,
    createInviteTeamMemberAPI,
    deleteTeamMemberAPI,
    getTeamListing,
    handleApiError,
    updateTeamMemberAPI,
} from 'palipali-panel-common-web';
import {combineEpics} from 'redux-observable';
import {RootState} from '../reducers';
import {deleteTeamMember, inviteTeamMember, setError, setLoading, setTeamMember, updateTeamMember} from '../reducers/teamMemberSlice';
import {getTeam} from '../reducers/teamViewSlice';
import {currentTeamIdSelector} from '../selectors/teamViewSelectors';

const basicOperationSuccessActions = (successMessage: string) => [
    setLoading(false),
    addAlert({message: successMessage, type: AlertType.SUCCESS}),
];

const teamMemberUpdateSuccessActions: StateSuccessActionsFunction<ITeamMemberOutput> = (
    updatedMember: ITeamMemberOutput,
    state: RootState
) => {
    const currentTeamId = currentTeamIdSelector(state);
    return [
        setTeamMember({member: updatedMember}),
        getTeam(currentTeamId),
        closeModal(),
        ...basicOperationSuccessActions('teams.teamMemberEdit.alerts.update.success'),
    ];
};

const teamMemberInviteSuccessActions: StateSuccessActionsFunction<ITeamMemberOutput> = (data: ITeamMemberOutput, state: RootState) => {
    const currentTeamId = currentTeamIdSelector(state);
    if (data.isRejectedInvitation === true) {
        return [
            closeModal(),
            getTeam(currentTeamId),
            setLoading(false),
            addAlert({message: 'teams.teamMemberEdit.alerts.invite.invitationAlreadyRejected', type: AlertType.ERROR}),
        ];
    }
    return [closeModal(), getTeam(currentTeamId), ...basicOperationSuccessActions('teams.teamMemberEdit.alerts.invite.success')];
};

const teamMemberDeleteSuccessActions: StateSuccessActionsFunction<ITeamMemberOutput> = (data: ITeamMemberOutput, state: RootState) => {
    const currentTeamId = currentTeamIdSelector(state);
    return [
        closeModal(),
        getTeam(currentTeamId),
        getTeamListing(),
        ...basicOperationSuccessActions('teams.teamMemberEdit.alerts.delete.success'),
    ];
};

const errorActions = (error: any): any[] => {
    const errorObj = handleApiError(error);
    errorObj.type = AlertType.WARNING;
    return [setLoading(false), setError(errorObj.message), addAlert(errorObj)];
};

const teamMemberUpdateEpic = createActionPayloadEpic<ITeamMemberOutput>(
    updateTeamMemberAPI,
    teamMemberUpdateSuccessActions,
    errorActions,
    updateTeamMember().type
);

const teamMemberInviteEpic = createActionPayloadEpic<ITeamMemberOutput>(
    createInviteTeamMemberAPI,
    teamMemberInviteSuccessActions,
    errorActions,
    inviteTeamMember().type
);

const deleteTeamMemberEpic = createActionPayloadEpic<ITeamMemberOutput>(
    deleteTeamMemberAPI,
    teamMemberDeleteSuccessActions,
    errorActions,
    deleteTeamMember().type
);

const teamMemberEpic = combineEpics(teamMemberUpdateEpic, teamMemberInviteEpic, deleteTeamMemberEpic);

export default teamMemberEpic;
