import jwtDecode from 'jwt-decode';
import {IAuthToken, IMultiselectOption, UserRole} from 'palipali-panel-common-web';

export const setRoleFilters = (role: UserRole | null, roles: IMultiselectOption[]) => {
    const adaptingRoles = roles.filter((item: IMultiselectOption) => {
        if (role === UserRole.PURCHASER_TEAM_MANAGER && item.value === UserRole.PURCHASER_TEAM_EMPLOYEE) {
            return true;
        }
        if (
            role === UserRole.PURCHASER_TEAM_OWNER &&
            (item.value === UserRole.PURCHASER_TEAM_EMPLOYEE || item.value === UserRole.PURCHASER_TEAM_MANAGER)
        ) {
            return true;
        }
        return false;
    });

    const adaptedOptions = adaptingRoles.map((item) => ({
        value: item.value,
        label: item.label,
    }));

    return adaptedOptions;
};

export const authTokenTeamRoles = (authToken: string | null): {[key: string]: UserRole} | null => {
    if (!authToken) {
        return null;
    }
    const decodedToken = jwtDecode<IAuthToken>(authToken);
    return decodedToken?.teams ? decodedToken.teams : null;
};
export const getTeamAccessRole = (authToken?: string | null, teamId?: string | null): UserRole | null => {
    if (!authToken || !teamId) {
        return null;
    }

    const accountTeams = authTokenTeamRoles(authToken),
        role = accountTeams?.[teamId];
    return role ? role : null;
};
