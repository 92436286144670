import {Button, Grid, useTheme} from '@mui/material';
import {
    DateRange,
    FormikForm,
    IMultiselectOption,
    ITeamListingOutput,
    JobStatus,
    Translation,
    deepCloneObject,
    serverFormattedDateWithCustomTime,
    teamListingSelector,
    teamsAnyTeamRoleSelector,
} from 'palipali-panel-common-web';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {IJobFilters, changeHistoricalJobsFilters, setHistoricalJobsViewInitialized} from '../../../../store/reducers/jobListingSlice';
import {historicalJobListingInitializedSelector, jobHistoricalListingFiltersSelector} from '../../../../store/selectors/jobListingSelector';
import {historicalJobListFiltersFormConfig} from './historicalJobListFiltersFormConfig';

const HistoricalJobListFilters: React.FC = () => {
    const theme = useTheme(),
        dispatch = useDispatch(),
        jobFilters: IJobFilters = useSelector(jobHistoricalListingFiltersSelector),
        unfilteredTeams: ITeamListingOutput[] = useSelector(teamListingSelector),
        teamsConnectedToUser: string[] = useSelector(teamsAnyTeamRoleSelector),
        isInitialized = useSelector(historicalJobListingInitializedSelector),
        teams = unfilteredTeams ? unfilteredTeams.filter((team) => teamsConnectedToUser.includes(team.id)) : [];

    const teamListSelect = () => {
        const setOptions: IMultiselectOption[] = teams?.map((team: ITeamListingOutput) => ({
            value: team.id,
            label: team.name,
        }));

        return setOptions;
    };

    const [listSelect, setList] = useState<IMultiselectOption[]>([]),
        allTeams = teams?.map((team) => team.id),
        initialValues: IJobFilters = {
            deliveryStatus: jobFilters.deliveryStatus ? jobFilters.deliveryStatus : [],
            'team.id': jobFilters?.['team.id'] ? jobFilters['team.id'] : allTeams[0],
            'createdAt[after]': jobFilters['createdAt[after]'],
            'createdAt[before]': jobFilters['createdAt[before]'],
        };

    useEffect(() => {
        if (teams && teams.length > 0) {
            setList(teamListSelect());
            if (!isInitialized) {
                dispatch(setHistoricalJobsViewInitialized(true));
                const initialValuesCopy = deepCloneObject(initialValues);
                dispatch(changeHistoricalJobsFilters(initialValuesCopy));
            }
        }
    }, [isInitialized, teams]);

    const changeDeliveryStatusFilter = (status: JobStatus[]) => {
        const newFilters: IJobFilters = deepCloneObject(jobFilters);
        newFilters.deliveryStatus = status;
        dispatch(changeHistoricalJobsFilters(newFilters));
    };
    const changeTeamFilter = (team: string) => {
        const newFilters: IJobFilters = deepCloneObject(jobFilters);
        newFilters['team.id'] = team;
        dispatch(changeHistoricalJobsFilters(newFilters));
    };

    const handleFormChange = (formControlName: string, value: string | DateRange) => {
        if (formControlName === `team.id` && value !== '') {
            const selectedValue = value as string;
            changeTeamFilter(selectedValue);
        } else if (formControlName === 'createdFilter') {
            const dateRange: DateRange = value as DateRange;
            if (dateRange.startDate && dateRange.endDate) {
                const formattedStartDate = serverFormattedDateWithCustomTime(dateRange.startDate, 'startOfDay'),
                    formattedEndDate = serverFormattedDateWithCustomTime(dateRange.endDate, 'endOfDay'),
                    newFilters = deepCloneObject(jobFilters);
                (newFilters['createdAt[after]'] = formattedStartDate), (newFilters['createdAt[before]'] = formattedEndDate);
                dispatch(changeHistoricalJobsFilters(newFilters));
            }
        }
    };

    const initialDates: DateRange = {
        startDate: new Date(jobFilters['createdAt[after]']),
        endDate: new Date(jobFilters['createdAt[before]']),
    };
    return (
        <Grid className="filters-wrapper">
            <Grid className="tab-filters">
                <Button
                    variant="outlined"
                    className={`tab-filter ${
                        jobFilters.deliveryStatus && jobFilters.deliveryStatus?.length > 0 ? null : 'active'
                    } job-listing-status-filter-button-all`}
                    onClick={() => changeDeliveryStatusFilter([])}>
                    <Translation text="jobs.formControls.status.all" />
                </Button>
                <Button
                    variant="outlined"
                    className={`tab-filter ${
                        jobFilters.deliveryStatus &&
                        (jobFilters.deliveryStatus.includes(JobStatus.PROBLEM_C) ||
                            jobFilters.deliveryStatus.includes(JobStatus.PROBLEM_P) ||
                            jobFilters.deliveryStatus.includes(JobStatus.SEARCHING))
                            ? 'active'
                            : null
                    }
                        job-listing-status-filter-button-problem`}
                    onClick={() => changeDeliveryStatusFilter([JobStatus.PROBLEM_C, JobStatus.PROBLEM_P])}>
                    <Translation text="jobs.formControls.status.problem_p" />
                </Button>
                <Button
                    variant="outlined"
                    className={`tab-filter ${
                        jobFilters.deliveryStatus &&
                        (jobFilters.deliveryStatus.includes(JobStatus.COMPLETED) ||
                            jobFilters.deliveryStatus.includes(JobStatus.DELIVERY_DRIVE) ||
                            jobFilters.deliveryStatus.includes(JobStatus.PICKUP) ||
                            jobFilters.deliveryStatus.includes(JobStatus.PICKUP_DRIVE) ||
                            jobFilters.deliveryStatus.includes(JobStatus.DELIVERY))
                            ? 'active'
                            : null
                    }
                        job-listing-status-filter-button-delivered`}
                    onClick={() => changeDeliveryStatusFilter([JobStatus.COMPLETED])}>
                    <Translation text="jobs.formControls.status.delivered" />
                </Button>
                <Button
                    variant="outlined"
                    className={`tab-filter ${
                        jobFilters.deliveryStatus &&
                        (jobFilters.deliveryStatus.includes(JobStatus.CANCELLED_P) ||
                            jobFilters.deliveryStatus.includes(JobStatus.CANCELLED_C) ||
                            jobFilters.deliveryStatus.includes(JobStatus.PLANNED))
                            ? 'active'
                            : null
                    }
                        job-listing-status-filter-button-cancelled`}
                    onClick={() => changeDeliveryStatusFilter([JobStatus.CANCELLED_P, JobStatus.CANCELLED_C])}>
                    <Translation text="jobs.formControls.status.cancelled_p" />
                </Button>
            </Grid>
            <Grid className="input-filters">
                <FormikForm
                    fields={historicalJobListFiltersFormConfig(initialDates, listSelect)}
                    formId="historical-job-list-filters"
                    formClassName="historical-job-list-filters-form horizontal-form"
                    initialValues={initialValues}
                    enableReinitialize={true}
                    customEventChange={handleFormChange}
                    theme={theme}
                    onSubmit={() => null}
                />
            </Grid>
        </Grid>
    );
};

export default HistoricalJobListFilters;
