import {Box, Typography} from '@mui/material';
import {IPackageSizesOutput, PackageTypes, Translation, packageParametersSelector, DimensionSpacerIcon} from 'palipali-panel-common-web';
import React from 'react';
import {useSelector} from 'react-redux';

interface IBoxSizeDimensionDetailsProps {
    type: keyof IPackageSizesOutput;
    selected?: boolean;
}

const BoxSizeDimensionDetails: React.FC<IBoxSizeDimensionDetailsProps> = ({type}) => {
    const packageParameters: PackageTypes = useSelector(packageParametersSelector);
    const packageParameter = () => {
        switch (type) {
            case 's':
                return packageParameters.s;
            case 'm':
                return packageParameters.m;
            case 'l':
                return packageParameters.l;
            default:
                return packageParameters.s;
        }
    };

    return (
        <Box className={`box-size-details box-size-details-${type}`}>
            <Typography variant="inherit" className="helper-text-font-variant">
                <Translation text={'general.boxMeasurements.max'} />
            </Typography>
            <Typography variant="inherit" className="helper-text-font-variant sizes-details-wrapper">
                {packageParameter().a}
                <DimensionSpacerIcon />
                {packageParameter().b}
                <DimensionSpacerIcon />
                {packageParameter().c}
                <Translation text={'general.boxMeasurements.unit'} />
            </Typography>
            <Typography variant="inherit" className="helper-text-font-variant">
                <Translation text={`general.boxMeasurements.weight`} config={{weight: packageParameter().weight}} />
            </Typography>
        </Box>
    );
};

export default BoxSizeDimensionDetails;
