import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';
import {ITeamMemberState} from '../reducers/teamMemberSlice';

export const selectTeamMemberSlice = (state: RootState) => {
    return state.teamMember;
};

export const teamMemberSelector = createSelector([selectTeamMemberSlice], (state: ITeamMemberState) => state.member);
export const isTeamMemberLoadingSelector = createSelector([selectTeamMemberSlice], (state: ITeamMemberState) => state.isLoading);
export const isRedirectToTeamListSelector = createSelector([selectTeamMemberSlice], (state: ITeamMemberState) => state.redirectToTeamList);
