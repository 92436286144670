import {useMap} from 'react-leaflet';
import React, {useEffect} from 'react';
import L from 'leaflet';
import {isNullOrUndefined} from 'palipali-panel-common-web';

interface ICreateJobSenderDataProps {
    latitude: number | undefined;
    longitude: number | undefined;
    additionalPosition?: {
        latitude: number;
        longitude: number;
    };
    onCoordinatesSelected?: (latitude: number, longitude: number) => void;
}

const UpdateMapCenter: React.FC<ICreateJobSenderDataProps> = ({latitude, longitude, additionalPosition, onCoordinatesSelected}) => {
    const map = useMap();
    useEffect(() => {
        if (!map || isNullOrUndefined(latitude) || isNullOrUndefined(longitude)) return;
        if (latitude && longitude) {
            const latLng = L.latLng(latitude, longitude);
            if (additionalPosition) {
                const position = L.latLng(latitude, longitude),
                    additionalPositionCoordinates = L.latLng(additionalPosition.latitude, additionalPosition.longitude),
                    bounds = L.latLngBounds(position, additionalPositionCoordinates);
                map.fitBounds(bounds, {padding: [45, 45]});
                return;
            } else {
                map.panTo(latLng);
                return;
            }
        }
    }, [latitude, longitude, additionalPosition, map]);

    useEffect(() => {
        if (!map) return;
        if (onCoordinatesSelected) {
            const onClick = (e: L.LeafletMouseEvent) => {
                const coord = e.latlng;
                onCoordinatesSelected(coord.lat, coord.lng);
            };

            map.on('click', onClick);
            return () => {
                map.off('click', onClick);
            };
        }
    }, [map, onCoordinatesSelected]);

    return null;
};

export default UpdateMapCenter;
