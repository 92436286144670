import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';
import {ILocationViewState} from '../reducers/locationViewSlice';

export const selectLocationViewSlice = (state: RootState) => {
    return state.locationView;
};

export const locationSelector = createSelector([selectLocationViewSlice], (state: ILocationViewState) => state.location);

export const isLocationLoadingSelector = createSelector([selectLocationViewSlice], (state: ILocationViewState) => state.isLoading);
export const isRedirectToLocationListSelector = createSelector(
    [selectLocationViewSlice],
    (state: ILocationViewState) => state.redirectToLocationList
);
