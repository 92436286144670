import {Translation, ChevronLeftIcon} from 'palipali-panel-common-web';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Button, Stack, Typography} from '@mui/material';
import {CreateJobWizard} from './CreateJobWizard';

interface IActiveJobCreateProps {}

const ActiveJobCreate: React.FC<IActiveJobCreateProps> = () => {
    const navigate = useNavigate();

    return (
        <Stack className="content-view job-create-view">
            <div className="btn-back-wrapper">
                <Button onClick={() => navigate('/panel/active-orders/')} className="btn-action" startIcon={<ChevronLeftIcon />}>
                    <Translation text="jobs.orderDetails.buttons.return" />
                </Button>
            </div>
            <div className="header">
                <div className="title">
                    <Typography variant="h4" component="h2" color="text.primary">
                        <Translation text="jobs.createJob.title" />
                    </Typography>
                </div>
            </div>

            <CreateJobWizard />
        </Stack>
    );
};

export default ActiveJobCreate;
