import {useTheme} from '@emotion/react';
import {Button, Stack} from '@mui/material';
import {useFormik} from 'formik';
import {
    APP_THEME_DARK,
    APP_THEME_NORMAL,
    IAccountMeOutput,
    FormikForm,
    HorizontalCard,
    Translation,
    accountIsLoadingSelector,
    accountSelector,
    accountThemeSelector,
    IChangeWebSettings,
    changeWebSettings,
    localeList,
    ValidationErrorType,
} from 'palipali-panel-common-web';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import * as Yup from 'yup';
import settingsFormConfig from './settingsFormConfig';

const SettingsCard: React.FC = () => {
    const {t} = useTranslation();
    const validationSchema = Yup.object().shape({
        locale: Yup.string().required(`${t(ValidationErrorType.IS_REQUIRED)}`),
    });

    const isLoading: boolean = useSelector(accountIsLoadingSelector),
        accountInfo: IAccountMeOutput = useSelector(accountSelector),
        themeValue: string = useSelector(accountThemeSelector),
        currentLocale: string = accountInfo?.account?.locale,
        isDarkTheme = themeValue === APP_THEME_DARK,
        initialValues: SettingsCardFormValues = {
            locale: currentLocale,
            isDarkTheme: isDarkTheme,
        },
        dispatch = useDispatch(),
        [isAllowed, setIsAllowed] = useState<boolean>(false),
        theme = useTheme();

    useEffect(() => {
        setIsAllowed(false);
    }, [accountInfo, themeValue]);

    type SettingsCardFormValues = {
        locale: string;
        isDarkTheme: boolean;
    };

    const handleChange = (formControl: string, value: string) => {
        formik.setValues({
            ...formik.values,
            [formControl]: value,
        });
        setIsAllowed(true);
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values: SettingsCardFormValues) => {
            const webSettingsPayload: IChangeWebSettings = {
                misc: {theme: values.isDarkTheme ? APP_THEME_DARK : APP_THEME_NORMAL},
                locale: values.locale,
            };
            dispatch(changeWebSettings(webSettingsPayload));
        },
    });

    return (
        <HorizontalCard
            heading={<Translation text="account.settings.title" />}
            content={
                <Stack spacing={5}>
                    <FormikForm
                        fields={settingsFormConfig(localeList)}
                        formId="misc-settings-form"
                        initialValues={initialValues}
                        updatedValues={formik.values}
                        customEventChange={handleChange}
                        onSubmit={formik.handleSubmit}
                        theme={theme}
                    />
                </Stack>
            }
            actions={
                <Button
                    type="submit"
                    variant="text"
                    color="primary"
                    form="misc-settings-form"
                    size="medium"
                    className="btn-action account-settings-settings-form-save-button"
                    disabled={!isAllowed}>
                    <Translation text="buttons.update" />
                </Button>
            }
            showLoader={isLoading}
        />
    );
};

export default SettingsCard;
