import {
    AlertType,
    IModelApiResponseViewObject,
    IRawRestQueryParams,
    ListSuccessActionsFunction,
    ILocationOutput,
    addAlert,
    createFetchListEpic,
    flattenObj,
    getLocationListAPI,
    handleApiError,
} from 'palipali-panel-common-web';
import {combineEpics} from 'redux-observable';
import {
    changeLocationFilters,
    changeLocationListingPagination,
    getLocationListing,
    setError,
    setLoading,
    setLocationListing,
    setMetadata,
} from '../reducers/locationListingSlice';
import {RootState} from '../reducers';
import {locationFiltersSelector, locationListingPaginationSelector} from '../selectors/locationListingSelectors';

const locationListingSuccessActions: ListSuccessActionsFunction<ILocationOutput> = (
    locationsArray: ILocationOutput[],
    metadata: IModelApiResponseViewObject | null
) => {
    return [setLocationListing({locationList: locationsArray}), setMetadata({metadata: metadata})];
};

const errorActions = (error: any): any[] => {
    const errorObj = handleApiError(error);
    errorObj.type = AlertType.WARNING;
    return [setLoading(false), setError(errorObj.message), addAlert(errorObj)];
};

const errorActionsWithoutAlert = (error: any): any[] => {
    return [setLoading(false), setError(error.message)];
};

const getParameters = (state: RootState): IRawRestQueryParams => {
    const pagination = locationListingPaginationSelector(state),
        filters = locationFiltersSelector(state);

    const params = {
        ...pagination,
        ...filters,
    };
    if (params) {
        const parametersFlattened = flattenObj(params);
        return parametersFlattened;
    }
    return [];
};

const fetchLocationListEpic = createFetchListEpic<ILocationOutput>(
    getLocationListAPI,
    locationListingSuccessActions,
    errorActions,
    getLocationListing().type,
    (state: RootState) => getParameters(state)
);

const changePaginationEpic = createFetchListEpic<ILocationOutput>(
    getLocationListAPI,
    locationListingSuccessActions,
    errorActions,
    changeLocationListingPagination().type,
    (state: RootState) => getParameters(state)
);

const changeLocationFiltersEpic = createFetchListEpic<ILocationOutput>(
    getLocationListAPI,
    locationListingSuccessActions,
    errorActionsWithoutAlert,
    changeLocationFilters().type,
    (state: RootState) => getParameters(state)
);

const locationListingEpic = combineEpics(fetchLocationListEpic, changePaginationEpic, changeLocationFiltersEpic);

export default locationListingEpic;
