import {Box, Badge, Button, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, Typography} from '@mui/material';
import {
    BadgeType,
    ITranslationName,
    LanguageLocale,
    ILocationOutput,
    ILocationTypeOutput,
    NoDataInfo,
    Translation,
    activeLanguageSelector,
    PhoneComponent,
    calculateItemIndex,
    Eye,
} from 'palipali-panel-common-web';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import {setLocation} from 'src/store/reducers/locationViewSlice';
import {IPaginationParams} from 'src/store/reducers/locationListingSlice';

interface ILocationListTableProps {
    isLoading: boolean;
    locations: ILocationOutput[];
    teamId: string;
    pagination: IPaginationParams;
    locationTypes?: ILocationTypeOutput[];
}

const LocationListTable: React.FC<ILocationListTableProps> = ({isLoading, teamId, locations, locationTypes, pagination}) => {
    const activeLanguage: LanguageLocale = useSelector(activeLanguageSelector),
        navigate = useNavigate(),
        dispatch = useDispatch();

    const getBadgeType = (locationTypeId: string | undefined): BadgeType => {
        let type = BadgeType.LOCATION_TYPE_WAREHOUSE;
        if (locationTypes) {
            const result: ILocationTypeOutput[] = locationTypes.filter((type) => type.id === locationTypeId),
                typeName = result[0]?.code;
            type =
                typeName === BadgeType.LOCATION_TYPE_SHOP
                    ? BadgeType.LOCATION_TYPE_SHOP
                    : typeName === BadgeType.LOCATION_TYPE_WAREHOUSE
                    ? BadgeType.LOCATION_TYPE_WAREHOUSE
                    : BadgeType.LOCATION_TYPE_WHOLESALER;
        }
        return type;
    };

    const renderTableBody = () => {
        if (isLoading) return;

        if (!locations || locations.length === 0) {
            return (
                <TableRow>
                    <TableCell colSpan={12}>
                        <NoDataInfo text={'locations.locationList.table.noResults'} />
                    </TableCell>
                </TableRow>
            );
        }
        const locationTypeName = (locationTypeId: string) => {
            if (!locationTypes || locationTypes.length === 0) return '';
            const locationType = locationTypes.find((locationType: ILocationTypeOutput) => locationType.id === locationTypeId);
            const locationName: ITranslationName | undefined = locationType?.name_i18n;
            return locationType && locationName ? locationName[activeLanguage] : '';
        };

        const setLocationAndNavigate = (location: ILocationOutput) => {
            dispatch(setLocation(location));
            navigate(`/panel/locations/${location.id}`, {state: {teamId: teamId}});
        };
        return locations.map((location: ILocationOutput, index: number) => (
            <TableRow key={location.id}>
                <TableCell>
                    <Box sx={{display: 'flex', alignItems: 'center', gap: '1.6rem'}}>
                        <span className="index-number-cell">
                            <Typography component="span" className="index-number">{`#${calculateItemIndex(
                                pagination.page,
                                pagination.itemsPerPage,
                                index
                            )}`}</Typography>
                        </span>
                        <Box sx={{maxWidth: '20vw'}}>
                            <Typography noWrap>{location.name}</Typography>
                        </Box>
                    </Box>
                </TableCell>
                <TableCell sx={{maxWidth: '20vw'}}>
                    <Typography noWrap>{location.companyName}</Typography>
                </TableCell>
                <TableCell>{location.addressLine}</TableCell>
                <TableCell>
                    <PhoneComponent phone={location.phone} />
                </TableCell>
                <TableCell>
                    <Badge className={`badge ${getBadgeType(location.locationTypeId)}`}>{locationTypeName(location.locationTypeId)}</Badge>
                </TableCell>
                <TableCell className="action-cell">
                    <div className="action-cell-container">
                        <div className="btn-action-wrapper">
                            <Button
                                onClick={() => setLocationAndNavigate(location)}
                                className="btn action-button"
                                color="inherit"
                                startIcon={<Eye />}>
                                <Translation text="jobs.jobView.details" />
                            </Button>
                        </div>
                    </div>
                </TableCell>
            </TableRow>
        ));
    };
    return (
        <TableContainer>
            <Table className="custom-list">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Box sx={{paddingLeft: '4.3rem'}}>
                                <Translation text="locations.locationList.table.headers.name" />
                            </Box>
                        </TableCell>
                        <TableCell>
                            <Translation text="locations.locationList.table.headers.companyName" />
                        </TableCell>
                        <TableCell>
                            <Translation text="locations.locationList.table.headers.address" />
                        </TableCell>
                        <TableCell>
                            <Translation text="locations.locationList.table.headers.phone" />
                        </TableCell>
                        <TableCell>
                            <Translation text="locations.locationList.table.headers.locationType" />
                        </TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>{renderTableBody()}</TableBody>
            </Table>
        </TableContainer>
    );
};

export default LocationListTable;
