import {FC, Suspense, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Routes, Route, Navigate} from 'react-router-dom';
import {Box, ThemeProvider, Theme} from '@mui/material';
import {
    useSettings,
    withAuthGuard,
    VerticalLayout,
    UserRole,
    Toast,
    PrivateRoute,
    LogoutComponent,
    accountUserNameSelector,
    initApp,
    authTokenSelector,
    setAuthState,
    setLoginFailure,
    getTeamListing,
    Loader,
    isLoadingSelector,
    LoaderType,
    AppDataRefreshComponent,
    refreshTokenSelector,
    silentRefreshDictionariesAndParameters,
} from 'palipali-panel-common-web';
import {useSections} from './config';
import {useSubMenuSections} from './sub-menu-config';
import ActiveOrders from '../ActiveJobs';
import HistoricalOrders from '../HistoricalJobs';
import Locations from '../Locations';
import Teams from '../Teams';
import Account from '../Account';

interface IPanelHostProps {
    theme: Theme;
    userRole: UserRole;
}

export const PanelHost: FC<IPanelHostProps> = withAuthGuard((props) => {
    const settings = useSettings();
    const sections = useSections();
    const subMenuSections = useSubMenuSections();
    const backgroundColor = props.theme.palette.mode === 'dark' ? 'neutral.800' : 'neutral.100';
    const userName = useSelector(accountUserNameSelector);
    const isLoading = useSelector(isLoadingSelector);
    const dispatch = useDispatch();
    const authToken = useSelector(authTokenSelector);
    const refreshToken = useSelector(refreshTokenSelector);

    useEffect(() => {
        if (!authToken || !refreshToken) {
            dispatch(setLoginFailure('alerts.noAccessError'));
            dispatch(setAuthState(null, null, null, false, []));
        } else {
            dispatch(initApp(refreshToken, UserRole.PURCHASER));
            dispatch(getTeamListing());
        }
    }, []);

    const fetchData = () => {
        dispatch(silentRefreshDictionariesAndParameters());
    };

    return (
        <PrivateRoute userRole={UserRole.PURCHASER}>
            <ThemeProvider theme={props.theme}>
                <VerticalLayout
                    sections={sections}
                    subMenuSections={subMenuSections}
                    navColor={settings.navColor}
                    userName={userName}
                    {...props}>
                    <Suspense>
                        <AppDataRefreshComponent silentDictionaryRefreshFunction={fetchData} />
                        <Box sx={{backgroundColor: backgroundColor}}>
                            <Routes>
                                <Route path="active-orders/*" element={<ActiveOrders />} key="active-orders" />
                                <Route path="historical-orders/*" element={<HistoricalOrders />} key="historical-orders" />
                                <Route path="locations/*" element={<Locations />} key="locations" />
                                <Route path="teams/*" element={<Teams />} key="teams" />
                                <Route path="account" element={<Account />} key="account" />
                                <Route path="logout" element={<LogoutComponent theme={props.theme} />} key="logout" />
                                <Route path="*" element={<Navigate to="active-orders" />} />
                            </Routes>
                        </Box>
                    </Suspense>
                    <Toast />
                </VerticalLayout>
            </ThemeProvider>
            <Loader showLoader={isLoading} type={LoaderType.Global} />
        </PrivateRoute>
    );
});
