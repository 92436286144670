import {Button, Grid, Stack, Typography, useTheme} from '@mui/material';
import {
    CustomModalComponent,
    FormikForm,
    IMultiselectOption,
    ITeamMemberOutput,
    IUpdateTeamMemberInput,
    Loader,
    LoaderType,
    MemberOperationType,
    Translation,
    UserRole,
    currentlySelectedModalTypeSelector,
    isModalOpenSelector,
} from 'palipali-panel-common-web';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {isTeamMemberLoadingSelector} from 'src/store/selectors/teamMemberSelectors';
import {setTeamMember, updateTeamMember} from '../../../../../store/reducers/teamMemberSlice';
import {setRoleFilters} from '../utils/roleFilters';
import TeamMemberDetailsList from './TeamMemberDetailsList';
import {teamMemberEditFormConfig} from './teamMemberEditFormConfig';
import TeamMemberRoleDescription from '../TeamMemberRoleDescription';

interface ITeamMemberEditModalProps {
    userRole: UserRole | null;
    teamMember: ITeamMemberOutput;
}

export interface ITeamMemberEditModalFormValues {
    role: string | null | undefined;
}

const TeamMemberEditModal: React.FC<ITeamMemberEditModalProps> = ({userRole, teamMember}) => {
    const dispatch = useDispatch(),
        theme = useTheme(),
        {t} = useTranslation(),
        isOpen = useSelector(isModalOpenSelector),
        isLoading = useSelector(isTeamMemberLoadingSelector),
        [isAllowed, setIsAllowed] = useState<boolean>(false),
        isEditModalType = useSelector(currentlySelectedModalTypeSelector) === MemberOperationType.TEAMS_EDIT_MEMBER;

    const isModalOpen = isOpen && isEditModalType;
    useEffect(() => {
        if (teamMember.id) {
            dispatch(setTeamMember(teamMember.id));
        }
    }, [teamMember.id]);

    const selectUserRoles = [UserRole.PURCHASER_TEAM_EMPLOYEE, UserRole.PURCHASER_TEAM_MANAGER, UserRole.PURCHASER_TEAM_OWNER];
    const roles: IMultiselectOption[] = Object.values(selectUserRoles).map((role) => ({
        value: role,
        label: t(`roles.${role}`),
    }));
    const [adaptedRoles, setAdaptedRole] = useState<IMultiselectOption[]>([
        {
            value: '',
            label: '',
        },
    ]);
    const onSubmit = (values: IUpdateTeamMemberInput) => {
        const payload = {
            member: {
                role: values.role,
                id: teamMember.id,
            },
        };
        dispatch(updateTeamMember(payload));
    };
    const initialValues = {
        role: teamMember.role,
    };

    useEffect(() => {
        if (isModalOpen) {
            setAdaptedRole(setRoleFilters(userRole, roles));
        }
    }, [isModalOpen]);

    return (
        <CustomModalComponent
            theme={theme}
            customClasses="team-member-edit-modal"
            title={
                <Typography color="text.primary" variant="h6" component="h2">
                    <Translation text={`teams.teamOperation.title.editUser`} />
                </Typography>
            }
            content={
                <Stack sx={{width: '100%'}}>
                    {teamMember && <TeamMemberDetailsList teamMember={teamMember} />}
                    <Grid className="team-member-role-select">
                        <FormikForm
                            fields={teamMemberEditFormConfig(adaptedRoles)}
                            submitAllowed={setIsAllowed}
                            formId="team-member-edit"
                            initialValues={initialValues}
                            onSubmit={onSubmit}
                            theme={theme}
                        />
                        <TeamMemberRoleDescription />
                    </Grid>
                    <Loader showLoader={isLoading} type={LoaderType.Local} />
                </Stack>
            }
            footer={
                <Button type="submit" variant="contained" color="primary" fullWidth form="team-member-edit" disabled={!isAllowed}>
                    <Translation text={`teams.teamOperation.buttons.edit`} />
                </Button>
            }
        />
    );
};

export default TeamMemberEditModal;
