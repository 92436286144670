import {Box, Button, Divider, Grid, Stack, Typography, useTheme} from '@mui/material';
import 'leaflet-defaulticon-compatibility';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css';
import {
    IPurchaserCurrentJobDetailsOutput,
    JobStatus,
    TimeComponent,
    Translation,
    formatPhoneNumber,
    formatPhoneToPayload,
    formatSecondsToDuration,
    teamByIdSelector,
    userFullName,
    ChevronRight,
    ChevronLeftIcon,
    isModalOpenSelector,
    currentlySelectedModalTypeSelector,
    MemberOperationType,
    openModal,
    selectSystemParameter,
    SystemParameterCode,
} from 'palipali-panel-common-web';
import React, {useEffect} from 'react';
import {MapContainer, Marker} from 'react-leaflet';
import {useDispatch, useSelector} from 'react-redux';
import {
    CreateJobWizardSteps,
    placeJob,
    recalculateJobDetails,
    setCurrentStep,
    setShouldRecalculateJobDetails,
} from '../../../../store/reducers/createJobViewSlice';
import {
    additionalPaymentAuthorizationClientSecretSelector,
    createdJobSelector,
    shouldRecalculateJobDetailsSelector,
} from '../../../../store/selectors/createJobViewSelectors';
import BoxSizeIconWithBackground from '../../../shared/BoxSizeIconWithBackground';
import CustomTileLayer from '../../../shared/CustomTileLayer';
import TimerButton from '../../../shared/TimerButton';
import {receiverMarkerIcon, senderMarkerIcon} from '../../../shared/mapIcons';
import UpdateMapCenter from '../CreateJobSenderData/UpdateMapCenter';
import CreateJobSummaryAdditionalPaymentAuthorizationStripeWrapper from './CreateJobSummaryAdditionalPaymentAuthorizationStripeWrapper';
import JobPaymentDetails from './JobPaymentDetails';
import LocationMayBeClosedModal from './LocationMayBeClosedModal';

const CreateJobSummary: React.FC = () => {
    const dispatch = useDispatch(),
        theme = useTheme(),
        isDarkMode = theme.palette.mode === 'dark',
        createdJob: IPurchaserCurrentJobDetailsOutput | null = useSelector(createdJobSelector),
        team = useSelector((state) => teamByIdSelector(state, createdJob?.team?.id)),
        shouldRenewOrder: boolean = useSelector(shouldRecalculateJobDetailsSelector),
        isModalOpen: boolean = useSelector(isModalOpenSelector),
        modalType = useSelector(currentlySelectedModalTypeSelector),
        summaryValidFor = useSelector((state) => selectSystemParameter(state, SystemParameterCode.JOB_ESTIMATION_VALID_FOR)),
        DEFAULT_SUMMARY_VALID_FOR = 60,
        additionalPaymentAuthorizationClientSecret = useSelector(additionalPaymentAuthorizationClientSecretSelector);

    const recipientName = userFullName(createdJob?.recipient?.firstName, createdJob?.recipient?.lastName),
        recipientPhone = createdJob?.recipient?.phone ? formatPhoneNumber(createdJob?.recipient.phone) : null;
    const placeNewJob = () => {
        if (createdJob) {
            dispatch(placeJob(createdJob.id));
        }
    };

    useEffect(() => {
        if (createdJob && createdJob.isPickupAtRiskOfBeingClosed) {
            dispatch(openModal(MemberOperationType.LOCATION_MAY_BE_CLOSED));
        }
    }, [createdJob]);

    const handleRenewOrder = () => {
        dispatch(setShouldRecalculateJobDetails(true));
    };

    const recalculateJob = () => {
        if (createdJob) {
            const fromLocationPhone = createdJob.fromLocation.phone
                    ? {
                          country: createdJob.fromLocation.phone.country,
                          phone: formatPhoneToPayload(createdJob.fromLocation.phone.phone),
                      }
                    : null,
                fromLocationPoint = createdJob.fromLocation.point
                    ? {
                          latitude: createdJob.fromLocation.point.latitude,
                          longitude: createdJob.fromLocation.point.longitude,
                      }
                    : null,
                toLocationPhone = createdJob.toLocation.phone
                    ? {
                          country: createdJob.toLocation.phone.country,
                          phone: formatPhoneToPayload(createdJob.toLocation.phone.phone),
                      }
                    : null,
                toLocationPoint = createdJob.toLocation.point
                    ? {
                          latitude: createdJob.toLocation.point.latitude,
                          longitude: createdJob.toLocation.point.longitude,
                      }
                    : null;
            const createJobPayload = {
                teamId: createdJob.team.id,
                packageSize: createdJob.packageSize,
                insuranceValue: createdJob.insuranceValue
                    ? {
                          amount: createdJob.insuranceValue.amount,
                          currency: createdJob.insuranceValue.currency.name,
                      }
                    : null,
                fromLocation: {
                    addressLine: createdJob.fromLocation.locationId ? null : createdJob.fromLocation?.addressLine || null,
                    companyName: createdJob.fromLocation.locationId ? null : createdJob.fromLocation?.companyName || null,
                    flatNumber: createdJob.fromLocation.locationId ? null : createdJob.fromLocation?.flatNumber || null,
                    locationId: createdJob.fromLocation.locationId,
                    phone: fromLocationPhone,
                    point: createdJob.fromLocation.locationId ? null : fromLocationPoint,
                    street: createdJob.fromLocation.locationId ? null : createdJob.fromLocation?.street || null,
                    houseNumber: createdJob.fromLocation.locationId ? null : createdJob.fromLocation?.houseNumber || null,
                    zip: createdJob.fromLocation.locationId ? null : createdJob.fromLocation?.zip || null,
                    city: createdJob.fromLocation.locationId ? null : createdJob.fromLocation?.city || null,
                    country: createdJob.fromLocation.locationId ? null : createdJob.fromLocation?.country || null,
                },
                toLocation: {
                    addressLine: createdJob.toLocation.locationId ? null : createdJob.toLocation?.addressLine || null,
                    companyName: createdJob.toLocation.locationId ? null : createdJob.toLocation?.companyName || null,
                    flatNumber: createdJob.toLocation.locationId ? null : createdJob.toLocation?.flatNumber || null,
                    locationId: createdJob.toLocation.locationId,
                    phone: createdJob.toLocation.locationId ? null : toLocationPhone,
                    point: createdJob.toLocation.locationId ? null : toLocationPoint,
                    street: createdJob.toLocation.locationId ? null : createdJob.toLocation?.street || null,
                    houseNumber: createdJob.toLocation.locationId ? null : createdJob.toLocation?.houseNumber || null,
                    zip: createdJob.toLocation.locationId ? null : createdJob.toLocation?.zip || null,
                    city: createdJob.toLocation.locationId ? null : createdJob.toLocation?.city || null,
                    country: createdJob.toLocation.locationId ? null : createdJob.toLocation?.country || null,
                },
                processAt: createdJob?.processAt || null,
                recipient: {
                    firstName: createdJob.recipient.teamMemberId ? null : createdJob.recipient?.firstName || null,
                    lastName: createdJob.recipient.teamMemberId ? null : createdJob.recipient?.lastName || null,
                    phone: createdJob.recipient.teamMemberId
                        ? null
                        : createdJob?.recipient?.phone
                        ? {
                              country: createdJob?.recipient?.phone?.country,
                              phone: createdJob?.recipient?.phone?.phone,
                          }
                        : null,
                    teamMemberId: createdJob.recipient.teamMemberId,
                },
                orderNumber: createdJob?.orderNumber || null,
                description: createdJob?.description || null,
            };
            const payload = {
                jobId: createdJob.id,
                createJobInput: createJobPayload,
            };
            dispatch(recalculateJobDetails(payload));
        }
    };
    const isNoCouriersPresent = createdJob?.deliveryStatus === JobStatus.FAILED,
        isPlaceJobDisabled = shouldRenewOrder || isNoCouriersPresent;
    return (
        <Stack className="sender-data-container">
            <Grid container>
                <Grid item xs={12} md={6} className="sender-data-wrapper">
                    <Stack className="summary-details-wrapper">
                        <Box className={`summary-details ${isDarkMode ? 'dark-mode' : ''}`}>
                            <Typography color={'text.primary'} className={`summary-label ${isPlaceJobDisabled ? 'disabled' : ''}`}>
                                <Translation text={'jobs.createJob.jobSummary.estimatedTime'} />
                            </Typography>

                            <Typography
                                color="text.secondary"
                                className={`summary-value ${
                                    isPlaceJobDisabled ? 'disabled' : ''
                                } create-job-wizard-summary-step-summary-value-estimatedTime`}>
                                {createdJob?.estimatedTime ? formatSecondsToDuration(createdJob?.estimatedTime || null) : ''}
                            </Typography>
                        </Box>
                        <Divider />
                        <Box className={`summary-details ${isDarkMode ? 'dark-mode' : ''}`}>
                            <Typography color="text.primary" className={`summary-label ${isPlaceJobDisabled ? 'disabled' : ''}`}>
                                <Translation text={'jobs.createJob.jobSummary.estimatedDeliveryDate'} />
                            </Typography>

                            <Typography
                                color="text.secondary"
                                className={`summary-value ${
                                    isPlaceJobDisabled ? 'disabled' : ''
                                } create-job-wizard-summary-step-summary-value-estimatedDeliveryAt`}>
                                {createdJob?.estimatedDeliveryAt ? (
                                    <TimeComponent
                                        application="purchaser"
                                        date={createdJob.estimatedDeliveryAt}
                                        time={createdJob.estimatedTime}
                                        isSimpleTime
                                    />
                                ) : (
                                    ''
                                )}
                            </Typography>
                        </Box>
                        <Divider />
                        <Box className="summary-details">
                            <Typography className={'summary-label'}>
                                <Translation text={'jobs.createJob.jobSummary.packageSize'} />
                            </Typography>
                            <BoxSizeIconWithBackground
                                type={createdJob?.packageSize || 's'}
                                customTextStyles="create-job-wizard-summary-step-summary-value-packageSize"
                            />
                        </Box>
                        <Divider />
                        <Box className="summary-details">
                            <Typography color="text.primary" className={`summary-label`}>
                                <Translation text={'jobs.createJob.jobSummary.team'} />
                            </Typography>

                            <Typography
                                color="text.secondary"
                                className={`summary-value create-job-wizard-summary-step-summary-value-teamName`}>
                                {team?.name}
                            </Typography>
                        </Box>
                        <Divider />
                        <Box className="summary-details">
                            <Typography color="text.primary" className={`summary-label`}>
                                <Translation text={'jobs.createJob.jobSummary.receiver'} />
                            </Typography>

                            <Typography
                                color="text.secondary"
                                className={`summary-value create-job-wizard-summary-step-summary-value-recipient`}>
                                {`${recipientName} ${recipientPhone ? ` (${recipientPhone})` : ''}`}
                            </Typography>
                        </Box>
                        <Divider />
                        {/* TODO PT: Hidden till moment when PaliPali gets insurance company agreement */}
                        {/* {createdJob?.insuranceValue ? (
                            <>
                                <Box className="summary-details">
                                    <Typography color="text.primary" className={`summary-label`}>
                                        <Translation text={'jobs.createJob.jobSummary.insurancePrice'} />
                                    </Typography>

                                    <Typography color="text.secondary" className={`summary-value create-job-wizard-summary-step-summary-value-estimatedDeliveryAt`}>{moneyString(createdJob?.insuranceValue)}</Typography>
                                </Box>
                                <Divider />
                            </>
                        ) : null} */}
                    </Stack>
                    <JobPaymentDetails createdJob={createdJob} shouldRenewOrder={shouldRenewOrder} />
                </Grid>

                <Grid item xs={12} md={6} className="map-container">
                    <MapContainer
                        center={
                            createdJob?.fromLocation?.point
                                ? [createdJob.fromLocation.point.latitude, createdJob.fromLocation.point.longitude]
                                : [52.237049, 21.017532]
                        }
                        zoom={10}
                        maxZoom={18}
                        minZoom={2}
                        scrollWheelZoom={false}
                        dragging={false}
                        touchZoom={false}
                        doubleClickZoom={false}
                        zoomControl={false}>
                        <CustomTileLayer />
                        {createdJob?.fromLocation?.point ? (
                            <Marker
                                key={'fromLocation'}
                                icon={senderMarkerIcon}
                                position={[createdJob.fromLocation.point.latitude, createdJob.fromLocation.point.longitude]}></Marker>
                        ) : null}
                        {createdJob?.toLocation?.point ? (
                            <Marker
                                key={'toLocation'}
                                icon={receiverMarkerIcon}
                                position={[createdJob.toLocation.point.latitude, createdJob.toLocation.point.longitude]}></Marker>
                        ) : null}
                        <UpdateMapCenter
                            latitude={createdJob?.fromLocation?.point?.latitude}
                            longitude={createdJob?.fromLocation?.point?.longitude}
                            additionalPosition={
                                createdJob?.toLocation
                                    ? {
                                          latitude: createdJob?.toLocation.point.latitude,
                                          longitude: createdJob?.toLocation.point.longitude,
                                      }
                                    : undefined
                            }
                        />
                    </MapContainer>
                </Grid>
            </Grid>

            <Divider />
            <Grid className="navigation-buttons-container">
                <Button
                    key="prev-button"
                    onClick={() => {
                        dispatch(setCurrentStep(CreateJobWizardSteps.ADDITIONAL_DATA));
                    }}
                    className="btn-action"
                    startIcon={<ChevronLeftIcon />}>
                    <Translation text="jobs.createJob.generalData.prevButton" />
                </Button>
                {!isPlaceJobDisabled ? (
                    <TimerButton
                        key="next-timer-button"
                        onPressAction={placeNewJob}
                        onFinish={handleRenewOrder}
                        countdown={summaryValidFor?.value ? +summaryValidFor.value : DEFAULT_SUMMARY_VALID_FOR}
                        buttonText={'jobs.createJob.jobSummary.placeJob'}
                        endIcon={<ChevronRight />}
                    />
                ) : (
                    <Button
                        key="next-button"
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={() => recalculateJob()}
                        className="navigation-button next-button">
                        <Translation text="jobs.createJob.jobSummary.replaceJob" />
                    </Button>
                )}
            </Grid>

            {!additionalPaymentAuthorizationClientSecret ? (
                <></>
            ) : (
                <CreateJobSummaryAdditionalPaymentAuthorizationStripeWrapper
                    additionalPaymentAuthorizationClientSecret={additionalPaymentAuthorizationClientSecret}
                />
            )}
            {isModalOpen && modalType === MemberOperationType.LOCATION_MAY_BE_CLOSED && <LocationMayBeClosedModal />}
        </Stack>
    );
};

export default CreateJobSummary;
