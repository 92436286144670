import {PayloadAction} from '@reduxjs/toolkit';
import {
    createBaseReducerSlice,
    IBaseReducerState,
    IInviteTeamMemberInput,
    ITeamMemberOutput,
    IUpdateTeamMemberInput,
} from 'palipali-panel-common-web';

export interface ITeamMemberState extends IBaseReducerState {
    member: ITeamMemberOutput | null;
    redirectToTeamList: boolean;
}

export interface ISetTeamMember {
    member: ITeamMemberOutput | null;
}

export interface ISetRedirectToTeamList {
    redirectToTeamList: boolean;
}

export interface IGetMember {
    memberId: string;
}

const initialState: ITeamMemberState = {
    member: null,
    isLoading: false,
    isInitialized: false,
    error: null,
    redirectToTeamList: false,
};

const teamMemberSlice = createBaseReducerSlice({
    name: 'teamMemberView',
    initialState: initialState,
    reducers: {
        setTeamMember: (state: ITeamMemberState, action: PayloadAction<ISetTeamMember>) => {
            state.member = action.payload.member;
        },
        getTeamMember: {
            reducer: (state: ITeamMemberState) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
            prepare(memberId: string) {
                return {
                    payload: memberId,
                };
            },
        },
        inviteTeamMember: {
            reducer: (state: ITeamMemberState) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
            prepare(member: IInviteTeamMemberInput) {
                return {
                    payload: member,
                };
            },
        },
        deleteTeamMember: {
            reducer: (state: ITeamMemberState) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
            prepare(id: string) {
                return {
                    payload: id,
                };
            },
        },
        updateTeamMember: {
            reducer: (state: ITeamMemberState) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
            prepare(member: IUpdateTeamMemberInput) {
                return {
                    payload: member,
                };
            },
        },

        setRedirectToTeamList: {
            reducer: (state: ITeamMemberState, action: PayloadAction<ISetRedirectToTeamList>) => {
                return {
                    ...state,
                    redirectToTeamList: action.payload.redirectToTeamList,
                };
            },
            prepare(redirectToTeamList: boolean) {
                return {
                    payload: {redirectToTeamList: redirectToTeamList},
                };
            },
        },
        returnToInitialTeamState: () => {
            return {
                ...initialState,
            };
        },
    },
});

export const {
    setTeamMember,
    setLoading,
    setError,
    setInitialized,
    returnToInitialTeamState,
    getTeamMember,
    inviteTeamMember,
    updateTeamMember,
    setRedirectToTeamList,
    deleteTeamMember,
} = teamMemberSlice.actions;
export default teamMemberSlice.reducer;
