import {Button, Divider, Grid, Rating, Stack, Typography, useTheme} from '@mui/material';
import {
    AuthorizedImage,
    CustomModalComponent,
    IJobCourierOutput,
    IPurchaserJobDetailsOutput,
    JobStatus,
    JobViewDetailsListItem,
    Loader,
    LoaderType,
    PhoneComponent,
    Translation,
    formattedRatingValue,
    StarEmptyIcon,
    StarFullIcon,
} from 'palipali-panel-common-web';
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import defaultImage from '../../../../assets/images/ellipse.png';
import {createJobOpinion} from '../../../../store/reducers/jobSlice';
import {canCreateOpinionSelector, isJobLoadingSelector, jobSelector} from '../../../../store/selectors/jobSelector';

interface CourierDetailsModalProps {
    courier: IJobCourierOutput;
    isRatingShown?: boolean;
}

const CourierDetailsModal: React.FC<CourierDetailsModalProps> = ({courier, isRatingShown}) => {
    const canCreateOpinion = useSelector(canCreateOpinionSelector),
        [ratingValue, setRatingValue] = useState<number>(courier?.issuedRating ? courier.issuedRating / 10 : 0),
        job: IPurchaserJobDetailsOutput | null = useSelector(jobSelector),
        isLoading = useSelector(isJobLoadingSelector),
        dispatch = useDispatch(),
        theme = useTheme();

    if (!job) {
        return null;
    }

    const handleRatingSubmit = () => {
        const payloadRating = ratingValue * 10;
        dispatch(createJobOpinion(job.id, payloadRating));
    };

    const cancellationStatuses = [JobStatus.CANCELLED_C, JobStatus.CANCELLED_P, JobStatus.PROBLEM_C, JobStatus.PROBLEM_P];
    const phoneNumberShown = courier.phone && !job.isCompleted && !cancellationStatuses.includes(job.deliveryStatus);

    return (
        <CustomModalComponent
            theme={theme}
            customClasses="courier-details-modal"
            title={
                <Typography color="text.primary" variant="h6" component="h2">
                    <Translation text={`jobs.orderDetails.courier`} />
                </Typography>
            }
            content={
                <Grid sx={{width: '100%'}}>
                    <Stack className="courier-account-content">
                        <div className="image-wrapper">
                            <AuthorizedImage url={courier.avatar?.fileUrls?.smallThumb} default={defaultImage} />
                        </div>
                        <Typography variant="subtitle2" color="text.primary">
                            {courier.firstName}
                        </Typography>
                    </Stack>
                    <dl className="job-details-list modal-list">
                        <JobViewDetailsListItem
                            label={'jobs.courierDetails.registrationNumber'}
                            value={courier?.vehicle?.registrationNumber}
                        />
                        <Divider />
                        <JobViewDetailsListItem
                            label={'jobs.courierDetails.carModel'}
                            value={
                                courier?.vehicle?.make && courier?.vehicle?.model
                                    ? `${courier?.vehicle?.make} ${courier?.vehicle?.model}`
                                    : undefined
                            }
                        />
                        <Divider />
                        <JobViewDetailsListItem label={'jobs.courierDetails.color'} value={courier?.vehicle?.color?.name} />
                        <Divider />
                        {phoneNumberShown && (
                            <>
                                <JobViewDetailsListItem
                                    label={'jobs.courierDetails.phone'}
                                    value={<PhoneComponent phone={courier.phone} />}
                                />
                                <Divider />
                            </>
                        )}
                    </dl>
                    {(canCreateOpinion || courier?.issuedRating) && isRatingShown ? (
                        <>
                            <div className="rating-section">
                                <Rating
                                    className="stars"
                                    name="courier-rating"
                                    color="primary"
                                    onChange={(event, newValue) => {
                                        setRatingValue(newValue || 0);
                                    }}
                                    value={ratingValue}
                                    icon={<StarFullIcon fontSize="inherit" />}
                                    emptyIcon={<StarEmptyIcon fontSize="inherit" />}
                                    precision={1}
                                    size="large"
                                    disabled={!!courier?.issuedRating}
                                />

                                {courier?.issuedRating || ratingValue ? (
                                    <Typography className="rating-value">
                                        {formattedRatingValue(courier.issuedRating ? courier.issuedRating : ratingValue * 10)}
                                    </Typography>
                                ) : null}
                            </div>
                        </>
                    ) : null}
                    <Loader showLoader={isLoading} type={LoaderType.Local} />
                </Grid>
            }
            footer={
                canCreateOpinion && isRatingShown ? (
                    <Button
                        type="button"
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={() => handleRatingSubmit()}
                        disabled={ratingValue ? false : true}>
                        <Translation text={`jobs.courierDetails.buttons.rate`} />
                    </Button>
                ) : undefined
            }
        />
    );
};

export default CourierDetailsModal;
