import {Box, Divider, Stack, Theme, Typography} from '@mui/material';
import {IPurchaserCurrentJobDetailsOutput, JobStatus, Translation, CreditCard02} from 'palipali-panel-common-web';
import React from 'react';
import JobPriceComponent from '../../../../shared/JobPriceComponent';

interface IJobPaymentDetailsProps {
    createdJob: IPurchaserCurrentJobDetailsOutput | null;
    shouldRenewOrder: boolean;
}

const JobPaymentDetails: React.FC<IJobPaymentDetailsProps> = ({createdJob, shouldRenewOrder}) => {
    if (!createdJob) {
        return null;
    }
    const noCouriersFound = createdJob?.deliveryStatus === JobStatus.FAILED;
    const renderPaymentData = () => {
        if (noCouriersFound) {
            return (
                <Box
                    className="no-couriers-box"
                    sx={{backgroundColor: (theme: Theme) => (theme.palette.mode === 'dark' ? 'neutral.800' : 'primary.light')}}>
                    <Typography variant="caption" color="text.primary" className="no-couriers-text">
                        <Translation text={'jobs.createJob.jobSummary.noCouriersFound'}></Translation>
                    </Typography>
                </Box>
            );
        } else {
            return (
                <>
                    <Typography variant="subtitle1" color="text.primary">
                        <Translation text={'jobs.createJob.jobSummary.payment'}></Translation>
                    </Typography>
                    <Divider />
                    <Box className={`payment-details button-small-font-variant ${shouldRenewOrder ? 'disabled' : ''} `}>
                        <Box className="card-details">
                            <CreditCard02 />
                            <Typography variant="inherit" className={`create-job-wizard-summary-step-summary-value-creditCardNumber`}>
                                {createdJob?.creditCardNumber ? `**** ${createdJob.creditCardNumber}` : '-'}
                            </Typography>
                        </Box>
                        <JobPriceComponent job={createdJob} />
                    </Box>
                </>
            );
        }
    };
    return <Stack className="credit-card-details">{renderPaymentData()}</Stack>;
};

export default JobPaymentDetails;
