import React from 'react';
import {IPackageSizesOutput} from 'palipali-panel-common-web';
import {Box, Typography} from '@mui/material';
import checkIcon from '../../../../../assets/images/check-icon.svg';

interface IBoxSizeIconProps {
    type: keyof IPackageSizesOutput;
    selected?: boolean;
}
const BoxSizeIcon: React.FC<IBoxSizeIconProps> = ({type, selected}) => {
    return (
        <Box className={`package-icon package-icon-${type}`}>
            {selected ? (
                <img src={checkIcon} alt="Check icon" />
            ) : (
                <Typography variant="inherit" className="helper-text-font-variant bold">
                    {(type as string).toUpperCase()}
                </Typography>
            )}
        </Box>
    );
};

export default BoxSizeIcon;
