import React from 'react';
import {AlertTriangle, CustomModalComponent, Translation} from 'palipali-panel-common-web';
import {Box, Grid, Typography, useTheme} from '@mui/material';

interface ILocationMayBeClosedModalProps {}
const LocationMayBeClosedModal: React.FC<ILocationMayBeClosedModalProps> = () => {
    const theme = useTheme();

    return (
        <CustomModalComponent
            theme={theme}
            customClasses="location-may-be-closed-modal"
            title={
                <Typography color="text.primary" variant="h6" component="h2">
                    <Translation text={`jobs.createJob.jobSummary.locationClosedWarningModal.title`} />
                </Typography>
            }
            content={
                <Grid>
                    <Box justifyContent={'center'} display={'flex'} mb={3}>
                        <AlertTriangle className="triangle" />
                    </Box>
                    <Typography variant="body1" component="p" color="text.primary" textAlign={'center'}>
                        <Translation text="jobs.createJob.jobSummary.locationClosedWarningModal.description" />
                    </Typography>
                </Grid>
            }
        />
    );
};

export default LocationMayBeClosedModal;
