import {Action, PayloadAction} from '@reduxjs/toolkit';
import {
    AlertType,
    DetailsSuccessActionsFunction,
    IApiSingleResponseBase,
    ICanOutput,
    IMoneyOutput,
    IPurchaserCurrentJobDetailsOutput,
    IPurchaserJobDetailsOutput,
    IReportJobProblemInput,
    ITeamMemberBasicOutput,
    IUpdateJobInput,
    ListSuccessActionsFunction,
    StateSuccessActionsFunction,
    addAlert,
    authTokenSelector,
    checkMobileNumberAPI,
    closeModal,
    createActionPayloadEpic,
    createFetchDetailsEpic,
    createFetchListEpic,
    createOperationEpic,
    createOpinionAPI,
    deleteJobAPI,
    flattenObj,
    getCanCancelJobAPI,
    getCanCreateOpinionJobAPI,
    getCanDeleteJobAPI,
    getCanReportJobAPI,
    getCheckCancellationFeeAPI,
    getHistoricalJobAPI,
    getJobAPI,
    getTeamMembersAPI,
    handleApiError,
    updateCancelJobAPI,
    updateJobAPI,
    updateReportJobProblemAPI,
} from 'palipali-panel-common-web';
import {push} from 'react-router-redux';
import {Epic, combineEpics, ofType} from 'redux-observable';
import {of, Observable, timer} from 'rxjs';
import {catchError, mergeMap, switchMap} from 'rxjs/operators';
import {RootState} from '../reducers';
import {
    cancelJob,
    changeCanBeCancelled,
    changeCanBeDeleted,
    changeCanBeReported,
    changeCanCreateOpinion,
    checkCanBeCancelled,
    checkCanBeDeleted,
    checkCanBeReported,
    checkCanCreateOpinion,
    checkCancellationFee,
    createJobOpinion,
    deleteJob,
    getHistoricalJob,
    getJob,
    sendProblem,
    setCancellationFee,
    setError,
    setJob,
    setJobTeamMembers,
    setJobTeamMembersFilter,
    setLoading,
    setRedirectToJob,
    updateJob,
    checkHistoricalJob,
} from '../reducers/jobSlice';
import {jobIdSelector, jobTeamMemberFilterSelector} from '../selectors/jobSelector';

const basicOperationSuccessActions = (successMessage: string) => [
    setLoading(false),
    addAlert({message: successMessage, type: AlertType.SUCCESS}),
    setRedirectToJob(true),
];
const jobSuccessActions: DetailsSuccessActionsFunction<IPurchaserCurrentJobDetailsOutput> = (job: IPurchaserCurrentJobDetailsOutput) => {
    return [setJob(job), checkCanBeCancelled(job.id), checkCanBeDeleted(job.id), checkCanBeReported(job.id), checkCanCreateOpinion(job.id)];
};

const jobUpdatedSuccessActions: DetailsSuccessActionsFunction<IPurchaserCurrentJobDetailsOutput> = (
    job: IPurchaserCurrentJobDetailsOutput
) => {
    return [
        setJob(job),
        checkCanBeCancelled(job.id),
        checkCanBeDeleted(job.id),
        checkCanBeReported(job.id),
        checkCanCreateOpinion(job.id),
        addAlert({message: 'jobs.alert.jobUpdated', type: AlertType.SUCCESS}),
    ];
};

const createJobOpinionSuccessActions: StateSuccessActionsFunction<string> = (data: string, state: RootState) => {
    const historicJobId = jobIdSelector(state);

    return [getHistoricalJob(historicJobId), addAlert({message: 'jobs.alert.opinionSent', type: AlertType.SUCCESS})];
};

const getJobTeamMembersSuccessActions: ListSuccessActionsFunction<ITeamMemberBasicOutput> = (
    teamMembersArray: ITeamMemberBasicOutput[]
) => {
    return [setJobTeamMembers(teamMembersArray)];
};
const historicalJobSuccessActions: DetailsSuccessActionsFunction<IPurchaserJobDetailsOutput> = (job: IPurchaserJobDetailsOutput) => {
    return [setJob(job), checkCanCreateOpinion(job.id)];
};
const checkSuccessActions: DetailsSuccessActionsFunction<ICanOutput> = (canBeReported: ICanOutput) => {
    return [changeCanBeReported(canBeReported.can)];
};
const checkCanBeDeletedSuccessActions: DetailsSuccessActionsFunction<ICanOutput> = (canBeDeleted: ICanOutput) => {
    return [changeCanBeDeleted(canBeDeleted.can)];
};
const checkCanCreateOpinionSuccessActions: DetailsSuccessActionsFunction<ICanOutput> = (canCreateOpinion: ICanOutput) => {
    return [changeCanCreateOpinion(canCreateOpinion.can)];
};
const checkCancelSuccessActions: any = (data: ICanOutput, stateValue: any) => {
    let actions = [changeCanBeCancelled(data.can)];
    if (data.can === true) {
        const jobId = stateValue.job.job.id;
        actions = [...actions, checkCancellationFee(jobId)];
    }
    return actions;
};
const checkCancellationFeeSuccessActions: DetailsSuccessActionsFunction<IMoneyOutput> = (cancellationFee: IMoneyOutput) => {
    return [setCancellationFee(cancellationFee)];
};
const sendReportSuccessAction: StateSuccessActionsFunction<{problem: IReportJobProblemInput; id: string}> = (
    data: {problem: IReportJobProblemInput; id: string},
    state: any
) => {
    return [closeModal(), push(`/panel/historical-orders/${state.job.job.id}`), ...basicOperationSuccessActions('jobs.alert.reportSent')];
};

const cancelJobSuccessAction: DetailsSuccessActionsFunction<IPurchaserCurrentJobDetailsOutput> = (
    job: IPurchaserCurrentJobDetailsOutput
) => {
    return [
        closeModal(),
        push(`/panel/historical-orders/${job?.id}`, {shouldCheck: true}),
        ...basicOperationSuccessActions('jobs.alert.jobCancelled'),
    ];
};

const deleteJobSuccessAction: DetailsSuccessActionsFunction<null> = () => {
    return [closeModal(), push('/panel/active-orders'), ...basicOperationSuccessActions('jobs.alert.jobDeleted')];
};

const errorActions = (error: any, isCustomError?: boolean): any[] => {
    const errorObj = handleApiError(error);
    errorObj.type = AlertType.WARNING;
    if (isCustomError && errorObj.message.includes('Access Denied')) {
        errorObj.message = 'jobs.alert.orderUnavailable';
    }
    return [setLoading(false), setError(errorObj.message), addAlert(errorObj)];
};

const getJobErrorActions = (error: any): any[] => {
    const errorObj = handleApiError(error);
    errorObj.type = AlertType.ERROR;

    if (
        errorObj.message.includes('invalid_identifier') ||
        errorObj.message.includes('Not Found') ||
        errorObj.message.includes('Access Denied.')
    ) {
        errorObj.message = errorObj.message.includes('Access Denied.') ? 'jobs.alert.jobNotActive' : 'jobs.alert.jobNotFound';

        return [setLoading(false), setError(errorObj.message), addAlert(errorObj), push('/panel/active-orders')];
    }
    return [setLoading(false), setError(errorObj.message), addAlert(errorObj)];
};

const getHistoricalJobEpic = createFetchDetailsEpic<IPurchaserJobDetailsOutput>(
    getHistoricalJobAPI,
    historicalJobSuccessActions,
    errorActions,
    getHistoricalJob().type
);

const getJobEpic = createFetchDetailsEpic<IPurchaserCurrentJobDetailsOutput>(
    getJobAPI,
    jobSuccessActions,
    getJobErrorActions,
    getJob().type
);

const checkCanBeReportedEpic = createFetchDetailsEpic<ICanOutput>(
    getCanReportJobAPI,
    checkSuccessActions,
    errorActions,
    checkCanBeReported().type
);

const checkCanBeCancelledEpic = createActionPayloadEpic<ICanOutput>(
    getCanCancelJobAPI,
    checkCancelSuccessActions,
    errorActions,
    checkCanBeCancelled().type
);

const checkCanBeDeletedEpic = createActionPayloadEpic<ICanOutput>(
    getCanDeleteJobAPI,
    checkCanBeDeletedSuccessActions,
    errorActions,
    checkCanBeDeleted().type
);

const checkCanCreateOpinionEpic = createActionPayloadEpic<ICanOutput>(
    getCanCreateOpinionJobAPI,
    checkCanCreateOpinionSuccessActions,
    errorActions,
    checkCanCreateOpinion().type
);

const checkCancellationFeeEpic = createFetchDetailsEpic<IMoneyOutput>(
    getCheckCancellationFeeAPI,
    checkCancellationFeeSuccessActions,
    errorActions,
    checkCancellationFee().type
);

const getJobTeamMembersEpic = createFetchListEpic<ITeamMemberBasicOutput>(
    getTeamMembersAPI,
    getJobTeamMembersSuccessActions,
    errorActions,
    setJobTeamMembersFilter().type,
    (state: RootState) => {
        const queryParameters = jobTeamMemberFilterSelector(state);
        if (queryParameters) {
            const parametersFlattened = flattenObj(queryParameters);
            return parametersFlattened;
        }
        return [];
    },
    () => []
);

const updateJobEpic: Epic = (action$, state$) =>
    action$.pipe(
        ofType(updateJob().type),
        switchMap((action: PayloadAction<{id: string; job: IUpdateJobInput}>) => {
            const authToken = authTokenSelector(state$.value);
            const recipientData = action.payload.job.recipient;
            if (recipientData.teamMemberId === null && recipientData.phone) {
                return checkMobileNumberAPI(authToken, recipientData.phone.country, recipientData.phone.phone).pipe(
                    switchMap(() => {
                        return updateJobAPI(authToken, {job: action.payload.job, id: action.payload.id}).pipe(
                            mergeMap((res: IApiSingleResponseBase<IPurchaserCurrentJobDetailsOutput>) =>
                                of(...jobUpdatedSuccessActions(res))
                            )
                        );
                    }),
                    catchError(() => {
                        return of(addAlert({message: 'formValidation.errors.isPhoneValid', type: AlertType.ERROR}), setLoading(false));
                    })
                );
            } else {
                return updateJobAPI(authToken, {job: action.payload.job, id: action.payload.id}).pipe(
                    mergeMap((res: IApiSingleResponseBase<IPurchaserCurrentJobDetailsOutput>) => of(...jobUpdatedSuccessActions(res))),
                    catchError((error) => {
                        return errorActions(error);
                    })
                );
            }
        })
    );

const createOpinionEpic = createActionPayloadEpic<string>(
    createOpinionAPI,
    createJobOpinionSuccessActions,
    errorActions,
    createJobOpinion().type
);

const sendReportEpic = createActionPayloadEpic<{problem: IReportJobProblemInput; id: string}>(
    updateReportJobProblemAPI,
    sendReportSuccessAction,
    errorActions,
    sendProblem().type
);

const cancelJobEpic = createOperationEpic<IPurchaserCurrentJobDetailsOutput>(
    updateCancelJobAPI,
    cancelJobSuccessAction,
    (error) => errorActions(error, true),
    cancelJob().type
);

const deleteJobEpic = createOperationEpic<null>(deleteJobAPI, deleteJobSuccessAction, errorActions, deleteJob().type);

const checkHistoricJobDetailsEpic: Epic = (action$, state$) =>
    action$.pipe(
        ofType(checkHistoricalJob().type),
        switchMap((action) => {
            function pollForJobCompletion(): Observable<Action> {
                const authToken = authTokenSelector(state$.value);
                return getHistoricalJobAPI(authToken, action.payload).pipe(
                    switchMap((response) => {
                        if (response.isCompleted) {
                            return of(...historicalJobSuccessActions(response));
                        } else {
                            return timer(500).pipe(switchMap(() => pollForJobCompletion()));
                        }
                    }),
                    catchError((error) => {
                        return of(...errorActions(error));
                    })
                );
            }

            return pollForJobCompletion();
        }),
        catchError((error) => {
            return of(...errorActions(error));
        })
    );

const jobEpic = combineEpics(
    getJobEpic,
    checkCanBeReportedEpic,
    sendReportEpic,
    getHistoricalJobEpic,
    checkCanBeCancelledEpic,
    checkCanBeDeletedEpic,
    checkCancellationFeeEpic,
    getJobTeamMembersEpic,
    updateJobEpic,
    createOpinionEpic,
    checkCanCreateOpinionEpic,
    cancelJobEpic,
    deleteJobEpic,
    checkHistoricJobDetailsEpic
);

export default jobEpic;
