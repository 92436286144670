import {PayloadAction} from '@reduxjs/toolkit';
import moment from 'moment';
import {
    IModelApiResponseViewObject,
    IPurchaserJobListingOutput,
    JobStatus,
    PAGINATION_ITEMS_PER_PAGE,
    createBaseReducerSlice,
    isSameValue,
} from 'palipali-panel-common-web';

export interface IJobFilters {
    'createdAt[before]': string | Date;
    'createdAt[after]': string | Date;
    deliveryStatus?: JobStatus[];
    'team.id'?: string;
}

export interface IChangeActiveJobsFilters {
    activeJobsFilters: IJobFilters;
}

export interface ISetJobsMetadata {
    metadata: IModelApiResponseViewObject | null;
}

export interface IChangeHistoricalJobsFilters {
    historicalJobsFilters: IJobFilters;
}

export interface IPaginationParams {
    itemsPerPage: number;
    page: number;
}

export interface JobListingState {
    historicalJobs: IPurchaserJobListingOutput[] | null;
    activeJobs: IPurchaserJobListingOutput[] | null;
    isLoading: boolean;
    isActiveJobsViewInitialized: boolean;
    isHistoricalJobsViewInitialized: boolean;
    error: string | null;
    historicalJobsFilters: IJobFilters;
    activeJobsFilters: IJobFilters;
    activeJobsPagination: IPaginationParams;
    historicalJobsPagination: IPaginationParams;
    activeJobsMetadata: IModelApiResponseViewObject | null;
    historicalJobsMetadata: IModelApiResponseViewObject | null;
}

export interface ISetHistoricalJobListing {
    historicalJobs: IPurchaserJobListingOutput[] | null;
}
export interface ISetActiveJobListing {
    activeJobs: IPurchaserJobListingOutput[] | null;
}

const initialState: JobListingState = {
    historicalJobs: null,
    activeJobs: null,
    isLoading: false,
    isActiveJobsViewInitialized: false,
    isHistoricalJobsViewInitialized: false,
    error: null,
    historicalJobsFilters: {
        deliveryStatus: [],
        'team.id': undefined,
        'createdAt[after]': `${moment().startOf('month')}`,
        'createdAt[before]': `${moment().endOf('day')}`,
    },
    activeJobsFilters: {
        deliveryStatus: [],
        'team.id': undefined,
        'createdAt[after]': `${moment().startOf('month')}`,
        'createdAt[before]': `${moment().endOf('day')}`,
    },
    activeJobsPagination: {
        page: 1,
        itemsPerPage: PAGINATION_ITEMS_PER_PAGE,
    },
    historicalJobsPagination: {
        page: 1,
        itemsPerPage: PAGINATION_ITEMS_PER_PAGE,
    },
    activeJobsMetadata: null,
    historicalJobsMetadata: null,
};

const JobListingSlice = createBaseReducerSlice({
    name: 'jobListing',
    initialState: initialState,
    reducers: {
        getActiveJobList: (state: JobListingState) => {
            return {
                ...state,
                isLoading: true,
            };
        },

        getHistoricalJobList: (state: JobListingState) => {
            return {
                ...state,
                isLoading: true,
            };
        },
        applyHistoricalJobsFilters: (state: JobListingState) => {
            return {
                ...state,
                isLoading: true,
            };
        },
        applyActiveJobsFilters: (state: JobListingState) => {
            return {
                ...state,
                isLoading: true,
            };
        },
        setHistoricalJobsMetadata: (state: JobListingState, action: PayloadAction<ISetJobsMetadata>) => {
            state.historicalJobsMetadata = action.payload.metadata;
        },
        setActiveJobsMetadata: (state: JobListingState, action: PayloadAction<ISetJobsMetadata>) => {
            state.activeJobsMetadata = action.payload.metadata;
        },
        setActiveJobTeamFilter: (state: JobListingState, action: PayloadAction<string>) => {
            state.activeJobsFilters['team.id'] = action.payload;
        },
        changeActiveJobsFilters: {
            reducer: (state: JobListingState, action: PayloadAction<IChangeActiveJobsFilters>) => {
                if (isSameValue(action.payload.activeJobsFilters, state.activeJobsFilters)) {
                    return {
                        ...state,
                    };
                }

                return {
                    ...state,
                    isLoading: true,
                    activeJobsFilters: action.payload.activeJobsFilters,
                };
            },
            prepare: (activeJobsFilters: IJobFilters) => {
                return {
                    payload: {
                        activeJobsFilters: activeJobsFilters,
                    },
                };
            },
        },
        changeHistoricalJobsFilters: {
            reducer: (state: JobListingState, action: PayloadAction<IChangeHistoricalJobsFilters>) => {
                if (isSameValue(action.payload.historicalJobsFilters, state.historicalJobsFilters)) {
                    return {
                        ...state,
                        isLoading: true,
                    };
                }

                return {
                    ...state,
                    isLoading: true,
                    historicalJobsFilters: action.payload.historicalJobsFilters,
                };
            },
            prepare: (historicalJobsFilters: IJobFilters) => {
                return {
                    payload: {
                        historicalJobsFilters: historicalJobsFilters,
                    },
                };
            },
        },
        setActiveJobsList: {
            reducer: (state: JobListingState, action: PayloadAction<ISetActiveJobListing>) => {
                return {
                    ...state,
                    isLoading: false,
                    activeJobs: action.payload.activeJobs,
                };
            },
            prepare: (activeJobs: IPurchaserJobListingOutput[] | null) => {
                return {
                    payload: {
                        activeJobs: activeJobs,
                    },
                };
            },
        },
        changeActiveJobsListingPagination: (state: JobListingState, action: PayloadAction<IPaginationParams>) => {
            state.activeJobsPagination = action.payload;
            state.isLoading = true;
        },
        changeHistoricalJobsListingPagination: (state: JobListingState, action: PayloadAction<IPaginationParams>) => {
            state.historicalJobsPagination = action.payload;
            state.isLoading = true;
        },
        setHistoricalJobsList: {
            reducer: (state: JobListingState, action: PayloadAction<ISetHistoricalJobListing>) => {
                return {
                    ...state,
                    isLoading: false,
                    historicalJobs: action.payload.historicalJobs,
                };
            },
            prepare: (historicalJobs: IPurchaserJobListingOutput[] | null) => {
                return {
                    payload: {
                        historicalJobs: historicalJobs,
                    },
                };
            },
        },
        setLoading: (state: JobListingState, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setActiveJobsViewInitialized: (state: JobListingState, action: PayloadAction<boolean>) => {
            state.isActiveJobsViewInitialized = action.payload;
        },
        setHistoricalJobsViewInitialized: (state: JobListingState, action: PayloadAction<boolean>) => {
            state.isHistoricalJobsViewInitialized = action.payload;
        },
        setError: (state: JobListingState, action: PayloadAction<string | null>) => {
            state.error = action.payload;
        },
        returnToInitialJobListingState: () => {
            return {...initialState};
        },
    },
});

export const {
    getActiveJobList,
    getHistoricalJobList,
    applyHistoricalJobsFilters,
    applyActiveJobsFilters,
    changeActiveJobsFilters,
    changeHistoricalJobsFilters,
    setActiveJobsList,
    setHistoricalJobsMetadata,
    setActiveJobsMetadata,
    setActiveJobTeamFilter,
    setHistoricalJobsList,
    returnToInitialJobListingState,
    changeActiveJobsListingPagination,
    changeHistoricalJobsListingPagination,
    setActiveJobsViewInitialized,
    setHistoricalJobsViewInitialized,
    setLoading,
    setError,
} = JobListingSlice.actions;

export default JobListingSlice.reducer;
