import {PayloadAction} from '@reduxjs/toolkit';
import {
    addAlert,
    AlertType,
    authTokenSelector,
    checkMobileNumberAPI,
    createFetchListEpic,
    createJobAPI,
    createOperationEpic,
    DetailsSuccessActionsFunction,
    flattenObj,
    getLocationListAPI,
    getSystemParametersAPI,
    getTeamMembersAPI,
    handleApiError,
    IJobLocationInput,
    IJobRecipientInput,
    ILocationOutput,
    IModelApiResponseViewObject,
    IPackageSizesOutput,
    IPlacedJobOutput,
    IPurchaserCurrentJobDetailsOutput,
    IRawRestQueryParams,
    isNotNullOrUndefined,
    ISystemParameterOutput,
    ITeamMemberOutput,
    ListSuccessActionsFunction,
    PAGINATION_ITEMS_PER_PAGE,
    placeJobAPI,
    replaceJobAPI,
    SystemParameterCode,
    updateAssignPackageSizeToDimensionsAPI,
} from 'palipali-panel-common-web';
import {push} from 'react-router-redux';
import {combineEpics, Epic, ofType} from 'redux-observable';
import {EMPTY, of} from 'rxjs';
import {catchError, expand, mergeMap, scan, switchMap} from 'rxjs/operators';
import {RootState} from '../reducers';
import {
    assignPackageDimensions,
    changeLocationFilters,
    changeLocationListingPagination,
    createJob,
    CreateJobWizardSteps,
    getParameters,
    getRecipients,
    IGetRecipients,
    IInsuranceParameters,
    placeJob,
    recalculateJobDetails,
    replaceJob,
    resetToInitialCreateJobViewState,
    setAdditionalPaymentAuthorizationClientSecret,
    setCreatedJob,
    setCreateJobLocationsLoading,
    setCreateJobViewError,
    setCreateJobViewLoading,
    setCurrentStep,
    setDimensionsModalLoading,
    setIsOutsideNavigation,
    setJobRecipientData,
    setJobSenderData,
    setLocationListing,
    setMetadata,
    setPackageDimensions,
    setParameters,
    setRecipients,
    setShouldRecalculateJobDetails,
    validateJobRecipientData,
    validateJobSenderData,
} from '../reducers/createJobViewSlice';
import {getActiveJobList} from '../reducers/jobListingSlice';
import {createJobLocationFiltersSelector, createJobLocationPaginationSelector} from '../selectors/createJobViewSelectors';

export interface IServerFormattedLocationFilters {
    'team.id': string | null;
    page: number;
    itemsPerPage?: number;
    name?: string | null;
    'locationType.id'?: string[] | null;
}

const createJobSuccessActions: DetailsSuccessActionsFunction<IPurchaserCurrentJobDetailsOutput> = (
    job: IPurchaserCurrentJobDetailsOutput
) => {
    const createdJob = removeSpecialKeys(job, ['@type', '@id']);
    return [setCreatedJob(createdJob), setCreateJobViewLoading(false), setCurrentStep(CreateJobWizardSteps.JOB_SUMMARY)];
};

const replaceJobSuccessActions: DetailsSuccessActionsFunction<IPurchaserCurrentJobDetailsOutput> = (
    job: IPurchaserCurrentJobDetailsOutput
) => {
    const createdJob = removeSpecialKeys(job, ['@type', '@id']);
    return [
        setCreatedJob(createdJob),
        setCreateJobViewLoading(false),
        setShouldRecalculateJobDetails(false),
        setCurrentStep(CreateJobWizardSteps.JOB_SUMMARY),
    ];
};

const recalculateJobDetailsActionSuccess: DetailsSuccessActionsFunction<IPurchaserCurrentJobDetailsOutput> = (
    createdJob: IPurchaserCurrentJobDetailsOutput
) => {
    return [setCreatedJob(createdJob), setCreateJobViewLoading(false), setShouldRecalculateJobDetails(false)];
};

const placeJobSuccessActions: DetailsSuccessActionsFunction<IPlacedJobOutput> = (o) => {
    if (isNotNullOrUndefined(o.clientSecret)) {
        return [setAdditionalPaymentAuthorizationClientSecret(o.clientSecret)];
    }

    return [
        addAlert({message: 'jobs.createJob.jobSummary.placedJob'}),
        push('/panel/active-orders'),
        getActiveJobList(),
        setCreateJobViewLoading(false),
        resetToInitialCreateJobViewState(),
        setIsOutsideNavigation(false),
    ];
};

const getLocationParameters = (state: RootState): IRawRestQueryParams => {
    const pagination = createJobLocationPaginationSelector(state),
        filters = createJobLocationFiltersSelector(state);
    const params = {
        ...pagination,
        ...filters,
    };
    if (params) {
        const parametersFlattened = flattenObj(params);
        return parametersFlattened;
    }
    return [];
};

const getLocationsSuccessActions: ListSuccessActionsFunction<ILocationOutput> = (
    locationsArray: ILocationOutput[],
    metadata: IModelApiResponseViewObject | null
) => {
    return [
        setLocationListing({locationList: locationsArray}),
        setCreateJobLocationsLoading(false),
        setMetadata({locationsMetadata: metadata}),
    ];
};

const assignPackageSizeToDimensionsSuccessActions: DetailsSuccessActionsFunction<IPackageSizesOutput> = (
    packageSize: IPackageSizesOutput
) => {
    const packageSizes = removeSpecialKeys(packageSize, ['@type', '@id', '@context']);
    return [setPackageDimensions(packageSizes), setDimensionsModalLoading(false)];
};

const getParametersSuccessActions: ListSuccessActionsFunction<ISystemParameterOutput> = (parameters: ISystemParameterOutput[]) => {
    const params: IInsuranceParameters = {
        minValue: 0,
        maxValue: 0,
    };

    params.minValue =
        Number(parameters?.find((item) => item.code === SystemParameterCode.JOB_PRICING_INSURANCE_MINIMUM_VALUE)?.value) / 100 || 0;
    params.maxValue =
        Number(parameters?.find((item) => item.code === SystemParameterCode.JOB_PRICING_INSURANCE_MAXIMUM_VALUE)?.value) / 100 || 0;

    return [setParameters(params)];
};

const errorActions = (error: any): any[] => {
    const errorObj = handleApiError(error);

    return [setCreateJobViewLoading(false), setDimensionsModalLoading(false), setCreateJobViewError(errorObj.message), addAlert(errorObj)];
};

const stripeErrorActions = (error: any): any[] => {
    let errorObj: any;
    if (
        error.response?.['i18n:messageCode'] === 'app.exception.payment_method_cannot_be_captured' &&
        error.response?.['i18n:messageParams']?.[`%code%`]
    ) {
        errorObj = {
            message: `app.exception.${error.response['i18n:messageParams'][`%code%`]}`,
            type: AlertType.ERROR,
        };
    } else {
        errorObj = handleApiError(error);
    }
    return [setCreateJobViewLoading(false), setCreateJobViewError(errorObj.message), addAlert(errorObj)];
};

const errorLocationActions = (error: any): any[] => {
    const errorObj = handleApiError(error);
    return [setCreateJobLocationsLoading(false), setCreateJobViewError(errorObj.message), addAlert(errorObj)];
};

const createJobEpic = createOperationEpic<IPurchaserCurrentJobDetailsOutput>(
    createJobAPI,
    createJobSuccessActions,
    errorActions,
    createJob().type
);

const replaceJobEpic = createOperationEpic<IPurchaserCurrentJobDetailsOutput>(
    replaceJobAPI,
    replaceJobSuccessActions,
    errorActions,
    replaceJob().type
);

const recalculateJobDetailsEpic = createOperationEpic<IPurchaserCurrentJobDetailsOutput>(
    replaceJobAPI,
    recalculateJobDetailsActionSuccess,
    errorActions,
    recalculateJobDetails().type
);

const placeJobEpic = createOperationEpic<IPlacedJobOutput>(placeJobAPI, placeJobSuccessActions, stripeErrorActions, placeJob().type);

const changeLocationListFiltersEpic = createFetchListEpic<ILocationOutput>(
    getLocationListAPI,
    getLocationsSuccessActions,
    errorLocationActions,
    changeLocationFilters().type,
    (state: RootState) => getLocationParameters(state)
);

const changeLocationListPaginationEpic = createFetchListEpic<ILocationOutput>(
    getLocationListAPI,
    getLocationsSuccessActions,
    errorLocationActions,
    changeLocationListingPagination().type,
    (state: RootState) => getLocationParameters(state)
);

const getRecipientsEpic: Epic = (action$, state$) =>
    action$.pipe(
        ofType(getRecipients().type),
        switchMap((action: PayloadAction<IGetRecipients>) => {
            const authToken = authTokenSelector(state$.value);

            const params = {
                'team.id': action.payload.teamId,
                page: 1,
                itemsPerPage: PAGINATION_ITEMS_PER_PAGE,
            };
            const parametersFlattened = flattenObj(params);

            return getTeamMembersAPI(authToken, {}, parametersFlattened).pipe(
                expand((apiRes) => {
                    if (apiRes['hydra:totalItems'] > PAGINATION_ITEMS_PER_PAGE * params.page) {
                        params.page++;
                        const newParametersFlattened = flattenObj(params);
                        return getTeamMembersAPI(authToken, {}, newParametersFlattened);
                    } else {
                        return EMPTY;
                    }
                }),
                scan((acc: ITeamMemberOutput[], recipientsList) => {
                    return [...acc, ...recipientsList['hydra:member']];
                }, []),
                mergeMap((recipientsList) => {
                    return of(setRecipients(recipientsList), setCreateJobViewLoading(false));
                }),
                catchError(errorActions)
            );
        })
    );

const validateJobSenderDataEpic: Epic = (action$, state$) =>
    action$.pipe(
        ofType(validateJobSenderData().type),
        switchMap((action: PayloadAction<{fromLocation: IJobLocationInput; isOutsideNavigation: boolean}>) => {
            const authToken = authTokenSelector(state$.value);
            const phoneData = action.payload.fromLocation.phone;
            if (phoneData) {
                return checkMobileNumberAPI(authToken, phoneData.country, phoneData.phone).pipe(
                    switchMap(() => {
                        return of(setJobSenderData(action.payload.fromLocation), setCurrentStep(CreateJobWizardSteps.RECEIVER_DATA));
                    }),
                    catchError(() => {
                        return of(
                            addAlert({message: 'formValidation.errors.isPhoneValid', type: AlertType.ERROR}),
                            setCreateJobViewLoading(false)
                        );
                    })
                );
            } else {
                return of(setJobSenderData(action.payload.fromLocation, setCurrentStep(CreateJobWizardSteps.RECEIVER_DATA)));
            }
        })
    );

const validateJobRecipientDataEpic: Epic = (action$, state$) =>
    action$.pipe(
        ofType(validateJobRecipientData().type),
        switchMap((action: PayloadAction<IJobRecipientInput>) => {
            const authToken = authTokenSelector(state$.value);
            const recipientData = action.payload;
            if (recipientData.teamMemberId === null && recipientData.phone) {
                return checkMobileNumberAPI(authToken, recipientData.phone.country, recipientData.phone.phone).pipe(
                    switchMap(() => {
                        return of(setJobRecipientData(action.payload), setCurrentStep(CreateJobWizardSteps.ADDITIONAL_DATA));
                    }),
                    catchError(() => {
                        return of(
                            addAlert({message: 'formValidation.errors.isPhoneValid', type: AlertType.ERROR}),
                            setCreateJobViewLoading(false)
                        );
                    })
                );
            } else {
                return of(setJobRecipientData(action.payload), setCurrentStep(CreateJobWizardSteps.ADDITIONAL_DATA));
            }
        })
    );

const getParametersEpic = createFetchListEpic<ISystemParameterOutput>(
    getSystemParametersAPI,
    getParametersSuccessActions,
    errorActions,
    getParameters().type,
    () => []
);

const assignPackageSizeToDimensionEpic = createOperationEpic<IPackageSizesOutput>(
    updateAssignPackageSizeToDimensionsAPI,
    assignPackageSizeToDimensionsSuccessActions,
    errorActions,
    assignPackageDimensions().type
);

const removeSpecialKeys = (obj: {[key: string]: any}, keysToRemove: string[]): IPurchaserCurrentJobDetailsOutput => {
    if (typeof obj === 'object' && obj !== null) {
        for (const key in obj) {
            if (keysToRemove.includes(key)) {
                delete obj[key];
            } else if (typeof obj[key] === 'object') {
                removeSpecialKeys(obj[key], keysToRemove);
            }
        }
    }
    return obj as IPurchaserCurrentJobDetailsOutput;
};

const createJobViewEpic = combineEpics(
    createJobEpic,
    replaceJobEpic,
    placeJobEpic,
    getRecipientsEpic,
    changeLocationListFiltersEpic,
    changeLocationListPaginationEpic,
    assignPackageSizeToDimensionEpic,
    recalculateJobDetailsEpic,
    getParametersEpic,
    validateJobRecipientDataEpic,
    validateJobSenderDataEpic
);

export default createJobViewEpic;
