import {
    FormikFieldTypes,
    FormikFormControlType,
    FormikGroupFieldConfig,
    TextMaskType,
    ValidationErrorType,
    selectPhoneCodeList,
    isPhoneNumberTypeMobile,
} from 'palipali-panel-common-web';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';

const senderDataFormConfig = (): FormikGroupFieldConfig[] => {
    const {t} = useTranslation();

    return [
        {
            formikControlType: FormikFormControlType.GROUP_CONTROL,
            controlsPerRow: 2,
            fields: [
                {
                    name: 'companyName',
                    label: t('jobs.createJob.senderData.formControls.companyName'),
                    validation: Yup.string().max(255).notRequired(),
                    type: FormikFieldTypes.TEXT,
                    controlFlexWidth: 2,
                },
                {
                    name: 'flatNumber',
                    label: t('jobs.createJob.senderData.formControls.flatNumber'),
                    validation: Yup.string().max(255).notRequired(),
                    type: FormikFieldTypes.TEXT,
                    controlFlexWidth: 4,
                },
            ],
        },
        {
            formikControlType: FormikFormControlType.GROUP_CONTROL,
            controlsPerRow: 2,
            fields: [
                {
                    name: 'dialingCode',
                    label: `${t('auth.formControls.dialingCode')}`,
                    validation: Yup.string().required(`${t(ValidationErrorType.IS_REQUIRED)}`),
                    type: FormikFieldTypes.SELECT,
                    className: `account-profile-phone-code`,
                    formControlClassName: 'phone-code-select',
                    flexSettings: '0',
                    isRequired: true,
                    options: selectPhoneCodeList,
                },
                {
                    name: 'phone',
                    label: t('jobs.createJob.senderData.formControls.phone'),
                    validation: Yup.string()
                        .test('is-valid-phone', `${t(ValidationErrorType.IS_MOBILE_NUMBER)}`, function (value) {
                            const {dialingCode} = this.parent;

                            if (!value) return true;

                            try {
                                return isPhoneNumberTypeMobile(dialingCode, value);
                            } catch (error) {
                                return false;
                            }
                        })
                        .required(`${t(ValidationErrorType.IS_REQUIRED)}`),
                    type: FormikFieldTypes.MASKED,
                    textMaskType: TextMaskType.PHONE,
                    flexSettings: '1',
                    isRequired: true,
                },
            ],
        },
    ];
};
export default senderDataFormConfig;
