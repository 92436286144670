import {Button, Grid, Typography, useTheme} from '@mui/material';
import {CustomModalComponent, IOpenHoursOutput, Translation, closeModal, deepCloneObject} from 'palipali-panel-common-web';
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import OpeningHoursControl from '../OpeningHours/OpeningHoursControl';
import {useEffect} from 'react';

interface IOpeningHoursModalProps {
    data: IOpenHoursOutput;
    setNewHoursSet: (data: IOpenHoursOutput) => void;
}

const OpeningHoursModal: React.FC<IOpeningHoursModalProps> = ({data, setNewHoursSet}) => {
    const [isOpenHourInputValid, setIsOpenHourInputValid] = useState<boolean>(false),
        theme = useTheme(),
        dispatch = useDispatch(),
        [isDataPresent, setIsDataPresent] = useState<boolean>(false),
        [isDataValid, setIsDataValid] = useState<boolean>(false),
        initialData = deepCloneObject(data),
        [updatedData, setUpdatedData] = useState<IOpenHoursOutput>(initialData);

    useEffect(() => {
        setIsOpenHourInputValid(isDataPresent && isDataValid);
    }, [isDataPresent, isDataValid]);

    const areSchedulesEqual = (schedule1: IOpenHoursOutput, schedule2: IOpenHoursOutput) => {
        const days = ['_0', '_1', '_2', '_3', '_4', '_5', '_6'] as const;

        for (const dayKey of days) {
            const day1 = schedule1[dayKey];
            const day2 = schedule2[dayKey];
            if ((day1 === null && day2 !== null) || (day1 !== null && day2 === null)) {
                return false;
            }

            if (day1 && day2) {
                if (day1.from !== day2.from || day1.to !== day2.to) {
                    return false;
                }
            }
        }

        return true;
    };

    const onHourChange = (selectedDay: string, openingHour: string, closingHour: string, isDayAvailable: boolean) => {
        const updatedDateData = {
            ...updatedData,
            [selectedDay]: isDayAvailable
                ? {
                      from: openingHour,
                      to: closingHour,
                  }
                : null,
        };
        setUpdatedData(updatedDateData);
    };
    const applyChangesIfValid = () => {
        const areEqual = areSchedulesEqual(initialData, updatedData);
        if (areEqual === true) {
            dispatch(closeModal());
        } else {
            setNewHoursSet(updatedData);
            dispatch(closeModal());
        }
    };
    return (
        <CustomModalComponent
            theme={theme}
            title={
                <Typography color="text.primary" variant="h6" component="h2">
                    <Translation text="locations.locationOperation.openingHours.title" />
                </Typography>
            }
            content={
                <Grid sx={{width: '100%'}} className="opening-hours-modal-card">
                    <div className="opening-hours-card-content">
                        {Object.entries(data).map(([key, value]) => {
                            return (
                                <div key={`day_control_${key}`} className={'day-control'}>
                                    <OpeningHoursControl
                                        data={data}
                                        day={key}
                                        dayData={value}
                                        onHourChange={onHourChange}
                                        setIsDataPresent={setIsDataPresent}
                                        setIsDataValid={setIsDataValid}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </Grid>
            }
            footer={
                <Button
                    className="jobs-job-delete-modal-submit-button"
                    type="button"
                    fullWidth
                    variant="contained"
                    disabled={!isOpenHourInputValid}
                    color="primary"
                    onClick={() => applyChangesIfValid()}>
                    <Translation text={'locations.locationOperation.openingHours.buttons.accept'} />
                </Button>
            }
        />
    );
};

export default OpeningHoursModal;
