import React from 'react';
import {Route, Routes} from 'react-router-dom';
import HistoricalJobList from './HistoricalJobList';
import HistoricalJobView from './HistoricalJobView';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getTeamListing, isAuthTokenExpiredSelector, setTeamListing} from 'palipali-panel-common-web';
import {setHistoricalJobsViewInitialized} from 'src/store/reducers/jobListingSlice';

const HistoricalJobs: React.FC = () => {
    const dispatch = useDispatch();
    const isAuthTokenExpired: boolean = useSelector(isAuthTokenExpiredSelector);

    useEffect(() => {
        if (!isAuthTokenExpired) {
            dispatch(getTeamListing());
        }

        return () => {
            dispatch(setTeamListing({teamList: []}));
            dispatch(setHistoricalJobsViewInitialized(false));
        };
    }, [isAuthTokenExpired]);
    return (
        <Routes>
            <Route path="/:id" element={<HistoricalJobView />} key="historical-job-view" />
            <Route path="/" element={<HistoricalJobList />} key="historical-job-list" />
        </Routes>
    );
};

export default HistoricalJobs;
