import {SvgIcon} from '@mui/material';
import {MemberOperationType, openModal, LogOut01, UserSquare} from 'palipali-panel-common-web';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

export const useSubMenuSections = () => {
    const {t} = useTranslation(),
        dispatch = useDispatch();

    return useMemo(() => {
        return [
            {
                items: [
                    {
                        title: t('menuItems.account'),
                        path: '/panel/account',
                        icon: (
                            <SvgIcon fontSize="large">
                                <UserSquare />
                            </SvgIcon>
                        ),
                        class: 'account-menu-item',
                    },
                    {
                        title: t('menuItems.logout'),
                        onClick: () => {
                            dispatch(openModal(MemberOperationType.LOGOUT));
                        },
                        icon: (
                            <SvgIcon fontSize="large">
                                <LogOut01 />
                            </SvgIcon>
                        ),
                        class: 'logout-menu-item',
                    },
                ],
            },
        ];
    }, [t]);
};
