import {Card, Divider, Grid, useTheme} from '@mui/material';
import {useFormik} from 'formik';
import {
    CustomTablePagination,
    FormikForm,
    ILocationOutput,
    ILocationTypeOutput,
    IModelApiResponseViewObject,
    ITeamListingOutput,
    Loader,
    LoaderType,
    PAGINATION_ITEMS_PER_PAGE,
    firstTeamFromOwnerOrManagerRoleSelector,
    getTeamListing,
    locationTypesSelector,
    locationsTeamListingSelector,
    teamsFromOwnerOrManagerRoleSelector,
} from 'palipali-panel-common-web';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import LocationListTable from 'src/components/shared/LocationListTable';
import {
    ILocationFilters,
    IPaginationParams,
    changeLocationFilters,
    changeLocationListingPagination,
} from '../../../../store/reducers/locationListingSlice';
import {
    isLocationListingLoadingSelector,
    locationFiltersSelector,
    locationListingMetadataSelector,
    locationListingPaginationSelector,
} from '../../../../store/selectors/locationListingSelectors';
import locationFiltersFormConfig from './locationFiltersFormConfig';

interface ILocationListCardProps {
    locations?: ILocationOutput[];
    teamId?: string;
    onTeamChange?: (teamId: string | null) => void;
}

interface ILocationsFormValues {
    team: string;
}

const LocationListCard: React.FC<ILocationListCardProps> = ({teamId, locations, onTeamChange}) => {
    const theme = useTheme(),
        locationTypes: ILocationTypeOutput[] = useSelector(locationTypesSelector),
        pagination: IPaginationParams = useSelector(locationListingPaginationSelector),
        metadata: IModelApiResponseViewObject | null = useSelector(locationListingMetadataSelector),
        locationFilters: ILocationFilters = useSelector(locationFiltersSelector),
        isLoading = useSelector(isLocationListingLoadingSelector),
        teams: ITeamListingOutput[] = useSelector(locationsTeamListingSelector),
        teamsOwnedOrManaged: string[] = useSelector(teamsFromOwnerOrManagerRoleSelector),
        firstTeamFromOwnerList: string | undefined = useSelector(firstTeamFromOwnerOrManagerRoleSelector),
        filteredTeams = teams.filter((team) => teamsOwnedOrManaged.includes(team.id)),
        defaultTeamValue = teamId ? teamId : locationFilters['team.id'] ? locationFilters['team.id'] : '',
        initialValues: ILocationsFormValues = {
            team: defaultTeamValue ? defaultTeamValue : firstTeamFromOwnerList ? firstTeamFromOwnerList : '',
        },
        dispatch = useDispatch();

    useEffect(() => {
        if (onTeamChange && initialValues.team) {
            onTeamChange(initialValues.team);
        }
        dispatch(getTeamListing());
    }, []);

    const teamListSelect = () => {
        const setOptions = filteredTeams?.map((team: ITeamListingOutput) => ({
            value: team.id,
            label: team.name,
        }));
        return setOptions;
    };

    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: () => {
            return;
        },
    });

    useEffect(() => {
        const payload = {
            ...locationFilters,
            ['team.id']: locationFilters['team.id'] ? locationFilters['team.id'] : firstTeamFromOwnerList ? firstTeamFromOwnerList : null,
        };
        dispatch(changeLocationFilters(payload, 1));
    }, []);

    const renderFilters = () => {
        return (
            <Grid className="filters-wrapper">
                <Grid className="tab-filters"></Grid>
                <Grid className="input-filters">
                    <div className="dummy-flex-div"></div>
                    <FormikForm
                        fields={locationFiltersFormConfig(teamListSelect())}
                        formId="location-list-filters"
                        formClassName="horizontal-form"
                        initialValues={initialValues}
                        updatedValues={formik.values}
                        customEventChange={(formControlName: string, value: string) => {
                            formik.setFieldValue('team', value);

                            const payload = {
                                ...locationFilters,
                                ['team.id']: value,
                            };
                            dispatch(changeLocationFilters(payload));

                            if (onTeamChange) {
                                onTeamChange(value);
                            }
                        }}
                        theme={theme}
                        onSubmit={() => null}
                    />
                </Grid>
            </Grid>
        );
    };

    return (
        <Card className="custom-card">
            {renderFilters()}
            <Divider />
            <LocationListTable
                isLoading={isLoading}
                pagination={pagination}
                locations={locations ? locations : []}
                locationTypes={locationTypes}
                teamId={formik.values.team}
            />

            <CustomTablePagination
                metadata={metadata}
                onChangePage={(event, pageNumber) =>
                    dispatch(changeLocationListingPagination({page: pageNumber, itemsPerPage: PAGINATION_ITEMS_PER_PAGE}))
                }
                page={pagination.page}
                rowsPerPage={PAGINATION_ITEMS_PER_PAGE}
            />
            <Loader showLoader={isLoading} type={LoaderType.Local} />
        </Card>
    );
};

export default LocationListCard;
