import {Button, Stack, Typography} from '@mui/material';
import {
    MemberOperationType,
    Translation,
    openModal,
    HorizontalCard,
    isModalOpenSelector,
    currentlySelectedModalTypeSelector,
} from 'palipali-panel-common-web';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {deleteLocation} from 'src/store/reducers/locationViewSlice';
import {isLocationLoadingSelector} from '../../../../store/selectors/locationViewSelectors';
import DeleteLocationModal from './DeleteLocationModal';
import {LocationOperationType} from '..';

interface IDeleteLocationProps {
    locationId: string;
    operationType: LocationOperationType;
}

const DeleteLocation: React.FC<IDeleteLocationProps> = ({locationId, operationType}) => {
    const dispatch = useDispatch(),
        isModalOpen = useSelector(isModalOpenSelector),
        modalType = useSelector(currentlySelectedModalTypeSelector),
        isLoading = useSelector(isLocationLoadingSelector);

    const deleteSelectedLocation = () => {
        dispatch(deleteLocation(locationId));
    };

    return (
        <HorizontalCard
            heading={<Translation text={'locations.locationOperation.deleteLocation.title'} />}
            content={
                <Stack spacing={3} direction={'row'}>
                    <Stack>
                        <Typography className="title"></Typography>
                    </Stack>
                    <Stack spacing={3} sx={{alignItems: 'flex-start'}}>
                        <Typography className="description">
                            <Translation text={'locations.locationOperation.deleteLocation.description'} />
                        </Typography>
                        <Button
                            color="error"
                            disabled={isLoading || !locationId}
                            variant="outlined"
                            onClick={() => dispatch(openModal(MemberOperationType.LOCATIONS_DELETE_LOCATION))}>
                            <Translation text={'locations.locationOperation.deleteLocation.deleteButton'} />
                        </Button>
                    </Stack>
                    {isModalOpen &&
                        modalType === MemberOperationType.LOCATIONS_DELETE_LOCATION &&
                        locationId &&
                        operationType === LocationOperationType.EDIT && (
                            <DeleteLocationModal isLoading={isLoading} deleteLocation={deleteSelectedLocation} />
                        )}
                </Stack>
            }
        />
    );
};

export default DeleteLocation;
