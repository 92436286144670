import {FormikFieldTypes, FormikFormControlType, FormikGroupFieldConfig} from 'palipali-panel-common-web';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';

const receiverDataFormConfig = (): FormikGroupFieldConfig[] => {
    const {t} = useTranslation();

    return [
        {
            formikControlType: FormikFormControlType.GROUP_CONTROL,
            controlsPerRow: 2,
            fields: [
                {
                    name: 'companyName',
                    label: t('jobs.createJob.senderData.formControls.companyName'),
                    validation: Yup.string().max(255).notRequired(),
                    type: FormikFieldTypes.TEXT,
                    controlFlexWidth: 2,
                },
                {
                    name: 'flatNumber',
                    label: t('jobs.createJob.senderData.formControls.flatNumber'),
                    validation: Yup.string().max(255).notRequired(),
                    type: FormikFieldTypes.TEXT,
                    controlFlexWidth: 4,
                },
            ],
        },
    ];
};
export default receiverDataFormConfig;
