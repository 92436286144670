import {isAuthenticatedSelector} from 'palipali-panel-common-web';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Navigate} from 'react-router-dom';

const PANEL_BASE_URL = '/panel/active-orders/';
const AUTH_BASE_URL = '/auth/login/';
const Home: React.FC = () => {
    const isAuthenticated = useSelector(isAuthenticatedSelector),
        [isAuthenticatedState, setIsAuthenticated] = useState(isAuthenticated);

    useEffect(() => {
        setIsAuthenticated(isAuthenticated);
    }, [isAuthenticated]);

    const path = isAuthenticatedState ? PANEL_BASE_URL : AUTH_BASE_URL;

    return <Navigate replace to={path} />;
};

export default Home;
