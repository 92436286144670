import {PayloadAction} from '@reduxjs/toolkit';
import {
    IPurchaserCurrentJobDetailsOutput,
    createBaseReducerSlice,
    IReportJobProblemInput,
    IPurchaserJobDetailsOutput,
    IMoneyOutput,
    ITeamMemberBasicOutput,
    isSameValue,
    IUpdateJobInput,
    IJobCancelInput,
} from 'palipali-panel-common-web';

export interface JobState {
    job: IPurchaserCurrentJobDetailsOutput | null;
    canBeReported: boolean;
    canBeCancelled: boolean;
    canBeDeleted: boolean;
    canCreateOpinion: boolean;
    cancellationFee: IMoneyOutput | null;
    isLoading: boolean;
    isInitialized: boolean;
    error: string | null;
    redirectToJob: boolean;
    redirectToHistoricalJobView: boolean;
    jobTeamMembers: ITeamMemberBasicOutput[];
    jobTeamMembersFilters: IJobTeamMembersFilter;
    redirectToActiveJobsList: boolean;
}

export interface IJobTeamMembersFilter {
    team: {id: string | null};
}

export interface ISetJob {
    job: IPurchaserCurrentJobDetailsOutput;
}

export interface ISetJobTeamMembers {
    jobTeamMembers: ITeamMemberBasicOutput[];
}

export interface ISetRedirectToActiveJobsList {
    redirectToActiveJobsList: boolean;
}

export interface ISetRedirectToHistoricalJobView {
    redirectToHistoricalJobView: boolean;
}

export interface IChangeJobTeamMembersFilter {
    jobTeamMembersFilters: IJobTeamMembersFilter;
}

export interface IChangeCanBeReported {
    canBeReported: boolean;
}

export interface IChangeCanBeCancelled {
    canBeCancelled: boolean;
}

export interface IChangeCanBeDeleted {
    canBeDeleted: boolean;
}

export interface IChangeCanCreateOpinion {
    canCreateOpinion: boolean;
}

export interface ISetCancellationFee {
    cancellationFee: IMoneyOutput | null;
}

export interface IChangeRedirectToJobList {
    redirectToJob: boolean;
}

const initialState: JobState = {
    job: null,
    canBeReported: false,
    canBeCancelled: false,
    canBeDeleted: false,
    canCreateOpinion: false,
    cancellationFee: null,
    isLoading: false,
    isInitialized: false,
    error: null,
    redirectToJob: false,
    redirectToHistoricalJobView: false,
    jobTeamMembers: [],
    jobTeamMembersFilters: {
        team: {
            id: '',
        },
    },
    redirectToActiveJobsList: false,
};

const JobSlice = createBaseReducerSlice({
    name: 'job',
    initialState: initialState,
    reducers: {
        getJob: (state: JobState) => {
            return {
                ...state,
                isLoading: true,
            };
        },
        getHistoricalJob: (state: JobState) => {
            return {
                ...state,
                isLoading: true,
            };
        },
        checkHistoricalJob: (state: JobState) => {
            return {
                ...state,
                isLoading: true,
            };
        },
        setLoading: (state: JobState, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setError: (state: JobState, action: PayloadAction<string | null>) => {
            state.error = action.payload;
        },
        setJob: {
            reducer: (state: JobState, action: PayloadAction<ISetJob>) => {
                return {
                    ...state,
                    isLoading: false,
                    job: action.payload.job,
                };
            },
            prepare: (job: IPurchaserCurrentJobDetailsOutput | IPurchaserJobDetailsOutput) => {
                return {
                    payload: {
                        job: job,
                    },
                };
            },
        },
        sendProblem: {
            reducer: (state: JobState) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
            prepare: (problem: IReportJobProblemInput | null, id: string) => {
                return {
                    payload: {
                        problem: problem,
                        id: id,
                    },
                };
            },
        },
        checkCanBeReported: (state: JobState) => {
            return {
                ...state,
            };
        },
        changeCanBeReported: {
            reducer: (state: JobState, action: PayloadAction<IChangeCanBeReported>) => {
                return {
                    ...state,
                    canBeReported: action.payload.canBeReported,
                };
            },
            prepare: (canBeReported: boolean) => {
                return {
                    payload: {
                        canBeReported,
                    },
                };
            },
        },
        checkCanBeCancelled: (state: JobState) => {
            return {
                ...state,
            };
        },
        changeCanBeCancelled: {
            reducer: (state: JobState, action: PayloadAction<IChangeCanBeCancelled>) => {
                return {
                    ...state,
                    canBeCancelled: action.payload.canBeCancelled,
                };
            },
            prepare: (canBeCancelled: boolean) => {
                return {
                    payload: {
                        canBeCancelled,
                    },
                };
            },
        },
        checkCanBeDeleted: (state: JobState) => {
            return {
                ...state,
            };
        },
        changeCanBeDeleted: {
            reducer: (state: JobState, action: PayloadAction<IChangeCanBeDeleted>) => {
                return {
                    ...state,
                    canBeDeleted: action.payload.canBeDeleted,
                };
            },
            prepare: (canBeDeleted: boolean) => {
                return {
                    payload: {
                        canBeDeleted,
                    },
                };
            },
        },
        checkCanCreateOpinion: (state: JobState) => {
            return {
                ...state,
            };
        },
        changeCanCreateOpinion: {
            reducer: (state: JobState, action: PayloadAction<IChangeCanCreateOpinion>) => {
                return {
                    ...state,
                    canCreateOpinion: action.payload.canCreateOpinion,
                };
            },
            prepare: (canCreateOpinion: boolean) => {
                return {
                    payload: {
                        canCreateOpinion,
                    },
                };
            },
        },
        checkCancellationFee: (state: JobState) => {
            return {
                ...state,
            };
        },
        setCancellationFee: {
            reducer: (state: JobState, action: PayloadAction<ISetCancellationFee>) => {
                return {
                    ...state,
                    cancellationFee: action.payload.cancellationFee,
                };
            },
            prepare: (cancellationFee: IMoneyOutput | null) => {
                return {
                    payload: {
                        cancellationFee,
                    },
                };
            },
        },
        setRedirectToJob: {
            reducer: (state: JobState, action: PayloadAction<IChangeRedirectToJobList>) => {
                return {
                    ...state,
                    redirectToJob: action.payload.redirectToJob,
                };
            },
            prepare: (redirectToJobList: boolean) => {
                return {
                    payload: {
                        redirectToJobList: redirectToJobList,
                    },
                };
            },
        },
        getJobTeamMembers: (state: JobState) => {
            return {
                ...state,
                isLoading: true,
            };
        },
        setJobTeamMembers: {
            reducer: (state: JobState, action: PayloadAction<ISetJobTeamMembers>) => {
                return {
                    ...state,
                    isLoading: false,
                    jobTeamMembers: action.payload.jobTeamMembers,
                };
            },
            prepare: (jobTeamMembers: ITeamMemberBasicOutput[]) => {
                return {
                    payload: {
                        jobTeamMembers: jobTeamMembers,
                    },
                };
            },
        },
        setJobTeamMembersFilter: {
            reducer: (state: JobState, action: PayloadAction<IJobTeamMembersFilter>) => {
                if (isSameValue(action.payload.team.id, state.jobTeamMembersFilters.team.id)) {
                    return {
                        ...state,
                    };
                }

                return {
                    ...state,
                    isLoading: true,
                    jobTeamMembersFilters: action.payload,
                };
            },
            prepare: (id: string) => {
                return {
                    payload: {
                        team: {id: id},
                    },
                };
            },
        },
        updateJob: {
            reducer: (state: JobState) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
            prepare(id: string, job: IUpdateJobInput) {
                return {
                    payload: {id: id, job: job},
                };
            },
        },
        createJobOpinion: {
            reducer: (state: JobState) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
            prepare(id: string, rating: number) {
                return {
                    payload: {id: id, rating: rating},
                };
            },
        },
        cancelJob: {
            reducer: (state: JobState) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
            prepare: (cancelReason: IJobCancelInput, id: string) => {
                return {
                    payload: {
                        cancelReason: cancelReason,
                        id: id,
                    },
                };
            },
        },
        deleteJob: {
            reducer: (state: JobState) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
            prepare: (id: string) => {
                return {
                    payload: {
                        id: id,
                    },
                };
            },
        },
        redirectToActiveJobsList: {
            reducer: (state: JobState, action: PayloadAction<ISetRedirectToActiveJobsList>) => {
                return {
                    ...state,
                    redirectToActiveJobsList: action.payload.redirectToActiveJobsList,
                };
            },
            prepare: (redirectToActiveJobsList: boolean) => {
                return {
                    payload: {
                        redirectToActiveJobsList: redirectToActiveJobsList,
                    },
                };
            },
        },
        redirectToHistoricalJobView: {
            reducer: (state: JobState, action: PayloadAction<ISetRedirectToHistoricalJobView>) => {
                return {
                    ...state,
                    redirectToHistoricalJobView: action.payload.redirectToHistoricalJobView,
                };
            },
            prepare: (redirectToHistoricalJobView: boolean) => {
                return {
                    payload: {
                        redirectToHistoricalJobView: redirectToHistoricalJobView,
                    },
                };
            },
        },
        returnToInitialJobState: () => {
            return {...initialState};
        },
    },
});

export const {
    getJob,
    setError,
    setInitialized,
    setLoading,
    setJob,
    getHistoricalJob,
    checkHistoricalJob,
    returnToInitialJobState,
    sendProblem,
    redirectToHistoricalJobView,
    changeCanBeReported,
    changeCanBeCancelled,
    changeCanBeDeleted,
    checkCanBeReported,
    checkCanBeCancelled,
    checkCanBeDeleted,
    setRedirectToJob,
    checkCancellationFee,
    setCancellationFee,
    getJobTeamMembers,
    setJobTeamMembers,
    setJobTeamMembersFilter,
    redirectToActiveJobsList,
    updateJob,
    createJobOpinion,
    checkCanCreateOpinion,
    changeCanCreateOpinion,
    cancelJob,
    deleteJob,
} = JobSlice.actions;

export default JobSlice.reducer;
