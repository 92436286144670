import {Grid, Stack} from '@mui/material';
import React from 'react';
import SettingsCard from './SettingsCard';

const SettingsTab: React.FC = () => {
    return (
        <Grid sx={{display: 'flex', flexDirection: 'column'}}>
            <Stack spacing={5}>
                <SettingsCard />
            </Stack>
        </Grid>
    );
};

export default SettingsTab;
