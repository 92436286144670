import {Box, Button, Stack, Typography} from '@mui/material';
import {Translation, ChevronRight} from 'palipali-panel-common-web';
import type {Page as PageType} from 'palipali-panel-common-web';
import icon from '../../../assets/images/no-teams-icon.png';
import {useNavigate} from 'react-router-dom';

const NoTeamsInfoView: PageType = () => {
    const navigate = useNavigate();

    return (
        <Box className="content-view" sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <Box className="panel-info-box" sx={{backgroundColor: 'primary.light'}}>
                <div className="info-box-icon">
                    <img alt="No teams info icon" src={icon} />
                </div>
                <Stack className="info-box">
                    <Typography variant="overline" color="primary.main">
                        <Translation text="activeJobs.noTeamsInfo.subtitle" />
                    </Typography>
                    <Stack className="info-box-description">
                        <Typography variant="h6" component="h2">
                            <Translation text="activeJobs.noTeamsInfo.title" />
                        </Typography>
                        <Typography variant="body1" component="p">
                            <Translation text="activeJobs.noTeamsInfo.description" />
                        </Typography>
                    </Stack>
                    <Button
                        variant="contained"
                        color="primary"
                        size="medium"
                        endIcon={<ChevronRight size={16} />}
                        onClick={() => navigate(`/panel/teams/create`)}>
                        <Translation text="activeJobs.noTeamsInfo.button" />
                    </Button>
                </Stack>
            </Box>
        </Box>
    );
};

export default NoTeamsInfoView;
