import {Button, Grid, useTheme} from '@mui/material';
import {
    DateRange,
    FormikForm,
    IMultiselectOption,
    ITeamListingOutput,
    JobStatus,
    Translation,
    activeValidTeamListingSelector,
    deepCloneObject,
    serverFormattedDateWithCustomTime,
} from 'palipali-panel-common-web';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {IJobFilters, changeActiveJobsFilters, setActiveJobsViewInitialized} from '../../../../store/reducers/jobListingSlice';
import {redirectToActiveJobsList} from '../../../../store/reducers/jobSlice';
import {activeJobListingInitializedSelector, jobActiveListingFiltersSelector} from '../../../../store/selectors/jobListingSelector';
import {redirectToActiveJobsListSelector} from '../../../../store/selectors/jobSelector';
import {activeJobListFiltersFormConfig} from './activeJobListFiltersFormConfig';

const ActiveJobListFilters: React.FC = () => {
    const theme = useTheme(),
        dispatch = useDispatch(),
        jobFilters: IJobFilters = useSelector(jobActiveListingFiltersSelector),
        redirectToActiveJobs = useSelector(redirectToActiveJobsListSelector),
        isInitialized = useSelector(activeJobListingInitializedSelector),
        teams: ITeamListingOutput[] = useSelector(activeValidTeamListingSelector);

    const teamListSelect = () => {
        const setOptions = teams?.map((team: ITeamListingOutput) => ({
            value: team.id,
            label: team.name,
        }));

        return setOptions;
    };

    const [listSelect, setList] = useState<IMultiselectOption[]>([]),
        allTeams = teams?.map((team) => team.id),
        initialValues: IJobFilters = {
            deliveryStatus: jobFilters.deliveryStatus ? jobFilters.deliveryStatus : undefined,
            'team.id': jobFilters?.['team.id'] && jobFilters['team.id'].length > 0 ? jobFilters['team.id'] : allTeams[0],
            'createdAt[after]': jobFilters['createdAt[after]'],
            'createdAt[before]': jobFilters['createdAt[before]'],
        };

    useEffect(() => {
        if (redirectToActiveJobs) {
            dispatch(redirectToActiveJobsList(false));
        }
    }, [redirectToActiveJobs]);

    useEffect(() => {
        if (teams && teams.length > 0) {
            setList(teamListSelect());
            if (!isInitialized) {
                dispatch(setActiveJobsViewInitialized(true));
                const initialValuesCopy = deepCloneObject(initialValues);
                dispatch(changeActiveJobsFilters(initialValuesCopy));
            }
        }
    }, [isInitialized, teams]);

    const changeDeliveryStatusFilter = (status: JobStatus[]) => {
        const newFilters: IJobFilters = deepCloneObject(jobFilters);
        newFilters.deliveryStatus = status;
        dispatch(changeActiveJobsFilters(newFilters));
    };
    const changeTeamFilter = (team: string) => {
        const newFilters: IJobFilters = deepCloneObject(jobFilters);
        newFilters['team.id'] = team;
        dispatch(changeActiveJobsFilters(newFilters));
    };

    const handleFormChange = (formControlName: string, value: string | DateRange) => {
        if (formControlName === 'team.id' && value !== '') {
            const selectedValue = value as string;
            changeTeamFilter(selectedValue);
        } else if (formControlName === 'createdFilter') {
            const dateRange: DateRange = value as DateRange;
            if (dateRange.startDate && dateRange.endDate) {
                const formattedStartDate = serverFormattedDateWithCustomTime(dateRange.startDate, 'startOfDay'),
                    formattedEndDate = serverFormattedDateWithCustomTime(dateRange.endDate, 'endOfDay'),
                    newFilters = deepCloneObject(jobFilters);
                (newFilters['createdAt[after]'] = formattedStartDate), (newFilters['createdAt[before]'] = formattedEndDate);
                dispatch(changeActiveJobsFilters(newFilters));
            }
        }
    };

    const initialDates: DateRange = {
        startDate: new Date(jobFilters['createdAt[after]']),
        endDate: new Date(jobFilters['createdAt[before]']),
    };
    return (
        <Grid className="filters-wrapper">
            <Grid className="tab-filters">
                <Button
                    variant="outlined"
                    className={`tab-filter ${
                        jobFilters.deliveryStatus && jobFilters.deliveryStatus?.length > 0 ? null : 'active'
                    } job-listing-status-filter-button-all`}
                    onClick={() => changeDeliveryStatusFilter([])}>
                    <Translation text="jobs.formControls.status.all" />
                </Button>
                <Button
                    variant="outlined"
                    className={`tab-filter ${
                        jobFilters.deliveryStatus &&
                        (jobFilters.deliveryStatus.includes(JobStatus.PROBLEM_C) ||
                            jobFilters.deliveryStatus.includes(JobStatus.PROBLEM_P) ||
                            jobFilters.deliveryStatus.includes(JobStatus.SEARCHING))
                            ? 'active'
                            : null
                    }
                                job-listing-status-filter-button-problem`}
                    onClick={() => changeDeliveryStatusFilter([JobStatus.SEARCHING])}>
                    <Translation text="jobs.formControls.status.searching" />
                </Button>
                <Button
                    variant="outlined"
                    className={`tab-filter ${
                        jobFilters.deliveryStatus &&
                        (jobFilters.deliveryStatus.includes(JobStatus.COMPLETED) ||
                            jobFilters.deliveryStatus.includes(JobStatus.DELIVERY_DRIVE) ||
                            jobFilters.deliveryStatus.includes(JobStatus.PICKUP) ||
                            jobFilters.deliveryStatus.includes(JobStatus.PICKUP_DRIVE) ||
                            jobFilters.deliveryStatus.includes(JobStatus.DELIVERY))
                            ? 'active'
                            : null
                    }
                                job-listing-status-filter-button-delivered`}
                    onClick={() =>
                        changeDeliveryStatusFilter([JobStatus.DELIVERY_DRIVE, JobStatus.PICKUP, JobStatus.PICKUP_DRIVE, JobStatus.DELIVERY])
                    }>
                    <Translation text="jobs.formControls.status.delivery" />
                </Button>
                <Button
                    variant="outlined"
                    className={`tab-filter ${
                        jobFilters.deliveryStatus &&
                        (jobFilters.deliveryStatus.includes(JobStatus.CANCELLED_P) ||
                            jobFilters.deliveryStatus.includes(JobStatus.CANCELLED_C) ||
                            jobFilters.deliveryStatus.includes(JobStatus.PLANNED))
                            ? 'active'
                            : null
                    }
                                job-listing-status-filter-button-cancelled`}
                    onClick={() => changeDeliveryStatusFilter([JobStatus.PLANNED])}>
                    <Translation text="jobs.formControls.status.planned" />
                </Button>
            </Grid>
            <Grid className="input-filters">
                <FormikForm
                    fields={activeJobListFiltersFormConfig(initialDates, listSelect)}
                    formId="active-job-list-filters"
                    formClassName="active-job-list-filters-form horizontal-form"
                    initialValues={initialValues}
                    enableReinitialize={true}
                    customEventChange={handleFormChange}
                    theme={theme}
                    onSubmit={() => null}
                />
            </Grid>
        </Grid>
    );
};

export default ActiveJobListFilters;
