import {Stack} from '@mui/material';
import {ITeamViewOutput} from 'palipali-panel-common-web';
import React from 'react';
import PaymentMethodCard from './PaymentMethodCard';
import TeamDeleteCard from './TeamDeleteCard';
import TeamDetailsCard from './TeamDetailsCard';

interface ITeamDetailsTabProps {
    teamView: ITeamViewOutput;
}

const TeamDetailsTab: React.FC<ITeamDetailsTabProps> = ({teamView}) => {
    const path = window.location.pathname,
        editMode = path.includes('edit');

    return (
        <Stack spacing={5}>
            <TeamDetailsCard teamView={teamView} editMode={editMode} />
            <PaymentMethodCard teamView={teamView} editMode={editMode} />
            <TeamDeleteCard teamView={teamView} editMode={editMode} />
        </Stack>
    );
};

export default TeamDetailsTab;
