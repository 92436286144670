import {Button, Unstable_Grid2 as Grid, Stack, Typography, useTheme} from '@mui/material';
import {
    CustomModalComponent,
    FormikForm,
    IModelJobProblemType,
    Translation,
    purchaserJobProblemTypesSelector,
    ChevronRight,
} from 'palipali-panel-common-web';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router';
import {redirectToHistoricalJobView, sendProblem} from '../../../store/reducers/jobSlice';
import {redirectToHistoricalJobViewSelector} from '../../../store/selectors/jobSelector';
import reportIssueFormConfig from './reportIssueFormConfig';

interface ReportIssueModalProps {
    id: string | null;
}

interface ReportIssueFormValues {
    description: string | undefined;
    imgId: string | undefined;
}

interface IssuePayload {
    id: string | null;
    type: string | null;
    code?: string | null;
}

const ReportIssueModal: React.FC<ReportIssueModalProps> = ({id}) => {
    const dispatch = useDispatch(),
        allowedReports: IModelJobProblemType[] = useSelector(purchaserJobProblemTypesSelector),
        initialValues: ReportIssueFormValues = {
            description: undefined,
            imgId: undefined,
        },
        [isValid, setIsValid] = useState<boolean>(false),
        isRedirectToHistoricalJobView = useSelector(redirectToHistoricalJobViewSelector),
        location = useLocation().pathname.split('/'),
        jobType = location[3],
        navigate = useNavigate();

    const onSubmitAction = (values: ReportIssueFormValues) => {
        const mediaObjectId = values.imgId;
        const payload = {
            problemTypeId: issue.id,
            description: values.description,
            mediaObjectId: mediaObjectId ? mediaObjectId : null,
        };
        dispatch(sendProblem(payload, id));
        return;
    };

    useEffect(() => {
        if (isRedirectToHistoricalJobView === true && jobType === 'active') {
            navigate(`/panel/historical-order/${id}`);
        } else {
            dispatch(redirectToHistoricalJobView(false));
        }
    }, [isRedirectToHistoricalJobView]);

    useEffect(() => {
        return () => {
            setIssue({
                id: null,
                type: null,
                code: null,
            });
        };
    }, []);

    const [issue, setIssue] = useState<IssuePayload>({
        id: null,
        type: null,
        code: null,
    });

    const theme = useTheme();

    return (
        <CustomModalComponent
            theme={theme}
            customClasses="report-issue-modal"
            title={
                <Typography color="text.primary" variant="h6" component="h2">
                    {!issue ? <Translation text={`jobs.report.title`} /> : <Translation text={`jobs.report.describeSituation`} />}
                </Typography>
            }
            content={
                <Grid sx={{width: '100%'}}>
                    <Stack className={`report-list ${issue.type === null ? '' : 'sr-only'}`}>
                        {allowedReports
                            ? allowedReports?.map((item: IModelJobProblemType) => {
                                  return (
                                      <div
                                          key={item.id}
                                          className="report-item"
                                          onClick={() =>
                                              setIssue({
                                                  id: item.id,
                                                  type: item.name,
                                                  code: item.code,
                                              })
                                          }>
                                          <Typography>{item.name}</Typography>
                                          <div>
                                              <ChevronRight />
                                          </div>
                                      </div>
                                  );
                              })
                            : null}
                    </Stack>
                    <Stack className={`${issue.type === null ? 'sr-only' : ''} report-problem-form-wrapper`}>
                        <div className="report-problem-header">
                            <Typography variant="subtitle1" component="h3" color="text.primary">
                                {issue.type}
                            </Typography>
                        </div>
                        <FormikForm
                            fields={reportIssueFormConfig(issue.code === 'other')}
                            formId="report-issue-form"
                            initialValues={initialValues}
                            submitAllowed={setIsValid}
                            theme={theme}
                            onSubmit={onSubmitAction}
                        />
                    </Stack>
                </Grid>
            }
            footer={
                issue.type !== null ? (
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        form="report-issue-form"
                        disabled={issue.code === 'other' && !isValid}>
                        <Translation text={`jobs.report.buttons.reportProblem`} />
                    </Button>
                ) : undefined
            }
        />
    );
};

export default ReportIssueModal;
