import * as Yup from 'yup';
import {
    FormikFieldTypes,
    FormikFormControlType,
    FormikGroupFieldConfig,
    FormikRegexSettings,
    IMultiselectOption,
    TextMaskType,
    ValidationErrorType,
    selectPhoneCodeList,
    isPhoneNumberTypeMobile,
} from 'palipali-panel-common-web';
import {useTranslation} from 'react-i18next';

const recipientDataFormConfig = (editRecipientData: boolean, recipientsArray: IMultiselectOption[] | []): FormikGroupFieldConfig[] => {
    const {t} = useTranslation();

    const baseFormFields: FormikGroupFieldConfig[] = [
        {
            formikControlType: FormikFormControlType.GROUP_CONTROL,
            fields: [
                {
                    name: 'editRecipientData',
                    label: t('jobs.editOrder.fillRecipientData'),
                    type: FormikFieldTypes.SWITCH,
                    className: 'switch-form-control-wrapper',
                    validation: Yup.boolean(),
                },
            ],
        },
    ];
    if (editRecipientData) {
        baseFormFields.push(
            {
                formikControlType: FormikFormControlType.GROUP_CONTROL,
                fields: [
                    {
                        name: 'firstName',
                        label: t('jobs.editOrder.firstName'),
                        validation: Yup.string()
                            .max(255)
                            .test('noMoreThanOne', `${t(ValidationErrorType.IS_NO_REPEATED_SPECIAL_CHARACTERS)}`, (value) => {
                                const dashRegExp = /-.*-/;
                                const singleQuoteRegExp = /'.*'/;
                                const result = dashRegExp.exec(value || '') || singleQuoteRegExp.exec(value || '');
                                if (result) {
                                    return false;
                                }
                                return true;
                            })
                            .matches(FormikRegexSettings.LETTERS_AND_DASH, `${t(ValidationErrorType.IS_ONLY_LETTERS)}`)
                            .matches(
                                FormikRegexSettings.NO_REPEATED_DASH_IN_THE_MIDDLE,
                                `${t(ValidationErrorType.IS_NO_REPEATED_SPECIAL_CHARACTERS)}`
                            )
                            .matches(FormikRegexSettings.NO_DASH_END, `${t(ValidationErrorType.IS_NOT_DASH_ENDED)}`)
                            .required(`${t(ValidationErrorType.IS_REQUIRED)}`),
                        type: FormikFieldTypes.TEXT,
                        isRequired: true,
                        lettersOnly: true,
                    },
                ],
            },
            {
                formikControlType: FormikFormControlType.GROUP_CONTROL,
                fields: [
                    {
                        name: 'lastName',
                        label: t('jobs.editOrder.lastName'),
                        validation: Yup.string()
                            .max(255)
                            .test('noMoreThanOne', `${t(ValidationErrorType.IS_NO_REPEATED_SPECIAL_CHARACTERS)}`, (value) => {
                                const dashRegExp = /-.*-/;
                                const singleQuoteRegExp = /'.*'/;
                                const result = dashRegExp.exec(value || '') || singleQuoteRegExp.exec(value || '');
                                if (result) {
                                    return false;
                                }
                                return true;
                            })
                            .matches(FormikRegexSettings.LETTERS_AND_DASH, `${t(ValidationErrorType.IS_ONLY_LETTERS)}`)
                            .matches(
                                FormikRegexSettings.NO_REPEATED_DASH_IN_THE_MIDDLE,
                                `${t(ValidationErrorType.IS_NO_REPEATED_SPECIAL_CHARACTERS)}`
                            )
                            .matches(FormikRegexSettings.NO_DASH_END, `${t(ValidationErrorType.IS_NOT_DASH_ENDED)}`)
                            .required(`${t(ValidationErrorType.IS_REQUIRED)}`),
                        type: FormikFieldTypes.TEXT,
                        isRequired: true,
                        lettersOnly: true,
                    },
                ],
            },
            {
                formikControlType: FormikFormControlType.GROUP_CONTROL,
                controlsPerRow: 2,

                fields: [
                    {
                        name: 'country',
                        label: `${t('jobs.editOrder.dialingCode')}`,
                        validation: Yup.string().required(`${t(ValidationErrorType.IS_REQUIRED)}`),
                        type: FormikFieldTypes.SELECT,
                        className: `job-edit-order-phone-code`,
                        formControlClassName: 'phone-code-select',
                        flexSettings: '0',
                        isRequired: true,
                        options: selectPhoneCodeList,
                    },
                    {
                        name: 'phone',
                        label: t('jobs.editOrder.phone'),
                        validation: Yup.string()
                            .test('is-valid-phone', `${t(ValidationErrorType.IS_MOBILE_NUMBER)}`, function (value) {
                                const {country} = this.parent;

                                if (!value) return true;

                                try {
                                    return isPhoneNumberTypeMobile(country, value);
                                } catch (error) {
                                    return false;
                                }
                            })
                            .required(`${t(ValidationErrorType.IS_REQUIRED)}`),
                        type: FormikFieldTypes.MASKED,
                        textMaskType: TextMaskType.PHONE,
                        flexSettings: '1',
                        isRequired: true,
                    },
                ],
            },
            {
                formikControlType: FormikFormControlType.GROUP_CONTROL,
                fields: [
                    {
                        name: 'orderNumber',
                        label: t('jobs.editOrder.orderNumber'),
                        validation: Yup.string()
                            .notRequired()
                            .max(30, `${t('formValidation.errors.orderNumberValidation', {maxChars: 30})}`),
                        type: FormikFieldTypes.TEXT,
                        characterLimit: 30,
                    },
                ],
            },
            {
                formikControlType: FormikFormControlType.GROUP_CONTROL,
                fields: [
                    {
                        name: 'description',
                        label: t('jobs.editOrder.description'),
                        validation: Yup.string()
                            .notRequired()
                            .max(500, `${t('formValidation.errors.orderNumberValidation', {maxChars: 500})}`),
                        type: FormikFieldTypes.TEXT,
                        rows: 6,
                        characterLimit: 500,
                    },
                ],
            }
        );
    } else if (!editRecipientData) {
        baseFormFields.push(
            {
                formikControlType: FormikFormControlType.GROUP_CONTROL,
                fields: [
                    {
                        name: 'teamMemberId',
                        label: `${t('jobs.editOrder.recipient')}`,
                        validation: Yup.string().required(`${t(ValidationErrorType.IS_REQUIRED)}`),
                        type: FormikFieldTypes.SELECT,
                        options: recipientsArray,
                    },
                ],
            },
            {
                formikControlType: FormikFormControlType.GROUP_CONTROL,
                fields: [
                    {
                        name: 'orderNumber',
                        label: t('jobs.editOrder.orderNumber'),
                        validation: Yup.string()
                            .notRequired()
                            .max(30, `${t('formValidation.errors.orderNumberValidation', {maxChars: 30})}`),
                        type: FormikFieldTypes.TEXT,
                        characterLimit: 30,
                    },
                ],
            },
            {
                formikControlType: FormikFormControlType.GROUP_CONTROL,
                fields: [
                    {
                        name: 'description',
                        label: t('jobs.editOrder.description'),
                        validation: Yup.string()
                            .notRequired()
                            .max(500, `${t('formValidation.errors.orderNumberValidation', {maxChars: 500})}`),
                        type: FormikFieldTypes.TEXT,
                        rows: 6,
                        characterLimit: 500,
                    },
                ],
            }
        );
    }
    return baseFormFields;
};
export default recipientDataFormConfig;
