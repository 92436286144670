import {
    Box,
    Button,
    Card,
    Divider,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import {
    CustomTablePagination,
    Eye,
    IPurchaserJobListingOutput,
    JobPrice,
    JobStatus,
    JobStatusDetailsBadge,
    Loader,
    LoaderType,
    LocationFormattedAddress,
    LocationFormattedAddressType,
    NoDataInfo,
    PAGINATION_ITEMS_PER_PAGE,
    RouteIcon,
    SizeComponent,
    TimeComponent,
    Translation,
    calculateItemIndex,
    teamsFromOwnerOrManagerRoleSelector,
} from 'palipali-panel-common-web';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {resetToInitialCreateJobViewState, setIsOutsideNavigation} from 'src/store/reducers/createJobViewSlice';
import {changeActiveJobsListingPagination} from '../../../store/reducers/jobListingSlice';
import {
    activeJobsListingSelector,
    isJobListLoadingSelector,
    jobActiveCurrentlySelectedTeamSelector,
    jobActiveListingMetadataSelector,
    jobActiveListingPaginationSelector,
} from '../../../store/selectors/jobListingSelector';
import ActiveOrderListFilters from './ActiveJobListFilters';

const ActiveJobList: React.FC = () => {
    const navigate = useNavigate(),
        dispatch = useDispatch(),
        jobList = useSelector(activeJobsListingSelector),
        activeJobsMetadata = useSelector(jobActiveListingMetadataSelector),
        activeJobsPagination = useSelector(jobActiveListingPaginationSelector),
        teamOwnedOrManagedByUser: string[] = useSelector(teamsFromOwnerOrManagerRoleSelector),
        currentlySelectedTeamId: string | undefined = useSelector(jobActiveCurrentlySelectedTeamSelector),
        isCurrentlySelectedTeamOwnedOrManagedByUser = currentlySelectedTeamId
            ? teamOwnedOrManagedByUser?.includes(currentlySelectedTeamId)
            : false,
        isLoading: boolean = useSelector(isJobListLoadingSelector);
    const resetCurrentCreateJobFlowAndNavigate = () => {
        dispatch(setIsOutsideNavigation(false));
        dispatch(resetToInitialCreateJobViewState());
        navigate('create');
    };

    const renderTableBody = () => {
        if (isLoading) return;

        if (!jobList || jobList?.length === 0) {
            return (
                <TableRow>
                    <TableCell colSpan={6}>
                        <NoDataInfo text={'jobs.noJobs'} />
                    </TableCell>
                </TableRow>
            );
        }

        const renderDeliveryDate = (job: IPurchaserJobListingOutput) => {
            const dataToDisplay = job.deliveryStatus === JobStatus.PLANNED ? job.processAt : job.createdAt;
            return <TimeComponent application="purchaser" date={dataToDisplay} time={job.time} />;
        };

        return jobList?.map((item: IPurchaserJobListingOutput, index: number) => (
            <TableRow key={item.id}>
                <TableCell>
                    <Box sx={{display: 'flex', alignItems: 'center', gap: '1.6rem'}}>
                        <span className="index-number-cell">
                            <Typography component="span" className="index-number" color="text.primary">{`#${calculateItemIndex(
                                activeJobsPagination.page,
                                activeJobsPagination.itemsPerPage,
                                index
                            )}`}</Typography>
                        </span>
                        <Stack>
                            <Typography color="text.primary" variant="body2" component="div">
                                <LocationFormattedAddress address={item.toLocation} addressType={LocationFormattedAddressType.GENERAL} />
                            </Typography>
                            <Typography color="text.secondary" variant="body2">
                                {renderDeliveryDate(item)}
                            </Typography>
                        </Stack>
                    </Box>
                </TableCell>
                <TableCell>
                    <Box className="flex-centered-item">
                        <Typography>
                            <SizeComponent size={item.packageSize} />
                        </Typography>
                    </Box>
                </TableCell>
                <TableCell className="package-status-badge">
                    <Box className="flex-centered-item">
                        <JobStatusDetailsBadge status={item.deliveryStatus} />
                    </Box>
                </TableCell>
                <TableCell>
                    <Box className="flex-centered-item">
                        <JobPrice price={item.cost} toFixed={2} />
                    </Box>
                </TableCell>
                <TableCell className="action-cell">
                    <div className="action-cell-container">
                        <div className="btn-action-wrapper">
                            <Button onClick={() => navigate(`${item.id}`)} className="btn btn-action" startIcon={<Eye />}>
                                <Translation text="jobs.jobView.details" />
                            </Button>
                        </div>
                    </div>
                </TableCell>
            </TableRow>
        ));
    };

    return (
        <Stack className="content-view">
            <div className="header">
                <div className="title">
                    <Typography variant="h4" component="h2" color="text.primary">
                        <Translation text="activeJobs.title" />
                    </Typography>
                </div>
                <Button
                    onClick={() => resetCurrentCreateJobFlowAndNavigate()}
                    variant="contained"
                    color="primary"
                    size="medium"
                    className="active-jobs-list-create-job-button"
                    disabled={!isCurrentlySelectedTeamOwnedOrManagedByUser}
                    startIcon={<RouteIcon />}>
                    <Translation text="jobs.formControls.newOrder" />
                </Button>
            </div>
            <Card className="custom-card">
                <ActiveOrderListFilters />
                <Divider />
                <TableContainer>
                    <Table className="custom-list">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Box sx={{paddingLeft: '8rem'}}>
                                        <Translation text="jobs.table.info" />
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Box className="flex-centered-item">
                                        <Translation text="jobs.table.category" />
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Box className="flex-centered-item">
                                        <Translation text="jobs.table.status" />
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Box className="flex-centered-item">
                                        <Translation text="jobs.table.price" />
                                    </Box>
                                </TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>{renderTableBody()}</TableBody>
                    </Table>
                </TableContainer>
                <CustomTablePagination
                    metadata={activeJobsMetadata}
                    onChangePage={(event, pageNumber) =>
                        dispatch(changeActiveJobsListingPagination({page: pageNumber, itemsPerPage: PAGINATION_ITEMS_PER_PAGE}))
                    }
                    page={activeJobsPagination.page}
                    rowsPerPage={PAGINATION_ITEMS_PER_PAGE}
                />
                <Loader showLoader={isLoading} type={LoaderType.Local} />
            </Card>
        </Stack>
    );
};

export default ActiveJobList;
