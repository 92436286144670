import {Button, Unstable_Grid2 as Grid, Stack, Typography, useTheme} from '@mui/material';
import {useFormik} from 'formik';
import {
    AlertTriangle,
    CustomModalComponent,
    FormikForm,
    IJobCancellationReasonOutput,
    IMoneyOutput,
    IRadioOption,
    Translation,
    deepCloneObject,
    jobCancellationReasonSelector,
    moneyString,
} from 'palipali-panel-common-web';
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {cancelJob} from '../../../../store/reducers/jobSlice';
import descriptionFormConfig from './descriptionFormConfig';
import {Trans} from 'react-i18next';

interface IActiveJobCancelModalProps {
    id: string | null;
    jobNumber: string | null;
    cancellationFee: IMoneyOutput | null;
}

interface CancelJobFormValues {
    cancelReasonId: string | null;
    description: string | null;
}

const ActiveJobCancelModal: React.FC<IActiveJobCancelModalProps> = ({id, jobNumber, cancellationFee}) => {
    const dispatch = useDispatch(),
        cancellationReasons: IJobCancellationReasonOutput[] = useSelector(jobCancellationReasonSelector),
        [selectedReasonCode, setSelectedReasonCode] = useState<string | null>(),
        initialValues: CancelJobFormValues = {
            cancelReasonId: '',
            description: '',
        };
    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: (values: CancelJobFormValues) => {
            const payload = {
                jobCancellationReasonId: values.cancelReasonId,
                cancellationDescription: values.description === '' ? null : values.description,
            };
            dispatch(cancelJob(payload, id));
        },
    });

    const [isConfirmationStep, setIsConfirmationStep] = useState<boolean>(false);
    const sortedCancellationReasons = cancellationReasons
        ? deepCloneObject(cancellationReasons).sort((a: IJobCancellationReasonOutput, b: IJobCancellationReasonOutput) => {
              if (a.code === 'other') return 1;
              if (b.code === 'other') return -1;
              return 0;
          })
        : [];
    const cancellationReasonsOptions = sortedCancellationReasons.map((reason: IJobCancellationReasonOutput): IRadioOption => {
        return {
            value: reason.id,
            label: reason.name,
            additionalValue: reason.code,
        };
    });

    const isOtherChosen = selectedReasonCode === 'other';
    const theme = useTheme();
    return (
        <CustomModalComponent
            theme={theme}
            title={
                <Typography color="text.primary" variant="h6" component="h2">
                    {!isConfirmationStep ? (
                        <Translation text={`jobs.cancelJob.title`} />
                    ) : (
                        <Translation text={`jobs.cancelJob.cancelTitle`} />
                    )}
                </Typography>
            }
            content={
                <Grid sx={{width: '100%'}}>
                    <Stack className={`report-list ${isConfirmationStep ? 'sr-only' : ''}`}>
                        <FormikForm
                            fields={descriptionFormConfig(cancellationReasonsOptions, isOtherChosen)}
                            formId="cancel-job-reason"
                            theme={theme}
                            initialValues={initialValues}
                            updatedValues={formik.values}
                            customEventChange={(formControl: string, value) => {
                                if (formControl === 'cancelReasonId') {
                                    const selectedOption = cancellationReasonsOptions.find((option) => option.value === value);
                                    if (selectedOption) {
                                        setSelectedReasonCode(selectedOption.additionalValue);
                                        if (selectedOption.additionalValue === 'other') {
                                            formik.setFieldValue('description', '');
                                        }
                                    }
                                }
                                formik.setFieldValue(formControl, value);
                            }}
                            formClassName="cancel-job-reason-form"
                            onSubmit={formik.handleSubmit}
                        />
                    </Stack>
                    <Stack className={`cancel-job-modal-content ${isConfirmationStep ? '' : 'sr-only'}`}>
                        <div className="confirm-modal-icon-container">
                            <AlertTriangle />
                        </div>
                        <Stack className="cancel-job-modal-description-wrapper">
                            <Typography variant="body1" component="p" color="text.primary">
                                <Trans
                                    i18nKey="jobs.cancelJob.subtitle"
                                    values={{jobNumber: jobNumber}}
                                    components={[<span className={'bold-text'} key={'job-number'} />]}
                                />
                            </Typography>
                            <Typography variant="body1" component="p" color="text.primary">
                                <Trans
                                    i18nKey={`jobs.cancelJob.costInformation`}
                                    values={{price: moneyString(cancellationFee) || '-'}}
                                    components={[<span className="bold-text" key="0" />]}
                                />
                            </Typography>
                        </Stack>
                    </Stack>
                </Grid>
            }
            footer={
                isConfirmationStep ? (
                    <Button
                        fullWidth
                        type="submit"
                        color="primary"
                        variant="contained"
                        form="cancel-job-reason"
                        onClick={formik.submitForm}
                        autoFocus>
                        <Translation text="jobs.cancelJob.buttons.confirmCancel" />
                    </Button>
                ) : (
                    <Button
                        fullWidth
                        type="button"
                        color="primary"
                        disabled={!formik.values.cancelReasonId}
                        variant="contained"
                        onClick={(event) => {
                            event.preventDefault();
                            setIsConfirmationStep(true);
                        }}
                        autoFocus>
                        <Translation text="jobs.cancelJob.buttons.cancel" />
                    </Button>
                )
            }
        />
    );
};

export default ActiveJobCancelModal;
