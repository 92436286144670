import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';
import {ITeamViewState} from '../reducers/teamViewSlice';

export const selectTeamViewSlice = (state: RootState) => {
    return state.teamView;
};

export const teamSelector = createSelector([selectTeamViewSlice], (state: ITeamViewState) => state.team);
export const activeTabSelector = createSelector([selectTeamViewSlice], (state: ITeamViewState) => state.activeTab);
export const isTeamLoadingSelector = createSelector([selectTeamViewSlice], (state: ITeamViewState) => state.isLoading);
export const currentTeamIdSelector = createSelector([selectTeamViewSlice], (state: ITeamViewState) => state.team?.id);
export const clientSecretSelector = createSelector([selectTeamViewSlice], (state: ITeamViewState) => state.clientSecret);
export const canDeleteTeamSelector = createSelector([selectTeamViewSlice], (state: ITeamViewState) => state.canDeleteTeam);
export const canChangePaymentMethodSelector = createSelector(
    [selectTeamViewSlice],
    (state: ITeamViewState) => state.canChangePaymentMethod
);
export const teamLocationFiltersSelector = createSelector([selectTeamViewSlice], (state: any) => state.teamLocationFilters);
export const teamLocationListingSelector = createSelector([selectTeamViewSlice], (state: any) => state.teamLocationList);
export const teamLocationListingMetadataSelector = createSelector([selectTeamViewSlice], (state: any) => state.metadata);
export const teamLocationListingPaginationSelector = createSelector([selectTeamViewSlice], (state: any) => state.pagination);
