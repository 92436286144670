import {
    Box,
    Button,
    Card,
    Divider,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import {
    CustomTablePagination,
    Eye,
    IPurchaserJobListingOutput,
    JobPrice,
    JobStatusDetailsBadge,
    Loader,
    LoaderType,
    LocationFormattedAddress,
    LocationFormattedAddressType,
    NoDataInfo,
    PAGINATION_ITEMS_PER_PAGE,
    ReceiptCheck,
    RouteIcon,
    SizeComponent,
    TimeComponent,
    Translation,
    authTokenSelector,
    calculateItemIndex,
    downloadInvoice,
} from 'palipali-panel-common-web';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import {changeHistoricalJobsListingPagination, setHistoricalJobsViewInitialized} from '../../../store/reducers/jobListingSlice';
import {IPaginationParams} from '../../../store/reducers/locationListingSlice';
import {
    historicalJobsListingSelector,
    isJobListLoadingSelector,
    jobHistoricalListingMetadataSelector,
    jobHistoricalListingPaginationSelector,
} from '../../../store/selectors/jobListingSelector';
import HistoricalJobListFilters from './HistoricalJobListFilters';

interface IHistoricalJobListProps {}

const HistoricalJobList: React.FC<IHistoricalJobListProps> = () => {
    const location = useLocation(),
        pathSegments = location.pathname.split('/').filter((segment) => segment !== ''),
        jobsType = pathSegments[pathSegments.length - 1],
        navigate = useNavigate(),
        dispatch = useDispatch(),
        jobList = useSelector(historicalJobsListingSelector),
        jobPagination: IPaginationParams = useSelector(jobHistoricalListingPaginationSelector),
        jobMetadata = useSelector(jobHistoricalListingMetadataSelector),
        isLoading: boolean = useSelector(isJobListLoadingSelector),
        authToken: string = useSelector(authTokenSelector);

    const submitDownload = (id: string | null, name?: string) => {
        if (id) {
            downloadInvoice(id, process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '', authToken, dispatch, name);
        }
    };

    const navigateToJobDetails = (id: string) => {
        dispatch(setHistoricalJobsViewInitialized(false));
        navigate(`${id}`);
    };

    const renderTableBody = () => {
        if (isLoading) return;

        if (!jobList || jobList?.length === 0) {
            return (
                <TableRow>
                    <TableCell colSpan={6}>
                        <NoDataInfo text={'jobs.noJobs'} />
                    </TableCell>
                </TableRow>
            );
        }

        return jobList?.map((item: IPurchaserJobListingOutput, index: number) => (
            <TableRow key={item.id}>
                <TableCell>
                    <Box sx={{display: 'flex', alignItems: 'center', gap: '1.6rem'}}>
                        <span className="index-number-cell">
                            <Typography component="span" className="index-number">{`#${calculateItemIndex(
                                jobPagination.page,
                                jobPagination.itemsPerPage,
                                index
                            )}`}</Typography>
                        </span>
                        <Stack>
                            <Typography color="text.primary" variant="body2" component="div">
                                <LocationFormattedAddress address={item.toLocation} addressType={LocationFormattedAddressType.GENERAL} />
                            </Typography>
                            <Typography color="text.secondary" variant="body2" component="div">
                                <Typography className="package-date">
                                    <TimeComponent date={item.createdAt} application="purchaser" time={item.time} isHistoricalView={true} />
                                </Typography>
                            </Typography>
                        </Stack>
                    </Box>
                </TableCell>
                <TableCell>
                    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Typography>
                            <SizeComponent size={item.packageSize} />
                        </Typography>
                    </Box>
                </TableCell>
                <TableCell className="package-status-badge">
                    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <JobStatusDetailsBadge status={item.deliveryStatus} />
                    </Box>
                </TableCell>
                <TableCell>
                    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <JobPrice price={item.cost} toFixed={2} />
                    </Box>
                </TableCell>
                <TableCell className="action-cell">
                    <div className="action-cell-container">
                        <div className="btn-action-wrapper">
                            <Button
                                className="btn btn-action"
                                disabled={!item.invoiceId || item.canDownloadInvoice === false}
                                onClick={() => submitDownload(item?.invoiceId)}
                                startIcon={<ReceiptCheck size={18} />}>
                                <Translation text="jobs.jobView.invoice" />
                            </Button>
                        </div>
                        <div className="btn-action-wrapper">
                            <Button onClick={() => navigateToJobDetails(`${item.id}`)} className="btn btn-action" startIcon={<Eye />}>
                                <Translation text="jobs.jobView.details" />
                            </Button>
                        </div>
                    </div>
                </TableCell>
            </TableRow>
        ));
    };

    return (
        <div className="content-view">
            <Stack>
                <div className="header">
                    <div className="title">
                        <Typography variant="h4" component="h2" color="text.primary">
                            <Translation text="historicalJobs.title" />
                        </Typography>
                        {jobsType === 'active' && (
                            <Button
                                onClick={() => navigate(`create-job`)}
                                variant="contained"
                                color="primary"
                                size="medium"
                                startIcon={<RouteIcon />}>
                                <Translation text="jobs.formControls.newOrder" />
                            </Button>
                        )}
                    </div>
                </div>
                <Card className="custom-card">
                    <HistoricalJobListFilters />
                    <Divider />
                    <TableContainer>
                        <Table className="custom-list">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Box sx={{paddingLeft: '4.3rem'}}>
                                            <Translation text="jobs.table.info" />
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                            <Translation text="jobs.table.category" />
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                            <Translation text="jobs.table.status" />
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                            <Translation text="jobs.table.price" />
                                        </Box>
                                    </TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>{renderTableBody()}</TableBody>
                        </Table>
                    </TableContainer>
                    <CustomTablePagination
                        metadata={jobMetadata}
                        onChangePage={(event, pageNumber) =>
                            dispatch(changeHistoricalJobsListingPagination({page: pageNumber, itemsPerPage: PAGINATION_ITEMS_PER_PAGE}))
                        }
                        page={jobPagination.page}
                        rowsPerPage={PAGINATION_ITEMS_PER_PAGE}
                    />
                    <Loader showLoader={isLoading} type={LoaderType.Local} />
                </Card>
            </Stack>
        </div>
    );
};

export default HistoricalJobList;
