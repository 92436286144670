const en = {
    app: {
        title: 'Requestor Panel',
        validation: {
            phone_taken: 'Provided phone number is already registered in the system.',
            user_does_not_exists: 'User with entered name does not exist.',
            user_already_confirmed: 'User with the entered name is already confirmed.',
            object_with_given_id_does_not_exists: 'Object with the entered ID does not exist.',
            user_is_not_owner_of_object: 'User is not the owner of this object.',
            object_must_implement_ownable_interface: 'Object must implement OwnableInterface to pass validation.',
            value_not_part_of_enum: 'Entered value is incorrect',
            location_not_supported:
                "The execution of the order is not possible because the locations are not within the application's operating area.",
            invalid_domain: 'URL adress contains an invalid domain.',
            invalid_vehicle: 'Lack of authorization to use this vehicle.',
            vehicle_occupied: 'Vehicle is occupied by another user.',
            incompatible_package_sizes: 'Selected package sizes are not acceptable for this vehicle.',
            not_full_team_member: 'User is not a rightful member of the team.',
            not_team_member: 'User is not a member of the team.',
            insufficient_team_role: 'User does not have the necessary rights in the team or is not a member of the team.',
            missing_phone_in_from_location: 'Phone number of the shipment point is required.',
            not_fleet_courier: 'Fleet courier does not belong to a fleet partner.',
            location_type_not_accessible: 'This type of location is not allowed.',
            invalid_city_limits: 'City boundaries have an incorrect format.',
            enum_value_not_not_supported: 'The specified enuma %value% is not supported.',
            greater_than_now: 'Date must be in the future.',
            invalid_nip_length: 'NIP must contain 10 digits.',
            invalid_nip_number: 'Incorrect NIP number.',
            locale_not_supported: 'Specified location is not supported.',
            locations_have_the_same_localisation: 'Selected pick-up and delivery points have the same location.',
        },
        violation: {
            processing_time_exceeds_limit: 'Job processing time exceeding time limit.',
        },
        exception: {
            authentication_required: 'Authorization is required to make a payment.',
            approve_with_id: 'Failed to authorize payment.',
            call_issuer: 'For an unknown reason, the payment could not be authorized.',
            card_not_supported: 'The card does not support payments.',
            card_velocity_exceeded: 'Payment limit exceeded.',
            currency_not_supported: 'Currency is not supported.',
            do_not_honor: 'Failed to authorize payment.',
            do_not_try_again: 'Failed to authorize payment.',
            duplicate_transaction: 'Payment has already been processed.',
            expired_card: 'Payment card expired.',
            fraudulent: 'Payment was rejected.',
            generic_decline: 'Failed to authorize payment.',
            incorrect_number: 'Provided incorrect number of payment card.',
            incorrect_pin: 'Provided invalid PIN number.',
            incorrect_zip: 'Provided an invalid postal code.',
            insufficient_funds: 'Insufficient funds in the account or exceeded card payment limit.',
            invalid_account: 'Provided incorrect number of bank account.',
            invalid_amount: 'Provided an incorrect amount.',
            invalid_cvc: 'Provided invalid CVC number.',
            invalid_expiry_year: 'Provided incorrect year of validity of the payment card.',
            invalid_number: 'Provided incorrect number of payment card.',
            invalid_pin: 'Provided invalid PIN number.',
            issuer_not_available: 'Failed to authorize payment.',
            lost_card: 'Payment card has been lost.',
            merchant_blacklist: 'Failed to authorize payment.',
            new_account_information_available: 'Failed to authorize payment.',
            no_action_taken: 'Failed to authorize payment.',
            not_permitted: 'Failed to authorize payment.',
            offline_pin_required: 'Failed to authorize payment.',
            online_or_offline_pin_required: 'Failed to authorize payment.',
            pickup_card: 'Failed to authorize payment.',
            pin_try_exceeded: 'Failed to authorize payment.',
            processing_error: 'Failed to authorize payment.',
            reenter_transaction: 'Failed to authorize payment.',
            restricted_card: 'Failed to authorize payment.',
            revocation_of_all_authorizations: 'Failed to authorize payment.',
            revocation_of_authorization: 'Failed to authorize payment.',
            security_violation: 'Failed to authorize payment.',
            service_not_allowed: 'Failed to authorize payment.',
            stolen_card: 'Failed to authorize payment.',
            stop_payment_order: 'Failed to authorize payment.',
            testmode_decline: 'A test payment card was used. This is not allowed in this mode.',
            transaction_not_allowed: 'Failed to authorize payment.',
            try_again_later: 'Failed to authorize payment.',
            withdrawal_count_limit_exceeded: 'Failed to authorize payment.',
            payment_intent_authentication_failure: 'Failed to authorize payment.',
            setup_intent_authentication_failure: 'Failed to authorize card addition.',

            general_error: 'Error occurred during payment.',
            insufficient_funds_error: 'Insufficient funds in the account or exceeded card payment limit.',
            card_expired_error: 'Validity of the payment card has expired.',
            object_with_given_id_does_not_exists: 'Object with the entered ID does not exist.',
            user_is_not_owner_of_object: 'User is not the owner of this object.',
            object_must_implement_ownable_interface: 'Object must implement OwnableInterface to pass validation.',
            value_not_part_of_enum: 'Entered value is incorrect',
            location_not_supported:
                "The execution of the order is not possible because the locations are not within the application's operating area.",
            invalid_domain: 'URL adress contains an invalid domain.',
            invalid_vehicle: 'Lack of authorization to use this vehicle.',
            vehicle_occupied: 'Vehicle is occupied by another user.',
            incompatible_package_sizes: 'Selected package sizes are not acceptable for this vehicle.',
            not_full_team_member: 'User is not a rightful member of the team.',
            not_team_member: 'User is not a member of the team.',
            insufficient_team_role: 'User does not have the necessary rights in the team or is not a member of the team.',
            missing_phone_in_from_location: 'Phone number of the shipment point is required.',
            not_fleet_courier: 'Fleet courier does not belong to a fleet partner.',
            location_type_not_accessible: 'This type of location is not allowed.',
            invalid_city_limits: 'City boundaries have an incorrect format.',
            user_already_exists: 'User with enetered Email address already exists.',
            file_not_provided: 'No file attached.',
            authentication_token_could_not_be_resolved: 'Authorization token cannot be served.',
            concurrent_conflict: 'Operation cannot be completed due to conflict.',
            cant_change_role_to_owner: 'You cannot change the role of the user who is the owner.',
            invalid_role_invited: 'You can only invite members with a role lower than yours.',
            invalid_team_member_invitation: 'This invitation is invalid.',
            cant_invite_deleted_user: 'The invited user has been removed and cannot be invited again.',
            invalid_date_format: 'Wrong date form.',
            invalid_invitation: 'Incorrect invitation.',
            invalid_invitation_action: 'Unauthorized action on an invitation.',
            invalid_recipient_data: 'Order recipients data must include the team members ID or first name, last name and phone number.',
            invalid_job_location_data: 'Order location data must include the location ID or location data including address and location.',
            location_not_allowed: 'Selected location is not public or does not belong to the selected team.',
            courier_job_proposal_rejected: 'An order proposal that has been rejected cannot be accepted.',
            courier_job_proposal_accepted: 'An order proposal that has been accepted cannot be rejected.',
            courier_job_proposal_expired: 'This order is no longer available.',
            invalid_job_problem_type: 'Wrong type of order problem.',
            invalid_uuid_provided: 'Entered ID is incorrect.',
            invalid_vehicle_provided: 'Selected vehicles do not exist or lack the authority to use them in the order.',
            invoicing_service_exception: 'Failed to download the invoice.',
            invalid_phone_number: 'Incorrect phone number.',
            cant_download_invoice: 'Failed to download the invoice.',
            account_not_confirmed: 'Account has not been confirmed.',
            account_not_active: 'Account is not active.',
            account_deleted: 'Account is deleted.',
            account_is_invitation: 'Account is an invitation.',
            user_not_found: 'Entered user does not exist.',
            payment_method_cannot_be_captured:
                'The selected payment method cannot be used in the moment. Please check if you have enough funds',
            not_allowed_to_login: 'You cannot log in to this application with this account.',
            invalid_identifier: 'Provided ID is incorrect.',
            vehicle_in_use: 'Vehicle is in use.',
        },
        timeComponent: {
            estimatedTime: ' (estimated time)',
        },
    },
    logoutModal: {
        title: 'Log out',
        content: 'Are you sure you want to log out',
        logout: 'Log out',
    },
    auth: {
        header: {
            purchaser: 'Requestor',
        },
        authFooter: {
            srTitle: 'Page footer including contact information, site navigation and newsletter sign-ups',
            addressColumn: {
                title: 'PaliPali',
            },
            contactColumn: {
                title: 'Contact',
                correspondenceAddressTitle: 'Address for correspondence:',
            },
            linksColumn: {
                title: 'PaliPali',
                aboutUs: 'About us',
                career: 'Career',
                cooperation: 'Co-operation',
                contact: 'Contact',
                policies: 'Terms and Conditions and privacy policy',
                safePayments: 'Secure payment',
            },
            newsletterColumn: {
                title: 'Stay informed',
                subscribeInfo: 'Subscribe to our newsletter',
                newsletterLabelSR: 'Enter your email address to receive the newsletter',
                newsletterSubmitButtonSR: 'Newsletter subscription form approval button',
            },
            copyright: 'PaliPali {{date}} © All rights reserved.',
            socialIcons: {
                facebook: 'Link leading to Facebook profile',
                instagram: 'Link leading to Instagram profile',
                linkedin: 'Link leading to Linkedin profile',
                youtube: 'Link leading to Youtube channel',
            },
        },
        formControls: {
            email: 'Email',
            password: 'Password',
            newPassword: 'New password',
            confirmPassword: 'Repeat password',
            repeatPassword: 'Repeat password',
            placeholderConfirmPassword: 'Repeat password',
            phone: 'Phone number',
            city: 'City',
            firstName: 'First name',
            lastName: 'Last name',
            dialingCode: 'Prefix',
            privacyPolicy: 'I have read the <0>Regulations</0> and <1>Privacy Policy</1> of the PaliPali application.',
            placeholders: {
                email: 'Email',
                password: 'Password',
                newPassword: 'New password',
                repeatPassword: 'Repeat password',
                confirmPassword: 'Repeat password',
                city: 'Enter the city',
                firstName: 'First name',
                lastName: 'Last name',
                phone: 'Phone number',
                phoneCode: 'Code',
            },
        },
        login: {
            title: 'Logging',
            haveAccount: 'New to PaliPali?',
            register: 'Register',
            resetPassword: 'Forgot your password?',
            loginButton: 'Log in',
            availableCities: 'Available cities',
            availableCitiesDescription: 'Find out in which cities you can realize trips using the PaliPali app.',
            availableCitiesLink: 'Check out <0>palipali.pl/cities</0> and stay informed.',
        },
        register: {
            title: 'Registration',
            createAccount: 'Create a Requestor account',
            accountCreated: 'Do you already have an account?  ',
            login: 'Log in',
            registerButton: 'Register',
        },
        confirmRegistration: {
            title: 'Confirm registration',
            subtitle: 'To complete your registration on the platform, click the button below',
            confirmButton: 'Confirm',
        },
        resetPassword: {
            title: 'Reset password',
            subtitle: 'Enter your email address. We will send a link to change your password to it.',
            sendEmailButton: 'Send link to change password',
            backToLoginButton: 'Logging',
        },
        newPassword: {
            title: 'Reset password',
            subtitle: 'Enter new password.',
            setPasswordButton: 'Set new password',
        },
        confirmEmailChange: {
            title: 'Confirm change of email address',
            subtitle: 'To change your email address click on the button below',
            confirmButton: 'Confirm',
        },
        alert: {
            invalidConfirmRegistrationURL: 'Incorrect link to confirm registration.',
            loginSuccess: 'Logging in successfully.',
            loginFailed: 'Incorrect login or password. Please try again.',
            logout: 'You are logged out.',
            registrationSuccess: 'Successfully registered. Check your email to confirm your registration.',
            confirmRegistrationSuccess: 'Your email has been confirmed.',
            sendResetPasswordMailSuccess: 'Check your email for a link to reset your password.',
            confirmResetPasswordSuccess: 'Your password has been changed. You can log in to the panel.',
            authenticationError: 'You must be logged in to access the data.',
            confirmTeamMemberInvitationSuccess: 'Your invitation to join the team has been confirmed, log in to the application.',
            rejectTeamMemberInvitationSuccess: 'An invitation to join the team was rejected.',
            confirmTeamMemberNotRegisteredInvitationSuccess: 'An invitation to join the team was accepted, register to use the app.',
        },
    },
    activeJobs: {
        noTeamsInfo: {
            subtitle: 'Inactive account',
            title: 'Add a team and specify a payment method',
            description: 'Add a team, and then assign to it the payment card necessary for processing orders.',
            button: 'Add team',
        },
        title: 'Active orders',
    },
    historicalJobs: {
        title: 'Orders history',
    },
    jobs: {
        noJobs: 'No orders',
        alert: {
            opinionSent: 'Opinion was sent.',
            reportSent: 'Problem was reported.',
            jobDeleted: 'Order was deleted.',
            jobCancelled: 'Order was cancelled.',
            jobCreated: 'Order was created.',
            jobUpdated: 'Order was updated.',
            jobNotFound: 'Job not found.',
            jobNotActive: 'This order is no longer available. Check the order history for the reason.',
            orderUnavailable: 'The order can no longer be cancelled.',
        },
        formControls: {
            newOrder: 'New order',
            team: 'Team',
            noActiveTeams: 'No active teams',
            status: {
                all: 'All',
                delivered: 'Realized',
                cancelled_p: 'Cancalled',
                cancelled_c: 'Cancelled by Courier',
                problem_c: 'Reported by Courier',
                problem_p: 'Reported',
                planned: 'Planned',
                searching: 'Searching',
                pickup: 'Picking up a shipment',
                pickup_drive: 'Reach',
                delivery: 'In progress',
            },
            date: 'Period',
        },
        table: {
            packageSize: 'Size',
            address: 'Location',
            date: 'Date',
            time: 'Delivery time',
            status: 'Status',
            price: 'Cost',
            info: 'Information',
            index: 'No.',
            category: 'Order category',
        },
        jobView: {
            price: 'Cost',
            courier: 'Courier',
            details: 'Details',
            report: 'Report Problem',
            payment: 'Payment',
            invoice: 'Download invoice',
        },
        report: {
            title: 'Reason for reporting problem',
            wrongDetails: 'Incorrectly specified order details',
            incompletePackage: 'Shipment is incomplete',
            noOnsiteOrdering: 'No shipment on site',
            noCourierIdentification: 'Impossible to confirm identity of courier',
            shopIsClosed: 'Shipping point is closed',
            other: 'Other',
            description: 'Describe problematic situation',
            describeSituation: 'Describe situation',
            add: 'Add',
            descriptionPhoto: 'Photo illustrating the problem',
            buttons: {
                reportProblem: 'Report Problem',
            },
        },
        cancelJob: {
            title: 'Reason for order cancellation',
            cancelTitle: 'Cancel order',
            subtitle: 'Are you sure you want to cancel an active order #',
            describeCancelReason: 'Describe the reason for order cancellation',
            costInformation: `By cancelling the order you will be charged a fee of <0>{{ price }} gross</0>.`,
            srReasonListTitle: 'List of reasons for order cancellation',
            buttons: {
                confirmCancel: 'Yes, I want to cancel the order',
                cancel: 'Cancel order',
            },
        },
        deleteJob: {
            title: 'Delete order',
            subtitle: 'Are you sure you want to delete a scheduled order?',
            warning: 'This operation is irreversible.',
            buttons: {
                deleteJobConfirm: 'Yes, I want to delete the order',
            },
        },
        courierDetails: {
            registrationNumber: 'Registration number',
            carModel: 'Vehicle',
            color: 'Color',
            phone: 'Phone',
            buttons: {
                rate: 'Rate',
            },
        },
        orderDetails: {
            cost: 'Cost',
            team: 'Team',
            customer: 'Requestor',
            receiver: 'Recipient',
            insuranceCost: 'Amount of insurance',
            innerOrderNumber: 'Internal order number',
            otherInformation: 'Other information',
            additionalInfo: 'Additional information',
            deliveryPin: 'Pickup PIN code',
            sendingPoint: 'Shipment point',
            courier: 'Courier',
            reportProblem: 'Report Problem',
            edit: 'Edit',
            processAt: 'The order will be published on:',
            noInfo: 'No information on publication of the order',
            estimatedTime: ' (estimated time)',
            courierArriving: 'Courier will be soon on place.',
            refresh: 'Refresh',
            timer: {
                hours: 'h',
                minutes: 'min',
            },
            buttons: {
                srDelete: 'Order delete button',
                return: 'Return',
                downloadInvoice: 'Get an invoice',
                renewOrder: 'Re-order',
            },
            alerts: {
                failedJob: 'This order is no longer available. Check the order history for the reason.',
            },
        },
        createJob: {
            title: 'New order',
            wizardSteps: {
                general: 'Order information',
                sender: 'Shipment point',
                receiver: 'Destination',
                team: 'Recipient',
                additionalData: 'Additional information',
                summary: 'Order summary',
            },
            generalData: {
                formControls: {
                    team: 'Team',
                    insurancePrice: 'Amount',
                    insuranceCurrency: 'PLN',
                    date: 'Date and time',
                    max: 'max',
                },
                orderCategory: 'Order category',
                orderCategoryUnknown: `I don't know which category to choose`,
                insurance: 'Insurance',
                orderInsured: 'I want to insure the object of the order',
                completionPeriod: 'Execution time',
                completionPeriodInfo:
                    'The order will be made available for execution at the date and time indicated. This is not a delivery date.',
                immediateCompletion: 'Immediately',
                prevButton: 'Back',
                nextButton: 'Next',
                boxDimensionsModal: {
                    title: 'Enter dimensions',
                    formControls: {
                        length: 'Length',
                        height: 'Height',
                        width: 'Width',
                        weight: 'Weight',
                        cm: 'cm',
                        kg: 'kg',
                    },
                    suggestedCategory: 'Suggested order category',
                    checkDimensions: 'To confirm the order category, enter the values describing the shipment.',
                    notSuitableDimensions:
                        'Your shipment is too large. The maximum dimensions of the shipment are {{a}} x {{b}} x {{c}} cm with a weight of {{weight}} kg.',
                    accept: 'accept',
                },
                jobCompletionModal: {
                    title: 'Date and time of realization',
                    dateSelect: 'Date',
                    timeSelect: 'Time',
                },
            },
            senderData: {
                senderPlace: 'Shipment point',
                description: 'Indicate the place from which the order will leave, e.g., wholesale, warehouse.',
                saveAddress: 'Save address',
                noAutocompleteResults: 'Address not found',
                formControls: {
                    address: 'Address',
                    companyName: 'Company name',
                    flatNumber: 'Local number',
                    phone: `Shipper's phone number`,
                },
                addressBookModal: {
                    title: 'Address book',
                    addressBook: 'Address book',
                    authorizedWarehouses: 'Partners',
                    types: {
                        store: 'Store',
                        construction: 'Construction site',
                        warehouse: 'Wholesale',
                    },
                    searchPlaceholder: 'Search location',
                    noLocations: 'No locations match search requirements',
                    street: 'street',
                },
                phoneDetails:
                    'Enter the cell phone number of the person at the place of shipment. An SMS with a pickup code will be sent to the indicated phone number during the execution of the order.',
            },
            receiverData: {
                receiverPlace: 'Destination',
                description: 'Indicate the place to which the Courier is to deliver the order, e.g. construction site.',
            },
            recipientData: {
                description: 'Indicate the data of the person authorized to receive the order.',
                formControls: {
                    provideRecipientData: `Enter recipient's data`,
                    selectRecipient: 'Select recipient',
                    firstName: 'First name',
                    lastName: 'Last name',
                    phone: 'Phone number',
                },
                teamMembersModal: {
                    title: 'Users',
                    noTeamMembers: 'No added team members',
                },
            },
            additionalData: {
                formControls: {
                    orderNumber: 'Internal order number',
                    additionalData: 'Additional information',
                },
                orderNumberShare: 'The internal order number will be shared with the sender and the courier.',
                summaryButton: 'Go to summary',
            },
            jobSummary: {
                estimatedTime: 'Estimated delivery time',
                estimatedDeliveryDate: 'Estimated delivery date',
                packageSize: 'Order category',
                team: 'Team',
                receiver: 'Recipient',
                insurancePrice: 'Amount of insurance',
                payment: 'Payment',
                placeJob: 'Execute the order ({{ time }}s)',
                replaceJob: 'Repeat the quotation',
                placedJob: 'Order was created',
                paymentOnHold: 'Payment suspended',
                totalPrice: 'Gross:',
                taxPrice: 'TAX 23%:',
                netPrice: 'Net:',
                noCouriersFound: 'Unfortunately, we could not find a courier, renew the quote to try again.',
                locationClosedWarningModal: {
                    title: 'The pickup location may be closed',
                    description:
                        "It looks like the courier may reach the pickup location after it is closed. If the pickup location is closed, and the courier is unable to collect your order due to this reason, you will be charged the cost of the courier's travel to the pickup location.",
                },
            },
        },
        editOrder: {
            title: 'Edit order',
            fillRecipientData: `Enter recipient's data`,
            recipient: 'Recipient',
            orderNumber: 'Internal order number',
            description: 'Additional information',
            firstName: 'First name',
            lastName: 'Last name',
            dialingCode: 'Prefix',
            phone: 'Phone number',
            buttons: {
                update: 'Update',
            },
        },
    },
    account: {
        title: 'Account',
        subtitle: {
            profile: 'Profile',
            settings: 'Settings',
        },
        profile: {
            title: 'Profile',
            formControls: {
                username: 'Email',
                phone: 'Phone number',
                dialingCode: 'Country code',
                firstName: 'First name',
                lastName: 'Last name',
                cityId: 'City',
            },
            buttons: {
                update: 'Update',
            },
        },
        status: {
            inactive: 'Inactive',
            active: 'Active',
        },
        settings: {
            title: 'Settings',
            formControls: {
                language: 'Language',
                highContrastMode: 'High contrast mode',
            },
            highContrastMode: 'High contrast mode',
            highContrastModeSubtitle: 'Recommended for the less sighted people',
            status: {
                inactive: 'Inactive',
                active: 'Active',
            },
        },
        changePassword: {
            title: 'Password change',
            formControls: {
                oldPassword: 'Current password',
                newPassword: 'New password',
                confirmNewPassword: 'Repeat new password',
            },
            buttons: {
                update: 'Update',
            },
        },
        deleteProfile: {
            title: 'Delete account',
            subtitle: 'Delete your account and all data related to it.',
            warning: 'This operation is irreversible.',
            cannotBeDeleted: 'You cannot delete the account now.',
            buttons: {
                deleteAccount: 'Delete account',
            },
            alerts: {
                deleted: 'Account was deleted.',
            },
        },
        info: {
            name: 'First name',
            surname: 'Last name',
            email: 'Email',
            tel: 'Phone number',
            city: 'City',
            language: 'Language',
            theme: 'Theme',
        },
        locale: {
            en_US: 'English',
            pl_PL: 'Polish',
            uk_UA: 'Ukrainian',
        },
        themes: {
            dark: 'High contrast',
            normal: 'Normal',
        },
        modal: {
            title: 'Choose avatar',
        },
        alert: {
            imageAddSuccess: 'Photo added.',
            miscChangeSuccess: 'Theme was changed',
            changeLocaleSuccess: 'Language has been changed',
            changePasswordSuccess: 'Password is changed',
            deleteAccountSuccess: 'Account is deleted',
            updateProfileDataSuccess: 'Data has been updated',
            changeEmailSuccess: 'Email address is changed',
        },
    },
    profileEdit: {
        title: 'Profile',
        formControls: {
            username: 'Email',
            phone: 'Phone number',
            dialingCode: 'Country code',
            firstName: 'First name',
            lastName: 'Last name',
            cityId: 'City',
        },
        links: {
            changePassword: 'Password change',
            deleteAccount: 'Delete account',
        },
        buttons: {
            confirm: 'Update',
        },
        settings: {
            title: 'Settings',
            formControls: {
                language: 'Language',
                highContrastMode: 'High contrast mode',
            },
        },
        resetPassword: {
            title: 'Password change',
            formControls: {
                oldPassword: 'Current password',
                newPassword: 'New password',
                confirmNewPassword: 'Confirm new password',
            },
            buttons: {
                saveChanges: 'Save changes',
            },
        },
    },
    locations: {
        title: 'Locations',
        filters: {
            team: 'Team',
            allTeams: 'All',
        },
        locationList: {
            title: 'Locations list',
            createLocation: 'Add location',
            table: {
                headers: {
                    name: 'Location name',
                    companyName: 'Company name',
                    address: 'Address',
                    phone: 'Phone',
                    locationType: 'Type',
                    actions: 'Actions',
                },
                buttons: {
                    editLocation: 'Edit location',
                    viewLocation: 'View location',
                    deleteLocation: 'Delete location',
                },
                noResults: 'No location',
            },
        },
        locationOperation: {
            title: {
                create: 'New location',
                edit: 'Location',
                createTeamLocation: 'New location for team',
            },
            formControls: {
                name: {
                    label: 'Location name',
                    placeholder: 'Enter the location name',
                },
                locationTypeId: {
                    label: 'Type',
                    placeholder: 'Select the type of location',
                },
                companyName: {
                    label: 'Company name',
                    placeholder: 'Enter company name',
                },
                phoneCode: {
                    label: 'Code',
                    placeholder: 'Code',
                },
                phone: {
                    label: 'Phone number',
                    placeholder: 'Enter phone number',
                },
                address: {
                    label: 'Address',
                    placeholder: 'Enter address',
                },
                houseNo: {
                    label: 'Local number',
                    placeholder: 'Enter local number',
                },
            },
            openingHours: {
                title: 'Opening hours',
                openingHours: 'Open',
                closingHours: 'Close',
                buttons: {
                    edit: 'Edit',
                    save: 'Save',
                    accept: 'Confirm',
                },
                closed: 'Closed',
                weekdays: {
                    _0: 'Monday',
                    _1: 'Tuesday',
                    _2: 'Wednesday',
                    _3: 'Thursday',
                    _4: 'Friday',
                    _5: 'Saturday',
                    _6: 'Sunday',
                },
            },
            buttons: {
                create: 'Create location',
                edit: 'Update',
                createTeamLocation: 'Create location',
            },
            deleteLocation: {
                title: 'Deleting location',
                description: 'Delete location. This operation is irreversible.',
                deleteButton: 'Delete location',
                modal: {
                    title: 'Delete location',
                    description: 'Are you sure you want to delete the location?',
                    reminder: 'This operation is irreversible.',
                },
            },
            locationCreated: 'Location was created',
            teamLocationCreated: 'Location was created for the team',
            locationUpdated: 'Location updated',
            locationDeleted: 'Selected location has been removed',
            phoneDetails:
                'If the location is selected as the place of shipment, a text message with a pickup code will be sent to the cell phone number provided. Make sure the number owner is at the shipment location.',
        },
    },
    teams: {
        title: 'Teams',
        createTeam: 'Add team',
        newTeam: 'New team',
        tabs: {
            teamMembers: 'Users',
            details: 'General information',
            locations: 'Locations',
        },
        teamList: {
            title: 'Team list',
            table: {
                headers: {
                    name: 'Name',
                    memberCount: 'Number of members',
                    actions: 'Actions',
                    verified: 'Verification',
                },
                buttons: {
                    users: 'Users ({{ count }})',
                    viewTeam: 'Details',
                    payments: 'Payments',
                    deleteTeam: 'Delete',
                    localizations: 'Locations ({{ count }})',
                },
                noResults: 'No teams',
                noTeamUsersResults: 'No users',
                states: {
                    unverified: 'To complete',
                },
            },
        },
        teamView: {
            title: 'Details',
            teamDetails: {
                title: 'Details',
                subtitle: 'Accounting data',
                labels: {
                    companyName: 'Company name',
                    teamName: 'Team name',
                    address: 'Address',
                    taxId: 'NIP number',
                    clearingEmail: 'Accounting email',
                    paymentMethod: 'Payment method',
                    removeTeam: 'Team deletion',
                },
                street: 'street',
                paymentDescription: 'We use Stripe to process the payments.',
                removeDesription: 'Delete team. This operation is irreversible.',
                buttons: {
                    removeTeam: 'Delete team',
                    removePaymentMethod: 'Delete the payment method',
                    addPaymentMethod: 'Add payment method',
                },
            },
            teamMembers: 'Team members',
            loadingTeam: 'Loading the team...',
            table: {
                headers: {
                    name: 'First name and last name',
                    email: 'Email',
                    phone: 'Phone number',
                    role: 'Role',
                    actions: 'Actions',
                },
            },
            buttons: {
                edit: 'Edit',
                update: 'Update',
                createTeam: 'Create team',
            },
            alerts: {
                paymentMethod: {
                    added: 'The payment method was added correctly.',
                    processing: 'The payment method is in processing. The page will refresh soon.',
                    failed: 'Failed to add payment method',
                },
            },
        },
        teamMemberEdit: {
            alerts: {
                update: {
                    success: 'User role was changed.',
                },
                delete: {
                    success: 'Deletion of the user was successful.',
                },
                invite: {
                    success: 'User is invited.',
                    invitationAlreadyRejected:
                        'You have already invited this user, but he declined the invitation. Contact the administrator if you want to invite him again.',
                },
            },
        },
        teamOperation: {
            title: {
                create: 'Add team',
                edit: 'Edit team',
                addUser: 'Add user',
                editUser: 'Edit user',
                removeUser: 'Delete user',
                removeTeam: 'Delete team',
                removePaymentMethod: 'Delete the payment method',
            },
            editUser: {
                firstName: 'First name',
                lastName: 'Last name',
                email: 'Email',
                phone: 'Phone number',
            },
            inviteUser: {
                employee: 'Employee',
                employeeDescription: 'Select this role if the user should only be able to receive orders.',
                manager: 'Manager',
                managerDescription: 'Select this role if the user should be able to create orders and manage a team of employees.',
            },
            description: {
                removeUser: `Are you sure you want to delete a user from the team <0>{{ teamName }}</0>?`,
                removeTeam: 'Are you sure you want to delete the team?',
                removePaymentMethod: 'Are you sure you want to delete the payment method?',
                warning: 'This operation is irreversible.',
            },
            formControls: {
                name: 'Team name',
                companyName: 'Company name',
                taxIdentifier: 'NIP number',
                street: 'Street',
                houseNumber: 'Building number',
                flatNumber: 'Local number',
                city: 'City',
                zip: 'Zip code',
                clearingEmail: 'Accounting email',
            },
            buttons: {
                create: 'Create team',
                edit: 'Update',
                addUser: 'Add user',
                addLocation: 'Add location',
                editUser: 'Edit user',
                removeUser: 'Delete user',
                removeTeam: 'Delete team',
                removePaymentMethod: 'Delete the payment method',
            },
            alerts: {
                create: {
                    success: 'Team was created.',
                },
                update: {
                    success: 'Team was updated.',
                },
                delete: {
                    success: 'Team was deleted.',
                },
            },
        },
        removeButton: {
            team: {
                title: 'Delete team',
                message: 'Are you sure you want to delete the team {{name}}?',
                button: 'Delete team',
            },
            member: {
                title: 'Delete a team member',
                message: 'Are you sure you want to remove a team member {{name}}?',
                button: 'Delete a team member',
            },
        },
    },
    roles: {
        ROLE_PURCHASER_TEAM_OWNER: 'Owner',
        ROLE_PURCHASER_TEAM_MANAGER: 'Manager',
        ROLE_PURCHASER_TEAM_EMPLOYEE: 'Employee',
        invited: 'Invited',
    },
    buttons: {
        add: 'Add',
        addNew: 'Add new',
        upload: 'Send',
        more: 'More',
        edit: 'Edit',
        saveChanges: 'Save changes',
        discard: 'Reject',
        apply: 'Apply',
        buy: 'Buy',
        readLess: 'Less...',
        readMore: 'More...',
        close: 'Close',
        join: 'Join',
        showAll: 'Show all',
        next: 'Next',
        previous: 'Previous',
        submit: 'Confirm',
        change: 'Change',
        remove: 'Delete',
        update: 'Update',
        resetPassword: 'Reset password',
        cancel: 'Cancel',
        create: 'Create',
        confirm: 'Confirm',
        sendInvitation: 'Send invitation',
        view: 'View',
        detach: 'Detach',
        delete: 'Delete',
    },
    dateRangePicker: {
        config: {
            previousMonth: 'Previous month',
            lastWeek: 'Last week',
            today: 'Today',
            currentMonth: 'Current month',
        },
    },
    formValidation: {
        errors: {
            minLength: 'Value is too short',
            maxLength: 'Value is too long',
            isEmailValid: 'Email is incorrect',
            isPostCodeValid: 'Postcode is incorrect',
            isRequired: 'Area is required',
            isNipValid: 'NIP is incorrect',
            isPhoneValid: 'Phone number is incorrect',
            isMobileValid: 'Mobile phone number is incorrect',
            isCheckedTrue: 'Area should be checked',
            isTrue: 'Area should be checked',
            isPasswordSame: 'Passwords do not match',
            isStartDateValid: 'Please select a date from the future',
            isStartDateGreaterOrEvenValid: 'Please select a date from the future',
            isEndDateValid: 'Please select your return date after your departure date',
            isNumber: 'Specified value should be a number',
            isPasswordString: 'Password is incorrect.',
            atLeastOneChecked: 'Please tick at least one option',
            termsAndConditions: 'Conditions must be accepted.',
            isInteger: 'Value should be an integer',
            isPositiveNumber: 'Value should be a positive number',
            isLengthValid: 'Length is incorrect',
            onlyLetters: 'Value should contain only letters',
            noEndWithDash: 'Hyphens allowed only in the middle',
            isLaterThanNow: 'Date must be in the future',
            isProcessAtLaterThanNow: 'Date of implementation must be in the future',
            noRepeatedSpecialCharacters: 'There can only be one linking character',
            containingLetterNotStartingWithWhitespace: 'Area cannot begin or end with a space',
            badAddress: 'Pick an address containing a street and building number',
            orderNumberValidation: 'Field can contain maximum {{maxChars}} characters.',
        },
    },
    footer: {
        copyright: `PaliPali {{date}} © All right reserved`,
    },
    maintenance: {
        pageNotFound: 'Error - page not found',
    },
    menuItems: {
        teams: 'Teams',
        account: 'Account',
        locations: 'Locations',
        settings: 'Settings',
        logout: 'Logout',
        jobs: 'Orders history',
        activeJobs: 'Active orders',
    },
    alerts: {
        warning: 'Warning!',
        error: 'Error!',
        success: 'Success!',
        info: 'Information',
        authError: 'User name or password is incorrect.',
        noAccessError: 'You are not authorised to view this page.',
        baseError: 'An error has occurred, please contact your administrator.',
        loginError: 'Login error occurred, please try again.',
        tryAgain: 'Try again',
        unknown: 'Unknown error has occurred.',
        accessDenied: 'You do not have the authority.',
        paymentMethodAttached: 'Payment method has been successfully added.',
        paymentMethodDeleted: 'Payment method has been deleted.',
        accountNotConfirmed: 'Account has not been confirmed.',
        accountNotActive: 'Account is not active.',
        accountDeleted: 'Account has been deleted.',
        accountIsInvitation: 'Incorrect data.',
        wrongAccountError: 'You cannot log in to this application with this account.',
        invalidOrExpiredToken: 'Your token has expired or is invalid.',
        expiredTokenLogout: 'Your session has expired. You have been logged out.',
    },
    month: {
        january: 'January',
        february: 'February',
        march: 'March',
        april: 'April',
        may: 'May',
        june: 'June',
        july: 'July',
        august: 'August',
        september: 'September',
        october: 'October',
        november: 'November',
        december: 'December',
    },
    weekday: {
        sunday: 'Su',
        monday: 'Mo',
        tuesday: 'Tu',
        wednesday: 'We',
        thursday: 'Th',
        friday: 'Fr',
        saturday: 'Sa',
    },
    datepickerWeekday: {
        Su: 'Su',
        Mo: 'Mo',
        Tu: 'Tu',
        We: 'We',
        Th: 'Th',
        Fr: 'Fr',
        Sa: 'Sa',
    },
    general: {
        clearTextButton: 'Clear',
        noDataInfo: 'No data',
        prefixModal: {
            title: 'Select a prefix',
            noCountries: 'No countries to display',
        },
        cityModal: {
            title: 'Select a city',
            noCities: 'No cities to display',
        },
        locale: {
            pl_PL: 'Polish',
            en_US: 'English',
            uk_UA: 'Ukrainian',
            pl_pl: 'Polish',
            en_us: 'English',
            uk_ua: 'Ukrainian',
        },
        wrongLocale: '-',
        teamsModal: {
            noTeams: 'You have no teams added',
        },
        durationTime: '{{ hour }} g {{ minute }} min',
        currency: {
            pln: 'zł',
        },
        boxMeasurements: {
            max: 'max',
            weight: 'up to {{weight}} kg',
            unit: ' cm',
        },
        grossPrice: 'gross',
    },
    packageSizes: {
        label: 'Available order categories',
        maxWeight: 'up to {{weight}} kg',
        max: 'max.',
        s: {
            label: 'S',
        },
        m: {
            label: 'M',
        },
        l: {
            label: 'L',
        },
    },
    pagination: {
        pageCount: '<0>{{ page }}</0> z <1>{{ totalPages }}</1>',
    },
    jobStatusDetails: {
        planned: 'Planned',
        searching: 'Searching',
        drive: 'On the road',
        pickup: 'Taking delivery',
        delivery: 'Delivery of the consignment',
        delivered: 'Realised',
        cancelledPurchaser: 'Cancelled',
        cancelledCourier: 'Cancelled by Courier',
        problemPurchaser: 'Reported',
        problemCourier: 'Reported by Courier',
        deliveryDrive: 'In delivering',
        failed: 'Failure',
    },
    avatarUpload: {
        select: 'Select',
        change: 'Change',
        update: 'Update',
    },
    countryPhoneCodes: {
        AD: 'Andorra (376)',
        AE: 'United Arab Emirates (971)',
        AF: 'Afghanistan (93)',
        AG: 'Antigua and Barbuda (1-268)',
        AI: 'Anguilla (1-264)',
        AL: 'Albania (355)',
        AM: 'Armenia (374)',
        AN: 'Netherlands Antilles (599)',
        AO: 'Angola (244)',
        AQ: 'Antarctica (672)',
        AR: 'Argentina (54)',
        AS: 'American Samoa (1-684)',
        AT: 'Austria (43)',
        AU: 'Australia (61)',
        AW: 'Aruba (297)',
        AZ: 'Azerbaijan (994)',
        BA: 'Bosnia and Herzegovina (387)',
        BB: 'Barbados (1-246)',
        BD: 'Bangladesh (880)',
        BE: 'Belgium (32)',
        BF: 'Burkina Faso (226)',
        BG: 'Bulgaria (359)',
        BH: 'Bahrain (973)',
        BI: 'Burundi (257)',
        BJ: 'Benin (229)',
        BL: 'Saint-Barthélemy (590)',
        BM: 'Bermuda (1-441)',
        BN: 'Brunei (673)',
        BO: 'Bolivia (591)',
        BR: 'Brazil (55)',
        BS: 'Bahamas (1-242)',
        BT: 'Bhutan (975)',
        BW: 'Botswana (267)',
        BY: 'Belarus (375)',
        BZ: 'Belize (501)',
        CA: 'Canada (1)',
        CC: 'Cocos Islands (61)',
        CD: 'Democratic Republic of the Congo (243)',
        CF: 'Central African Republic (236)',
        CG: 'Congo (242)',
        CH: 'Switzerland (41)',
        CI: "Côte d'Ivoire (225)",
        CK: 'Cook Islands (682)',
        CL: 'Chile (56)',
        CM: 'Cameroon (237)',
        CN: 'China (86)',
        CO: 'Colombia (57)',
        CR: 'Costa Rica (506)',
        CU: 'Cuba (53)',
        CV: 'Cape Verde (238)',
        CW: 'Curaçao (599)',
        CX: 'Christmas Island (61)',
        CY: 'Cyprus (357)',
        CZ: 'Czech Republic (420)',
        DE: 'Germany (49)',
        DJ: 'Djibouti (253)',
        DK: 'Denmark (45)',
        DM: 'Dominica (1-767)',
        DO: 'Dominican Republic (1-809)',
        DZ: 'Algeria (213)',
        EC: 'Ecuador (593)',
        EE: 'Estonia (372)',
        EG: 'Egypt (20)',
        EH: 'Western Sahara (212)',
        ER: 'Eritrea (291)',
        ES: 'Spain (34)',
        ET: 'Ethiopia (251)',
        FI: 'Finland (358)',
        FJ: 'Fiji (679)',
        FK: 'Falkland Islands (500)',
        FM: 'Micronesia (691)',
        FO: 'Faroe Islands (298)',
        FR: 'France (33)',
        GA: 'Gabon (241)',
        GB: 'Great Britain (44)',
        GD: 'Grenada (1-473)',
        GE: 'Georgia (995)',
        GG: 'Guernsey (44-1481)',
        GH: 'Ghana (233)',
        GI: 'Gibraltar (350)',
        GL: 'Greenland (299)',
        GM: 'Gambia (220)',
        GN: 'Guinea (224)',
        GQ: 'Equatorial Guinea (240)',
        GR: 'Greece (30)',
        GT: 'Guatemala (502)',
        GU: 'Guam (1-671)',
        GW: 'Guinea-Bissau (245)',
        GY: 'Guyana (592)',
        HN: 'Hong Kong (852)',
        HR: 'Croatia (385)',
        HT: 'Haiti (509)',
        HU: 'Hungary (36)',
        ID: 'Indonesia (62)',
        IE: 'Ireland (353)',
        IL: 'Israel (972)',
        IM: 'Isle of Man (44-1624)',
        IN: 'India (91)',
        IO: 'British Indian Ocean Territory (246)',
        IQ: 'Iraq (964)',
        IR: 'Iran (98)',
        IS: 'Iceland (354)',
        IT: 'Italy (39)',
        JE: 'Jersey (44-1534)',
        JM: 'Jamaica (1-876)',
        JO: 'Jordan (962)',
        JP: 'Japan (81)',
        KE: 'Kenya (254)',
        KG: 'Kyrgyzstan (996)',
        KH: 'Cambodia (588)',
        KI: 'Kiribati (686)',
        KM: 'Chambers (269)',
        KN: 'Saint Kitts i Nevis (1-869)',
        KP: 'North Korea (850)',
        KR: 'South Korea (82)',
        KW: 'Kuwait (965)',
        KY: 'Cayman Islands (1-345)',
        KZ: 'Kazakhstan (7)',
        LA: 'Laos (856)',
        LB: 'Liban (961)',
        LC: 'Saint Lucia (1-758)',
        LI: 'Liechtenstein (423)',
        LK: 'Sri Lanka (94)',
        LR: 'Liberia (231)',
        LS: 'Lesotho (266)',
        LT: 'Lithuania (370)',
        LU: 'Luxembourg (352)',
        LV: 'Latvia (371)',
        LY: 'Libya (218)',
        MA: 'Morocco (212)',
        MC: 'Monaco (373)',
        MD: 'Moldavia (373)',
        ME: 'Montenegro (382)',
        MF: 'Saint Martin (590)',
        MG: 'Madagascar (261)',
        MH: 'Wyspy Marshalla (692)',
        MK: 'North Macedonia (389)',
        ML: 'Mali (223)',
        MM: 'Myanmar (95)',
        MN: 'Mongolia (976)',
        MO: 'Macau (853)',
        MP: 'Northern Mariana Islands (1-670)',
        MR: 'Mauritania (222)',
        MS: 'Montserrat (1-664)',
        MT: 'Malta (356)',
        MU: 'Mauritius (230)',
        MV: 'Maldives (960)',
        MW: 'Malawi (265)',
        MX: 'Mexico (52)',
        MY: 'Malaysia (60)',
        MZ: 'Mozambique (258)',
        NA: 'Namibia (264)',
        NC: 'New Caledonia (687)',
        NE: 'Niger (227)',
        NG: 'Nigeria (234)',
        NI: 'Nicaragua (505)',
        NL: 'Netherlands (31)',
        NO: 'Norway (47)',
        NP: 'Nepal (977)',
        NR: 'Nauru (674)',
        NU: 'Niue (683)',
        NZ: 'New Zealand (64)',
        OM: 'Oman (968)',
        PA: 'Panama (507)',
        PE: 'Peru (51)',
        PF: 'French Polynesia (689)',
        PG: 'Papua New Guinea (675)',
        PH: 'Philippines (63)',
        PK: 'Pakistan (92)',
        PL: 'Poland (48)',
        PM: 'Saint-Pierre and Miquelon (508)',
        PN: 'Pitcairn (64)',
        PR: 'Puerto Rico (1-787)',
        PS: 'Palestine (970)',
        PT: 'Portugal (351)',
        PW: 'Palau (680)',
        PY: 'Paraguay (595)',
        QA: 'Qatar (974)',
        RO: 'Romania (40)',
        RS: 'Serbia (381)',
        RU: 'Russia (7)',
        RW: 'Rwanda (250)',
        SA: 'Saudi Arabia (966)',
        SB: 'Solomon Islands (677)',
        SC: 'Seychelles (248)',
        SD: 'Sudan (249)',
        SE: 'Sweden (46)',
        SG: 'Singapore (65)',
        SH: 'Saint Helena (290)',
        SI: 'Slovenia (386)',
        SJ: 'Svalbard and Jan Mayen (47)',
        SK: 'Slovakia (421)',
        SL: 'Sierra Leone (232)',
        SM: 'San Marino (378)',
        SN: 'Senegal (221)',
        SO: 'Somalia (252)',
        SR: 'Surinam (597)',
        SS: 'South Sudan (211)',
        ST: 'São Tomé and Príncipe (239)',
        SV: 'Salvador (503)',
        SX: 'Sint Maarten (1-721)',
        SY: 'Syria (963)',
        SZ: 'Eswatini (268)',
        TD: 'Chad (235)',
        TG: 'Togo (228)',
        TH: 'Thailand (66)',
    },
    // special auth errors from the backend
    'An authentication exception occurred.': 'An authentication error occurred..',
    'Authentication credentials could not be found.': 'Authentication data not found.',
    'Authentication request could not be processed due to a system problem.':
        'Authentication request could not be completed successfully due to a problem with the system.',
    'Invalid credentials.': 'Incorrect data.',
    'Cookie has already been used by someone else.': 'his cookie is used by someone else.',
    'Not privileged to request the resource.': 'No authority for requesting the indicated resource.',
    'Invalid CSRF token.': 'Incorrect token CSRF.',
    'No authentication provider found to support the authentication token.':
        'No authentication mechanism capable of handling the submitted token was found.',
    'No session available, it either timed out or cookies are not enabled.': 'No session data, session expired or cookies not enabled.',
    'No token could be found.': 'Token not found.',
    'Username could not be found.': 'The user with the specified name does not exist.',
    'Account has expired.': 'Account has expiredv.',
    'Credentials have expired.': 'Authentication data expired.',
    'Account is disabled.': 'Account is disabled.',
    'Account is locked.': 'Account is blocked.',
    'Too many failed login attempts, please try again later.': 'Too many unsuccessful login attempts, please try again later.',
    'Invalid or expired login link.': 'Incorrect or expired login link.',
    'Too many failed login attempts, please try again in %minutes% minute.':
        'Too many unsuccessful login attempts, try again after %minutes% minutes.',
    'Too many failed login attempts, please try again in %minutes% minutes.':
        'Too many unsuccessful login attempts, try again after %minutes% minutes.',
    // end special auth errors from the backend
};

export default en;
