import {FormikFieldConfig, FormikFieldTypes, ValidationErrorType} from 'palipali-panel-common-web';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';

const reportIssueFormConfig = (isOtherOptionChosen: boolean): FormikFieldConfig[] => {
    const {t} = useTranslation();
    const validation = isOtherOptionChosen ? Yup.string().required(`${t(ValidationErrorType.IS_REQUIRED)}`) : Yup.string();
    return [
        {
            name: 'description',
            label: t('jobs.report.description'),
            validation: validation,
            type: FormikFieldTypes.TEXT,
            isRequired: isOtherOptionChosen,
            rows: 6,
            characterLimit: 500,
        },
        {
            name: 'imgId',
            label: t('jobs.report.descriptionPhoto'),
            validation: Yup.string(),
            acceptedFileTypes: 'image',
            className: 'jobs-job-report-modal-upload-image',
            multipleFiles: false,
            getFileIdValue: true,
            placeholder: `${t('jobs.report.descriptionPhoto')}`,
            type: FormikFieldTypes.FILE,
        },
    ];
};
export default reportIssueFormConfig;
