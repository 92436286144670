import {
    IAuthToken,
    ITeamListingOutput,
    Translation,
    UserRole,
    authTokenSelector,
    teamListingSelector,
    NoDataInfo,
    isTeamListingLoadingSelector,
    Loader,
    LoaderType,
    taxIdentifierFormatter,
    LocationFormattedAddress,
    LocationFormattedAddressType,
    Eye,
    LocalizationIcon,
    Users,
    AddUserIcon,
    getTeamListing,
    isAuthTokenExpiredSelector,
} from 'palipali-panel-common-web';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {Box, Button, Card, Stack, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, Typography} from '@mui/material';
import jwtDecode from 'jwt-decode';
import {changeActiveTab} from 'src/store/reducers/teamViewSlice';

const TeamList: React.FC = () => {
    const teams = useSelector(teamListingSelector),
        dispatch = useDispatch(),
        isLoading: boolean = useSelector(isTeamListingLoadingSelector),
        token: string = useSelector(authTokenSelector),
        navigate = useNavigate(),
        isAuthTokenExpired: boolean = useSelector(isAuthTokenExpiredSelector);

    useEffect(() => {
        if (!isAuthTokenExpired) {
            dispatch(getTeamListing());
        }
    }, [dispatch, isAuthTokenExpired]);

    const authTokenTeamRoles = (authToken: string | null): {[key: string]: UserRole} | null => {
        if (!authToken) {
            return null;
        }
        const decodedToken = jwtDecode<IAuthToken>(authToken);
        return decodedToken?.teams ? decodedToken.teams : null;
    };
    const getTeamAccessRole = (authToken: string | null, teamId?: string | null): UserRole | null => {
        if (!authToken || !teamId) {
            return null;
        }

        const accountTeams = authTokenTeamRoles(authToken),
            role = accountTeams?.[teamId];

        return role ? role : null;
    };

    const renderTableBody = () => {
        if (isLoading) return;

        if (!teams || teams.length === 0) {
            return (
                <TableRow>
                    <TableCell colSpan={6}>
                        <NoDataInfo text={'teams.teamList.table.noResults'} />
                    </TableCell>
                </TableRow>
            );
        }
        const getNavigationLink = (teamId: string) => {
            const role = getTeamAccessRole(token, teamId);
            if (role === UserRole.PURCHASER_TEAM_OWNER) {
                return `edit/${teamId}`;
            } else if (role === UserRole.PURCHASER_TEAM_MANAGER) {
                return `${teamId}`;
            } else {
                null;
            }
        };
        return teams.map((team: ITeamListingOutput, index: number) => {
            const navigationLink = getNavigationLink(team.id);

            return (
                <TableRow key={team.id}>
                    <TableCell>
                        <Box sx={{display: 'flex', alignItems: 'center', gap: '1.6rem'}}>
                            <span className="index-number-cell">
                                <Typography component="span" className="index-number">{`#${index + 1}`}</Typography>
                            </span>
                            <Typography>{`${team.name ? team.name : '-'}`}</Typography>
                        </Box>
                    </TableCell>
                    <TableCell>
                        <Typography>{team.companyName}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography component="div">
                            {team.city && team.houseNumber ? (
                                <LocationFormattedAddress address={team} addressType={LocationFormattedAddressType.TEAM} />
                            ) : (
                                '-'
                            )}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography>{taxIdentifierFormatter(team.taxIdentifier)}</Typography>
                    </TableCell>
                    <TableCell className="action-cell">
                        <div className="action-cell-container">
                            <div className="btn-action-wrapper">
                                <Button
                                    disabled={!navigationLink}
                                    className="btn btn-action"
                                    onClick={() => {
                                        dispatch(changeActiveTab('locations'));
                                        navigate(`${navigationLink}`);
                                    }}
                                    startIcon={<LocalizationIcon />}>
                                    <Translation
                                        text="teams.teamList.table.buttons.localizations"
                                        config={{count: team.privateLocationsCount || 0}}
                                    />
                                </Button>
                            </div>
                            <div className="btn-action-wrapper">
                                <Button
                                    disabled={!navigationLink}
                                    className="btn btn-action"
                                    onClick={() => {
                                        dispatch(changeActiveTab('members'));
                                        navigate(`${navigationLink}`);
                                    }}
                                    startIcon={<Users />}>
                                    <Translation text="teams.teamList.table.buttons.users" config={{count: team.memberCount || 0}} />
                                </Button>
                            </div>
                            <div className="btn-action-wrapper">
                                <Button
                                    disabled={!navigationLink}
                                    className="btn btn-action"
                                    onClick={() => {
                                        dispatch(changeActiveTab('details'));
                                        navigate(`${navigationLink}`);
                                    }}
                                    startIcon={<Eye />}>
                                    <Translation text="teams.teamList.table.buttons.viewTeam" />
                                </Button>
                            </div>
                        </div>
                    </TableCell>
                </TableRow>
            );
        });
    };

    return (
        <Stack>
            <div className="header">
                <div className="title">
                    <Typography variant="h4" component="h2" color="text.primary">
                        <Translation text="teams.title" />
                    </Typography>
                </div>
                <Button
                    className="header-button"
                    onClick={() => navigate('create')}
                    variant="contained"
                    color="primary"
                    size="medium"
                    startIcon={<AddUserIcon />}>
                    <Translation text="teams.teamOperation.title.create" />
                </Button>
            </div>

            <Card className="custom-card">
                <TableContainer>
                    <Table className="custom-list" sx={{borderTopLeftRadius: '2rem', borderTopRightRadius: '2rem', overflow: 'hidden'}}>
                        <TableHead>
                            <TableRow>
                                <TableCell className="team-name-header">
                                    <Box sx={{paddingLeft: '4.3rem'}}>
                                        <Translation text="teams.teamView.teamDetails.labels.teamName" />
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Translation text="teams.teamView.teamDetails.labels.companyName" />
                                </TableCell>
                                <TableCell>
                                    <Translation text="teams.teamView.teamDetails.labels.address" />
                                </TableCell>
                                <TableCell>
                                    <Translation text="teams.teamView.teamDetails.labels.taxId" />
                                </TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>{renderTableBody()}</TableBody>
                    </Table>
                </TableContainer>
                <Loader showLoader={isLoading} type={LoaderType.Local} />
            </Card>
        </Stack>
    );
};

export default TeamList;
