import {Box, Button, Card, Divider, Stack, Typography, useTheme} from '@mui/material';
import L, {LatLng, LatLngBounds} from 'leaflet';
import 'leaflet-defaulticon-compatibility';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css';
import moment from 'moment';
import {
    AlertType,
    ChevronLeftIcon,
    IMoneyOutput,
    IPurchaserCurrentJobDetailsOutput,
    JobStatus,
    JobViewAdditionalInfo,
    JobViewHeader,
    Loader,
    LocationPointBox,
    LocationPointBoxType,
    LocationPointType,
    MemberOperationType,
    TimeComponent,
    Translation,
    addAlert,
    closeModal,
    currentlySelectedModalTypeSelector,
    isModalOpenSelector,
    RefreshIcon,
} from 'palipali-panel-common-web';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {ColorFormat, CountdownCircleTimer} from 'react-countdown-circle-timer';
import {MapContainer, Marker, useMap} from 'react-leaflet';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {carMarkerIconDefault, receiverMarkerIcon, senderMarkerIcon} from 'src/components/shared/mapIcons';
import {deleteJob, getJob, returnToInitialJobState} from '../../../store/reducers/jobSlice';
import {cancellationFeeSelector, isJobLoadingSelector, jobSelector} from '../../../store/selectors/jobSelector';
import CustomTileLayer from '../../shared/CustomTileLayer';
import ReportIssueModal from '../../shared/ReportIssueModal';
import JobActionButtonComponent from './ActiveJobActionButton';
import CancelOrderModal from './ActiveJobCancelModal';
import DeleteOrderModal from './ActiveJobDeleteModal';
import EditOrderModal from './ActiveJobEditModal';
import CourierDetailsModal from './CourierDetailsModal';
import JobViewDetails from './JobViewDetails';
import TimeValueDisplaySpan, {CircleTimerType} from './TimeValueDisplaySpan';

interface IActiveJobViewProps {}

const ActiveJobView: React.FC<IActiveJobViewProps> = () => {
    const theme = useTheme();
    const [countNegative, setCountNegative] = useState<boolean>(false);
    const [displayInfo, setDisplayInfo] = useState<boolean>(false);
    const SetBounds = ({coords}: {coords: LatLngBounds}) => {
        const map = useMap();

        useEffect(() => {
            if (map) {
                map.fitBounds(coords);
            }
        }, [map, coords]);

        return null;
    };

    const {id} = useParams(),
        cancellationFee: IMoneyOutput | null = useSelector(cancellationFeeSelector),
        isModalOpen: boolean = useSelector(isModalOpenSelector),
        isLoading: boolean = useSelector(isJobLoadingSelector),
        modalType = useSelector(currentlySelectedModalTypeSelector),
        dispatch = useDispatch(),
        job: IPurchaserCurrentJobDetailsOutput | null = useSelector(jobSelector),
        navigate = useNavigate();

    const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);

    useEffect(() => {
        if (id) {
            dispatch(getJob(id));
        }
        return () => {
            dispatch(returnToInitialJobState());
            if (timeoutRef) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [id]);

    const handleDelete = () => {
        dispatch(deleteJob(id));
        dispatch(closeModal(false));
    };

    const renderTimer = () => {
        if (!job || job.deliveryStatus === JobStatus.PLANNED) return;

        return (
            <Box mt={2} width={'100%'} position={'relative'}>
                <Divider />
                <Stack className="job-view-circular-progress-wrapper">
                    <Box bgcolor={theme.palette.mode === 'dark' ? 'neutral.900' : '#FFFF'}>
                        <CountdownCircleTimer
                            size={46}
                            onComplete={handleOnTimerEnd}
                            strokeWidth={2}
                            trailColor={
                                countNegative
                                    ? (theme.palette.error.main as ColorFormat)
                                    : theme.palette.mode === 'dark'
                                    ? ('neutral.800' as ColorFormat)
                                    : ('neutral.50' as ColorFormat)
                            }
                            trailStrokeWidth={2}
                            isPlaying
                            duration={getFullTimerTimeInSeconds || 0}
                            initialRemainingTime={calculateTimerTimeThatRemainedFromFullTimerTime}
                            colors={
                                countNegative ? (theme.palette.error.main as ColorFormat) : (theme.palette.primary.main as ColorFormat)
                            }>
                            {(prop) => (
                                <TimeValueDisplaySpan
                                    {...prop}
                                    jobStatus={job.deliveryStatus}
                                    countNegative={countNegative}
                                    type={timerType}
                                    negativeElapsedTime={calculateTimerTimeThatRemainedFromFullTimerTime}
                                />
                            )}
                        </CountdownCircleTimer>
                    </Box>
                </Stack>
            </Box>
        );
    };

    const timerType = useMemo(() => {
        if (job?.deliveryStatus === JobStatus.DELIVERY) {
            return CircleTimerType.MINUTES_AND_SECONDS;
        }
        return CircleTimerType.MINUTES;
    }, [job?.deliveryStatus]);

    const handleOnTimerEnd = () => {
        if (job?.deliveryStatus === JobStatus.DELIVERY) {
            return setCountNegative(true);
        }
        return setDisplayInfo(true);
    };

    const getFullTimerTimeInSeconds = useMemo(() => {
        if (!job?.estimatedDeliveryAt || !job?.courierJobStartedAt) return undefined;
        if (job?.deliveryStatus === JobStatus.DELIVERY) {
            return moment(job?.deliveryTimeExpiresAt).diff(moment(job?.deliveryStartedAt), 'seconds');
        }
        return moment(job?.estimatedDeliveryAt).diff(job?.courierJobStartedAt, 'seconds');
    }, [job?.estimatedDeliveryAt, job?.courierJobStartedAt, job?.deliveryStatus, countNegative]);

    const calculateTimerTimeThatRemainedFromFullTimerTime = useMemo(() => {
        if (!job) {
            return 0;
        }

        if (job?.deliveryStatus === JobStatus.DELIVERY && job?.deliveryTimeExpiresAt) {
            return moment(job?.deliveryTimeExpiresAt).diff(moment(), 'seconds');
        }

        return moment(job?.estimatedDeliveryAt).diff(moment(), 'seconds');
    }, [job?.estimatedDeliveryAt, job?.deliveryTimeExpiresAt, job?.deliveryStatus, countNegative]);

    const renderFutureJobInfo = () => {
        if (!job || job.deliveryStatus !== JobStatus.PLANNED || !job.processAt) return;

        return (
            <Stack className="job-timer">
                {job.processAt && (
                    <>
                        <Typography className="processed-at-wrapper" color="text.secondary" variant="caption">
                            <Translation text="jobs.orderDetails.processAt" />
                        </Typography>
                        <Typography className="bold-text" color="text.secondary" variant="caption">
                            <TimeComponent application="purchaser" date={job.processAt} time={job.time} isSimpleTime />
                        </Typography>
                    </>
                )}
            </Stack>
        );
    };

    const renderMap = () => {
        if (!job) return;

        const lastCourierLocation = job.courierLocations[0],
            courierLatLng: L.LatLng | undefined = lastCourierLocation
                ? L.latLng(lastCourierLocation.latitude, lastCourierLocation.longitude)
                : undefined,
            fromLocationLatLng: LatLng = L.latLng(job.fromLocation.point.latitude, job.fromLocation.point.longitude),
            toLocationLatLng: LatLng = L.latLng(job.toLocation.point.latitude, job.toLocation.point.longitude);
        const bounds = L.latLngBounds(fromLocationLatLng, toLocationLatLng);
        if (courierLatLng) {
            bounds.extend(courierLatLng);
        }

        return (
            <div className="map-container">
                <MapContainer
                    zoom={12}
                    scrollWheelZoom={false}
                    maxZoom={18}
                    minZoom={2}
                    dragging={false}
                    doubleClickZoom={false}
                    zoomControl={false}
                    touchZoom={false}>
                    {job && job.deliveryStatus !== JobStatus.PLANNED ? (
                        <Button
                            className="refresh-position-button"
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                if (id) {
                                    dispatch(returnToInitialJobState());

                                    if (timeoutRef) clearTimeout(timeoutRef.current);
                                    timeoutRef.current = setTimeout(() => {
                                        dispatch(getJob(id));
                                    }, 0);
                                }
                            }}
                            disabled={isLoading}
                            startIcon={<RefreshIcon />}>
                            <Translation text="jobs.orderDetails.refresh" />
                        </Button>
                    ) : null}

                    <SetBounds coords={bounds} />
                    <CustomTileLayer />
                    {job.fromLocation.point ? (
                        <Marker icon={senderMarkerIcon} position={[job.fromLocation.point.latitude, job.fromLocation.point.longitude]} />
                    ) : null}
                    {job.toLocation.point ? (
                        <Marker icon={receiverMarkerIcon} position={[job.toLocation.point.latitude, job.toLocation.point.longitude]} />
                    ) : null}
                    {job.courierLocations.length !== 0 && lastCourierLocation ? (
                        <Marker icon={carMarkerIconDefault} position={[lastCourierLocation?.latitude, lastCourierLocation?.longitude]} />
                    ) : null}
                </MapContainer>
            </div>
        );
    };

    const renderJobInfo = () => {
        if (!job) return;
        if (job.deliveryStatus === JobStatus.FAILED) {
            dispatch(addAlert({message: 'jobs.orderDetails.alerts.failedJob', type: AlertType.ERROR}));
            navigate('/panel/active-orders');
        }
        return (
            <div className="job-info">
                <Stack className="job-view-header">
                    <Box className="btn-actions">
                        <Button onClick={() => navigate('/panel/active-orders')} className="btn btn-action" startIcon={<ChevronLeftIcon />}>
                            <Translation text="jobs.orderDetails.buttons.return" />
                        </Button>
                        <JobActionButtonComponent job={job} />
                    </Box>
                    <Divider />
                    <JobViewHeader job={job} />
                </Stack>
                {renderTimer()}
                {displayInfo && (
                    <Box py={1} display={'flex'} justifyContent={'center'} alignItems={'center'} className={'arriving-info'}>
                        <Typography variant="subtitle2" color="text.secondary">
                            <Translation text="jobs.orderDetails.courierArriving" />
                        </Typography>
                    </Box>
                )}
                {renderFutureJobInfo()}
                <div className="location-points">
                    <LocationPointBox type={LocationPointBoxType.ACTIVE} locationType={LocationPointType.SENDER} job={job} />
                    <LocationPointBox type={LocationPointBoxType.ACTIVE} locationType={LocationPointType.RECEIVER} job={job} />
                </div>{' '}
                <Divider />
                <Box sx={{display: 'flex', flexDirection: 'column', flex: 1}}>
                    <JobViewDetails job={job} />
                    <JobViewAdditionalInfo job={job} titleKey={'jobs.orderDetails.additionalInfo'} />
                </Box>
            </div>
        );
    };

    return (
        <Card className="job-view-wrapper">
            <div className="job-view">
                {renderJobInfo()}
                {renderMap()}
            </div>

            {isModalOpen && modalType === MemberOperationType.ACTIVE_ORDERS_ORDER_REPORT && <ReportIssueModal id={id ? id : null} />}

            {job?.courier && isModalOpen && modalType === MemberOperationType.ACTIVE_ORDERS_COURIER_DETAILS && (
                <CourierDetailsModal courier={job.courier} />
            )}
            {job && isModalOpen && modalType === MemberOperationType.ACTIVE_ORDERS_ORDER_EDIT && <EditOrderModal job={job} />}

            {isModalOpen && modalType === MemberOperationType.ACTIVE_ORDERS_ORDER_CANCEL && (
                <CancelOrderModal cancellationFee={cancellationFee} id={id ? id : null} jobNumber={job?.number ? job.number : null} />
            )}

            {isModalOpen && modalType === MemberOperationType.ACTIVE_ORDERS_ORDER_DELETE && (
                <DeleteOrderModal handleConfirm={() => handleDelete()} />
            )}
            <Loader showLoader={isLoading} />
        </Card>
    );
};

export default ActiveJobView;
