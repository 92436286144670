import {Box, Typography} from '@mui/material';
import {IPurchaserCurrentJobDetailsOutput, moneyString, Translation} from 'palipali-panel-common-web';
import React from 'react';

interface IJobPriceComponentProps {
    job: IPurchaserCurrentJobDetailsOutput | null;
}

const JobPriceComponent: React.FC<IJobPriceComponentProps> = ({job}) => {
    return (
        <Box className={'job-price'}>
            <Box className={'price-labels'}>
                <Typography variant="inherit">
                    <Translation text={'jobs.createJob.jobSummary.netPrice'} />
                </Typography>
                <Typography variant="inherit" className={`secondary-value`}>
                    <Translation text={'jobs.createJob.jobSummary.taxPrice'} />
                </Typography>
                <Typography variant="inherit" className={`secondary-value gross-value`}>
                    <Translation text={'jobs.createJob.jobSummary.totalPrice'} />
                </Typography>
            </Box>
            <Box className={'price-values'}>
                <Typography variant="inherit">{moneyString(job?.costNet)}</Typography>
                <Typography variant="inherit" className={`secondary-value`}>
                    {moneyString(job?.costTax)}
                </Typography>
                <Typography variant="inherit" className={`secondary-value gross-value`}>
                    {moneyString(job?.cost)}
                </Typography>
            </Box>
        </Box>
    );
};

export default JobPriceComponent;
