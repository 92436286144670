import {Box, Button, Card, CardActions, CardContent, Divider, Grid, Stack, Typography, useTheme} from '@mui/material';
import {useFormik} from 'formik';
import L from 'leaflet';
import 'leaflet-defaulticon-compatibility';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css';
import {
    ChevronLeftIcon,
    FormikForm,
    IGeoPointInput,
    IGooglePlaceDetail,
    ILocationInput,
    ILocationOutput,
    IOpenHoursInput,
    IOpenHoursOutput,
    IPhoneInput,
    Loader,
    LoaderType,
    MemberOperationType,
    Translation,
    createLocationTypesSelector,
    currentlySelectedModalTypeSelector,
    getAddressParts,
    isModalOpenSelector,
    locationTranslationSelector,
} from 'palipali-panel-common-web';
import {IGoogleAddressParts} from 'palipali-panel-common-web/dist/model/location';
import React, {useEffect, useState} from 'react';
import Geocode from 'react-geocode';
import {MapContainer, Marker} from 'react-leaflet';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router';
import {useNavigate, useParams} from 'react-router-dom';
import {isNavigationOutsideViewSelector} from 'src/store/selectors/createJobViewSelectors';
import {
    createJobLocation,
    createLocation,
    createTeamLocation,
    setRedirectToLocationList,
    updateLocation,
} from '../../../store/reducers/locationViewSlice';
import {
    isLocationLoadingSelector,
    isRedirectToLocationListSelector,
    locationSelector,
} from '../../../store/selectors/locationViewSelectors';
import UpdateMapCenter from '../../ActiveJobs/ActiveJobCreate/CreateJobSenderData/UpdateMapCenter';
import CustomTileLayer from '../../shared/CustomTileLayer';
import MapClickHandler from '../../shared/MapClickHandler';
import DeleteLocation from './DeleteLocationCard';
import OpeningHoursCard from './OpeningHours';
import OpeningHoursModal from './OpeningHoursModal';
import locationOperationFormConfig from './locationOperationFormConfig';

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

export const enum LocationOperationType {
    CREATE = 'create',
    EDIT = 'edit',
    CREATE_TEAM_LOCATION = 'createTeamLocation',
}

interface ILocationCreateEditProps {
    operationType: LocationOperationType;
}

export interface ILocationOperationValues {
    name: string | null;
    companyName: string | null;
    dialingCode: string;
    phone: string | null;
    addressLine: string | null;
    flatNumber: string | null;
    point: IGeoPointInput | null;
    locationTypeId: string | null;
    street: string | null;
    houseNumber: string | null;
    zip: string | null;
    city: string | null;
    country: string | null;
}

const LocationCreateEdit: React.FC<ILocationCreateEditProps> = ({operationType}) => {
    const theme = useTheme(),
        locationTypes = useSelector(createLocationTypesSelector),
        isLoading = useSelector(isLocationLoadingSelector),
        isModalOpen = useSelector(isModalOpenSelector),
        modalType = useSelector(currentlySelectedModalTypeSelector),
        isCreateJobLocationCreation = useSelector(isNavigationOutsideViewSelector),
        dispatch = useDispatch(),
        {id} = useParams(),
        selectedLocation: ILocationOutput | null = id ? useSelector(locationSelector) : null,
        [defaultCoordinates, setDefaultCoordinates] = useState<IGeoPointInput | null>(null),
        [isSubmitAllowed, setIsSubmitAllowed] = useState<boolean>(false);

    const initialValues: ILocationOperationValues = {
        name: selectedLocation?.name || '',
        companyName: selectedLocation?.companyName || '',
        dialingCode: selectedLocation?.phone?.country || '+48',
        phone: selectedLocation?.phone?.phone || '',
        addressLine: selectedLocation?.addressLine || '',
        flatNumber: selectedLocation?.flatNumber || '',
        point: selectedLocation?.point
            ? {
                  latitude: selectedLocation.point.latitude,
                  longitude: selectedLocation.point.longitude,
              }
            : null,
        locationTypeId: selectedLocation?.locationTypeId || '',
        street: selectedLocation && selectedLocation.street ? selectedLocation.street : '',
        houseNumber: selectedLocation && selectedLocation.houseNumber ? selectedLocation.houseNumber : '',
        zip: selectedLocation && selectedLocation.zip ? selectedLocation.zip : '',
        city: selectedLocation && selectedLocation.city ? selectedLocation.city : '',
        country: selectedLocation && selectedLocation.country ? selectedLocation.country : '',
    };

    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: () => {
            performLocationOperation();
        },
        validateOnBlur: true,
        validateOnChange: true,
    });

    const defaultData: IOpenHoursOutput = {
        _0: {from: '08:00', to: '16:00'},
        _1: {from: '08:00', to: '16:00'},
        _2: {from: '08:00', to: '16:00'},
        _3: {from: '08:00', to: '16:00'},
        _4: {from: '08:00', to: '16:00'},
        _5: {from: '08:00', to: '16:00'},
        _6: {from: '08:00', to: '16:00'},
    };

    const [updatedData, setUpdatedData] = useState<IOpenHoursOutput>(defaultData);

    const isRedirectToLocationList = useSelector(isRedirectToLocationListSelector);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (GOOGLE_MAPS_API_KEY) {
            Geocode.setApiKey(GOOGLE_MAPS_API_KEY);
        }
    }, []);

    useEffect(() => {
        const locationDetails = location.state?.location;

        if (locationDetails) {
            formik.setValues(
                {
                    ...formik.values,
                    name: locationDetails?.name,
                    companyName: locationDetails?.companyName,
                    dialingCode: locationDetails?.phone?.country || '+48',
                    phone: locationDetails?.phone?.phone,
                    addressLine: locationDetails?.addressLine,
                    flatNumber: locationDetails?.flatNumber,
                    point: locationDetails?.point
                        ? {
                              latitude: locationDetails.point.latitude,
                              longitude: locationDetails.point.longitude,
                          }
                        : null,
                    locationTypeId: locationDetails?.locationTypeId,
                    street: locationDetails?.street,
                    houseNumber: locationDetails?.houseNumber,
                    zip: locationDetails?.zip,
                    city: locationDetails?.city,
                    country: locationDetails?.country,
                },
                false
            );
        }
    }, [location]);

    useEffect(() => {
        if (isRedirectToLocationList) {
            dispatch(setRedirectToLocationList(false));
            navigate('/panel/locations');
        }
    }, [isRedirectToLocationList, navigate]);

    const setNewHoursSet = (data: IOpenHoursOutput) => {
        formik.setFieldValue('openHoursChanged', true);
        setUpdatedData(data);
    };

    const performLocationOperation = () => {
        const teamId = location.state.teamId;
        if (!enabledSubmit || !formik.values.point) return null;
        const phoneObject: IPhoneInput | null = formik?.values?.phone
            ? {country: formik?.values?.dialingCode, phone: formik?.values?.phone}
            : null;
        const createPayload: ILocationInput = {
            name: formik?.values?.name || '',
            companyName: formik?.values?.companyName || null,
            phone: phoneObject,
            openHours: updatedData ? updatedData : null,
            addressLine: formik?.values?.addressLine || '',
            flatNumber: formik.values.flatNumber && formik.values.flatNumber !== '' ? formik.values.flatNumber : null,
            point: formik.values.point,
            locationTypeId: formik?.values?.locationTypeId || '',
            teamId: teamId ? teamId : null,
            street: formik.values?.street,
            houseNumber: formik.values?.houseNumber || '',
            zip: formik.values?.zip || '',
            city: formik.values?.city || '',
            country: formik.values?.country || '',
        };
        if (isCreateJobLocationCreation) {
            dispatch(createJobLocation(createPayload));
        } else if (operationType === LocationOperationType.CREATE) {
            dispatch(createLocation(createPayload));
        } else if (operationType === LocationOperationType.EDIT && id) {
            createPayload.teamId = selectedLocation?.teamId || null;
            dispatch(updateLocation(id, createPayload));
        } else if (operationType === LocationOperationType.CREATE_TEAM_LOCATION) {
            dispatch(createTeamLocation(createPayload));
        }
        formik.resetForm();
    };

    const locationTypesMultiselect = () => {
        return locationTypes.map((location) => {
            const translationName = useSelector((state) => locationTranslationSelector(state, location.id));
            return {
                label: translationName ? translationName : '',
                value: location.id,
            };
        });
    };

    useEffect(() => {
        if (selectedLocation) {
            const defaultData: IOpenHoursOutput = updatedData,
                openHours = selectedLocation.openHours;
            for (const key in openHours) {
                if (key.startsWith('_')) {
                    defaultData[key as keyof IOpenHoursInput] =
                        openHours === null || openHours[key as keyof IOpenHoursInput] === null
                            ? null
                            : {
                                  from: openHours[key as keyof IOpenHoursInput]?.from || '',
                                  to: openHours[key as keyof IOpenHoursInput]?.to || '',
                              };
                }
            }
            formik.setValues({
                ...formik.values,
                dialingCode: selectedLocation?.phone?.country || '+48',
                phone: selectedLocation?.phone?.phone || '',
                name: selectedLocation.name,
                companyName: selectedLocation?.companyName || '',
                locationTypeId: selectedLocation.locationTypeId,
                point: selectedLocation?.point
                    ? {
                          latitude: selectedLocation.point.latitude,
                          longitude: selectedLocation.point.longitude,
                      }
                    : null,
            });
            setDefaultCoordinates(selectedLocation.point);
            setUpdatedData(defaultData);
        }
    }, [selectedLocation]);

    const onCoordinatesChange = (lat: number, lng: number) => {
        const point: IGeoPointInput = {
            latitude: lat,
            longitude: lng,
        };

        Geocode.fromLatLng(String(lat), String(lng)).then(
            (response) => {
                const addressParts = getAddressParts(response.results[0] as unknown as IGooglePlaceDetail);
                const address = response.results[0].formatted_address;
                formik.setValues({
                    ...formik.values,
                    addressLine: address,
                    point: point,
                    ...addressParts,
                });
            },
            (error) => {
                console.error(error);
            }
        );

        setDefaultCoordinates(point);
    };

    const locationMarkerIcon = L.icon({
        iconUrl: require('../../../assets/images/location-marker-icon.png'),
        iconSize: [48, 48],
        iconAnchor: [24, 48],
        popupAnchor: [-3, -76],
    });

    const isAddressPartsValid = () =>
        formik.values.zip && formik.values.street && formik.values.houseNumber && formik.values.city && formik.values.country;

    const enabledSubmit = isAddressPartsValid() && formik.dirty && isSubmitAllowed && formik.isValid;

    const isFieldDirty = (fieldName: keyof ILocationOperationValues) => {
        return formik.values[fieldName] !== formik.initialValues[fieldName];
    };

    const setAddressAndCoordsFromMapAction = (coords: [number, number], address: string, addressParts: IGoogleAddressParts) => {
        formik.setValues({
            ...formik.values,
            ...addressParts,
            addressLine: address,
            point: {
                latitude: coords[0],
                longitude: coords[1],
            },
        });
    };

    const onAddressClear = () => {
        formik.setValues({
            ...formik.values,
            addressLine: '',
            point: null,
            street: '',
            houseNumber: '',
            zip: '',
            city: '',
            country: '',
        });
    };

    return (
        <Stack>
            <div className="btn-back-wrapper">
                <Button onClick={() => navigate(-1)} className="btn-action" startIcon={<ChevronLeftIcon />}>
                    <Translation text="jobs.orderDetails.buttons.return" />
                </Button>
            </div>
            <div className="header">
                <div className="title">
                    <Typography variant="h4" component="h2" color="text.primary">
                        <Translation text={`locations.locationOperation.title.${operationType}`} />
                    </Typography>
                </div>
            </div>
            <Stack spacing={5} sx={{position: 'relative'}}>
                <Loader showLoader={isLoading} type={LoaderType.Local} customClass="elevated-loader" />
                <Card className="custom-card m-0">
                    <CardContent>
                        <Grid container direction={{xs: 'column', sm: 'row'}}>
                            <Grid item xs={12} md={6} p={3}>
                                <FormikForm
                                    fields={locationOperationFormConfig(locationTypesMultiselect())}
                                    formId="location-data"
                                    initialValues={initialValues}
                                    updatedValues={formik.values}
                                    shouldValidate={formik.dirty && selectedLocation !== null}
                                    theme={theme}
                                    submitAllowed={setIsSubmitAllowed}
                                    customEventChange={(formControl: string, value: string) => {
                                        formik.setFieldValue(formControl, value);
                                    }}
                                    enableReinitialize={true}
                                    onAddressClear={onAddressClear}
                                    onAddressChange={(
                                        addressLine: string,
                                        point: IGeoPointInput | null,
                                        addressParts: IGoogleAddressParts
                                    ) => {
                                        formik.setValues({
                                            ...formik.values,
                                            addressLine: addressLine,
                                            point: point,
                                            ...addressParts,
                                        });

                                        setDefaultCoordinates(point);
                                    }}
                                    onSubmit={() => performLocationOperation()}
                                    isAutocompleteError={!isAddressPartsValid() && isFieldDirty('addressLine')}
                                />

                                <Box sx={{marginTop: '1rem'}}>
                                    <OpeningHoursCard openingHours={updatedData} />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6} p={3}>
                                <Box className="map-container">
                                    <MapContainer
                                        center={
                                            formik.values?.point
                                                ? [formik.values.point.latitude, formik.values.point.longitude]
                                                : [52.237049, 21.017532]
                                        }
                                        zoom={12}
                                        maxZoom={18}
                                        minZoom={2}
                                        scrollWheelZoom={false}>
                                        <CustomTileLayer />
                                        {formik?.values?.point ? (
                                            <Marker
                                                position={[formik.values.point.latitude, formik.values.point.longitude]}
                                                icon={locationMarkerIcon}
                                            />
                                        ) : null}
                                        <UpdateMapCenter
                                            latitude={
                                                defaultCoordinates?.latitude ? defaultCoordinates?.latitude : formik.values?.point?.latitude
                                            }
                                            longitude={
                                                defaultCoordinates?.longitude
                                                    ? defaultCoordinates?.longitude
                                                    : formik.values?.point?.longitude
                                            }
                                            onCoordinatesSelected={onCoordinatesChange}
                                        />
                                        <MapClickHandler onLocationChange={setAddressAndCoordsFromMapAction} />
                                    </MapContainer>
                                </Box>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <Divider />
                    <CardActions>
                        <Box className={'button-container'}>
                            <Button color="primary" variant="contained" type="submit" form="location-data" disabled={!enabledSubmit}>
                                <Translation text={`locations.locationOperation.buttons.${operationType}`} />
                            </Button>
                        </Box>
                    </CardActions>
                </Card>
                {id && <DeleteLocation locationId={id} operationType={operationType} />}
            </Stack>
            {isModalOpen && modalType === MemberOperationType.LOCATIONS_OPENING_HOURS && (
                <OpeningHoursModal data={updatedData} setNewHoursSet={setNewHoursSet} />
            )}
        </Stack>
    );
};

export default LocationCreateEdit;
