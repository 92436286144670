import {Box, Button, Divider, Grid, Stack, Typography, useTheme} from '@mui/material';
import {useFormik} from 'formik';
import {
    FormikForm,
    IJobRecipientInput,
    IMultiselectOption,
    ITeamMemberOutput,
    Translation,
    formatPhoneNumber,
    ifUndefinedSetEmptyString,
    isNullOrUndefined,
    ChevronLeftIcon,
    ChevronRight,
    isPhoneNumberTypeMobile,
} from 'palipali-panel-common-web';
import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    CreateJobWizardSteps,
    getRecipients,
    resetRecipients,
    setCurrentStep,
    validateJobRecipientData,
} from '../../../../store/reducers/createJobViewSlice';
import {
    createJobGeneralDataSelector,
    createJobRecipientByIdSelector,
    createJobRecipientDataSelector,
    createJobRecipientsListSelector,
} from '../../../../store/selectors/createJobViewSelectors';
import recipientDataFormConfig from './recipientDataFormConfig';

interface RecipientDataForm {
    isNewTeamMember: boolean;
    teamMemberId: string | null;
    firstName: string | null;
    lastName: string | null;
    country: string;
    phone: string;
}

interface ICreateJobTeamMemberDataProps {}

const CreateJobTeamMemberData: React.FC<ICreateJobTeamMemberDataProps> = () => {
    const theme = useTheme(),
        dispatch = useDispatch(),
        recipientData = useSelector(createJobRecipientDataSelector),
        [selectedTeamMember, setSelectedTeamMember] = useState<ITeamMemberOutput | null>(null),
        generalData = useSelector(createJobGeneralDataSelector),
        teamMembers: ITeamMemberOutput[] | null = useSelector(createJobRecipientsListSelector),
        teamMember = useSelector((state) => createJobRecipientByIdSelector(state, recipientData?.teamMemberId)),
        [initialValues, setInitialValues] = useState<RecipientDataForm>({
            isNewTeamMember: (recipientData && isNullOrUndefined(recipientData.teamMemberId)) || false,
            teamMemberId: recipientData?.teamMemberId ? recipientData.teamMemberId : null,
            firstName: ifUndefinedSetEmptyString(recipientData?.firstName),
            lastName: ifUndefinedSetEmptyString(recipientData?.lastName),
            country: recipientData?.phone?.country ? recipientData.phone.country : '+48',
            phone: ifUndefinedSetEmptyString(recipientData?.phone?.phone),
        });

    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: (values) => {
            handleSaveData(values);
        },
    });

    useEffect(() => {
        if (recipientData) {
            setInitialValues({
                isNewTeamMember: isNullOrUndefined(recipientData.teamMemberId) || false,
                teamMemberId: recipientData.teamMemberId ? recipientData.teamMemberId : null,
                firstName: ifUndefinedSetEmptyString(recipientData?.firstName),
                lastName: ifUndefinedSetEmptyString(recipientData?.lastName),
                country: recipientData?.phone?.country ? recipientData.phone.country : '+48',
                phone: ifUndefinedSetEmptyString(recipientData?.phone?.phone),
            });
        }
    }, [recipientData]);

    useEffect(() => {
        if (teamMember) {
            setSelectedTeamMember(teamMember);
        }
    }, [teamMember]);

    useEffect(() => {
        if (generalData?.teamId) {
            dispatch(getRecipients(generalData.teamId));
        }

        return () => {
            dispatch(resetRecipients());
        };
    }, [generalData]);

    useEffect(() => {
        if (teamMembers && selectedTeamMember && teamMembers.length && teamMembers?.includes(selectedTeamMember)) {
            formik.setFieldValue('teamMember', selectedTeamMember.id);
        }
    }, [teamMembers, selectedTeamMember]);

    const handleSaveData = (values: RecipientDataForm) => {
        if ((values.firstName && values.phone && values.lastName) || !values.isNewTeamMember) {
            const teamMemberId = !values.isNewTeamMember && selectedTeamMember ? selectedTeamMember?.id : null,
                recipientData: IJobRecipientInput = {
                    teamMemberId: teamMemberId,
                    firstName: !teamMemberId ? values?.firstName : '',
                    lastName: !teamMemberId ? values.lastName : '',
                    phone: !teamMemberId
                        ? {
                              country: values.country || '',
                              phone: values.phone as string,
                          }
                        : {
                              country: '',
                              phone: '',
                          },
                };
            dispatch(validateJobRecipientData(recipientData));
        }
    };

    const getTeamMemberDetails = (teamMember: ITeamMemberOutput): string => {
        const name = `${teamMember?.details?.firstName || ''} ${teamMember?.details?.lastName || ''} `,
            phone = teamMember?.details?.phone ? `(${formatPhoneNumber(teamMember.details.phone)})` : '',
            details = name + phone;

        return details;
    };

    const isValid = () => {
        return (
            (formik.values.isNewTeamMember &&
                formik.values.firstName &&
                formik.values.lastName &&
                formik.values.phone &&
                formik.values.country) ||
            (!formik.values.isNewTeamMember && selectedTeamMember) ||
            (!formik.values.isNewTeamMember && recipientData !== null)
        );
    };

    const prevCountryRef = useRef(formik.values.country);

    useEffect(() => {
        if (formik.values.country !== prevCountryRef.current) {
            formik.setFieldTouched('phone', true, false);
            formik.validateField('phone');
            prevCountryRef.current = formik.values.country;
        }
    }, [formik.values.country]);

    const teamMembersOptions = (): IMultiselectOption[] | [] =>
        teamMembers
            ? teamMembers.map((item: ITeamMemberOutput) => ({
                  value: item.id,
                  label: getTeamMemberDetails(item),
              }))
            : [];

    return (
        <Stack className="sender-data-container">
            <Box className="create-job-recipient-heading-wrapper">
                <Typography variant="subtitle1" color="text.secondary">
                    <Translation text="jobs.createJob.recipientData.description" />
                </Typography>
            </Box>
            <Grid container>
                <Grid item xs={12} sm={8} md={8} lg={5} className="create-job-recipient-data-wrapper">
                    <FormikForm
                        fields={recipientDataFormConfig(formik.values.isNewTeamMember, teamMembersOptions())}
                        formId="job-recipient-data"
                        formClassName="create-job-recipient-data-form"
                        initialValues={initialValues}
                        updatedValues={formik.values}
                        customEventChange={(formControlName, value) => {
                            if (formControlName === 'isNewTeamMember') {
                                formik.setFieldValue('isNewTeamMember', value);
                            } else if (formControlName === 'teamMember') {
                                const selectedTeamMember = teamMembers?.find((item) => item.id === value);
                                if (selectedTeamMember) {
                                    setSelectedTeamMember(selectedTeamMember);
                                }
                            } else {
                                formik.setFieldValue(formControlName, value);
                            }
                        }}
                        theme={theme}
                        onSubmit={(values) => {
                            handleSaveData(values);
                        }}
                    />
                </Grid>
            </Grid>

            <Divider />
            <Grid className={'navigation-buttons-container'}>
                <Button
                    key="prev-button"
                    onClick={() => {
                        dispatch(setCurrentStep(CreateJobWizardSteps.RECEIVER_DATA));
                    }}
                    className="btn-action"
                    startIcon={<ChevronLeftIcon />}>
                    <Translation text="jobs.createJob.generalData.prevButton" />
                </Button>
                <Button
                    key="next-button"
                    disabled={
                        !isValid() ||
                        (formik.values.isNewTeamMember && !isPhoneNumberTypeMobile(formik.values.country, formik.values.phone))
                    }
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={() => formik.handleSubmit()}
                    className="navigation-button next-button"
                    form="job-recipient-data"
                    endIcon={<ChevronRight />}>
                    <Translation text="jobs.createJob.generalData.nextButton" />
                </Button>
            </Grid>
        </Stack>
    );
};

export default CreateJobTeamMemberData;
