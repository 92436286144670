import {Button, Stack, Tab, Tabs, Typography} from '@mui/material';
import React, {useCallback, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router';
import {
    IAccountBasicInfoOutput,
    Loader,
    Translation,
    accountInfoSelector,
    authTokenSelector,
    ChevronLeftIcon,
} from 'palipali-panel-common-web';
import {
    changeActiveTab,
    checkCanChangeTeamPaymentMethod,
    checkCanDeleteTeam,
    getTeam,
    returnToInitialTeamState,
} from 'src/store/reducers/teamViewSlice';
import {activeTabSelector, isTeamLoadingSelector, teamLocationListingSelector, teamSelector} from 'src/store/selectors/teamViewSelectors';
import TeamDetailsTab from './TeamDetailsTab';
import TeamLocationsTab from './TeamLocationsTab';
import TeamMembersTab from './TeamMembersTab';

interface ITeamViewProps {}

const TeamView: React.FC<ITeamViewProps> = () => {
    const activeTab = useSelector(activeTabSelector);
    const handleTabsChange = useCallback((event: React.SyntheticEvent<Element, Event>, value: string): void => {
        dispatch(changeActiveTab(value));
    }, []);
    const {id} = useParams(),
        navigate = useNavigate(),
        token: string = useSelector(authTokenSelector),
        account: IAccountBasicInfoOutput = useSelector(accountInfoSelector),
        teamLocations = useSelector(teamLocationListingSelector),
        isLoading: boolean = useSelector(isTeamLoadingSelector),
        dispatch = useDispatch(),
        team = useSelector(teamSelector);
    const {t} = useTranslation();

    useEffect(() => {
        if (id) {
            dispatch(getTeam(id));
            dispatch(checkCanDeleteTeam(id));
            dispatch(checkCanChangeTeamPaymentMethod(id));
        }
        return () => {
            dispatch(returnToInitialTeamState());
        };
    }, []);
    const renderTabContent = (tab: string): JSX.Element => {
        if (!team) return <></>;
        switch (tab) {
            case 'details':
                return <TeamDetailsTab teamView={team} />;
            case 'members':
                return <TeamMembersTab id={id} token={token} account={account} team={team} />;
            case 'locations':
                return <TeamLocationsTab locations={teamLocations} id={id ? id : ''} />;
            default:
                return <></>;
        }
    };

    return (
        <Stack>
            <div className="btn-back-wrapper">
                <Button onClick={() => navigate('/panel/teams/')} className="btn-action" startIcon={<ChevronLeftIcon />}>
                    <Translation text="jobs.orderDetails.buttons.return" />
                </Button>
            </div>
            <div className="header">
                <div className="title">
                    <Typography variant="h4" component="h2" color="text.primary">
                        {team?.name ? team.name : ''}
                    </Typography>
                </div>
            </div>
            <div className="tabs-wrapper">
                <Tabs
                    indicatorColor="primary"
                    onChange={handleTabsChange}
                    scrollButtons="auto"
                    sx={{px: 3}}
                    textColor="primary"
                    value={activeTab}
                    className="tabs"
                    variant="scrollable">
                    <Tab key="details" label={t('teams.tabs.details')} value="details" />
                    <Tab
                        key="members"
                        label={t('teams.tabs.teamMembers') + `${team?.members.length ? ` (${team?.members.length})` : ''}`}
                        value="members"
                    />
                    <Tab
                        key="locations"
                        label={t('teams.tabs.locations') + `${team?.privateLocationsCount ? ` (${team?.privateLocationsCount})` : ''}`}
                        value="locations"
                    />
                </Tabs>
            </div>
            {renderTabContent(activeTab)}
            <Loader showLoader={isLoading} />
        </Stack>
    );
};

export default TeamView;
