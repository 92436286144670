import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import {FormikFieldConfig, FormikFieldTypes, IRadioOption, ValidationErrorType} from 'palipali-panel-common-web';

const descriptionFormConfig = (options: IRadioOption[] | [], isOtherSelected?: boolean): FormikFieldConfig[] => {
    const {t} = useTranslation();
    const initialFields: FormikFieldConfig[] = [
        {
            name: 'cancelReasonId',
            label: t('jobs.cancelJob.srReasonListTitle'),
            validation: Yup.string().required(`${t(ValidationErrorType.IS_REQUIRED)}`),
            type: FormikFieldTypes.RADIO,
            options: options,
        },
    ];

    if (isOtherSelected) {
        initialFields.push({
            name: 'description',
            label: t('jobs.cancelJob.describeCancelReason'),
            validation: Yup.string().required(`${t(ValidationErrorType.IS_REQUIRED)}`),
            type: FormikFieldTypes.TEXT,
            rows: 6,
            characterLimit: 500,
        });
    }
    return initialFields;
};
export default descriptionFormConfig;
