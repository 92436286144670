import {Button, useTheme} from '@mui/material';
import {
    IChangeUserPasswordInput,
    FormikForm,
    Translation,
    accountIsLoadingSelector,
    changePassword,
    HorizontalCard,
} from 'palipali-panel-common-web';
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import changePasswordFormConfig from './changePasswordFormConfig';

const ChangePasswordCard: React.FC = () => {
    const theme = useTheme(),
        isLoading: boolean = useSelector(accountIsLoadingSelector),
        [isSubmitAllowed, setIsSubmitAllowed] = useState<boolean>(false),
        dispatch = useDispatch();

    const initialValues = {
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: '',
    };

    return (
        <HorizontalCard
            heading={<Translation text="account.changePassword.title" />}
            content={
                <FormikForm
                    fields={changePasswordFormConfig()}
                    formId="change-password"
                    initialValues={initialValues}
                    theme={theme}
                    submitAllowed={(submitAllowed) => {
                        setIsSubmitAllowed(submitAllowed);
                    }}
                    onSubmit={(values) => {
                        const changePasswordPayload: IChangeUserPasswordInput = {
                            oldPassword: values.oldPassword,
                            newPassword: values.newPassword,
                        };
                        dispatch(changePassword(changePasswordPayload));
                    }}
                />
            }
            actions={
                <Button
                    type="submit"
                    variant="text"
                    form="change-password"
                    size="medium"
                    className="btn-action account-change-password-submit-button"
                    disabled={!isSubmitAllowed}>
                    <Translation text="buttons.update" />
                </Button>
            }
            showLoader={isLoading}
        />
    );
};

export default ChangePasswordCard;
