import React from 'react';
import {IPackageSizesOutput} from 'palipali-panel-common-web';
import BoxSizeIcon from './BoxSizeIcon';
import BoxSizeDimensionDetails from './BoxSizeDimensionDetails';
import {Box} from '@mui/material';

interface IBoxSizeItemProps {
    type: keyof IPackageSizesOutput;
    selected?: boolean;
    handleItemClick?: (value: keyof IPackageSizesOutput) => void;
}
const BoxSizeItem: React.FC<IBoxSizeItemProps> = ({type, handleItemClick, selected}) => {
    return (
        <Box
            className={`package-size-container package-size-container-${type}`}
            onClick={() => (handleItemClick ? handleItemClick(type) : null)}>
            <BoxSizeIcon type={type} selected={selected} />
            <BoxSizeDimensionDetails type={type} selected={selected} />
        </Box>
    );
};
export default BoxSizeItem;
