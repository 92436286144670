import {Box, Button, Divider, Typography} from '@mui/material';
import {IDayOpenHoursInput, MemberOperationType, IOpenHoursOutput, Translation, openModal, Edit2} from 'palipali-panel-common-web';
import React from 'react';
import {useDispatch} from 'react-redux';

interface IOpeningHoursProps {
    openingHours: IOpenHoursOutput;
}

const formatAvailability = (availability: IDayOpenHoursInput | null) => {
    if (!availability) {
        return <Translation text="locations.locationOperation.openingHours.closed" />;
    }
    return `${availability.from} - ${availability.to}`;
};

const OpeningHours: React.FC<IOpeningHoursProps> = ({openingHours}) => {
    const dispatch = useDispatch();
    return (
        <Box className="opening-hours-input-wrapper">
            <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '0.5rem'}}>
                <Typography className="title">
                    <Translation text="locations.locationOperation.openingHours.title" />
                </Typography>
                <Button
                    className="btn btn-action locations-location-operation-opening-hours-edit-button"
                    startIcon={<Edit2 size={16} />}
                    onClick={() => dispatch(openModal(MemberOperationType.LOCATIONS_OPENING_HOURS))}>
                    <Translation text={'locations.locationOperation.openingHours.buttons.edit'} />
                </Button>
            </Box>
            <Divider />
            <dl className="opening-hours-view-list">
                {Object.entries(openingHours).map(([day, availability], index) => (
                    <Box key={index}>
                        <Box className="opening-hours-list-row">
                            <Typography component="dt" variant="inherit" color="text.primary" className="opening-hours-list-label">
                                <Translation text={`locations.locationOperation.openingHours.weekdays.${day}`} />
                            </Typography>
                            <Typography component="dd" variant="inherit" className="opening-hours-list-value">
                                {formatAvailability(availability)}
                            </Typography>
                        </Box>
                    </Box>
                ))}
            </dl>
        </Box>
    );
};

export default OpeningHours;
