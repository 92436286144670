import {FormikFieldConfig, FormikFieldTypes, IMultiselectOption, ValidationErrorType} from 'palipali-panel-common-web';
import * as Yup from 'yup';
import {useTranslation} from 'react-i18next';

const locationFiltersFormConfig = (teamList: IMultiselectOption[]): FormikFieldConfig[] => {
    const {t} = useTranslation();

    return [
        {
            name: 'team',
            label: t('locations.filters.team'),
            validation: Yup.string().required(`${t(ValidationErrorType.IS_REQUIRED)}`),
            type: FormikFieldTypes.SELECT,
            options: teamList,
            className: 'select',
        },
    ];
};
export default locationFiltersFormConfig;
