import React from 'react';
import {Box} from '@mui/material';

interface ITabPanelProps {
    index: number;
    value: number;
    children?: React.ReactNode;
}

const CustomTabPanel: React.FC<ITabPanelProps> = ({index, value, children}) => {
    return (
        <div
            className="tabs-panel"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}>
            {value === index && <Box>{children}</Box>}
        </div>
    );
};

export default CustomTabPanel;
