import React from 'react';
import {Route, Routes} from 'react-router-dom';
import TeamList from './TeamList';
import TeamView from './TeamView';
import TeamCreate from './TeamCreate';
import LocationCreateEdit, {LocationOperationType} from '../Locations/LocationCreateEdit';

const Teams: React.FC = () => {
    return (
        <div className="content-view">
            <Routes>
                <Route path="create" element={<TeamCreate />} key="team-create" />
                <Route
                    path="location/create"
                    element={<LocationCreateEdit operationType={LocationOperationType.CREATE_TEAM_LOCATION} />}
                    key="location-create"
                />

                <Route path=":id" element={<TeamView />} key="team-view" />
                <Route path="/edit/:id" element={<TeamView />} key="edit-view" />
                <Route path="/" element={<TeamList />} key="team-list" />
            </Routes>
        </div>
    );
};

export default Teams;
