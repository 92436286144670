import {
    AlertType,
    IModelApiResponseViewObject,
    IPurchaserJobListingOutput,
    IRawRestQueryParams,
    ListSuccessActionsFunction,
    addAlert,
    createFetchListEpic,
    flattenObj,
    getHistoricalJobsListAPI,
    getJobsListAPI,
    handleApiError,
} from 'palipali-panel-common-web';
import {combineEpics} from 'redux-observable';
import {
    setError,
    setLoading,
    getActiveJobList,
    getHistoricalJobList,
    setHistoricalJobsList,
    setActiveJobsList,
    setHistoricalJobsMetadata,
    setActiveJobsMetadata,
    changeActiveJobsListingPagination,
    changeHistoricalJobsListingPagination,
    changeActiveJobsFilters,
    changeHistoricalJobsFilters,
} from '../reducers/jobListingSlice';
import {RootState} from '../reducers/index';
import {
    jobActiveListingFiltersSelector,
    jobActiveListingPaginationSelector,
    jobHistoricalListingFiltersSelector,
    jobHistoricalListingPaginationSelector,
} from '../selectors/jobListingSelector';

const jobHistoricalListingSuccessActions: ListSuccessActionsFunction<IPurchaserJobListingOutput> = (
    jobsArray: IPurchaserJobListingOutput[],
    metadata: IModelApiResponseViewObject | null
) => {
    return [setHistoricalJobsList(jobsArray), setHistoricalJobsMetadata({metadata: metadata}), setLoading(false)];
};

const jobActiveListingSuccessActions: ListSuccessActionsFunction<IPurchaserJobListingOutput> = (
    jobsArray: IPurchaserJobListingOutput[],
    metadata: IModelApiResponseViewObject | null
) => {
    return [setActiveJobsList(jobsArray), setActiveJobsMetadata({metadata: metadata}), setLoading(false)];
};

const errorActions = (error: any): any[] => {
    const errorObj = handleApiError(error);
    errorObj.type = AlertType.WARNING;
    return [setLoading(false), setError(errorObj.message), addAlert(errorObj)];
};

const getActiveJobsParameters = (state: RootState): IRawRestQueryParams | [] => {
    const queryParameters = jobActiveListingFiltersSelector(state),
        pagination = jobActiveListingPaginationSelector(state);
    const params = {
        ...pagination,
        ...queryParameters,
    };
    if (params) {
        const parametersFlattened = flattenObj(params);
        return parametersFlattened;
    }
    return [];
};
const getHistoricalJobsParameters = (state: RootState): IRawRestQueryParams | [] => {
    const queryParameters = jobHistoricalListingFiltersSelector(state),
        pagination = jobHistoricalListingPaginationSelector(state);
    const params = {
        ...pagination,
        ...queryParameters,
    };
    if (params) {
        const parametersFlattened = flattenObj(params);
        return parametersFlattened;
    }
    return [];
};

const getHistoricalJobListEpic = createFetchListEpic<IPurchaserJobListingOutput>(
    getHistoricalJobsListAPI,
    jobHistoricalListingSuccessActions,
    errorActions,
    getHistoricalJobList().type,
    (state: RootState) => getHistoricalJobsParameters(state)
);

const changeHistoricalJobsPaginationEpic = createFetchListEpic<IPurchaserJobListingOutput>(
    getHistoricalJobsListAPI,
    jobHistoricalListingSuccessActions,
    errorActions,
    changeHistoricalJobsListingPagination().type,
    (state: RootState) => getHistoricalJobsParameters(state)
);

const changeHistoricalJobsFiltersEpic = createFetchListEpic<IPurchaserJobListingOutput>(
    getHistoricalJobsListAPI,
    jobHistoricalListingSuccessActions,
    errorActions,
    changeHistoricalJobsFilters().type,
    (state: RootState) => getHistoricalJobsParameters(state)
);

const getActiveJobListEpic = createFetchListEpic<IPurchaserJobListingOutput>(
    getJobsListAPI,
    jobActiveListingSuccessActions,
    errorActions,
    getActiveJobList().type,
    (state: RootState) => getActiveJobsParameters(state)
);
const changeActiveJobsPaginationEpic = createFetchListEpic<IPurchaserJobListingOutput>(
    getJobsListAPI,
    jobActiveListingSuccessActions,
    errorActions,
    changeActiveJobsListingPagination().type,
    (state: RootState) => getActiveJobsParameters(state)
);
const changeActiveJobsFiltersEpic = createFetchListEpic<IPurchaserJobListingOutput>(
    getJobsListAPI,
    jobActiveListingSuccessActions,
    errorActions,
    changeActiveJobsFilters().type,
    (state: RootState) => getActiveJobsParameters(state)
);

const jobListingEpic = combineEpics(
    getHistoricalJobListEpic,
    getActiveJobListEpic,
    changeHistoricalJobsPaginationEpic,
    changeActiveJobsPaginationEpic,
    changeActiveJobsFiltersEpic,
    changeHistoricalJobsFiltersEpic
);

export default jobListingEpic;
