const pl = {
    app: {
        title: 'Panel Zlecającego',
        validation: {
            phone_taken: 'Podany numer telefonu jest już zarejestrowany w systemie.',
            user_does_not_exists: 'Użytkownik z podaną nazwą nie istnieje.',
            user_already_confirmed: 'Użytkownik z podaną nazwą już jest potwierdzony.',
            object_with_given_id_does_not_exists: 'Obiekt z podanym id nie istnieje.',
            user_is_not_owner_of_object: 'Użytkownik nie jest właścicielem tego obiektu.',
            object_must_implement_ownable_interface: 'Obiekt musi implementować OwnableInterface, aby móc przejść walidację.',
            value_not_part_of_enum: 'Podana wartość nie jest prawidłową wartością',
            location_not_supported:
                'Realizacja zlecenia dla wskazanych punktów nie jest możliwa, ponieważ żaden z punktów nie znajduje się w obszarze działania aplikacji.',
            invalid_domain: 'Adres url zawiera nieprawidłową domenę.',
            invalid_vehicle: 'Brak autoryzacji do użycia tego pojazdu.',
            vehicle_occupied: 'Pojazd jest zajęty przez innego użytkownika.',
            incompatible_package_sizes: 'Wybrane rozmiary przesyłek nie są akceptowalne dla tego pojazdu.',
            not_full_team_member: 'Użytkownik nie jest pełnoprawnym członkiem zespołu.',
            not_team_member: 'Użytkownik nie jest członkiem zespołu.',
            insufficient_team_role: 'Użytkownik nie posiada odpowiednich uprawnień w zespole lub nie jest jego członkiem.',
            missing_phone_in_from_location: 'Numer telefonu punktu nadania jest wymagany.',
            not_fleet_courier: 'Kurier flotowy nie przynależy do partnera flotowego.',
            location_type_not_accessible: 'Ten typ lokacji nie jest dozwolony.',
            invalid_city_limits: 'Podane granice miasta mają nieprawidłowy format.',
            enum_value_not_not_supported: 'Podana wartość enuma %value% nie jest wspierana.',
            greater_than_now: 'Data powinna być w przyszłości.',
            invalid_nip_length: 'NIP musi posiadać dokładnie 10 cyfr.',
            invalid_nip_number: 'Podany NIP jest nieprawidłowy.',
            locale_not_supported: 'Podana lokalizacja nie jest wspierana.',
            no_package_size_selected: 'Musisz wybrać co najmniej jedną kategorię.',
            locations_have_the_same_localisation: 'Wybrane punkty odbioru i dostawy mają taką samą lokalizację.',
        },
        violation: {
            processing_time_exceeds_limit: 'Zlecenie zaplanowane w zbyt odgległym terminie.',
        },
        exception: {
            authentication_required: 'Aby dokonać płatności wymagana jest autoryzacja.',
            approve_with_id: 'Nie udało się autoryzować płatności.',
            call_issuer: 'Z nieznanego powodu nie udało się autoryzować płatności.',
            card_not_supported: 'Karta nie obsługuje płatności.',
            card_velocity_exceeded: 'Przekroczono limit płatności.',
            currency_not_supported: 'Waluta nie jest obsługiwana.',
            do_not_honor: 'Nie udało się autoryzować płatności.',
            do_not_try_again: 'Nie udało się autoryzować płatności.',
            duplicate_transaction: 'Płatność została już zrealizowana.',
            expired_card: 'Karta płatnicza wygasła.',
            fraudulent: 'Płatność została odrzucona.',
            generic_decline: 'Nie udało się autoryzować płatności.',
            incorrect_number: 'Podano nieprawidłowy numer karty płatniczej.',
            incorrect_pin: 'Podano nieprawidłowy numer PIN.',
            incorrect_zip: 'Podano nieprawidłowy kod pocztowy.',
            insufficient_funds: 'Niewystarczająca ilość środków na koncie lub przekroczony limit płatności kartą.',
            invalid_account: 'Podano nieprawidłowy numer konta.',
            invalid_amount: 'Podano nieprawidłową kwotę.',
            invalid_cvc: 'Podano nieprawidłowy numer CVC.',
            invalid_expiry_year: 'Podano nieprawidłowy rok ważności karty płatniczej.',
            invalid_number: 'Podano nieprawidłowy numer karty płatniczej.',
            invalid_pin: 'Podano nieprawidłowy numer PIN.',
            issuer_not_available: 'Nie udało się autoryzować płatności.',
            lost_card: 'Karta płatnicza została zgubiona.',
            merchant_blacklist: 'Nie udało się autoryzować płatności.',
            new_account_information_available: 'Nie udało się autoryzować płatności.',
            no_action_taken: 'Nie udało się autoryzować płatności.',
            not_permitted: 'Nie udało się autoryzować płatności.',
            offline_pin_required: 'Nie udało się autoryzować płatności.',
            online_or_offline_pin_required: 'Nie udało się autoryzować płatności.',
            pickup_card: 'Nie udało się autoryzować płatności.',
            pin_try_exceeded: 'Nie udało się autoryzować płatności.',
            processing_error: 'Nie udało się autoryzować płatności.',
            reenter_transaction: 'Nie udało się autoryzować płatności.',
            restricted_card: 'Nie udało się autoryzować płatności.',
            revocation_of_all_authorizations: 'Nie udało się autoryzować płatności.',
            revocation_of_authorization: 'Nie udało się autoryzować płatności.',
            security_violation: 'Nie udało się autoryzować płatności.',
            service_not_allowed: 'Nie udało się autoryzować płatności.',
            stolen_card: 'Nie udało się autoryzować płatności.',
            stop_payment_order: 'Nie udało się autoryzować płatności.',
            testmode_decline: 'Użyto testowej karty płatniczej. W tym trybie jest to niedozwolone.',
            transaction_not_allowed: 'Nie udało się autoryzować płatności.',
            try_again_later: 'Nie udało się autoryzować płatności.',
            withdrawal_count_limit_exceeded: 'Nie udało się autoryzować płatności.',
            payment_intent_authentication_failure: 'Nie udało się autoryzować płatności.',
            setup_intent_authentication_failure: 'Nie udało się autoryzować dodania karty.',

            general_error: 'Wystąpił błąd podczas płatności.',
            insufficient_funds_error: 'Niewystarczająca ilość środków na koncie lub przekroczony limit płatności kartą.',
            card_expired_error: 'Ważność karty płatniczej wygasła.',
            object_with_given_id_does_not_exists: 'Obiekt z podanym id nie istnieje.',
            user_is_not_owner_of_object: 'Użytkownik nie jest właścicielem tego obiektu.',
            object_must_implement_ownable_interface: 'Obiekt musi implementować OwnableInterface, aby móc przejść walidację.',
            value_not_part_of_enum: 'Podana wartość nie jest prawidłową wartością',
            location_not_supported:
                'Realizacja zlecenia dla wskazanych punktów nie jest możliwa, ponieważ żaden z punktów nie znajduje się w obszarze działania aplikacji.',
            invalid_domain: 'Adres url zawiera nieprawidłową domenę.',
            invalid_vehicle: 'Brak autoryzacji do użycia tego pojazdu.',
            vehicle_occupied: 'Pojazd jest zajęty przez innego użytkownika.',
            incompatible_package_sizes: 'Wybrane rozmiary przesyłek nie są akceptowalne dla tego pojazdu.',
            not_full_team_member: 'Użytkownik nie jest pełnoprawnym członkiem zespołu.',
            not_team_member: 'Użytkownik nie jest członkiem zespołu.',
            insufficient_team_role: 'Użytkownik nie posiada odpowiednich uprawnień w zespole lub nie jest jego członkiem.',
            missing_phone_in_from_location: 'Numer telefonu punktu nadania jest wymagany.',
            not_fleet_courier: 'Kurier flotowy nie przynależy do partnera flotowego.',
            location_type_not_accessible: 'Ten typ lokacji nie jest dozwolony.',
            invalid_city_limits: 'Podane granice miasta mają nieprawidłowy format.',
            user_already_exists: 'Wskazany adres e-mail jest już zarejestrowany, podaj inny adres e-mail.',
            file_not_provided: 'Nie załączono pliku.',
            authentication_token_could_not_be_resolved: 'Token autoryzacyjny nie może zostać obsłużony.',
            concurrent_conflict: 'Operacja nie może zostać zrealizowana z powodu konfliktu.',
            cant_change_role_to_owner: 'Nie możesz zmienić roli użytkownika, który jest właścicielem.',
            invalid_role_invited: 'Możesz zaprosić tylko członków z rolą niższą o twojej.',
            invalid_team_member_invitation: 'To zaproszenie jest nieważne.',
            cant_invite_deleted_user: 'Zaproszony użytkownik został usunięty i nie może zostawać więcej zaproszony.',
            invalid_date_format: 'Błędny format daty.',
            invalid_invitation: 'Błędne zaproszenie.',
            invalid_invitation_action: 'Niedozwolona akcja na zaproszeniu.',
            invalid_recipient_data: 'Dane odbiorcy zlecenia muszą zawierać id członka zespołu lub imię, nazwisko oraz numer telefonu.',
            invalid_job_location_data: 'Dane lokacji zlecenia muszą zawierać id lokacji lub dane lokacji w tym adres oraz lokalizację.',
            location_not_allowed: 'Wskazana lokacja nie jest publiczna lub nie należy do wybranego zespołu.',
            courier_job_proposal_rejected: 'Nie można zaakceptować propozycji zlecenia, która została odrzucona.',
            courier_job_proposal_accepted: 'Nie można odrzucić propozycji zlecenia, która została zaakceptowana.',
            courier_job_proposal_expired: 'To zlecenie nie jest już dostępne.',
            invalid_job_problem_type: 'Niewłaściwy typ problemu zlecenia.',
            invalid_uuid_provided: 'Podany id jest nieprawidłowy.',
            invalid_vehicle_provided: 'Wskazane pojazdy nie istnieją lub brak uprawnień do użycia ich w zleceniu.',
            invoicing_service_exception: 'Nieudane pobieranie faktury.',
            invalid_phone_number: 'Podano nieprawidłowy numer telefonu.',
            cant_download_invoice: 'Nieudane pobieranie faktury.',
            account_not_confirmed: 'Konto nie zostało potwierdzone.',
            account_not_active: 'Konto nie jest aktywne.',
            account_deleted: 'Konto zostało usunięte.',
            account_is_invitation: 'Nieprawidłowe dane.',
            user_not_found: 'Wprowadzony użytkownik nie istnieje.',
            payment_method_cannot_be_captured: 'Nie można obecnie użyć wybranej metody płatności. Sprawdź czy masz wystarczające środki.',
            not_allowed_to_login: 'Nie możesz się zalogować do tej aplikacji za pomocą tego konta.',
            invalid_identifier: 'Podany identyfikator jest nieprawidłowy.',
            vehicle_in_use: 'Pojazd jest obecnie używany.',
        },
        timeComponent: {
            estimatedTime: ' (przewidywany czas)',
        },
    },
    logoutModal: {
        title: 'Wyloguj się',
        content: 'Czy na pewno chcesz się wylogować?',
        logout: 'Wyloguj się',
    },
    auth: {
        header: {
            purchaser: 'Zlecający',
        },
        authFooter: {
            srTitle: 'Stopka strony zawierająca dane kontaktowe, nawigację po stronie i zapisy do newslettera',
            addressColumn: {
                title: 'PaliPali',
            },
            contactColumn: {
                title: 'Kontakt',
                correspondenceAddressTitle: 'Adres korespondencyjny:',
            },
            linksColumn: {
                title: 'PaliPali',
                aboutUs: 'O nas',
                career: 'Kariera',
                cooperation: 'Współpraca',
                contact: 'Kontakt',
                policies: 'Regulamin i polityka prywatności',
                safePayments: 'Bezpieczne płatności',
            },
            newsletterColumn: {
                title: 'Bądź na bieżąco',
                subscribeInfo: 'Subskrybuj nasz newsletter',
                newsletterLabelSR: 'Wpisz swój adres e-mail aby otrzymac newsletter',
                newsletterSubmitButtonSR: 'Przycisk zatwierdzania formularza subskrypcji Newslettera',
            },
            copyright: 'PaliPali {{date}} © Wszystkie prawa zastrzeżone.',
            socialIcons: {
                facebook: 'Link prowadzący do profilu na Facebooku',
                instagram: 'Link prowadzący do profilu na Instagramie',
                linkedin: 'Link prowadzący do profilu na Linkedin',
                youtube: 'Link prowadzący do kanalu Youtube',
            },
        },
        formControls: {
            email: 'E-mail',
            password: 'Hasło',
            newPassword: 'Nowe hasło',
            confirmPassword: 'Powtórz hasło',
            repeatPassword: 'Powtórz hasło',
            placeholderConfirmPassword: 'Powtórz hasło',
            phone: 'Numer telefonu',
            city: 'Miasto',
            firstName: 'Imię',
            lastName: 'Nazwisko',
            dialingCode: 'Prefiks',
            privacyPolicy: 'Zapoznałem się z <0>Regulaminem</0> oraz <1>Polityką Prywatności</1> aplikacji PaliPali.',
            placeholders: {
                email: 'E-mail',
                password: 'Hasło',
                newPassword: 'Nowe hasło',
                repeatPassword: 'Powtórz hasło',
                confirmPassword: 'Powtórz hasło',
                city: 'Wprowadź miasto',
                firstName: 'Imię',
                lastName: 'Nazwisko',
                phone: 'Numer telefonu',
                phoneCode: 'Kod',
            },
        },
        login: {
            title: 'Logowanie',
            haveAccount: 'Nowy w PaliPali? ',
            register: 'Zarejestruj się.',
            resetPassword: 'Zapomniałeś hasło?',
            loginButton: 'Zaloguj się',
            availableCities: 'Dostępne miasta',
            availableCitiesDescription: 'Dowiedz się, w jakich miastach możesz realizować przejazdy z wykorzystaniem aplikacji PaliPali.',
            availableCitiesLink: 'Sprawdź <0>palipali.pl/miasta</0> i bądź na bieżąco.',
        },
        register: {
            title: 'Rejestracja',
            createAccount: 'Załóż konto Zlecającego',
            accountCreated: 'Posiadasz już konto? ',
            login: 'Zaloguj się.',
            registerButton: 'Zarejestruj się',
        },
        confirmRegistration: {
            title: 'Potwierdź rejestrację',
            subtitle: 'Aby zakończyć rejestrację na platformie kliknij przycisk poniżej',
            confirmButton: 'Potwierdź',
        },
        resetPassword: {
            title: 'Resetowanie hasła',
            subtitle: 'Wprowadź adres e-mail. Wyślemy na niego link do zmiany hasła.',
            sendEmailButton: 'Wyślij link do zmiany hasła',
            backToLoginButton: 'Logowanie',
        },
        newPassword: {
            title: 'Resetowanie hasła',
            subtitle: 'Wprowadź nowe hasło.',
            setPasswordButton: 'Ustaw nowe hasło',
        },
        confirmEmailChange: {
            title: 'Potwierdź zmianę adresu e-mail',
            subtitle: 'Żeby dokonać zmiany adresu e-mail kliknij na przycisk niżej',
            confirmButton: 'Potwierdź',
        },
        alert: {
            invalidConfirmRegistrationURL: 'Nieprawidłowy link potwierdzający rejestrację.',
            loginSuccess: 'Logowanie udane.',
            loginFailed: 'Nieprawidłowy login lub hasło. Spróbuj ponownie.',
            logout: 'Zostałeś wylogowany.',
            registrationSuccess: 'Pomyślnie zarejestrowano. Sprawdź swój e-mail w celu potwierdzenia rejestracji.',
            confirmRegistrationSuccess: 'Twój e-mail został potwierdzony.',
            sendResetPasswordMailSuccess: 'Sprawdź swój e-mail w celu uzyskania linku do resetowania hasła.',
            confirmResetPasswordSuccess: 'Twoje hasło zostało zmienione. Możesz się zalogować do panelu.',
            authenticationError: 'Musisz być zalogowany, aby uzyskać dostęp do danych.',
            confirmTeamMemberInvitationSuccess: 'Zaproszenie do zespołu zostało potwierdzone, zaloguj się do aplikacji.',
            confirmTeamMemberNotRegisteredInvitationSuccess:
                'Zaproszenie do zespołu potwierdzone, zarejestruj się by móc korzystać z aplikacji.',
            rejectTeamMemberInvitationSuccess: 'Zaproszenie do zespołu zostało odrzucone.',
        },
    },
    activeJobs: {
        noTeamsInfo: {
            subtitle: 'Nieaktywne konto',
            title: 'Dodaj zespół oraz określ metodę płatności',
            description: 'Dodaj zespół, a następnie przypisz do niego kartę płatniczą niezbędną do realizacji zleceń.',
            button: 'Dodaj zespół',
        },
        title: 'Aktywne zlecenia',
    },
    historicalJobs: {
        title: 'Historia zleceń',
    },
    jobs: {
        noJobs: 'Brak zleceń',
        alert: {
            opinionSent: 'Opinia została wysłana.',
            reportSent: 'Problem został zgłoszony.',
            jobDeleted: 'Zlecenie zostało usunięte.',
            jobCancelled: 'Zlecenie zostało anulowane.',
            jobCreated: 'Zlecenie zostało utworzone.',
            jobUpdated: 'Zlecenie zostało zaktualizowane.',
            jobNotFound: 'Zlecenie nie istnieje.',
            jobNotActive: 'To zlecenie nie jest już dostępne. Sprawdź w historii zleceń co jest tego powodem.',
            orderUnavailable: 'To zlecenie nie może być już anulowane.',
        },
        formControls: {
            newOrder: 'Nowe zlecenie',
            team: 'Zespół',
            noActiveTeams: 'Brak aktywnych zespołów',
            allTeams: 'Wszystkie',
            status: {
                all: 'Wszystkie',
                delivered: 'Zrealizowane',
                cancelled_p: 'Anulowane',
                cancelled_c: 'Anulowane przez Kuriera',
                problem_c: 'Zgłoszone przez Kuriera',
                problem_p: 'Zgłoszone',
                planned: 'Zaplanowane',
                searching: 'Szukanie',
                pickup: 'Podjęcie przesyłki',
                pickup_drive: 'Dojeżdża',
                delivery: 'W trakcie',
            },
            date: 'Okres',
        },
        table: {
            packageSize: 'Rozmiar',
            address: 'Miejsce lokalizacji',
            date: 'Data',
            time: 'Czas realizacji',
            status: 'Status',
            price: 'Koszt',
            index: 'Lp.',
            info: 'Informacje',
            category: 'Kategoria zlecenia',
        },
        jobView: {
            price: 'Koszt',
            courier: 'Kurier',
            details: 'Szczegóły',
            report: 'Zgłoś problem',
            payment: 'Płatność',
            invoice: 'Pobierz fakturę',
        },
        report: {
            title: 'Powód zglószenia problemu',
            wrongDetails: 'Żle określone szczegóły zlecenia',
            incompletePackage: 'Przesyłka jest niekompletna',
            noOnsiteOrdering: 'Brak zamówienia na miejscu',
            noCourierIdentification: 'Brak możliwości potwierdzenia tożsamości kuriera',
            shopIsClosed: 'Miejsce nadania jest zamknięte',
            other: 'Inne',
            description: 'Opisz problematyczną sytuację',
            describeSituation: 'Opisz sytuację',
            add: 'Dodaj',
            descriptionPhoto: 'Zdjęcie obrazujące problem',
            buttons: {
                reportProblem: 'Zgłoś problem',
            },
        },
        cancelJob: {
            title: 'Powód anulowania zlecenia',
            cancelTitle: 'Anuluj zlecenie',
            subtitle: `Czy na pewno chcesz anulować aktywne zlecenie <0>#{{ jobNumber }}</0>?`,
            describeCancelReason: 'Opisz powód anulowania zlecenia',
            costInformation: `Rezygnując ze zlecenia zostaniesz obciążony opłatą w wysokości <0>{{ price }} brutto</0>.`,
            srReasonListTitle: 'Lista powodów anulowania zlecenia',
            buttons: {
                confirmCancel: 'Tak, chcę anulować zlecenie',
                cancel: 'Anuluj zlecenie',
            },
        },
        deleteJob: {
            title: 'Usuń zlecenie',
            subtitle: 'Czy na pewno chcesz usunąć zaplanowane zlecenie?',
            warning: 'Ta operacja jest nieodwracalna.',
            buttons: {
                deleteJobConfirm: 'Tak, chcę usunąć zlecenie',
            },
        },
        courierDetails: {
            registrationNumber: 'Numer tablicy rejestracyjnej',
            carModel: 'Pojazd',
            color: 'Kolor',
            phone: 'Numer telefonu',
            buttons: {
                rate: 'Oceń',
            },
        },
        orderDetails: {
            cost: 'Koszt',
            team: 'Zespół',
            customer: 'Zlecający',
            receiver: 'Odbiorca',
            insuranceCost: 'Kwota ubiezpieczenia',
            innerOrderNumber: 'Numer wewnętrzny zamówienia',
            otherInformation: 'Informacje dodatkowe',
            additionalInfo: 'Dodatkowe informacje',
            deliveryPin: 'Kod odbioru',
            sendingPoint: 'Punkt nadania',
            courier: 'Kurier',
            reportProblem: 'Zgłoś problem',
            edit: 'Edytuj',
            processAt: 'Zlecenie zostanie opublikowane w dniu:',
            noInfo: 'Brak informacji o publikacji zlecenia',
            estimatedTime: ' (przewidywany czas)',
            courierArriving: 'Kurier niebawem będzie na miejscu.',
            refresh: 'Odśwież',
            timer: {
                hours: 'godz',
                minutes: 'min',
            },
            buttons: {
                srDelete: 'Przycisk usuwania zlecenia',
                return: 'Powrót',
                downloadInvoice: 'Uzyskaj fakturę',
                renewOrder: 'Ponów zlecenie',
            },
            alerts: {
                failedJob: 'To zlecenie nie jest już dostępne. Sprawdź w historii zleceń co jest tego powodem.',
            },
        },
        createJob: {
            title: 'Nowe zlecenie',
            wizardSteps: {
                general: 'Informacje o zleceniu',
                sender: 'Miejsce nadania',
                receiver: 'Miejsce docelowe',
                team: 'Odbiorca',
                additionalData: 'Informacje dodatkowe',
                summary: 'Podsumowanie zlecenia',
            },
            generalData: {
                formControls: {
                    team: 'Zespół',
                    insurancePrice: 'Kwota',
                    insuranceCurrency: 'zł',
                    date: 'Data i godzina',
                    max: 'max.',
                },
                orderCategory: 'Kategoria zlecenia',
                orderCategoryUnknown: 'Nie wiem jaką kategorię wybrać',
                insurance: 'Ubezpieczenie',
                orderInsured: 'Chcę ubezpieczyć zawartość zlecenia',
                completionPeriod: 'Termin realizacji',
                completionPeriodInfo:
                    'Zlecenie zostanie udostępnione do realizacji o wskazanej dacie i godzinie. Nie jest to termin dostarczenia.',
                immediateCompletion: 'Natychmiast',
                prevButton: 'Wstecz',
                nextButton: 'Dalej',
                boxDimensionsModal: {
                    title: 'Podaj wymiary',
                    formControls: {
                        length: 'Długość',
                        height: 'Wysokość',
                        width: 'Szerokość',
                        weight: 'Waga',
                        cm: 'cm',
                        kg: 'kg',
                    },
                    suggestedCategory: 'Zalecana kategoria zlecenia',
                    checkDimensions: 'Aby potwierdzić kategorię zlecenia, wprowadź wartości opisujące przesyłkę.',
                    notSuitableDimensions:
                        'Twoja przesyłka jest zbyt duża. Maksymalne wymiary przesyłki to {{a}} x {{b}} x {{c}} cm przy wadze {{weight}} kg.',
                    accept: 'Zatwierdź',
                },
                jobCompletionModal: {
                    title: 'Data i godzina realizacji',
                    dateSelect: 'Data',
                    timeSelect: 'Godzina',
                },
            },
            senderData: {
                senderPlace: 'Miejsce nadania',
                description: 'Wskaż miejsce, z którego wyruszy zlecenie np. hurtownia, magazyn.',
                saveAddress: 'Zapisz adres',
                noAutocompleteResults: 'Adres nie został znaleziony',
                formControls: {
                    address: 'Adres',
                    companyName: 'Nazwa firmy',
                    flatNumber: 'Numer lokalu',
                    phone: 'Numer telefonu nadawcy',
                },
                addressBookModal: {
                    title: 'Książka adresowa',
                    addressBook: 'Książka adresowa',
                    authorizedWarehouses: 'Partnerzy',
                    types: {
                        store: 'Sklep',
                        construction: 'Budowa',
                        warehouse: 'Hurtownia',
                    },
                    searchPlaceholder: 'Wyszukaj lokację',
                    noLocations: 'Nie ma lokalizacji spełniających wymagania wyszukiwania',
                    street: 'ul',
                },
                phoneDetails:
                    'Wprowadź numer telefonu komórkowego osoby będącej w miejscu nadania przesyłki. Na wskazany numer telefonu podczas realizacji zlecenia zostanie przesłany SMS z kodem odbioru.',
            },
            receiverData: {
                receiverPlace: 'Miejsce docelowe',
                description: 'Wskaż miejsce, do którego Kurier ma dostarczyć zlecenie np. plac budowy.',
            },
            recipientData: {
                description: 'Wskaż dane osoby upoważnionej do odebrania zlecenia.',
                formControls: {
                    provideRecipientData: 'Wprowadź dane odbiorcy',
                    selectRecipient: 'Wybierz odbiorcę',
                    firstName: 'Imię',
                    lastName: 'Nazwisko',
                    phone: 'Numer telefonu',
                },
                teamMembersModal: {
                    title: 'Użytkownicy',
                    noTeamMembers: 'Brak dodanych członków zespołu',
                },
            },
            additionalData: {
                formControls: {
                    orderNumber: 'Numer wewnętrzny zamówienia',
                    additionalData: 'Dodatkowe informacje',
                },
                orderNumberShare: 'Wprowadzony numer wewnętrzny zamówienia zostanie udostępniony nadawcy zlecenia oraz kurierowi.',
                summaryButton: 'Przejdź do podsumowania',
            },
            jobSummary: {
                estimatedTime: 'Przewidywany czas dostawy',
                estimatedDeliveryDate: 'Przewidywany termin dostawy',
                packageSize: 'Kategoria zlecenia',
                team: 'Zespół',
                receiver: 'Odbiorca',
                insurancePrice: 'Kwota ubiezpieczenia',
                payment: 'Płatność',
                placeJob: 'Realizuj zlecenie ({{ time }}s)',
                replaceJob: 'Ponów wycenę',
                placedJob: 'Zlecenie zostało utworzone',
                paymentOnHold: 'Płatność wstrzymana',
                totalPrice: 'Brutto:',
                taxPrice: 'VAT 23%:',
                netPrice: 'Netto:',
                noCouriersFound: 'Niestety nie udało się znaleźć kuriera, ponów wycenę aby spróbować ponownie.',
                locationClosedWarningModal: {
                    title: 'Miejsce nadania może być zamknięte',
                    description:
                        'Czas, w jakim kurier dotrze do miejsca nadania, może być późniejszy niż godziny otwarcia miejsca nadania. Jeśli miejsce nadania będzie już zamknięte i kurier z tego powodu nie będzie w stanie odebrać zamówienia, zostaniesz obciążony kosztem dojazdu kuriera do miejsca nadania.',
                },
            },
        },
        editOrder: {
            title: 'Edycja zlecenia',
            fillRecipientData: 'Wprowadź dane odbiorcy',
            recipient: 'Odbiorca',
            orderNumber: 'Numer wewnętrzny zamówienia',
            description: 'Dodatkowe informacje',
            firstName: 'Imię',
            lastName: 'Nazwisko',
            dialingCode: 'Prefiks',
            phone: 'Numer telefonu',
            buttons: {
                update: 'Aktualizuj',
            },
        },
    },
    account: {
        title: 'Konto',
        subtitle: {
            profile: 'Profil',
            settings: 'Ustawienia',
        },
        profile: {
            title: 'Profil',
            formControls: {
                username: 'E-mail',
                phone: 'Numer telefonu',
                dialingCode: 'Kod kraju',
                firstName: 'Imię',
                lastName: 'Nazwisko',
                cityId: 'Miasto',
            },
            buttons: {
                update: 'Aktualizuj',
            },
        },
        status: {
            inactive: 'Nieaktywny',
            active: 'Aktywny',
        },
        settings: {
            title: 'Ustawienia',
            formControls: {
                language: 'Język',
                highContrastMode: 'Tryb wysokiego kontrastu',
            },
            highContrastMode: 'Tryb wysokiego kontrastu',
            highContrastModeSubtitle: 'Wskazany dla osób słabiej widzących',
            status: {
                inactive: 'Nieaktywny',
                active: 'Aktywny',
            },
        },
        changePassword: {
            title: 'Zmiana hasła',
            formControls: {
                oldPassword: 'Aktualne hasło',
                newPassword: 'Nowe hasło',
                confirmNewPassword: 'Powtórz nowe hasło',
            },
            buttons: {
                update: 'Aktualizuj',
            },
        },
        deleteProfile: {
            title: 'Usuń konto',
            subtitle: 'Usuń swoje konto oraz wszystkie dane z nim związane.',
            warning: 'Ta operacja jest nieodwracalna.',
            cannotBeDeleted: 'Nie możesz teraz usunąć konta.',
            buttons: {
                deleteAccount: 'Usuń konto',
            },
            alerts: {
                deleted: 'Konto zostało usunięte.',
            },
        },
        info: {
            name: 'Imię',
            surname: 'Nazwisko',
            email: 'E-mail',
            tel: 'Numer telefonu',
            city: 'Miasto',
            language: 'Język',
            theme: 'Theme',
        },
        locale: {
            en_US: 'english',
            pl_PL: 'polski',
            uk_UA: 'українська',
        },
        themes: {
            dark: 'Wysoki kontrast',
            normal: 'Zwykły',
        },
        modal: {
            title: 'Wybierz avatar',
        },
        alert: {
            imageAddSuccess: 'Dodano zdjęcie.',
            miscChangeSuccess: 'Ustawienia zostały zmienione',
            changeLocaleSuccess: 'Język został zmieniony',
            changePasswordSuccess: 'Hasło zostało zmienione',
            deleteAccountSuccess: 'Konto zostało usunięte',
            updateProfileDataSuccess: 'Dane zostały zaktualizowane',
            changeEmailSuccess: 'Adres e-mail został zmieniony',
        },
    },
    profileEdit: {
        title: 'Profil',
        formControls: {
            username: 'E-mail',
            phone: 'Numer telefonu',
            dialingCode: 'Kod kraju',
            firstName: 'Imię',
            lastName: 'Nazwisko',
            cityId: 'Miasto',
        },
        links: {
            changePassword: 'Zmiana hasła',
            deleteAccount: 'Usuń konto',
        },
        buttons: {
            confirm: 'Aktualizuj',
        },
        settings: {
            title: 'Ustawienia',
            formControls: {
                language: 'Język',
                highContrastMode: 'Tryb wysokiego kontrastu',
            },
        },
        resetPassword: {
            title: 'Zmiana hasła',
            formControls: {
                oldPassword: 'Aktualne hasło',
                newPassword: 'Nowe hasło',
                confirmNewPassword: 'Potwierdż nowe hasło',
            },
            buttons: {
                saveChanges: 'Zapisz zmiany',
            },
        },
    },
    locations: {
        title: 'Lokalizacje',
        filters: {
            team: 'Zespół',
            allTeams: 'Wszystkie',
        },
        locationList: {
            title: 'Lista lokalizacji',
            createLocation: 'Dodaj lokalizację',
            table: {
                headers: {
                    name: 'Nazwa lokalizacji',
                    companyName: 'Nazwa firmy',
                    address: 'Adres',
                    phone: 'Telefon',
                    locationType: 'Typ',
                    actions: 'Akcje',
                },
                buttons: {
                    editLocation: 'Edytuj lokalizację',
                    viewLocation: 'Zobacz lokalizację',
                    deleteLocation: 'Usuń lokalizację',
                },
                noResults: 'Brak lokalizacji',
            },
        },
        locationOperation: {
            title: {
                create: 'Nowa lokalizacja',
                edit: 'Lokalizacja',
                createTeamLocation: 'Nowa lokalizacja dla zespołu',
            },
            formControls: {
                name: {
                    label: 'Nazwa lokalizacji',
                    placeholder: 'Wpisz nazwę lokalizacji',
                },
                locationTypeId: {
                    label: 'Typ',
                    placeholder: 'Wybierz typ lokalizacji',
                },
                companyName: {
                    label: 'Nazwa firmy',
                    placeholder: 'Wpisz nazwę firmy',
                },
                phoneCode: {
                    label: 'Kod',
                    placeholder: 'Kod',
                },
                phone: {
                    label: 'Numer telefonu',
                    placeholder: 'Wpisz numer telefonu',
                },
                address: {
                    label: 'Adres',
                    placeholder: 'Wpisz adres',
                },
                houseNo: {
                    label: 'Numer lokalu',
                    placeholder: 'Wpisz numer lokalu',
                },
            },
            openingHours: {
                title: 'Godziny otwarcia',
                openingHours: 'Otwarcie',
                closingHours: 'Zamknięcie',
                buttons: {
                    edit: 'Edytuj',
                    save: 'Zapisz',
                    accept: 'Zatwierdź',
                },
                closed: 'Zamknięte',
                weekdays: {
                    _0: 'Poniedziałek',
                    _1: 'Wtorek',
                    _2: 'Środa',
                    _3: 'Czwartek',
                    _4: 'Piątek',
                    _5: 'Sobota',
                    _6: 'Niedziela',
                },
            },
            buttons: {
                create: 'Utwórz lokalizację',
                edit: 'Aktualizuj',
                createTeamLocation: 'Utwórz lokalizację',
            },
            deleteLocation: {
                title: 'Usuwanie lokalizacji',
                description: 'Usuń lokalizację. Ta operacja jest nieodwracalna.',
                deleteButton: 'Usuń lokalizację',
                modal: {
                    title: 'Usuń lokalizację',
                    description: 'Czy na pewno chcesz usunąć lokalizację?',
                    reminder: 'Ta operacja jest nieodwracalna.',
                },
            },
            locationCreated: 'Lokalizacja została utworzona',
            teamLocationCreated: 'Lokalizacja została utworzona dla zespołu',
            locationUpdated: 'Lokalizacja została zaktualizowana',
            locationDeleted: 'Wybrana lokalizacja została usunięta',
            phoneDetails:
                'W przypadku wybrania lokalizacji jako miejsca nadania na podany numer telefonu komórkowego zostanie przesłany SMS z kodem odbioru. Upewnij się, że właściciel numeru znajduje się w miejscu nadania przesyłki.',
        },
    },
    teams: {
        title: 'Zespoły',
        createTeam: 'Dodaj zespół',
        newTeam: 'Nowy zespół',
        tabs: {
            teamMembers: 'Użytkownicy',
            details: 'Informacje ogólne',
            locations: 'Lokalizacje',
        },
        teamList: {
            title: 'Lista zespołów',
            table: {
                headers: {
                    name: 'Nazwa',
                    memberCount: 'Ilość członków',
                    actions: 'Akcje',
                    verified: 'Weryfikacja',
                },
                buttons: {
                    users: 'Użytkownicy ({{ count }})',
                    viewTeam: 'Szczegóły',
                    payments: 'Płatności',
                    deleteTeam: 'Usuń',
                    localizations: 'Lokalizacje ({{ count }})',
                },
                noResults: 'Brak zespołów',
                noTeamUsersResults: 'Brak użytkowników',
                states: {
                    unverified: 'Do uzupełnienia',
                },
            },
        },
        teamView: {
            title: 'Szczegóły',
            teamDetails: {
                title: 'Detale',
                subtitle: 'Dane rozliczeniowe',
                labels: {
                    companyName: 'Nazwa firmy',
                    teamName: 'Nazwa zespołu',
                    address: 'Adres',
                    taxId: 'NIP',
                    clearingEmail: 'E-mail rozliczeniowy',
                    paymentMethod: 'Metoda płatności',
                    removeTeam: 'Usuwanie zespołu',
                },
                street: 'ul.',
                paymentDescription: 'Do przetwarzania płatności wykorzystujemy Stripe.',
                removeDesription: 'Usuń zespół. Ta operacja jest nieodwracalna.',
                buttons: {
                    removeTeam: 'Usuń zespół',
                    removePaymentMethod: 'Usuń metodę płatności',
                    addPaymentMethod: 'Dodaj metodę płatności',
                },
            },
            teamMembers: 'Członkowie zespołu',
            loadingTeam: 'Ładowanie zespołu...',
            table: {
                headers: {
                    name: 'Imię i nazwisko',
                    email: 'E-mail',
                    phone: 'Numer telefonu',
                    role: 'Rola',
                    actions: 'Akcje',
                },
            },
            buttons: {
                edit: 'Edytuj',
                update: 'Aktualizuj',
                createTeam: 'Utwórz zespół',
            },
            alerts: {
                paymentMethod: {
                    added: 'Metoda płatności została poprawnie dodana.',
                    processing: 'Metoda płatności jest w trakcie przetwarzania. Strona niedługo się odświeży.',
                    failed: 'Nie udało się dodać metody płatności',
                },
            },
        },
        teamMemberEdit: {
            alerts: {
                update: {
                    success: 'Rola użytkownika została zmieniona.',
                },
                delete: {
                    success: 'Usunięcie użytkownika przebiegło pomyślnie.',
                },
                invite: {
                    success: 'Użytkownik został zaproszony.',
                    invitationAlreadyRejected:
                        'Już zaprosiłeś tego użytkownika, ale odrzucił zaproszenie. Skontaktuj się z administratorem jeśli chcesz go zaprosić jeszcze raz.',
                },
            },
        },
        teamOperation: {
            title: {
                create: 'Dodaj zespół',
                edit: 'Edytuj zespół',
                addUser: 'Dodaj użytkownika',
                editUser: 'Edytuj użytkownika',
                removeUser: 'Usuń użytkownika',
                removeTeam: 'Usuń zespół',
                removePaymentMethod: 'Usuń metodę płatności',
            },
            editUser: {
                firstName: 'Imię',
                lastName: 'Nazwisko',
                email: 'E-mail',
                phone: 'Numer telefonu',
            },
            inviteUser: {
                employee: 'Pracownik',
                employeeDescription: 'Wybierz tę rolę, jeżeli użytkownik powinien móc jedynie odbierać zlecenia.',
                manager: 'Manager',
                managerDescription:
                    'Wybierz tę rolę, jeżeli użytkownik powinien mieć możliwość tworzenia zleceń i zarządzania zespołem pracowników.',
            },
            description: {
                removeUser: `Czy na pewno chcesz usunąć użytkownika z zespołu <0>{{ teamName }}</0>?`,
                removeTeam: 'Czy na pewno chcesz usunąć zespół',
                removePaymentMethod: 'Czy na pewno chcesz usunąć metodę płatności?',
                warning: 'Ta operacja jest nieodwracalna.',
            },
            formControls: {
                name: 'Nazwa zespołu',
                companyName: 'Nazwa firmy',
                taxIdentifier: 'NIP',
                street: 'Ulica',
                houseNumber: 'Numer budynku',
                flatNumber: 'Numer lokalu',
                city: 'Miejscowość',
                zip: 'Kod pocztowy',
                clearingEmail: 'E-mail rozliczeniowy',
            },
            buttons: {
                create: 'Utwórz zespół',
                edit: 'Aktualizuj',
                addUser: 'Dodaj użytkownika',
                addLocation: 'Dodaj lokalizację',
                editUser: 'Edytuj użytkownika',
                removeUser: 'Usuń użytkownika',
                removeTeam: 'Usuń zespół',
                removePaymentMethod: 'Usuń metodę płatności',
            },
            alerts: {
                create: {
                    success: 'Zespół został utworzony.',
                },
                update: {
                    success: 'Zespół został zaktualizowany.',
                },
                delete: {
                    success: 'Zespół został usunięty.',
                },
            },
        },
        removeButton: {
            team: {
                title: 'Usuń zespół',
                message: 'Czy na pewno chcesz usunąć zespół {{name}}?',
                button: 'Usuń zespół',
            },
            member: {
                title: 'Usuń członka zespołu',
                message: 'Czy na pewno chcesz usunąć członka zespołu {{name}}?',
                button: 'Usuń członka zespołu',
            },
        },
    },
    roles: {
        ROLE_PURCHASER_TEAM_OWNER: 'Właściciel',
        ROLE_PURCHASER_TEAM_MANAGER: 'Manager',
        ROLE_PURCHASER_TEAM_EMPLOYEE: 'Pracownik',
        invited: 'Zaproszono',
    },
    buttons: {
        add: 'Dodaj',
        addNew: 'Dodaj nowy',
        upload: 'Wyślij',
        more: 'Więcej',
        edit: 'Edytuj',
        saveChanges: 'Zapisz zmiany',
        discard: 'Odrzuć',
        apply: 'Zastosuj',
        buy: 'Kup',
        readLess: 'Mniej...',
        readMore: 'Więcej...',
        close: 'Zamknij',
        join: 'Dołącz',
        showAll: 'Pokaz wszystkie',
        next: 'Następny',
        previous: 'Poprzedni',
        submit: 'Zatwierdź',
        change: 'Zmień',
        remove: 'Usuń',
        update: 'Aktualizuj',
        resetPassword: 'Resetuj hasło',
        cancel: 'Anuluj',
        create: 'Stwórz',
        confirm: 'Zatwierdź',
        sendInvitation: 'Wyślij zaproszenie',
        view: 'Zobacz',
        detach: 'Odłącz',
        delete: 'Usuń',
    },
    dateRangePicker: {
        config: {
            previousMonth: 'Previous month',
            lastWeek: 'Last week',
            today: 'Today',
            currentMonth: 'Current month',
        },
    },
    formValidation: {
        errors: {
            minLength: 'Wartość jest zbyt krótka',
            maxLength: 'Wartość jest zbyt długa',
            isEmailValid: 'E-mail jest niepoprawny',
            isPostCodeValid: 'Kod pocztowy jest nieprawidłowy',
            isRequired: 'Pole jest wymagane',
            isNipValid: 'NIP jest nieprawidłowy',
            isPhoneValid: 'Nieprawidłowy numer telefonu',
            isMobileValid: 'Nieprawidłowy numer komórkowy',
            isCheckedTrue: 'Pole powinno być zaznaczone',
            isTrue: 'Pole powinno być zaznaczone',
            isPasswordSame: 'Hasła nie zgadzają się',
            isStartDateValid: 'Proszę wybrać datę z przyszłości',
            isStartDateGreaterOrEvenValid: 'Proszę wybrać datę z przyszłości',
            isEndDateValid: 'Proszę wybrać datę powrotu po dacie wylotu',
            isNumber: 'Podana wartość powinna być liczbą',
            isPasswordString: 'Hasło jest nieprawidłowe.',
            atLeastOneChecked: 'Proszę zaznaczyć przynajmniej jedną opcję',
            termsAndConditions: 'Należy zaakceptować warunki.',
            isInteger: 'Wartość powinna być liczbą całkowitą',
            isPositiveNumber: 'Wartość powinna być liczbą dodatnią',
            isLengthValid: 'Długość jest nieprawidłowa',
            noRepeatedSpecialCharacters: 'Może być tylko jeden znak łączący',
            containingLetterNotStartingWithWhitespace: 'Pole nie może zaczynać ani kończyć się spacją',
            noEndWithDash: 'Nie może kończyć ani zaczynać się znakiem specjalnym',
            onlyLetters: 'Dozwolone są litery, spacja, apostrof i myślnik',
            isLaterThanNow: 'Data musi być w przyszłości',
            isProcessAtLaterThanNow: 'Data realizacji musi być w przyszłości',
            badAddress: 'Wskaż adres zawierający ulicę i numer budynku',
            orderNumberValidation: 'Pole może zawierać maksymalnie {{maxChars}} znaków.',
        },
    },
    footer: {
        copyright: `PaliPali {{date}} © All right reserved`,
    },
    maintenance: {
        pageNotFound: 'Błąd - nie znaleziono strony',
    },
    menuItems: {
        teams: 'Zespoły',
        account: 'Konto',
        locations: 'Lokalizacje',
        settings: 'Ustawienia',
        logout: 'Wyloguj',
        jobs: 'Historia zleceń',
        activeJobs: 'Aktywne zlecenia',
    },
    alerts: {
        warning: 'Uwaga!',
        error: 'Błąd!',
        success: 'Sukces!',
        info: 'Informacja',
        authError: 'Nazwa użytkownika lub hasło są nieprawidłowe.',
        noAccessError: 'Nie masz uprawnień do przeglądania tej strony.',
        wrongAccountError: 'Nie możesz się zalogować do tej aplikacji za pomocą tego konta.',
        baseError: 'Wystąpił błąd, skontaktuj się z administratorem.',
        loginError: 'Wystąpił błąd przy logowaniu, spróbuj ponownie.',
        tryAgain: 'Spróbuj ponownie',
        unknown: 'Wystąpił nieznany błąd.',
        accessDenied: 'Nie masz uprawnień.',
        paymentMethodAttached: 'Metoda płatności została pomyślnie dodana.',
        paymentMethodDeleted: 'Metoda płatności została usunięta.',
        accountNotConfirmed: 'Konto nie zostało potwierdzone.',
        accountNotActive: 'Konto nie jest aktywne.',
        accountDeleted: 'Konto zostało usunięte.',
        accountIsInvitation: 'Nieprawidłowe dane.',
        invalidOrExpiredToken: 'Twój token wygasł lub jest nieprawidłowy.',
        expiredTokenLogout: 'Twoja sesja wygasła. Zostałeś wylogowany.',
    },
    month: {
        january: 'Styczeń',
        february: 'Luty',
        march: 'Marzec',
        april: 'Kwiecień',
        may: 'Maj',
        june: 'Czerwiec',
        july: 'Lipiec',
        august: 'Sierpień',
        september: 'Wrzesień',
        october: 'Październik',
        november: 'Listopad',
        december: 'Grudzień',
    },
    weekday: {
        sunday: 'Nd',
        monday: 'Pon',
        tuesday: 'Wt',
        wednesday: 'Śr',
        thursday: 'Czw',
        friday: 'Pt',
        saturday: 'Sob',
    },
    datepickerWeekday: {
        Su: 'Nd',
        Mo: 'Pon',
        Tu: 'Wt',
        We: 'Śr',
        Th: 'Czw',
        Fr: 'Pt',
        Sa: 'Sob',
    },
    general: {
        clearTextButton: 'Wyczyść',
        noDataInfo: 'Brak danych',
        prefixModal: {
            title: 'Wybierz prefiks',
            noCountries: 'Brak krajów do wyświetlenia',
        },
        cityModal: {
            title: 'Wybierz miasto',
            noCities: 'Brak miast do wyświetlenia',
        },
        locale: {
            pl_PL: 'Polski',
            en_US: 'Angielski',
            uk_UA: 'Ukraiński',
            pl_pl: 'Polski',
            en_us: 'Angielski',
            uk_ua: 'Ukraiński',
        },
        wrongLocale: '-',
        teamsModal: {
            noTeams: 'Nie masz dodanych zespołów',
        },
        durationTime: '{{ hour }} g {{ minute }} min',
        currency: {
            pln: 'zł',
        },
        boxMeasurements: {
            max: 'max',
            weight: 'do {{weight}} kg',
            unit: ' cm',
        },
        grossPrice: 'brutto',
    },
    packageSizes: {
        label: 'Dostępne kategorie zleceń',
        maxWeight: 'do {{weight}} kg',
        max: 'max.',
        s: {
            label: 'S',
        },
        m: {
            label: 'M',
        },
        l: {
            label: 'L',
        },
    },
    jobStatusDetails: {
        planned: 'Zaplanowane',
        searching: 'Szukanie',
        drive: 'W drodze',
        pickup: 'Podjęcie przesyłki',
        delivery: 'Wydanie przesyłki',
        delivered: 'Zrealizowane',
        cancelledPurchaser: 'Anulowane',
        cancelledCourier: 'Anulowane przez Kuriera',
        problemPurchaser: 'Zgłoszone',
        problemCourier: 'Zgłoszone przez Kuriera',
        deliveryDrive: 'W doręczeniu',
        failed: 'Niepowodzenie',
    },
    pagination: {
        pageCount: '<0>{{ page }}</0> z <1>{{ totalPages }}</1>',
    },
    avatarUpload: {
        select: 'Wybierz',
        change: 'Zmień',
        update: 'Aktualizuj',
    },
    countryPhoneCodes: {
        AD: 'Andora (376)',
        AE: 'Zjednoczone Emiraty Arabskie (971)',
        AF: 'Afganistan (93)',
        AG: 'Antigua i Barbuda (1-268)',
        AI: 'Anguilla (1-264)',
        AL: 'Albania (355)',
        AM: 'Armenia (374)',
        AN: 'Antyle Holenderskie (599)',
        AO: 'Angola (244)',
        AQ: 'Antarktyka (672)',
        AR: 'Argentyna (54)',
        AS: 'Samoa Amerykańskie (1-684)',
        AT: 'Austria (43)',
        AU: 'Australia (61)',
        AW: 'Aruba (297)',
        AZ: 'Azerbejdżan (994)',
        BA: 'Bośnia i Hercegowina (387)',
        BB: 'Barbados (1-246)',
        BD: 'Bangladesz (880)',
        BE: 'Belgia (32)',
        BF: 'Burkina Faso (226)',
        BG: 'Bułgaria (359)',
        BH: 'Bahrajn (973)',
        BI: 'Burundi (257)',
        BJ: 'Benin (229)',
        BL: 'Saint-Barthélemy (590)',
        BM: 'Bermudy (1-441)',
        BN: 'Brunei (673)',
        BO: 'Boliwia (591)',
        BR: 'Brazylia (55)',
        BS: 'Bahamy (1-242)',
        BT: 'Bhutan (975)',
        BW: 'Botswana (267)',
        BY: 'Białoruś (375)',
        BZ: 'Belize (501)',
        CA: 'Kanada (1)',
        CC: 'Wyspy Kokosowe (61)',
        CD: 'Demokratyczna Republika Konga (243)',
        CF: 'Republika Środkowoafrykańska (236)',
        CG: 'Kongo (242)',
        CH: 'Szwajcaria (41)',
        CI: "Côte d'Ivoire (225)",
        CK: 'Wyspy Cooka (682)',
        CL: 'Chile (56)',
        CM: 'Kamerun (237)',
        CN: 'Chiny (86)',
        CO: 'Kolumbia (57)',
        CR: 'Kostaryka (506)',
        CU: 'Kuba (53)',
        CV: 'Republika Zielonego Przylądka (238)',
        CW: 'Curaçao (599)',
        CX: 'Wyspa Bożego Narodzenia (61)',
        CY: 'Cypr (357)',
        CZ: 'Czechy (420)',
        DE: 'Niemcy (49)',
        DJ: 'Dżibuti (253)',
        DK: 'Dania (45)',
        DM: 'Dominika (1-767)',
        DO: 'Republika Dominikańska (1-809)',
        DZ: 'Algieria (213)',
        EC: 'Ekwador (593)',
        EE: 'Estonia (372)',
        EG: 'Egipt (20)',
        EH: 'Sahara Zachodnia (212)',
        ER: 'Erytrea (291)',
        ES: 'Hiszpania (34)',
        ET: 'Etiopia (251)',
        FI: 'Finlandia (358)',
        FJ: 'Fidżi (679)',
        FK: 'Falklandy (500)',
        FM: 'Mikronezja (691)',
        FO: 'Wyspy Owcze (298)',
        FR: 'Francja (33)',
        GA: 'Gabon (241)',
        GB: 'Wielka Brytania (44)',
        GD: 'Grenada (1-473)',
        GE: 'Gruzja (995)',
        GG: 'Guernsey (44-1481)',
        GH: 'Ghana (233)',
        GI: 'Gibraltar (350)',
        GL: 'Grenlandia (299)',
        GM: 'Gambia (220)',
        GN: 'Gwinea (224)',
        GQ: 'Gwinea Równikowa (240)',
        GR: 'Grecja (30)',
        GT: 'Gwatemala (502)',
        GU: 'Guam (1-671)',
        GW: 'Gwinea Bissau (245)',
        GY: 'Gujana (592)',
        HN: 'Hongkong (852)',
        HR: 'Chorwacja (385)',
        HT: 'Haiti (509)',
        HU: 'Węgry (36)',
        ID: 'Indonezja (62)',
        IE: 'Irlandia (353)',
        IL: 'Izrael (972)',
        IM: 'Wyspa Man (44-1624)',
        IN: 'Indie (91)',
        IO: 'Brytyjskie Terytorium Oceanu Indyjskiego (246)',
        IQ: 'Irak (964)',
        IR: 'Iran (98)',
        IS: 'Islandia (354)',
        IT: 'Włochy (39)',
        JE: 'Jersey (44-1534)',
        JM: 'Jamajka (1-876)',
        JO: 'Jordania (962)',
        JP: 'Japonia (81)',
        KE: 'Kenia (254)',
        KG: 'Kirgistan (996)',
        KH: 'Kambodża (588)',
        KI: 'Kiribati (686)',
        KM: 'Komory (269)',
        KN: 'Saint Kitts i Nevis (1-869)',
        KP: 'Korea Północna (850)',
        KR: 'Korea Południowa (82)',
        KW: 'Kuwejt (965)',
        KY: 'Kajmany (1-345)',
        KZ: 'Kazachstan (7)',
        LA: 'Laos (856)',
        LB: 'Liban (961)',
        LC: 'Saint Lucia (1-758)',
        LI: 'Liechtenstein (423)',
        LK: 'Sri Lanka (94)',
        LR: 'Liberia (231)',
        LS: 'Lesotho (266)',
        LT: 'Litwa (370)',
        LU: 'Luksemburg (352)',
        LV: 'Łotwa (371)',
        LY: 'Libia (218)',
        MA: 'Maroko (212)',
        MC: 'Monako (373)',
        MD: 'Mołdawia (373)',
        ME: 'Czarnogóra (382)',
        MF: 'Saint Martin (590)',
        MG: 'Madagaskar (261)',
        MH: 'Wyspy Marshalla (692)',
        MK: 'Macedonia Północna (389)',
        ML: 'Mali (223)',
        MM: 'Myanmar (95)',
        MN: 'Mongolia (976)',
        MO: 'Makau (853)',
        MP: 'Mariany Północne (1-670)',
        MR: 'Mauretania (222)',
        MS: 'Montserrat (1-664)',
        MT: 'Malta (356)',
        MU: 'Mauritius (230)',
        MV: 'Malediwy (960)',
        MW: 'Malawi (265)',
        MX: 'Meksyk (52)',
        MY: 'Malezja (60)',
        MZ: 'Mozambik (258)',
        NA: 'Namibia (264)',
        NC: 'Nowa Kaledonia (687)',
        NE: 'Niger (227)',
        NG: 'Nigeria (234)',
        NI: 'Nikaragua (505)',
        NL: 'Holandia (31)',
        NO: 'Norwegia (47)',
        NP: 'Nepal (977)',
        NR: 'Nauru (674)',
        NU: 'Niue (683)',
        NZ: 'Nowa Zelandia (64)',
        OM: 'Oman (968)',
        PA: 'Panama (507)',
        PE: 'Peru (51)',
        PF: 'Polinezja Francuska (689)',
        PG: 'Papua-Nowa Gwinea (675)',
        PH: 'Filipiny (63)',
        PK: 'Pakistan (92)',
        PL: 'Polska (48)',
        PM: 'Saint-Pierre i Miquelon (508)',
        PN: 'Pitcairn (64)',
        PR: 'Portoryko (1-787)',
        PS: 'Palestyna (970)',
        PT: 'Portugalia (351)',
        PW: 'Palau (680)',
        PY: 'Paragwaj (595)',
        QA: 'Katar (974)',
        RO: 'Rumunia (40)',
        RS: 'Serbia (381)',
        RU: 'Rosja (7)',
        RW: 'Rwanda (250)',
        SA: 'Arabia Saudyjska (966)',
        SB: 'Wyspy Salomona (677)',
        SC: 'Seszele (248)',
        SD: 'Sudan (249)',
        SE: 'Szwecja (46)',
        SG: 'Singapur (65)',
        SH: 'Święta Helena (290)',
        SI: 'Słowenia (386)',
        SJ: 'Svalbard i Jan Mayen (47)',
        SK: 'Słowacja (421)',
        SL: 'Sierra Leone (232)',
        SM: 'San Marino (378)',
        SN: 'Senegal (221)',
        SO: 'Somalia (252)',
        SR: 'Surinam (597)',
        SS: 'Sudan Południowy (211)',
        ST: 'Wyspy Świętego Tomasza i Książęca (239)',
        SV: 'Salwador (503)',
        SX: 'Sint Maarten (1-721)',
        SY: 'Syria (963)',
        SZ: 'Eswatini (268)',
        TD: 'Czad (235)',
        TG: 'Togo (228)',
        TH: 'Tajlandia (66)',
    },
    // special auth errors from the backend
    'An authentication exception occurred.': 'Wystąpił błąd uwierzytelniania.',
    'Authentication credentials could not be found.': 'Dane uwierzytelniania nie zostały znalezione.',
    'Authentication request could not be processed due to a system problem.':
        'Żądanie uwierzytelniania nie mogło zostać pomyślnie zakończone z powodu problemu z systemem.',
    'Invalid credentials.': 'Nieprawidłowe dane.',
    'Cookie has already been used by someone else.': 'To ciasteczko jest używane przez kogoś innego.',
    'Not privileged to request the resource.': 'Brak uprawnień dla żądania wskazanego zasobu.',
    'Invalid CSRF token.': 'Nieprawidłowy token CSRF.',
    'No authentication provider found to support the authentication token.':
        'Nie znaleziono mechanizmu uwierzytelniania zdolnego do obsługi przesłanego tokenu.',
    'No session available, it either timed out or cookies are not enabled.':
        'Brak danych sesji, sesja wygasła lub ciasteczka nie są włączone.',
    'No token could be found.': 'Nie znaleziono tokenu.',
    'Username could not be found.': 'Użytkownik o podanej nazwie nie istnieje.',
    'Account has expired.': 'Konto wygasło.',
    'Credentials have expired.': 'Dane uwierzytelniania wygasły.',
    'Account is disabled.': 'Konto jest wyłączone.',
    'Account is locked.': 'Konto jest zablokowane.',
    'Too many failed login attempts, please try again later.': 'Zbyt dużo nieudanych prób logowania, proszę spróbować ponownie później.',
    'Invalid or expired login link.': 'Nieprawidłowy lub wygasły link logowania.',
    'Too many failed login attempts, please try again in %minutes% minute.':
        'Zbyt wiele nieudanych prób logowania, spróbuj ponownie po upływie %minutes% minut.',
    'Too many failed login attempts, please try again in %minutes% minutes.':
        'Zbyt wiele nieudanych prób logowania, spróbuj ponownie po upływie %minutes% minut.',
    // end special auth errors from the backend
};

export default pl;
