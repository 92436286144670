import {CombinedState, combineReducers} from 'redux';
import {createBrowserHistory} from 'history';
import {
    alertSlice,
    authSlice,
    changePasswordSlice,
    countrySlice,
    initLogout,
    loaderSlice,
    loginSlice,
    sagaSlice,
    citySlice,
    colorSlice,
    locationTypeSlice,
    vehicleTypeSlice,
    IChangePasswordState,
    CommonRootState,
    accountSlice,
    modalSlice,
    settlementPeriodSlice,
    IJobProblemTypeState,
    ISettlementPeriodSliceState,
    jobProblemTypeSlice,
    jobCancellationReasonSlice,
    IJobCancellationReasonState,
    teamListingSlice,
    ITeamListingState,
    systemParametersSlice,
    ISystemParametersState,
    confirmInvitationSlice,
    IConfirmInvitationState,
    ITeamsByRolesState,
    teamsByRolesSlice,
} from 'palipali-panel-common-web';
import {RouterState, createReduxHistoryContext} from 'redux-first-history';
import teamViewSlice, {ITeamViewState} from './teamViewSlice';
import teamMemberSlice, {ITeamMemberState} from './teamMemberSlice';
import locationListingSlice, {ILocationListingState} from './locationListingSlice';
import locationViewSlice, {ILocationViewState} from './locationViewSlice';
import JobListingSlice, {JobListingState} from './jobListingSlice';
import jobSlice, {JobState} from './jobSlice';
import createJobViewSlice, {ICreateJobViewState} from './createJobViewSlice';

const {routerReducer} = createReduxHistoryContext({history: createBrowserHistory()});

const appReducer = combineReducers({
    auth: authSlice,
    login: loginSlice,
    changePassword: changePasswordSlice,
    alert: alertSlice,
    saga: sagaSlice,
    country: countrySlice,
    city: citySlice,
    color: colorSlice,
    locationType: locationTypeSlice,
    vehicleType: vehicleTypeSlice,
    router: routerReducer,
    loader: loaderSlice,
    teamListing: teamListingSlice,
    teamView: teamViewSlice,
    teamMember: teamMemberSlice,
    account: accountSlice,
    jobListing: JobListingSlice,
    modal: modalSlice,
    locationListing: locationListingSlice,
    locationView: locationViewSlice,
    settlementPeriod: settlementPeriodSlice,
    job: jobSlice,
    jobProblemType: jobProblemTypeSlice,
    createJobView: createJobViewSlice,
    jobCancellationReason: jobCancellationReasonSlice,
    systemParameters: systemParametersSlice,
    confirmInvitation: confirmInvitationSlice,
    teamsByRoles: teamsByRolesSlice,
});

const rootReducer = (state: ReturnType<typeof appReducer> | undefined, action: any) => {
    if (action.type === initLogout.type) {
        return appReducer(undefined, {type: undefined});
    }

    return appReducer(state, action);
};
export type RootState = CombinedState<{
    changePassword: IChangePasswordState;
    router: RouterState;
    teamListing: ITeamListingState;
    teamView: ITeamViewState;
    teamMember: ITeamMemberState;
    locationListing: ILocationListingState;
    locationView: ILocationViewState;
    settlementPeriod: ISettlementPeriodSliceState;
    jobListing: JobListingState;
    job: JobState;
    jobProblemType: IJobProblemTypeState;
    createJobView: ICreateJobViewState;
    jobCancellationReason: IJobCancellationReasonState;
    systemParametersSlice: ISystemParametersState;
    confirmInvitation: IConfirmInvitationState;
    teamsByRoles: ITeamsByRolesState;
}> &
    CommonRootState;

export default rootReducer;
