import {
    AlertType,
    ICanOutput,
    DetailsSuccessActionsFunction,
    IModelApiResponseViewObject,
    IRawRestQueryParams,
    ListSuccessActionsFunction,
    ILocationOutput,
    ITeamViewOutput,
    addAlert,
    getCanChangePaymentMethodTeamAPI,
    getCanDeleteTeamAPI,
    createActionPayloadEpic,
    createFetchDetailsEpic,
    createFetchListEpic,
    flattenObj,
    getLocationListAPI,
    getTeamViewAPI,
    handleApiError,
    getPaymentClientSecretAPI,
    IPaymentClientSecretOutput,
} from 'palipali-panel-common-web';
import {combineEpics} from 'redux-observable';
import {
    changeTeamLocationListingPagination,
    checkCanChangeTeamPaymentMethod,
    checkCanDeleteTeam,
    getClientSecret,
    getTeam,
    getTeamLocationListing,
    setCanChangePaymentMethod,
    setCanDeleteTeam,
    setClientSecret,
    setError,
    setLoading,
    setMetadata,
    setTeam,
    setTeamLocationListing,
} from '../reducers/teamViewSlice';
import {RootState} from '../reducers';
import {teamLocationFiltersSelector, teamLocationListingPaginationSelector} from '../selectors/teamViewSelectors';

const teamLocationListingSuccessActions: ListSuccessActionsFunction<ILocationOutput> = (
    teamLocationsArray: ILocationOutput[],
    metadata: IModelApiResponseViewObject | null
) => {
    return [setTeamLocationListing({teamLocationList: teamLocationsArray}), setMetadata({metadata: metadata})];
};

const teamFetchSuccessActions: DetailsSuccessActionsFunction<ITeamViewOutput> = (fetchedTeam: ITeamViewOutput) => {
    return [setTeam({team: fetchedTeam}), getTeamLocationListing(fetchedTeam.id), setLoading(false)];
};

const clientSecretFetchSuccessActions: DetailsSuccessActionsFunction<IPaymentClientSecretOutput> = (
    clientSecret: IPaymentClientSecretOutput
) => {
    return [setClientSecret(clientSecret.setupIntentSecret), setLoading(false)];
};

const checkTeamDeleteSuccessActions: DetailsSuccessActionsFunction<ICanOutput> = (canOutput: ICanOutput) => {
    return [setCanDeleteTeam(canOutput.can), setLoading(false)];
};
const checkChangeTeamPaymentMethodSuccessActions: DetailsSuccessActionsFunction<ICanOutput> = (canOutput: ICanOutput) => {
    return [setCanChangePaymentMethod(canOutput.can), setLoading(false)];
};

const getParameters = (state: RootState): IRawRestQueryParams => {
    const pagination = teamLocationListingPaginationSelector(state),
        filters = teamLocationFiltersSelector(state);

    const params = {
        ...pagination,
        ...filters,
    };

    if (params) {
        const parametersFlattened = flattenObj(params);
        return parametersFlattened;
    }
    return [];
};

const errorActions = (error: any): any[] => {
    const errorObj = handleApiError(error);
    errorObj.type = AlertType.WARNING;
    return [setLoading(false), setError(errorObj.message), addAlert(errorObj)];
};

const checkTeamDeletePossibilityEpic = createActionPayloadEpic<ICanOutput>(
    getCanDeleteTeamAPI,
    checkTeamDeleteSuccessActions,
    errorActions,
    checkCanDeleteTeam().type
);

const checkChangeTeamPaymentMethodPossibilityEpic = createActionPayloadEpic<ICanOutput>(
    getCanChangePaymentMethodTeamAPI,
    checkChangeTeamPaymentMethodSuccessActions,
    errorActions,
    checkCanChangeTeamPaymentMethod().type
);

const fetchTeamLocationListingEpic = createFetchListEpic<ILocationOutput>(
    getLocationListAPI,
    teamLocationListingSuccessActions,
    errorActions,
    getTeamLocationListing().type,
    (state: RootState) => getParameters(state)
);

const changeTeamLocationListPaginationEpic = createFetchListEpic<ILocationOutput>(
    getLocationListAPI,
    teamLocationListingSuccessActions,
    errorActions,
    changeTeamLocationListingPagination().type,
    (state: RootState) => getParameters(state)
);

const fetchClientSecretEpic = createFetchDetailsEpic<IPaymentClientSecretOutput>(
    getPaymentClientSecretAPI,
    clientSecretFetchSuccessActions,
    errorActions,
    getClientSecret().type
);

const fetchTeamEpic = createFetchDetailsEpic<ITeamViewOutput>(getTeamViewAPI, teamFetchSuccessActions, errorActions, getTeam().type);

const teamViewEpic = combineEpics(
    fetchTeamEpic,
    fetchClientSecretEpic,
    changeTeamLocationListPaginationEpic,
    fetchTeamLocationListingEpic,
    checkTeamDeletePossibilityEpic,
    checkChangeTeamPaymentMethodPossibilityEpic
);

export default teamViewEpic;
