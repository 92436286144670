import {Box, Divider, Stack, Typography, useTheme} from '@mui/material';
import {FormikForm, Translation} from 'palipali-panel-common-web';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {setIsImmediateOrder, setIsProcessAt} from 'src/store/reducers/createJobViewSlice';
import {isImmediateOrderSelector, isProcessAtSelector} from 'src/store/selectors/createJobViewSelectors';
import immediateOrderFormConfig from './immediateOrderFormConfig';

interface IJobCompletionSectionProps {
    readonly shouldUpdateValidation: boolean;
}

const JobCompletionSection: React.FC<IJobCompletionSectionProps> = ({shouldUpdateValidation}) => {
    const theme = useTheme(),
        dispatch = useDispatch(),
        isImmediateOrder = useSelector(isImmediateOrderSelector),
        processAt = useSelector(isProcessAtSelector),
        initialValues = {
            isImmediateOrder: isImmediateOrder,
            processAt: processAt,
        };

    const onDateTimeChange = (value: Date) => {
        const dateString = value.toISOString();
        dispatch(setIsProcessAt(dateString));
    };

    return (
        <Box>
            <Typography variant="subtitle1" color="text.primary" paddingBottom={'0.4rem'}>
                <Translation text={'jobs.createJob.generalData.completionPeriod'} />
            </Typography>
            <Divider />
            <Stack gap={'1.6rem'} className="completion-control">
                <FormikForm
                    fields={immediateOrderFormConfig(isImmediateOrder)}
                    formId="completion-period-form"
                    formClassName="completion-period-form"
                    initialValues={initialValues}
                    updatedValues={initialValues}
                    shouldValidate={shouldUpdateValidation}
                    customEventChange={(formControlName, value) => {
                        if (formControlName === 'isImmediateOrder') {
                            dispatch(setIsImmediateOrder(value));
                        } else if (formControlName === 'processAt') {
                            onDateTimeChange(value);
                        }
                    }}
                    theme={theme}
                    onSubmit={() => void 0}
                />
                {!isImmediateOrder && (
                    <Typography variant="subtitle2" color="text.secondary">
                        <Translation text={'jobs.createJob.generalData.completionPeriodInfo'} />
                    </Typography>
                )}
            </Stack>
        </Box>
    );
};

export default JobCompletionSection;
