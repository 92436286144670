export const removeStripeQueryParams = (searchParams: URLSearchParams, setSearchParams: (newSearchParams: URLSearchParams) => void) => {
    const params = searchParams.toString();
    if (params.includes('setup_intent_client_secret')) {
        searchParams.delete('setup_intent_client_secret');
    }
    if (params.includes('setup_intent')) {
        searchParams.delete('setup_intent');
    }
    if (params.includes('redirect_status')) {
        searchParams.delete('redirect_status');
    }
    if (params.includes('payment_intent_client_secret')) {
        searchParams.delete('payment_intent_client_secret');
    }
    if (params.includes('payment_intent')) {
        searchParams.delete('payment_intent');
    }
    setSearchParams(searchParams);
};
