import {PayloadAction} from '@reduxjs/toolkit';
import {
    IAssignPackageSizeToDimensionsInput,
    ICreateJobInput,
    IJobLocationInput,
    IJobRecipientInput,
    ILocationOutput,
    IModelApiResponseViewObject,
    IMoneyInput,
    IPackageSizesOutput,
    IPurchaserCurrentJobDetailsOutput,
    ITeamMemberOutput,
    PAGINATION_ITEMS_PER_PAGE,
    createBaseReducerSlice,
    isSameValue,
} from 'palipali-panel-common-web';

export const enum CreateJobWizardSteps {
    GENERAL_DATA = 'generalData',
    SENDER_DATA = 'senderData',
    RECEIVER_DATA = 'receiverData',
    RECIPIENT_DATA = 'recipientData',
    ADDITIONAL_DATA = 'additionalData',
    JOB_SUMMARY = 'jobSummary',
}

export const createJobWizardStepsArray = [
    CreateJobWizardSteps.GENERAL_DATA,
    CreateJobWizardSteps.SENDER_DATA,
    CreateJobWizardSteps.RECEIVER_DATA,
    CreateJobWizardSteps.RECIPIENT_DATA,
    CreateJobWizardSteps.ADDITIONAL_DATA,
    CreateJobWizardSteps.JOB_SUMMARY,
];

export interface IGeneralData {
    teamId: string | null;
    packageSize: 's' | 'm' | 'l' | null;
    insuranceValue: IMoneyInput | null;
    isImmediateOrder: boolean;
    processAt: string | null;
}

export interface IAdditionalData {
    orderNumber: string | null;
    description: string | null;
}

export interface IInsuranceParameters {
    minValue: number;
    maxValue: number;
}

export interface ICreateJobLocationFilters {
    name: string | null;
    'locationType.id': string[] | string | null;
    'team.id': string[] | string | null;
}

export interface ICreateJobViewState {
    isLoading: boolean;
    isDimensionsModalLoading: boolean;
    isInitialized: boolean;
    error: string | null;
    generalData: IGeneralData;
    senderData: IJobLocationInput | null;
    receiverData: IJobLocationInput | null;
    recipientData: IJobRecipientInput | null;
    additionalData: IAdditionalData | null;
    currentStep: CreateJobWizardSteps;
    createdOrder: IPurchaserCurrentJobDetailsOutput | null;
    recipientsList: ITeamMemberOutput[] | null;
    locations: ILocationOutput[] | null;
    locationsPagination: IPaginationParams;
    locationsFilters: ICreateJobLocationFilters;
    locationsMetadata: IModelApiResponseViewObject | null;
    isLocationsLoading: boolean;
    showBoxSizesModal: boolean;
    packageDimensions: IPackageSizesOutput | null;
    shouldRecalculateJobDetails: boolean;
    jobParameters: IInsuranceParameters | null;
    clientSecret: string | null;
    isNavigationOutsideView: boolean;
}

export interface ISetJobGeneralData {
    generalData: IGeneralData;
}

export interface ISetJobSenderData {
    fromLocation: IJobLocationInput;
}
export interface ISetJobReceiverData {
    toLocation: IJobLocationInput;
}

export interface ISetJobRecipientData {
    recipient: IJobRecipientInput;
}

export interface ISetAdditionalData {
    additionalData: IAdditionalData;
}

export interface ISetCreateJobViewLoading {
    isLoading: boolean;
}

export interface ISetClientSecret {
    clientSecret: string | null;
}

export interface ISetCreateJobLocationsLoading {
    isLocationsLoading: boolean;
}

export interface ISetDimensionsModalLoading {
    isDimensionsModalLoading: boolean;
}

export interface ISetCreateJobViewError {
    error: string | null;
}

export interface ICreateJob {
    createJobInput: ICreateJobInput;
}

export interface ISetLocations {
    locations: ILocationOutput[] | null;
    locationsTotalResult: number;
    locationsPage: number;
}

export interface IGetRecipients {
    teamId: string | null;
}

export interface ISetRecipients {
    recipients: ITeamMemberOutput[] | null;
}

export interface IAssignPackageDimensions {
    packageDimensions: IAssignPackageSizeToDimensionsInput;
}

export interface ISetPackageDimensions {
    packageDimensions: IPackageSizesOutput | null;
}

export interface ISetCreatedOrder {
    createdOrder: IPurchaserCurrentJobDetailsOutput;
}

export interface IReplaceOrder {
    replacePayload: {
        jobId: string;
        createJobInput: ICreateJobInput;
    };
}

export interface IPlaceOrder {
    jobId: string;
}

export interface ISetModal {
    showModal: boolean;
}

export interface IChangeLocationFilters {
    locationsFilters: ICreateJobLocationFilters;
}

export interface IPaginationParams {
    itemsPerPage: number;
    page: number;
}

export interface ISetMetadata {
    locationsMetadata: IModelApiResponseViewObject | null;
}

export interface IChangePagination {
    locationsPagination: IPaginationParams;
}

export interface ISetLocationListing {
    locationList: ILocationOutput[] | null;
}

const generalDataInitialState: IGeneralData = {
    teamId: null,
    packageSize: null,
    insuranceValue: null,
    isImmediateOrder: true,
    processAt: null,
};

const initialState: ICreateJobViewState = {
    isLoading: false,
    isDimensionsModalLoading: false,
    isInitialized: false,
    error: null,
    generalData: generalDataInitialState,
    senderData: null,
    receiverData: null,
    recipientData: null,
    additionalData: null,
    currentStep: CreateJobWizardSteps.GENERAL_DATA,
    createdOrder: null,
    recipientsList: null,
    locations: null,
    locationsFilters: {
        name: null,
        'team.id': null,
        'locationType.id': null,
    },
    locationsMetadata: null,
    locationsPagination: {
        page: 1,
        itemsPerPage: PAGINATION_ITEMS_PER_PAGE,
    },
    isLocationsLoading: false,
    showBoxSizesModal: false,
    packageDimensions: null,
    shouldRecalculateJobDetails: false,
    jobParameters: null,
    clientSecret: null,
    isNavigationOutsideView: false,
};

const createJobViewSlice = createBaseReducerSlice({
    name: 'createJobView',
    initialState: initialState,
    reducers: {
        setJobGeneralData: {
            reducer: (state: ICreateJobViewState, action: PayloadAction<ISetJobGeneralData>) => {
                {
                    return {
                        ...state,
                        generalData: action.payload.generalData,
                        isLoading: false,
                    };
                }
            },
            prepare(generalData: IGeneralData) {
                return {
                    payload: {generalData},
                };
            },
        },
        setIsProcessAt: {
            reducer: (state: ICreateJobViewState, action: PayloadAction<string | null>) => {
                {
                    return {
                        ...state,
                        generalData: {
                            ...state.generalData,
                            processAt: action.payload,
                        },
                        isLoading: false,
                    };
                }
            },
            prepare(processAt: string | null) {
                return {
                    payload: processAt,
                };
            },
        },
        setIsImmediateOrder: {
            reducer: (state: ICreateJobViewState, action: PayloadAction<boolean>) => {
                {
                    return {
                        ...state,
                        generalData: {
                            ...state.generalData,
                            isImmediateOrder: action.payload,
                        },
                        isLoading: false,
                    };
                }
            },
            prepare(isImmediateOrder: boolean) {
                return {
                    payload: isImmediateOrder,
                };
            },
        },
        validateJobSenderData: {
            reducer: (state: ICreateJobViewState) => {
                {
                    return {
                        ...state,
                        isLoading: true,
                    };
                }
            },
            prepare(fromLocation: IJobLocationInput) {
                return {
                    payload: {
                        fromLocation,
                    },
                };
            },
        },
        setJobSenderData: {
            reducer: (state: ICreateJobViewState, action: PayloadAction<ISetJobSenderData>) => {
                {
                    return {
                        ...state,
                        senderData: action.payload.fromLocation,
                        isLoading: false,
                    };
                }
            },
            prepare(fromLocation: IJobLocationInput) {
                return {
                    payload: {fromLocation},
                };
            },
        },
        setJobReceiverData: {
            reducer: (state: ICreateJobViewState, action: PayloadAction<ISetJobReceiverData>) => {
                {
                    return {
                        ...state,
                        receiverData: action.payload.toLocation,
                        isLoading: false,
                    };
                }
            },
            prepare(toLocation: IJobLocationInput) {
                return {
                    payload: {toLocation},
                };
            },
        },
        setJobAdditionalData: {
            reducer: (state: ICreateJobViewState, action: PayloadAction<ISetAdditionalData>) => {
                {
                    return {
                        ...state,
                        additionalData: action.payload.additionalData,
                        isLoading: false,
                    };
                }
            },
            prepare(additionalData: IAdditionalData) {
                return {
                    payload: {additionalData},
                };
            },
        },
        validateJobRecipientData: {
            reducer: (state: ICreateJobViewState) => {
                {
                    return {
                        ...state,
                        isLoading: true,
                    };
                }
            },
            prepare(recipient: IJobRecipientInput) {
                return {
                    payload: recipient,
                };
            },
        },
        setJobRecipientData: {
            reducer: (state: ICreateJobViewState, action: PayloadAction<ISetJobRecipientData>) => {
                {
                    return {
                        ...state,
                        recipientData: action.payload.recipient,
                        isLoading: false,
                    };
                }
            },
            prepare(recipient: IJobRecipientInput) {
                return {
                    payload: {recipient},
                };
            },
        },
        setLocationListing: (state: ICreateJobViewState, action: PayloadAction<ISetLocationListing>) => {
            return {
                ...state,
                locations: action.payload.locationList,
                isLocationsLoading: false,
            };
        },
        changeLocationFilters: {
            reducer: (state: ICreateJobViewState, action: PayloadAction<IChangeLocationFilters>) => {
                if (isSameValue(action.payload.locationsFilters, state.locationsFilters)) {
                    return {
                        ...state,
                    };
                }

                return {
                    ...state,
                    isLocationsLoading: true,
                    locationsFilters: action.payload.locationsFilters,
                };
            },
            prepare: (locationsFilters: ICreateJobLocationFilters) => {
                return {
                    payload: {
                        locationsFilters: locationsFilters,
                    },
                };
            },
        },
        setMetadata: (state: ICreateJobViewState, action: PayloadAction<ISetMetadata>) => {
            return {
                ...state,
                locationsMetadata: action.payload.locationsMetadata,
            };
        },
        changeLocationListingPagination: (state: ICreateJobViewState, action: PayloadAction<IChangePagination>) => {
            return {
                ...state,
                locationsPagination: action.payload.locationsPagination,
                isLocationsLoading: true,
            };
        },
        setLocations: {
            reducer: (state: ICreateJobViewState, action: PayloadAction<ISetLocations>) => {
                return {
                    ...state,
                    locations: action.payload.locations,
                    locationsTotalResults: action.payload.locationsTotalResult,
                    locationsPage: action.payload.locationsPage,
                    isLocationsLoading: false,
                };
            },
            prepare(locations: ILocationOutput[] | null, locationsTotalResult: number, locationsPage: number) {
                return {
                    payload: {locations, locationsTotalResult, locationsPage},
                };
            },
        },
        setRecipients: {
            reducer: (state: ICreateJobViewState, action: PayloadAction<ISetRecipients>) => {
                {
                    return {
                        ...state,
                        recipientsList: action.payload.recipients,
                        isLoading: false,
                    };
                }
            },
            prepare(recipients: ITeamMemberOutput[] | null) {
                return {
                    payload: {recipients},
                };
            },
        },
        resetLocations: {
            reducer: (state: ICreateJobViewState) => {
                {
                    return {
                        ...state,
                        locations: null,
                        locationsPagination: {
                            page: 1,
                            itemsPerPage: PAGINATION_ITEMS_PER_PAGE,
                        },
                    };
                }
            },
            prepare: () => ({payload: null}),
        },
        resetRecipients: {
            reducer: (state: ICreateJobViewState) => {
                {
                    return {
                        ...state,
                        recipientsList: null,
                    };
                }
            },
            prepare: () => ({payload: null}),
        },
        setCreateJobViewError: {
            reducer: (state: ICreateJobViewState, action: PayloadAction<ISetCreateJobViewError>) => {
                {
                    return {
                        ...state,
                        error: action.payload.error,
                    };
                }
            },
            prepare(error: string | null) {
                return {
                    payload: {error},
                };
            },
        },
        setCreateJobViewLoading: {
            reducer: (state: ICreateJobViewState, action: PayloadAction<ISetCreateJobViewLoading>) => {
                {
                    return {
                        ...state,
                        isLoading: action.payload.isLoading,
                    };
                }
            },
            prepare(isLoading: boolean) {
                return {
                    payload: {isLoading},
                };
            },
        },
        setAdditionalPaymentAuthorizationClientSecret: {
            reducer: (state: ICreateJobViewState, action: PayloadAction<ISetClientSecret>) => {
                {
                    return {
                        ...state,
                        clientSecret: action.payload.clientSecret,
                    };
                }
            },
            prepare(clientSecret: string | null) {
                return {
                    payload: {clientSecret},
                };
            },
        },
        setCreateJobLocationsLoading: {
            reducer: (state: ICreateJobViewState, action: PayloadAction<ISetCreateJobLocationsLoading>) => {
                {
                    return {
                        ...state,
                        isLocationsLoading: action.payload.isLocationsLoading,
                    };
                }
            },
            prepare(isLocationsLoading: boolean) {
                return {
                    payload: {isLocationsLoading},
                };
            },
        },
        setDimensionsModalLoading: {
            reducer: (state: ICreateJobViewState, action: PayloadAction<ISetDimensionsModalLoading>) => {
                {
                    return {
                        ...state,
                        isDimensionsModalLoading: action.payload.isDimensionsModalLoading,
                    };
                }
            },
            prepare(isDimensionsModalLoading: boolean) {
                return {
                    payload: {isDimensionsModalLoading},
                };
            },
        },
        setShowBoxSizesModal: {
            reducer: (state: ICreateJobViewState, action: PayloadAction<ISetModal>) => {
                return {
                    ...state,
                    showBoxSizesModal: action.payload.showModal,
                };
            },
            prepare(showModal: boolean) {
                return {
                    payload: {showModal},
                };
            },
        },
        setIsOutsideNavigation: {
            reducer: (state: ICreateJobViewState, action: PayloadAction<boolean>) => {
                return {
                    ...state,
                    isNavigationOutsideView: action.payload,
                };
            },
            prepare(isNavigationOutsideView: boolean) {
                return {
                    payload: isNavigationOutsideView,
                };
            },
        },
        setPackageDimensions: {
            reducer: (state: ICreateJobViewState, action: PayloadAction<ISetPackageDimensions>) => {
                return {
                    ...state,
                    packageDimensions: action.payload.packageDimensions,
                };
            },
            prepare(packageDimensions: IPackageSizesOutput | null) {
                return {
                    payload: {packageDimensions},
                };
            },
        },
        setCreatedJob: {
            reducer: (state: ICreateJobViewState, action: PayloadAction<ISetCreatedOrder>) => {
                return {
                    ...state,
                    createdOrder: action.payload.createdOrder,
                };
            },
            prepare(createdOrder: IPurchaserCurrentJobDetailsOutput) {
                return {
                    payload: {createdOrder},
                };
            },
        },
        setShouldRecalculateJobDetails: {
            reducer: (state: ICreateJobViewState, action: PayloadAction<boolean>) => {
                return {
                    ...state,
                    shouldRecalculateJobDetails: action.payload,
                };
            },
            prepare(shouldRecalculateJobDetails: boolean) {
                return {
                    payload: shouldRecalculateJobDetails,
                };
            },
        },
        getRecipients: {
            reducer: (state: ICreateJobViewState) => {
                return {
                    ...state,
                    error: null,
                    isLoading: true,
                };
            },
            prepare(teamId: string | null) {
                return {
                    payload: {teamId},
                };
            },
        },
        assignPackageDimensions: {
            reducer: (state: ICreateJobViewState) => {
                return {
                    ...state,
                    error: null,
                    isDimensionsModalLoading: true,
                };
            },
            prepare(packageDimensions: IAssignPackageSizeToDimensionsInput) {
                return {
                    payload: {packageDimensions},
                };
            },
        },
        createJob: {
            reducer: (state: ICreateJobViewState) => {
                return {
                    ...state,
                    error: null,
                    isLoading: true,
                };
            },
            prepare(createJobInput: ICreateJobInput) {
                return {
                    payload: {createJobInput},
                };
            },
        },
        replaceJob: {
            reducer: (state: ICreateJobViewState) => {
                return {
                    ...state,
                    error: null,
                    isLoading: true,
                };
            },
            prepare(replacePayload: IReplaceOrder) {
                return {
                    payload: {replacePayload},
                };
            },
        },
        recalculateJobDetails: {
            reducer: (state: ICreateJobViewState) => {
                return {
                    ...state,
                    error: null,
                    isLoading: true,
                };
            },
            prepare(replacePayload: IReplaceOrder) {
                return {
                    payload: {replacePayload},
                };
            },
        },
        placeJob: {
            reducer: (state: ICreateJobViewState) => {
                return {
                    ...state,
                    error: null,
                    isLoading: true,
                };
            },
            prepare(jobId: string) {
                return {
                    payload: {jobId},
                };
            },
        },
        setCurrentStep: {
            reducer: (state: ICreateJobViewState, action: PayloadAction<CreateJobWizardSteps>) => {
                return {
                    ...state,
                    currentStep: action.payload,
                };
            },
            prepare(currentStep: CreateJobWizardSteps) {
                return {
                    payload: currentStep,
                };
            },
        },
        getParameters: {
            reducer: (state: ICreateJobViewState) => {
                return {
                    ...state,
                };
            },
            prepare() {
                return {
                    payload: {},
                };
            },
        },
        setParameters: {
            reducer: (state: ICreateJobViewState, action: PayloadAction<IInsuranceParameters>) => {
                return {
                    ...state,
                    jobParameters: action.payload,
                };
            },
            prepare(parameters: IInsuranceParameters) {
                return {
                    payload: parameters,
                };
            },
        },
        updateCurrentStepLocation: {
            reducer: (state: ICreateJobViewState, action: PayloadAction<{location: ILocationOutput}>) => {
                const currentStep = state.currentStep;

                const stepData: IJobLocationInput = {
                    locationId: action.payload.location.id,
                    companyName: action.payload.location.companyName || null,
                    phone: action.payload.location.phone || null,
                    addressLine: action.payload.location.addressLine || null,
                    flatNumber: action.payload.location.flatNumber || null,
                    point: {
                        latitude: action.payload.location.point?.latitude,
                        longitude: action.payload.location.point?.longitude,
                    },
                    city: action.payload.location.city,
                    country: action.payload.location.country,
                    zip: action.payload.location.zip,
                    street: action.payload.location.street,
                    houseNumber: action.payload.location.houseNumber,
                };
                if (currentStep === CreateJobWizardSteps.SENDER_DATA) {
                    return {
                        ...state,
                        senderData: stepData,
                    };
                }
                return {
                    ...state,
                    receiverData: stepData,
                };
            },
            prepare(location: ILocationOutput) {
                return {
                    payload: {
                        location,
                    },
                };
            },
        },
        resetToInitialCreateJobViewState: () => {
            return {
                ...initialState,
            };
        },
    },
});

export const {
    setJobGeneralData,
    validateJobSenderData,
    setJobSenderData,
    setJobReceiverData,
    validateJobRecipientData,
    setJobRecipientData,
    setCreateJobViewLoading,
    setAdditionalPaymentAuthorizationClientSecret,
    setCreateJobViewError,
    setShowBoxSizesModal,
    setJobAdditionalData,
    createJob,
    replaceJob,
    placeJob,
    setLocations,
    resetLocations,
    getRecipients,
    setRecipients,
    resetRecipients,
    assignPackageDimensions,
    setDimensionsModalLoading,
    setPackageDimensions,
    setCreatedJob,
    setShouldRecalculateJobDetails,
    recalculateJobDetails,
    resetToInitialCreateJobViewState,
    setCurrentStep,
    updateCurrentStepLocation,
    getParameters,
    setParameters,
    setIsProcessAt,
    setIsImmediateOrder,
    setCreateJobLocationsLoading,
    setLocationListing,
    changeLocationFilters,
    setMetadata,
    changeLocationListingPagination,
    setIsOutsideNavigation,
} = createJobViewSlice.actions;

export default createJobViewSlice.reducer;
