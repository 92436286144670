import {Stack} from '@mui/material';
import {IDayOpenHoursInput, IOpenHoursInput} from 'palipali-panel-common-web';
import React, {useEffect, useState} from 'react';
import DayControl from './DayControl';
import HourControl from './HourControl';

interface IOpeningHoursInputProps {
    data: IOpenHoursInput;
    day: string;
    dayData: IDayOpenHoursInput | null;
    onHourChange: (selectedDay: string, openingHour: string, closingHour: string, isDayAvailable: boolean) => void;
    setIsDataPresent: (isDataPresent: boolean) => void;
    setIsDataValid: (isDataValid: boolean) => void;
}

const OpeningHoursControl: React.FC<IOpeningHoursInputProps> = ({data, day, dayData, onHourChange, setIsDataPresent, setIsDataValid}) => {
    const [isDayAvailable, setIsDayAvailable] = useState<boolean>(true),
        [updatedData, setUpdatedData] = useState<IOpenHoursInput>(data);

    useEffect(() => {
        if (!isDayAvailable) {
            const updatedDateData = {
                ...updatedData,
                [day]: null,
            };
            setUpdatedData(updatedDateData);
        }
    }, [isDayAvailable]);

    return (
        <>
            <Stack direction={'row'} className={'day-control-wrapper'}>
                <DayControl
                    day={day}
                    data={dayData}
                    changeIsAvailableDay={(isAvailable: boolean) => {
                        setIsDayAvailable(isAvailable);
                        if (!isAvailable) {
                            const updatedDateData = {
                                ...updatedData,
                                [day]: null,
                            };
                            setUpdatedData(updatedDateData);
                            onHourChange(
                                day,
                                data[day as keyof IOpenHoursInput]?.from || '',
                                data[day as keyof IOpenHoursInput]?.to || '',
                                false
                            );
                        }
                    }}
                    setIsDataPresent={setIsDataPresent}
                />

                <HourControl
                    data={data}
                    selectedDay={day}
                    isDayAvailable={isDayAvailable}
                    isErrorChange={(isError: boolean) => {
                        setIsDataValid(!isError);
                    }}
                    onHourChange={(selectedDay: string, openingHour: string, closingHour: string) => {
                        const updatedDateData = {
                            ...updatedData,
                            [selectedDay]: {
                                from: openingHour,
                                to: closingHour,
                            },
                        };
                        setUpdatedData(updatedDateData);
                        setIsDataPresent(true);
                        onHourChange(selectedDay, openingHour, closingHour, isDayAvailable);
                    }}
                />
            </Stack>
        </>
    );
};

export default OpeningHoursControl;
