import {Box, Button, Card} from '@mui/material';
import {
    CustomTablePagination,
    ILocationOutput,
    ILocationTypeOutput,
    Loader,
    LoaderType,
    PAGINATION_ITEMS_PER_PAGE,
    Translation,
    locationTypesSelector,
    UserPlus,
} from 'palipali-panel-common-web';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import LocationListTable from 'src/components/shared/LocationListTable';
import {IPaginationParams} from 'src/store/reducers/locationListingSlice';
import {changeTeamLocationListingPagination} from 'src/store/reducers/teamViewSlice';
import {teamLocationListingMetadataSelector, teamLocationListingPaginationSelector} from 'src/store/selectors/teamViewSelectors';
import {isTeamMemberLoadingSelector} from '../../../../store/selectors/teamMemberSelectors';

interface ITeamLocationsTabProps {
    locations: ILocationOutput[];
    id: string;
}

const TeamLocationsTab: React.FC<ITeamLocationsTabProps> = ({locations, id}) => {
    const isLoading = useSelector(isTeamMemberLoadingSelector),
        metadata = useSelector(teamLocationListingMetadataSelector),
        locationTypes: ILocationTypeOutput[] = useSelector(locationTypesSelector),
        dispatch = useDispatch(),
        navigate = useNavigate(),
        pagination: IPaginationParams = useSelector(teamLocationListingPaginationSelector);

    return (
        <>
            <Box display={'flex'} justifyContent={'flex-end'} sx={{marginBottom: '3.2rem'}}>
                <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    className="header-button add-member-button"
                    onClick={() => {
                        navigate('/panel/teams/location/create', {state: {teamId: id}});
                    }}
                    startIcon={<UserPlus size={20} />}>
                    <Translation text="teams.teamOperation.buttons.addLocation" />
                </Button>
            </Box>

            <Card className="custom-card overflow-hidden">
                <LocationListTable
                    isLoading={isLoading}
                    pagination={pagination}
                    locations={locations}
                    locationTypes={locationTypes}
                    teamId={id}
                />
                <CustomTablePagination
                    metadata={metadata}
                    onChangePage={(event, pageNumber) =>
                        dispatch(changeTeamLocationListingPagination({page: pageNumber, itemsPerPage: PAGINATION_ITEMS_PER_PAGE}))
                    }
                    page={pagination.page}
                    rowsPerPage={PAGINATION_ITEMS_PER_PAGE}
                />
                <Loader showLoader={isLoading} type={LoaderType.Local} />
            </Card>
        </>
    );
};

export default TeamLocationsTab;
