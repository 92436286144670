import {Button, Typography, useTheme} from '@mui/material';
import {CustomModalComponent, Translation, AlertTriangle} from 'palipali-panel-common-web';
import React from 'react';
import {Trans} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {deleteTeamMember} from 'src/store/reducers/teamMemberSlice';

interface IDeleteMemberProps {
    id: string | null | undefined;
    teamName: string | null | undefined;
}

const TeamMemberDeleteModal: React.FC<IDeleteMemberProps> = ({id, teamName}) => {
    const dispatch = useDispatch(),
        theme = useTheme();

    return (
        <CustomModalComponent
            theme={theme}
            title={
                <Typography color="text.primary" variant="h6" component="h2">
                    <Translation text={`teams.teamOperation.title.removeUser`} />
                </Typography>
            }
            content={
                <>
                    <AlertTriangle />
                    <Typography className="delete-team-member-description">
                        <Trans
                            i18nKey="teams.teamOperation.description.removeUser"
                            values={{teamName: teamName}}
                            components={[<span className={'bold-text'} key={'team-name'} />]}
                        />
                    </Typography>
                    <Typography color="error" className="delete-team-member-warning">
                        <Translation text="teams.teamOperation.description.warning" />
                    </Typography>
                </>
            }
            footer={
                <Button type="submit" variant="contained" color="primary" fullWidth onClick={() => dispatch(deleteTeamMember(id))}>
                    <Translation text={`teams.teamOperation.buttons.removeUser`} />
                </Button>
            }
        />
    );
};

export default TeamMemberDeleteModal;
