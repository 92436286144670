import type {StepIconProps} from '@mui/material';
import {Avatar, Card, CardHeader, Divider, Grid, Step, StepLabel, Stepper, SvgIcon, Typography} from '@mui/material';
import CheckIcon from '@untitled-ui/icons-react/build/esm/Check';
import {Loader, LoaderType} from 'palipali-panel-common-web';
import type {FC} from 'react';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {createJobWizardStepsArray} from '../../../../store/reducers/createJobViewSlice';
import {currentWizardStepSelector, isCreateJobViewLoadingSelector} from '../../../../store/selectors/createJobViewSelectors';
import CreateJobAdditionalData from '../CreateJobAdditionalData';
import CreateJobGeneralData from '../CreateJobGeneralData';
import CreateJobReceiverData from '../CreateJobReceiverData';
import CreateJobSenderData from '../CreateJobSenderData';
import CreateJobSummary from '../CreateJobSummary';
import CreateJobTeamMemberData from '../CreateJobTeamMember';

const StepIcon: FC<StepIconProps> = (props) => {
    const {active, completed, icon} = props;
    const highlight = active || completed;

    return (
        <Avatar
            sx={{
                height: 40,
                width: 40,
                ...(highlight && {
                    backgroundColor: 'primary.main',
                    color: 'primary.contrastText',
                }),
            }}
            variant="rounded">
            {completed ? (
                <SvgIcon>
                    <CheckIcon />
                </SvgIcon>
            ) : (
                icon
            )}
        </Avatar>
    );
};

export const CreateJobWizard: FC = () => {
    const {t} = useTranslation();
    const currentStep = useSelector(currentWizardStepSelector),
        isLoading = useSelector(isCreateJobViewLoadingSelector);
    const steps = useMemo(() => {
        return [
            {
                label: t('jobs.createJob.wizardSteps.general'),
                content: <CreateJobGeneralData />,
            },
            {
                label: t('jobs.createJob.wizardSteps.sender'),
                content: <CreateJobSenderData />,
            },
            {
                label: t('jobs.createJob.wizardSteps.receiver'),
                content: <CreateJobReceiverData />,
            },
            {
                label: t('jobs.createJob.wizardSteps.team'),
                content: <CreateJobTeamMemberData />,
            },
            {
                label: t('jobs.createJob.wizardSteps.additionalData'),
                content: <CreateJobAdditionalData key={'additionalData'} />,
            },
            {
                label: t('jobs.createJob.wizardSteps.summary'),
                content: <CreateJobSummary />,
            },
        ];
    }, []);
    const currentStepIndex = createJobWizardStepsArray.findIndex((step) => step === currentStep);
    const stepperWrapper = (
        <Stepper activeStep={currentStepIndex} className="stepper-wrapper">
            {steps.map((step, index) => {
                return (
                    <Step key={`step-${index}`} className="stepper-step">
                        <StepLabel StepIconComponent={StepIcon} key={`step-label-${index}`} className="stepper-step-label">
                            <Typography
                                key={`step-label-text-${index}`}
                                variant="caption"
                                color={currentStepIndex === index ? 'text.primary' : 'text.secondary'}
                                className="stepper-step-label-text">
                                {step.label}
                            </Typography>
                        </StepLabel>
                    </Step>
                );
            })}
        </Stepper>
    );

    return (
        <Grid item xs={12} xl={6}>
            <Card className="custom-card new-job-card">
                <CardHeader title={stepperWrapper}></CardHeader>
                <Divider />
                <Grid item xs={12} xl={7}>
                    {steps[currentStepIndex].content}
                </Grid>
                <Loader showLoader={isLoading} type={LoaderType.Local} />
            </Card>
        </Grid>
    );
};
