import {Box, Button, Card, Divider, Stack, Typography} from '@mui/material';
import {LatLngBoundsExpression, LatLngExpression} from 'leaflet';
import 'leaflet-defaulticon-compatibility';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css';
import {
    ChevronLeftIcon,
    CreditCard02,
    IPurchaserCurrentJobDetailsOutput,
    JobPrice,
    JobStatus,
    JobViewAdditionalInfo,
    JobViewDetailsListButton,
    JobViewDetailsListItem,
    JobViewHeader,
    Loader,
    LoaderType,
    LocationPointBox,
    LocationPointBoxType,
    LocationPointType,
    MemberOperationType,
    PhoneComponent,
    SeverityPill,
    Translation,
    authTokenSelector,
    currentlySelectedModalTypeSelector,
    downloadInvoice,
    isModalOpenSelector,
    isUserTeamManagerSelector,
    isUserTeamOwnerSelector,
    openModal,
} from 'palipali-panel-common-web';
import React, {useEffect} from 'react';
import {MapContainer, Marker, useMap} from 'react-leaflet';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams, useLocation} from 'react-router-dom';
import {receiverMarkerIcon, senderMarkerIcon} from 'src/components/shared/mapIcons';
import {
    checkCanBeReported,
    checkHistoricalJob,
    getHistoricalJob,
    redirectToHistoricalJobView,
    returnToInitialJobState,
} from '../../../store/reducers/jobSlice';
import {
    canBeReportedSelector,
    isJobLoadingSelector,
    jobSelector,
    redirectToHistoricalJobViewSelector,
    redirectToJobSelector,
} from '../../../store/selectors/jobSelector';
import CourierDetailsModal from '../../ActiveJobs/ActiveJobView/CourierDetailsModal';
import CustomTileLayer from '../../shared/CustomTileLayer';
import ReportIssueModal from '../../shared/ReportIssueModal';
import {resetToInitialCreateJobViewState} from 'src/store/reducers/createJobViewSlice';
import JobPriceComponent from '../../shared/JobPriceComponent';

const HistoricalJobView: React.FC = () => {
    const {id} = useParams(),
        authToken: string = useSelector(authTokenSelector),
        canBeReported: boolean = useSelector(canBeReportedSelector),
        isModalOpen: boolean = useSelector(isModalOpenSelector),
        modalType = useSelector(currentlySelectedModalTypeSelector),
        redirect: boolean = useSelector(redirectToJobSelector),
        isRedirectToHistoricalJobView = useSelector(redirectToHistoricalJobViewSelector),
        dispatch = useDispatch(),
        isLoading: boolean = useSelector(isJobLoadingSelector),
        job: IPurchaserCurrentJobDetailsOutput | null = useSelector(jobSelector),
        isTeamOwner: boolean = useSelector(job?.team?.id ? isUserTeamOwnerSelector(job.team.id) : () => false),
        isTeamManager: boolean = useSelector(job?.team?.id ? isUserTeamManagerSelector(job.team.id) : () => false),
        isEditEnabled = isTeamOwner || isTeamManager,
        navigate = useNavigate(),
        location = useLocation();

    const isReportModalToggled = modalType === MemberOperationType.HISTORICAL_ORDERS_ORDER_REPORT;
    const canRenewOrder = React.useMemo(() => {
        return isEditEnabled ? true : job?.canDownloadInvoice === true;
    }, [isEditEnabled, job]);
    const SetBounds = ({coords}: {coords: LatLngBoundsExpression}) => {
        const map = useMap();

        useEffect(() => {
            if (map) {
                map.fitBounds(coords, {padding: [25, 25]});
            }
        }, [map, coords]);

        return null;
    };

    useEffect(() => {
        if (modalType === null && !isModalOpen) {
            dispatch(getHistoricalJob(id));
        }
    }, [isReportModalToggled]);

    useEffect(() => {
        if (id) {
            if (location.state?.shouldCheck) {
                dispatch(checkHistoricalJob(id));
            } else {
                dispatch(getHistoricalJob(id));
            }
        }
        return () => {
            dispatch(returnToInitialJobState(null));
        };
    }, [id]);

    useEffect(() => {
        const shouldCheckIsCompleted = location.state?.shouldCheck;
        if ((shouldCheckIsCompleted && job?.isCompleted && id) || (!shouldCheckIsCompleted && id)) {
            dispatch(checkCanBeReported(id));
        }
    }, [job, redirect]);

    useEffect(() => {
        if (isRedirectToHistoricalJobView) {
            dispatch(redirectToHistoricalJobView(false));
        }
    }, [isRedirectToHistoricalJobView]);

    const renderMap = () => {
        if (!job) return;
        const fromLocationLatLng: LatLngExpression = [job.fromLocation.point.latitude, job.fromLocation.point.longitude],
            toLocationLatLng: LatLngExpression = [job.toLocation.point.latitude, job.toLocation.point.longitude];

        return (
            <div className="map-container">
                <MapContainer
                    scrollWheelZoom={false}
                    dragging={false}
                    maxZoom={18}
                    minZoom={2}
                    touchZoom={false}
                    doubleClickZoom={false}
                    zoomControl={false}>
                    <SetBounds coords={[fromLocationLatLng, toLocationLatLng]} />

                    {job.fromLocation.point ? <Marker icon={senderMarkerIcon} position={fromLocationLatLng} /> : null}
                    {job.toLocation.point ? <Marker icon={receiverMarkerIcon} position={toLocationLatLng} /> : null}
                    <CustomTileLayer />
                </MapContainer>
            </div>
        );
    };

    const downloadJobInvoice = () => {
        if (!job?.invoiceId) {
            return;
        }

        return downloadInvoice(job.invoiceId, process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '', authToken, dispatch);
    };

    const renewOrder = () => {
        if (!job) return;
        const jobWithImmediateStart = {
            ...job,
            isImmediateOrder: true,
        };
        dispatch(resetToInitialCreateJobViewState(null));
        navigate('/panel/active-orders/create', {state: {job: jobWithImmediateStart}});
    };

    const renderJobInfo = () => {
        if (!job) return;
        return (
            <div className="job-info">
                <Stack className="job-view-header">
                    <Box className="btn-actions">
                        <Button
                            onClick={() => navigate('/panel/historical-orders')}
                            className="btn btn-action"
                            startIcon={<ChevronLeftIcon />}>
                            <Translation text="jobs.orderDetails.buttons.return" />
                        </Button>
                    </Box>
                    <Divider />
                    <JobViewHeader job={job} />
                </Stack>
                <Divider />
                <div className="location-points">
                    <LocationPointBox type={LocationPointBoxType.HISTORICAL} locationType={LocationPointType.SENDER} job={job} />
                    <LocationPointBox type={LocationPointBoxType.HISTORICAL} locationType={LocationPointType.RECEIVER} job={job} />
                </div>
                <Divider />
                <Box sx={{display: 'flex', flexDirection: 'column', flex: 1}}>
                    <dl className="job-details-list">
                        {job?.deliveryStatus === JobStatus.COMPLETED && job?.isPaymentOnHold ? (
                            <>
                                <JobViewDetailsListItem
                                    label={'jobs.orderDetails.cost'}
                                    value={<JobPrice price={job?.cost} isGrossPrice={true} />}
                                    valueClass={'price'}
                                />
                                <Divider />
                            </>
                        ) : null}
                        <JobViewDetailsListItem label={'jobs.orderDetails.team'} value={job.team.teamName} />
                        <Divider />
                        <JobViewDetailsListItem
                            label={'jobs.orderDetails.customer'}
                            value={
                                job.creator.firstName && job.creator.lastName
                                    ? `${job.creator.firstName} ${job.creator.lastName}`
                                    : undefined
                            }
                        />
                        <Divider />
                        <JobViewDetailsListItem
                            label={'jobs.orderDetails.receiver'}
                            value={
                                job.recipient.firstName && job.recipient.lastName
                                    ? `${job.recipient.firstName} ${job.recipient.lastName}`
                                    : undefined
                            }
                        />
                        <Divider />
                        <JobViewDetailsListItem
                            label={'jobs.orderDetails.sendingPoint'}
                            value={<PhoneComponent phone={job.fromLocation.phone} />}
                        />
                        <Divider />
                        <JobViewDetailsListItem label={'jobs.orderDetails.innerOrderNumber'} value={job.orderNumber} />
                        <Divider />
                        {job.courier && (
                            <>
                                <JobViewDetailsListButton
                                    label={'jobs.jobView.courier'}
                                    value={job.courier.firstName}
                                    onClick={() => dispatch(openModal(MemberOperationType.HISTORICAL_ORDERS_COURIER_DETAILS))}
                                />
                                <Divider />
                            </>
                        )}
                        {canBeReported && job?.deliveryStatus !== JobStatus.CANCELLED_P && (
                            <>
                                <JobViewDetailsListButton
                                    label={'jobs.jobView.report'}
                                    onClick={() => dispatch(openModal(MemberOperationType.HISTORICAL_ORDERS_ORDER_REPORT))}
                                />
                                <Divider />
                            </>
                        )}
                    </dl>
                    <JobViewAdditionalInfo job={job} titleKey={'jobs.orderDetails.additionalInfo'} />
                    {job?.deliveryStatus === JobStatus.CANCELLED_C ||
                    (job?.deliveryStatus === JobStatus.COMPLETED && job?.isPaymentOnHold) ||
                    (job?.deliveryStatus === JobStatus.CANCELLED_P && job?.cost?.amount === '0') ? null : (
                        <Stack>
                            <div className="payment-info-header">
                                <Typography className="payment-info" variant="subtitle1" color="text.primary">
                                    <Translation text={'jobs.createJob.jobSummary.payment'}></Translation>
                                </Typography>
                                {(job?.deliveryStatus === JobStatus.PROBLEM_C || job?.deliveryStatus === JobStatus.PROBLEM_P) &&
                                job?.isPaymentOnHold ? (
                                    <SeverityPill className={`warning payment-badge`}>
                                        <Translation text={`jobs.createJob.jobSummary.paymentOnHold`} />
                                    </SeverityPill>
                                ) : null}
                            </div>
                            <Divider />
                            <Box className={`button-small-font-variant payment-details`}>
                                <Box className="card-details">
                                    <CreditCard02 />
                                    <Typography variant="inherit">
                                        {job?.creditCardNumber ? `**** ${job.creditCardNumber}` : '-'}
                                    </Typography>
                                </Box>
                                <Box>
                                    <JobPriceComponent job={job} />
                                </Box>
                            </Box>
                        </Stack>
                    )}
                </Box>

                <div className="actions-container">
                    {job?.invoiceId && job.canDownloadInvoice === true && (
                        <Button className="invoice-button" onClick={() => downloadJobInvoice()} variant="contained" color="primary">
                            <Translation text="jobs.orderDetails.buttons.downloadInvoice" />
                        </Button>
                    )}
                    {canRenewOrder && (
                        <Button className="renew-order-button" onClick={() => renewOrder()} variant="contained" color="primary">
                            <Translation text="jobs.orderDetails.buttons.renewOrder" />
                        </Button>
                    )}
                </div>
            </div>
        );
    };

    return (
        <Card className="job-view-wrapper">
            <div className="job-view">
                {renderJobInfo()}
                {renderMap()}
                <Loader showLoader={isLoading} type={LoaderType.Local} />
            </div>

            {job && isModalOpen && modalType === MemberOperationType.HISTORICAL_ORDERS_ORDER_REPORT && (
                <ReportIssueModal id={id ? id : null} />
            )}

            {job && isModalOpen && modalType === MemberOperationType.HISTORICAL_ORDERS_COURIER_DETAILS && job?.courier && (
                <CourierDetailsModal courier={job.courier} isRatingShown={true} />
            )}
        </Card>
    );
};

export default HistoricalJobView;
