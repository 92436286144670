import React from 'react';
import {Divider} from '@mui/material';
import {PhoneComponent, ITeamMemberOutput, JobViewDetailsListItem} from 'palipali-panel-common-web';

interface ITeamMemberDetailsListProps {
    teamMember: ITeamMemberOutput;
}

const TeamMemberDetailsList: React.FC<ITeamMemberDetailsListProps> = ({teamMember}) => {
    const firstName: string = teamMember.details?.firstName ? teamMember.details.firstName : '-',
        lastName: string = teamMember.details?.lastName ? teamMember.details.lastName : '-',
        username: string = teamMember.details?.username ? teamMember.details.username : '-';

    return (
        <dl className="job-details-list modal-list">
            <JobViewDetailsListItem label={'teams.teamOperation.editUser.firstName'} value={firstName} />
            <Divider />
            <JobViewDetailsListItem label={'teams.teamOperation.editUser.lastName'} value={lastName} />
            <Divider />
            <JobViewDetailsListItem label={'teams.teamOperation.editUser.email'} value={username} />
            <Divider />
            {teamMember.details?.phone && (
                <>
                    <JobViewDetailsListItem
                        label={'teams.teamOperation.editUser.phone'}
                        value={<PhoneComponent phone={teamMember.details.phone} />}
                    />
                    <Divider />
                </>
            )}
        </dl>
    );
};

export default TeamMemberDetailsList;
