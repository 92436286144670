import {PayloadAction} from '@reduxjs/toolkit';
import {
    createBaseReducerSlice,
    ICreateTeamInput,
    IBaseReducerState,
    IModelApiResponseViewObject,
    initApiCall,
    ILocationOutput,
    PAGINATION_ITEMS_PER_PAGE,
    ITeamViewOutput,
    IUpdateTeamInput,
} from 'palipali-panel-common-web';
import {IPaginationParams} from './locationListingSlice';

export interface ITeamViewState extends IBaseReducerState {
    team: ITeamViewOutput | null;
    canDeleteTeam: boolean;
    canChangePaymentMethod: boolean;
    clientSecret: string | null;
    activeTab: string;
    teamLocationList: ILocationOutput[];
    pagination: IPaginationParams;
    teamLocationFilters: {'team.id': string | undefined};
    metadata: IModelApiResponseViewObject | null;
}

export interface ISetTeam {
    team: ITeamViewOutput | null;
}

export interface ISetRedirectToTeamList {
    redirectToTeamList: boolean;
}

export interface IGetTeam {
    teamId: string;
}

export interface ISetMetadata {
    metadata: IModelApiResponseViewObject | null;
}

export interface ISetLocationListing {
    teamLocationList: ILocationOutput[];
}

export interface IChangeActiveTab {
    activeTab: string;
}

const initialState: ITeamViewState = {
    team: null,
    isLoading: false,
    isInitialized: false,
    error: null,
    canDeleteTeam: false,
    canChangePaymentMethod: false,
    clientSecret: null,
    activeTab: 'details',
    teamLocationList: [],
    pagination: {
        page: 1,
        itemsPerPage: PAGINATION_ITEMS_PER_PAGE,
    },
    teamLocationFilters: {
        'team.id': undefined,
    },
    metadata: null,
};

const teamsViewSlice = createBaseReducerSlice({
    name: 'teamView',
    initialState: initialState,
    reducers: {
        returnToInitialTeamState: () => {
            return {
                ...initialState,
            };
        },
        setTeam: (state: ITeamViewState, action: PayloadAction<ISetTeam>) => {
            state.team = action.payload.team;
            state.teamLocationFilters['team.id'] = action.payload.team?.id;
        },
        setCanDeleteTeam: (state: ITeamViewState, action: PayloadAction<boolean>) => {
            state.canDeleteTeam = action.payload;
        },
        setCanChangePaymentMethod: (state: ITeamViewState, action: PayloadAction<boolean>) => {
            state.canChangePaymentMethod = action.payload;
        },
        setClientSecret: (state: ITeamViewState, action: PayloadAction<string | null>) => {
            state.clientSecret = action.payload;
        },
        changeActiveTab: {
            reducer: (state: ITeamViewState, action: PayloadAction<IChangeActiveTab>) => {
                return {
                    ...state,
                    activeTab: action.payload.activeTab,
                };
            },
            prepare: (activeTab: string) => {
                return {
                    payload: {
                        activeTab,
                    },
                };
            },
        },
        getTeam: {
            reducer: (state: ITeamViewState) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
            prepare(teamId: string) {
                return {
                    payload: {teamId: teamId},
                };
            },
        },
        getClientSecret: {
            reducer: (state: ITeamViewState) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
            prepare(paymentAccountId: string) {
                return {
                    payload: {paymentAccountId: paymentAccountId},
                };
            },
        },
        createTeam: {
            reducer: (state: ITeamViewState) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
            prepare(team: ICreateTeamInput) {
                return {
                    payload: {team: team},
                };
            },
        },
        deleteTeam: {
            reducer: (state: ITeamViewState) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
            prepare: (id: string) => {
                return {
                    payload: {
                        id: id,
                    },
                };
            },
        },
        updateTeam: {
            reducer: (state: ITeamViewState) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
            prepare(id: string, team: IUpdateTeamInput) {
                return {
                    payload: {id: id, team: team},
                };
            },
        },
        checkCanDeleteTeam: {
            reducer: (state: ITeamViewState) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
            prepare: (id: string) => {
                return {
                    payload: id,
                };
            },
        },
        checkCanChangeTeamPaymentMethod: {
            reducer: (state: ITeamViewState) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
            prepare: (id: string) => {
                return {
                    payload: id,
                };
            },
        },
        deletePaymentMethod: {
            reducer: (state: ITeamViewState) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
            prepare: (paymentAccountId: string, systemPaymentMethodId: string) => {
                return {
                    payload: {
                        paymentAccountId: paymentAccountId,
                        systemPaymentMethodId: systemPaymentMethodId,
                    },
                };
            },
        },
        setTeamLocationListing: (state: ITeamViewState, action: PayloadAction<ISetLocationListing>) => {
            return {
                ...state,
                teamLocationList: action.payload.teamLocationList,
                isLoading: false,
            };
        },
        setMetadata: (state: ITeamViewState, action: PayloadAction<ISetMetadata>) => {
            return {
                ...state,
                metadata: action.payload.metadata,
            };
        },
        changeTeamLocationListingPagination: (state: ITeamViewState, action: PayloadAction<IPaginationParams>) => {
            return {
                ...state,
                pagination: action.payload,
                isLoading: true,
            };
        },
        getTeamLocationListing: (state: ITeamViewState) => initApiCall(state),
    },
});

export const {
    setTeam,
    setLoading,
    setError,
    setInitialized,
    getTeam,
    updateTeam,
    changeActiveTab,
    createTeam,
    setRedirectToTeamList,
    returnToInitialTeamState,
    setCanDeleteTeam,
    checkCanDeleteTeam,
    checkCanChangeTeamPaymentMethod,
    setCanChangePaymentMethod,
    deleteTeam,
    deletePaymentMethod,
    setTeamLocationListing,
    setMetadata,
    changeTeamLocationListingPagination,
    getTeamLocationListing,
    setClientSecret,
    getClientSecret,
} = teamsViewSlice.actions;
export default teamsViewSlice.reducer;
