import {Translation} from 'palipali-panel-common-web';
import React from 'react';
import {Badge} from 'reactstrap';

interface ITeamMemberBadgeProps {
    role: string | null;
}

const TeamMemberBadge: React.FC<ITeamMemberBadgeProps> = ({role}) => {
    const renderTeamMemberStatus = (role: string | null) => {
        if (!role) {
            return <span> -- </span>;
        }
        return (
            <Badge className={`team-member-badge ${role}`} pill>
                <Translation text={`roles.${role}`} />
            </Badge>
        );
    };

    return renderTeamMemberStatus(role);
};

export default TeamMemberBadge;
