import {
    AlertType,
    DetailsSuccessActionsFunction,
    ILocationOutput,
    addAlert,
    closeModal,
    createLocationAPI,
    createOperationEpic,
    deleteLocationAPI,
    getTeamListing,
    handleApiError,
    updateLocationAPI,
    authTokenSelector,
    ILocationInput,
    checkMobileNumberAPI,
} from 'palipali-panel-common-web';
import {push} from 'react-router-redux';
import {combineEpics, Epic, ofType} from 'redux-observable';
import {
    createJobLocation,
    createLocation,
    createTeamLocation,
    deleteLocation,
    setError,
    setLoading,
    setLocation,
    updateLocation,
} from '../reducers/locationViewSlice';
import {changeActiveTab} from '../reducers/teamViewSlice';
import {updateCurrentStepLocation} from '../reducers/createJobViewSlice';
import {catchError, mergeMap, switchMap} from 'rxjs/operators';
import {PayloadAction} from '@reduxjs/toolkit';
import {of} from 'rxjs';

const createLocationSuccessActions: DetailsSuccessActionsFunction<ILocationOutput> = () => {
    return [addAlert({message: 'locations.locationOperation.locationCreated'}), setLoading(false), push('/panel/locations')];
};

const createTeamLocationSuccessActions: DetailsSuccessActionsFunction<ILocationOutput> = (location: ILocationOutput) => {
    const urlToTeamLocationList = `/panel/teams/edit/${location.teamId}`;
    return [
        addAlert({message: 'locations.locationOperation.teamLocationCreated'}),
        setLoading(false),
        getTeamListing(),
        changeActiveTab('locations'),
        push(urlToTeamLocationList),
    ];
};
const createJobLocationSuccessActions: DetailsSuccessActionsFunction<ILocationOutput> = (location) => {
    const urlToCreateJobWizard = `/panel/active-orders/create`;
    return [
        addAlert({message: 'locations.locationOperation.locationCreated'}),
        setLoading(false),
        push(urlToCreateJobWizard),
        updateCurrentStepLocation(location),
    ];
};

const updateLocationSuccessActions: DetailsSuccessActionsFunction<ILocationOutput> = (location: ILocationOutput) => {
    return [addAlert({message: 'locations.locationOperation.locationUpdated'}), setLoading(false), setLocation(location)];
};

const deleteLocationSuccessActions: DetailsSuccessActionsFunction<null> = () => {
    return [addAlert({message: 'locations.locationOperation.locationDeleted'}), setLoading(false), closeModal(), push('/panel/locations')];
};

const errorActions = (error: any): any[] => {
    const errorObj = handleApiError(error);
    errorObj.type = AlertType.WARNING;
    return [setLoading(false), setError(errorObj.message), addAlert(errorObj)];
};

const createJobLocationEpic = createOperationEpic<ILocationOutput>(
    createLocationAPI,
    createJobLocationSuccessActions,
    errorActions,
    createJobLocation().type
);

const createTeamLocationEpic: Epic = (action$, state$) =>
    action$.pipe(
        ofType(createTeamLocation().type),
        switchMap((action: PayloadAction<{location: ILocationInput}>) => {
            const phoneNumber = action.payload.location.phone,
                authToken = authTokenSelector(state$.value);

            if (phoneNumber !== null) {
                return checkMobileNumberAPI(authToken, phoneNumber.country, phoneNumber.phone).pipe(
                    switchMap(() => {
                        return createLocationAPI(authToken, {location: action.payload.location}).pipe(
                            mergeMap((res) => of(...createTeamLocationSuccessActions(res)))
                        );
                    }),
                    catchError(() => {
                        return of(addAlert({message: 'formValidation.errors.isPhoneValid', type: AlertType.ERROR}), setLoading(false));
                    })
                );
            } else {
                return createLocationAPI(authToken, {location: action.payload.location}).pipe(
                    mergeMap((res) => of(...createTeamLocationSuccessActions(res))),
                    catchError((error) => {
                        return errorActions(error);
                    })
                );
            }
        })
    );

const createLocationEpic: Epic = (action$, state$) =>
    action$.pipe(
        ofType(createLocation().type),
        switchMap((action: PayloadAction<{location: ILocationInput}>) => {
            const phoneNumber = action.payload.location.phone,
                authToken = authTokenSelector(state$.value);

            if (phoneNumber !== null) {
                return checkMobileNumberAPI(authToken, phoneNumber.country, phoneNumber.phone).pipe(
                    switchMap(() => {
                        return createLocationAPI(authToken, {location: action.payload.location}).pipe(
                            mergeMap((res) => of(...createLocationSuccessActions(res)))
                        );
                    }),
                    catchError(() => {
                        return of(addAlert({message: 'formValidation.errors.isPhoneValid', type: AlertType.ERROR}), setLoading(false));
                    })
                );
            } else {
                return createLocationAPI(authToken, {location: action.payload.location}).pipe(
                    mergeMap((res) => of(...createLocationSuccessActions(res))),
                    catchError((error) => {
                        return errorActions(error);
                    })
                );
            }
        })
    );

const updateLocationEpic: Epic = (action$, state$) =>
    action$.pipe(
        ofType(updateLocation().type),
        switchMap((action: PayloadAction<{id: string; location: ILocationInput}>) => {
            const phoneNumber = action.payload.location.phone,
                authToken = authTokenSelector(state$.value);

            if (phoneNumber !== null) {
                return checkMobileNumberAPI(authToken, phoneNumber.country, phoneNumber.phone).pipe(
                    switchMap(() => {
                        return updateLocationAPI(authToken, {id: action.payload.id, location: action.payload.location}).pipe(
                            mergeMap((res) => of(...updateLocationSuccessActions(res)))
                        );
                    }),
                    catchError(() => {
                        return of(addAlert({message: 'formValidation.errors.isPhoneValid', type: AlertType.ERROR}), setLoading(false));
                    })
                );
            } else {
                return updateLocationAPI(authToken, {id: action.payload.id, location: action.payload.location}).pipe(
                    mergeMap((res) => of(...updateLocationSuccessActions(res))),
                    catchError((error) => {
                        return errorActions(error);
                    })
                );
            }
        })
    );

const deleteLocationEpic = createOperationEpic<null>(deleteLocationAPI, deleteLocationSuccessActions, errorActions, deleteLocation().type);

const locationViewEpic = combineEpics(
    createLocationEpic,
    createTeamLocationEpic,
    createJobLocationEpic,
    updateLocationEpic,
    deleteLocationEpic
);

export default locationViewEpic;
