import {
    AlertType,
    CommonRootState,
    DetailsSuccessActionsFunction,
    StateSuccessActionsFunction,
    ITeamViewOutput,
    addAlert,
    authTokenSelector,
    closeModal,
    createActionPayloadEpic,
    createOperationEpic,
    createTeamAPI,
    deleteTeamAPI,
    updateDetachPaymentMethodAPI,
    getTeamListing,
    handleApiError,
    refreshTokenSelector,
    renewAuthToken,
    updateTeamAPI,
} from 'palipali-panel-common-web';
import {push} from 'react-router-redux';
import {combineEpics} from 'redux-observable';
import {RootState} from '../reducers';
import {
    checkCanChangeTeamPaymentMethod,
    createTeam,
    deletePaymentMethod,
    deleteTeam,
    getTeam,
    setError,
    setLoading,
    setTeam,
    updateTeam,
} from '../reducers/teamViewSlice';
import {teamSelector} from '../selectors/teamViewSelectors';

const basicOperationSuccessActions = (successMessage: string) => [
    getTeamListing(),
    setLoading(false),
    addAlert({message: successMessage, type: AlertType.SUCCESS}),
    push('/panel/teams'),
];

const teamOperationUpdateSuccessActions: DetailsSuccessActionsFunction<ITeamViewOutput> = (updatedTeam: ITeamViewOutput) => {
    return [setTeam({team: updatedTeam}), ...basicOperationSuccessActions('teams.teamOperation.alerts.update.success')];
};

const teamOperationCreateSuccessActions: StateSuccessActionsFunction<ITeamViewOutput> = (
    createdTeam: ITeamViewOutput,
    state: CommonRootState
) => {
    const authToken = authTokenSelector(state),
        refreshToken = refreshTokenSelector(state);

    return [
        setTeam({team: createdTeam}),
        getTeamListing(),
        setLoading(false),
        addAlert({message: 'teams.teamOperation.alerts.create.success', type: AlertType.SUCCESS}),
        push(`/panel/teams/edit/${createdTeam.id}`),
        renewAuthToken(refreshToken, authToken, true),
    ];
};

const teamOperationDeleteSuccessActions: DetailsSuccessActionsFunction<ITeamViewOutput> = () => {
    return [
        setTeam({team: null}),
        ...basicOperationSuccessActions('teams.teamOperation.alerts.delete.success'),
        closeModal(),
        push('/panel/teams'),
    ];
};

const teamOperationDeletePaymentMethodSuccessActions: StateSuccessActionsFunction<null> = (resp, state: CommonRootState) => {
    const team = teamSelector(state as RootState);

    return [
        getTeam(team?.id),
        checkCanChangeTeamPaymentMethod(team?.id),
        setLoading(false),
        closeModal(),
        addAlert({message: 'alerts.paymentMethodDeleted', type: AlertType.SUCCESS}),
    ];
};

const errorActions = (error: any): any[] => {
    const errorObj = handleApiError(error);
    errorObj.type = AlertType.WARNING;
    return [setLoading(false), setError(errorObj.message), addAlert(errorObj)];
};

const teamOperationDeletePaymentMethodErrorActions = (error: any): any[] => {
    return [closeModal(), ...errorActions(error)];
};

const teamUpdateEpic = createActionPayloadEpic<ITeamViewOutput>(
    updateTeamAPI,
    teamOperationUpdateSuccessActions,
    errorActions,
    updateTeam().type
);

const teamCreateEpic = createActionPayloadEpic<ITeamViewOutput>(
    createTeamAPI,
    teamOperationCreateSuccessActions,
    errorActions,
    createTeam().type
);

const teamDeleteEpic = createOperationEpic<ITeamViewOutput>(
    deleteTeamAPI,
    teamOperationDeleteSuccessActions,
    errorActions,
    deleteTeam().type
);

const deleteTeamPaymentMethodEpic = createActionPayloadEpic<null>(
    updateDetachPaymentMethodAPI,
    teamOperationDeletePaymentMethodSuccessActions,
    teamOperationDeletePaymentMethodErrorActions,
    deletePaymentMethod().type
);

const teamOperationEpic = combineEpics(teamUpdateEpic, teamCreateEpic, teamDeleteEpic, deleteTeamPaymentMethodEpic);

export default teamOperationEpic;
