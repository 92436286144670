import {
    FormikFieldTypes,
    FormikFormControlType,
    FormikGroupFieldConfig,
    FormikRegexSettings,
    IMultiselectOption,
    TextMaskType,
    ValidationErrorType,
    selectPhoneCodeList,
    isPhoneNumberTypeMobile,
} from 'palipali-panel-common-web';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';

const recipientDataFormConfig = (isNewTeamMember: boolean, teamMembers: IMultiselectOption[] | []): FormikGroupFieldConfig[] => {
    const {t} = useTranslation();

    const baseFormFields: FormikGroupFieldConfig[] = [
        {
            formikControlType: FormikFormControlType.GROUP_CONTROL,
            fields: [
                {
                    name: 'isNewTeamMember',
                    label: t('jobs.createJob.recipientData.formControls.provideRecipientData'),
                    type: FormikFieldTypes.SWITCH,
                    className: 'switch-form-control-wrapper create-job-recipient-data-provide-switch',
                    validation: Yup.boolean(),
                },
            ],
        },
    ];

    if (isNewTeamMember) {
        baseFormFields.push(
            {
                formikControlType: FormikFormControlType.GROUP_CONTROL,
                fields: [
                    {
                        name: 'firstName',
                        label: t('jobs.createJob.recipientData.formControls.firstName'),
                        validation: Yup.string()
                            .test('noMoreThanOne', `${t(ValidationErrorType.IS_NO_REPEATED_SPECIAL_CHARACTERS)}`, (value) => {
                                const dashRegExp = /-.*-/;
                                const singleQuoteRegExp = /'.*'/;
                                const result = dashRegExp.exec(value || '') || singleQuoteRegExp.exec(value || '');
                                if (result) {
                                    return false;
                                }
                                return true;
                            })
                            .required(`${t(ValidationErrorType.IS_REQUIRED)}`)
                            .matches(FormikRegexSettings.LETTERS_AND_DASH, `${t(ValidationErrorType.IS_ONLY_LETTERS)}`)
                            .matches(
                                FormikRegexSettings.MUST_CONTAIN_LETTER_WITHOUT_WHITESPACES,
                                `${t(ValidationErrorType.IS_CONTAINING_LETTER_NOT_STARTING_WITH_WHITESPACE)}`
                            )
                            .matches(
                                FormikRegexSettings.NO_REPEATED_DASH_IN_THE_MIDDLE,
                                `${t(ValidationErrorType.IS_NO_REPEATED_SPECIAL_CHARACTERS)}`
                            )
                            .matches(FormikRegexSettings.NO_DASH_END, `${t(ValidationErrorType.IS_NOT_DASH_ENDED)}`),
                        type: FormikFieldTypes.TEXT,
                        isRequired: true,
                        lettersOnly: true,
                    },
                ],
            },
            {
                formikControlType: FormikFormControlType.GROUP_CONTROL,
                fields: [
                    {
                        name: 'lastName',
                        label: t('jobs.createJob.recipientData.formControls.lastName'),
                        validation: Yup.string()
                            .test('noMoreThanOne', `${t(ValidationErrorType.IS_NO_REPEATED_SPECIAL_CHARACTERS)}`, (value) => {
                                const dashRegExp = /-.*-/;
                                const singleQuoteRegExp = /'.*'/;
                                const result = dashRegExp.exec(value || '') || singleQuoteRegExp.exec(value || '');
                                if (result) {
                                    return false;
                                }
                                return true;
                            })
                            .matches(FormikRegexSettings.LETTERS_AND_DASH, `${t(ValidationErrorType.IS_ONLY_LETTERS)}`)
                            .matches(
                                FormikRegexSettings.NO_REPEATED_DASH_IN_THE_MIDDLE,
                                `${t(ValidationErrorType.IS_NO_REPEATED_SPECIAL_CHARACTERS)}`
                            )
                            .matches(
                                FormikRegexSettings.MUST_CONTAIN_LETTER_WITHOUT_WHITESPACES,
                                `${t(ValidationErrorType.IS_CONTAINING_LETTER_NOT_STARTING_WITH_WHITESPACE)}`
                            )
                            .matches(FormikRegexSettings.NO_DASH_END, `${t(ValidationErrorType.IS_NOT_DASH_ENDED)}`)
                            .required(`${t(ValidationErrorType.IS_REQUIRED)}`),
                        type: FormikFieldTypes.TEXT,
                        isRequired: true,
                        lettersOnly: true,
                    },
                ],
            },
            {
                formikControlType: FormikFormControlType.GROUP_CONTROL,
                controlsPerRow: 2,
                fields: [
                    {
                        name: 'country',
                        label: `${t('auth.formControls.dialingCode')}`,
                        validation: Yup.string().required(`${t(ValidationErrorType.IS_REQUIRED)}`),
                        type: FormikFieldTypes.SELECT,
                        className: `account-profile-phone-code`,
                        formControlClassName: 'phone-code-select',
                        flexSettings: '0',
                        isRequired: true,
                        options: selectPhoneCodeList,
                    },
                    {
                        name: 'phone',
                        label: t('jobs.createJob.recipientData.formControls.phone'),
                        validation: Yup.string()
                            .test('is-valid-phone', `${t(ValidationErrorType.IS_MOBILE_NUMBER)}`, function (value) {
                                const {country} = this.parent;

                                if (!value) return true;

                                try {
                                    return isPhoneNumberTypeMobile(country, value);
                                } catch (error) {
                                    return false;
                                }
                            })
                            .required(`${t(ValidationErrorType.IS_REQUIRED)}`),
                        type: FormikFieldTypes.MASKED,
                        textMaskType: TextMaskType.PHONE,
                        flexSettings: '1',
                        isRequired: true,
                    },
                ],
            }
        );
    } else if (!isNewTeamMember) {
        baseFormFields.push({
            formikControlType: FormikFormControlType.GROUP_CONTROL,
            fields: [
                {
                    name: 'teamMember',
                    label: t('jobs.createJob.recipientData.formControls.selectRecipient'),
                    className: 'job-listing-status-filter-select-team',
                    validation: Yup.string().required(`${t(ValidationErrorType.IS_REQUIRED)}`),
                    type: FormikFieldTypes.SELECT,
                    options: teamMembers,
                },
            ],
        });
    }
    return baseFormFields;
};
export default recipientDataFormConfig;
