import {Box, Divider, Stack, Tab, Tabs, Typography, useTheme} from '@mui/material';
import {CustomModalComponent, ILocationOutput, Loader, LoaderType, Translation, teamByIdSelector} from 'palipali-panel-common-web';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {createJobGeneralDataSelector, isLocationsLoadingSelector} from '../../../../../store/selectors/createJobViewSelectors';
import CustomTabPanel from '../../../../shared/CustomTabPanel';
import {AddressBookType} from '../index';
import LocationsSelect from './LocationsSelect';

interface ILocationsModalProps {
    activeTab: AddressBookType;
    onSelectLocation: (location: ILocationOutput) => void;
}

const LocationsModal: React.FC<ILocationsModalProps> = ({activeTab, onSelectLocation}) => {
    const {t} = useTranslation(),
        generalData = useSelector(createJobGeneralDataSelector),
        isLoading = useSelector(isLocationsLoadingSelector),
        selectedTeam = useSelector((state) => teamByIdSelector(state, generalData?.teamId)),
        [selectedTab, setSelectedTab] = useState<AddressBookType>(activeTab),
        [value, setValue] = React.useState(0),
        theme = useTheme();

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        setSelectedTab(newValue === 0 ? AddressBookType.ADDRESS_BOOK : AddressBookType.AUTHORIZED_WAREHOUSES);
    };

    const onLocationSelected = (location: ILocationOutput) => {
        onSelectLocation(location);
    };

    const tabProps = (index: number) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    };

    return (
        <CustomModalComponent
            theme={theme}
            customClasses="address-book-modal"
            title={
                <Typography color="text.primary" variant="h6" component="h2">
                    <Translation text="jobs.createJob.senderData.addressBookModal.title" />
                </Typography>
            }
            content={
                <Stack className="address-book-modal-content-wrapper">
                    <Loader showLoader={isLoading} type={LoaderType.Local} />
                    <Stack>
                        <Typography sx={{textTransform: 'uppercase'}} color="text.primary" variant="subtitle1">
                            {selectedTeam?.name}
                        </Typography>
                        <Divider />
                    </Stack>

                    <Box sx={{borderBottom: 1, borderColor: 'divider'}} className="address-book-tabs">
                        <Tabs value={value} onChange={handleChange} className="tab-controls" aria-label="Address book tabs">
                            <Tab
                                key="address-book-modal-title"
                                className="create-job-address-book-tab-address-book"
                                label={t('jobs.createJob.senderData.addressBookModal.title')}
                                {...tabProps(0)}
                            />
                            <Tab
                                key="authorized-warehouses"
                                className="create-job-address-book-tab-authorized-warehouses"
                                label={t('jobs.createJob.senderData.addressBookModal.authorizedWarehouses')}
                                {...tabProps(1)}
                            />
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                        <LocationsSelect onSelectLocation={onLocationSelected} addressBookTab={selectedTab} />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        <LocationsSelect onSelectLocation={onLocationSelected} addressBookTab={selectedTab} />
                    </CustomTabPanel>
                </Stack>
            }
        />
    );
};

export default LocationsModal;
