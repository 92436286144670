import {Tab, Tabs, Typography} from '@mui/material';
import {Translation} from 'palipali-panel-common-web';
import * as React from 'react';
import {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import ProfileTab from './ProfileTab';
import SettingsTab from './SettingsTab';

const tabs = [
    {label: 'account.subtitle.profile', value: 'profile'},
    {label: 'account.subtitle.settings', value: 'settings'},
];

const Account: React.FC = () => {
    const [currentTab, setCurrentTab] = useState<string>('profile');
    const handleTabsChange = useCallback((event: React.SyntheticEvent<Element, Event>, value: string): void => {
        setCurrentTab(value);
    }, []);
    const renderTabContent = (tab: string): JSX.Element => {
        switch (tab) {
            case 'profile':
                return <ProfileTab />;
            case 'settings':
                return <SettingsTab />;
            default:
                return <></>;
        }
    };
    const {t} = useTranslation();

    return (
        <div className="content-view">
            <div className="header">
                <div className="title">
                    <Typography variant="h4" component="h2" color="text.primary">
                        <Translation text="account.title" />
                    </Typography>
                </div>
            </div>
            <div className="tabs-wrapper">
                <Tabs
                    indicatorColor="primary"
                    onChange={handleTabsChange}
                    scrollButtons="auto"
                    sx={{px: 3}}
                    textColor="primary"
                    value={currentTab}
                    variant="scrollable">
                    {tabs.map((tab) => (
                        <Tab
                            key={tab.value}
                            label={t(tab.label)}
                            value={tab.value}
                            className={`account-settings-tab-button-${tab.value}`}
                        />
                    ))}
                </Tabs>
            </div>
            {renderTabContent(currentTab)}
        </div>
    );
};

export default Account;
