import {FormikFieldTypes, FormikFormControlType, FormikGroupFieldConfig, ValidationErrorType} from 'palipali-panel-common-web';
import * as Yup from 'yup';
import {useTranslation} from 'react-i18next';

const boxSizesFormConfig = (): FormikGroupFieldConfig[] => {
    const {t} = useTranslation();

    return [
        {
            formikControlType: FormikFormControlType.GROUP_CONTROL,
            controlsPerRow: 2,
            fields: [
                {
                    name: 'length',
                    label: t('jobs.createJob.generalData.boxDimensionsModal.formControls.length'),
                    validation: Yup.number()
                        .typeError(`${t(ValidationErrorType.IS_NUMBER)}`)
                        .integer(`${t(ValidationErrorType.IS_INTEGER)}`)
                        .min(1, `${t(ValidationErrorType.IS_POSITIVE_NUMBER)}`)
                        .max(999, `${t(ValidationErrorType.IS_LENGTH_VALID)}`)
                        .required(`${t(ValidationErrorType.IS_REQUIRED)}`),
                    type: FormikFieldTypes.NUMBER,
                    maxNumberLength: 3,
                    flexSettings: '1',
                    endAdornment: 'cm',
                },
                {
                    name: 'height',
                    label: t('jobs.createJob.generalData.boxDimensionsModal.formControls.height'),
                    validation: Yup.number()
                        .typeError(`${t(ValidationErrorType.IS_NUMBER)}`)
                        .integer(`${t(ValidationErrorType.IS_INTEGER)}`)
                        .min(1, `${t(ValidationErrorType.IS_POSITIVE_NUMBER)}`)
                        .max(999, `${t(ValidationErrorType.IS_LENGTH_VALID)}`)
                        .required(`${t(ValidationErrorType.IS_REQUIRED)}`),
                    type: FormikFieldTypes.NUMBER,
                    flexSettings: '1',
                    maxNumberLength: 3,
                    endAdornment: 'cm',
                },
            ],
        },
        {
            formikControlType: FormikFormControlType.GROUP_CONTROL,
            controlsPerRow: 2,
            fields: [
                {
                    name: 'width',
                    label: t('jobs.createJob.generalData.boxDimensionsModal.formControls.width'),
                    validation: Yup.number()
                        .typeError(`${t(ValidationErrorType.IS_NUMBER)}`)
                        .integer(`${t(ValidationErrorType.IS_INTEGER)}`)
                        .min(1, `${t(ValidationErrorType.IS_POSITIVE_NUMBER)}`)
                        .max(999, `${t(ValidationErrorType.IS_LENGTH_VALID)}`)
                        .required(`${t(ValidationErrorType.IS_REQUIRED)}`),
                    type: FormikFieldTypes.NUMBER,
                    flexSettings: '1',
                    maxNumberLength: 3,
                    endAdornment: 'cm',
                },
                {
                    name: 'weight',
                    label: t('jobs.createJob.generalData.boxDimensionsModal.formControls.weight'),
                    validation: Yup.number()
                        .typeError(`${t(ValidationErrorType.IS_NUMBER)}`)
                        .integer(`${t(ValidationErrorType.IS_INTEGER)}`)
                        .min(1, `${t(ValidationErrorType.IS_POSITIVE_NUMBER)}`)
                        .max(999, `${t(ValidationErrorType.IS_LENGTH_VALID)}`)
                        .required(`${t(ValidationErrorType.IS_REQUIRED)}`),
                    type: FormikFieldTypes.NUMBER,
                    flexSettings: '1',
                    maxNumberLength: 3,
                    endAdornment: 'kg',
                },
            ],
        },
    ];
};
export default boxSizesFormConfig;
