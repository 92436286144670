import {IconButton} from '@mui/material';
import {
    IPurchaserCurrentJobDetailsOutput,
    isUserTeamManagerSelector,
    isUserTeamOwnerSelector,
    MemberOperationType,
    openModal,
    RedTrashIcon,
} from 'palipali-panel-common-web';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {canBeCancelledSelector, canBeDeletedSelector} from '../../../../store/selectors/jobSelector';

interface IJobViewDetailsProps {
    job: IPurchaserCurrentJobDetailsOutput;
}

const ActiveJobActionButton: React.FC<IJobViewDetailsProps> = ({job}) => {
    const canBeCancelled: boolean = useSelector(canBeCancelledSelector),
        canBeDeleted: boolean = useSelector(canBeDeletedSelector),
        dispatch = useDispatch();
    const isTeamOwner = useSelector(isUserTeamOwnerSelector(job.team.id)),
        isTeamManager = useSelector(isUserTeamManagerSelector(job.team.id)),
        isEditEnabled = isTeamOwner || isTeamManager;

    const handleAction = () => {
        if (canBeCancelled) {
            dispatch(openModal(MemberOperationType.ACTIVE_ORDERS_ORDER_CANCEL));
        }
        if (canBeDeleted && isEditEnabled) {
            dispatch(openModal(MemberOperationType.ACTIVE_ORDERS_ORDER_DELETE));
        }
    };

    if (!canBeCancelled && !canBeDeleted) {
        return null;
    }

    return (
        <IconButton onClick={() => handleAction()}>
            <RedTrashIcon />
        </IconButton>
    );
};

export default ActiveJobActionButton;
