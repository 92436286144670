import {Button, useTheme} from '@mui/material';
import {
    AvatarUploadComponent,
    FormikForm,
    HorizontalCard,
    IAccountBasicInfoOutput,
    IModelCity,
    IMultiselectOption,
    IUpdateAccountProfile,
    Translation,
    accountInfoSelector,
    accountIsLoadingSelector,
    accountSuccessfulSelector,
    citiesSelector,
    getFullAccount,
    updateAccountProfile,
    ValidationErrorType,
    FormikRegexSettings,
    isPhoneNumberValid,
} from 'palipali-panel-common-web';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Subscription} from 'rxjs';
import profileFormConfig from './profileFormConfig';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {useTranslation} from 'react-i18next';

const ProfileTab: React.FC = () => {
    const {t} = useTranslation();
    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .max(255)
            .matches(FormikRegexSettings.EMAIL, `${t(ValidationErrorType.IS_EMAIL)}`)
            .required(`${t(ValidationErrorType.IS_REQUIRED)}`),
        firstName: Yup.string()
            .max(255)
            .test('noMoreThanOne', `${t(ValidationErrorType.IS_NO_REPEATED_SPECIAL_CHARACTERS)}`, (value) => {
                const dashRegExp = /-.*-/;
                const singleQuoteRegExp = /'.*'/;
                const result = dashRegExp.exec(value || '') || singleQuoteRegExp.exec(value || '');
                if (result) {
                    return false;
                }
                return true;
            })
            .matches(FormikRegexSettings.LETTERS_AND_DASH, `${t(ValidationErrorType.IS_ONLY_LETTERS)}`)
            .matches(FormikRegexSettings.NO_REPEATED_DASH_IN_THE_MIDDLE, `${t(ValidationErrorType.IS_NO_REPEATED_SPECIAL_CHARACTERS)}`)
            .matches(FormikRegexSettings.NO_DASH_END, `${t(ValidationErrorType.IS_NOT_DASH_ENDED)}`)
            .required(`${t(ValidationErrorType.IS_REQUIRED)}`),
        lastName: Yup.string()
            .max(255)
            .matches(FormikRegexSettings.LETTERS_AND_DASH, `${t(ValidationErrorType.IS_ONLY_LETTERS)}`)
            .matches(FormikRegexSettings.NO_REPEATED_DASH_IN_THE_MIDDLE, `${t(ValidationErrorType.IS_NO_REPEATED_SPECIAL_CHARACTERS)}`)
            .matches(FormikRegexSettings.NO_DASH_END, `${t(ValidationErrorType.IS_NOT_DASH_ENDED)}`)
            .required(`${t(ValidationErrorType.IS_REQUIRED)}`),

        country: Yup.string().required(`${t(ValidationErrorType.IS_REQUIRED)}`),
        phone: Yup.string()
            .test('is-valid-phone', `${t(ValidationErrorType.IS_PHONE)}`, function (value) {
                const {country} = this.parent;
                if (!value) return true;

                try {
                    return isPhoneNumberValid(country, value);
                } catch (error) {
                    return false;
                }
            })
            .required(`${t(ValidationErrorType.IS_REQUIRED)}`),
        cityId: Yup.string().required(`${t(ValidationErrorType.IS_REQUIRED)}`),
    });

    const cities: IModelCity[] = useSelector(citiesSelector),
        accountInfo: IAccountBasicInfoOutput = useSelector(accountInfoSelector),
        isLoading: boolean = useSelector(accountIsLoadingSelector),
        isActionSuccessful: boolean = useSelector(accountSuccessfulSelector),
        [isSubmitAllowed, setIsSubmitAllowed] = useState<boolean>(false),
        [cityList, setCityList] = useState<IMultiselectOption[]>([]),
        subscriptions: Subscription[] = [],
        dispatch = useDispatch(),
        theme = useTheme();
    const initialValues = {
        email: accountInfo?.username ? accountInfo.username : '',
        firstName: accountInfo?.firstName ? accountInfo.firstName : '',
        lastName: accountInfo?.lastName ? accountInfo.lastName : '',
        country: accountInfo?.phone?.country ? accountInfo.phone.country : '+48',
        phone: accountInfo?.phone?.phone ? accountInfo.phone.phone : '',
        cityId: accountInfo?.cityId ? accountInfo.cityId : '',
        returnUrl: `${process.env.REACT_APP_URL}/auth/confirm-email`,
    };

    useEffect(() => {
        if (cities && cities.length > 0) {
            const cityList = cities.map((city: IModelCity): IMultiselectOption => {
                const option: IMultiselectOption = {
                    value: city.id,
                    label: city.name,
                };
                return option;
            });
            setCityList(cityList);
        }
    }, [cities]);

    useEffect(() => {
        dispatch(getFullAccount());
        return () => {
            subscriptions.forEach((subscription) => subscription.unsubscribe());
        };
    }, [isActionSuccessful]);

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            const updateAccountPayload: IUpdateAccountProfile = {
                username: values.email,
                firstName: values.firstName,
                lastName: values.lastName,
                country: values.country,
                phone: values.phone,
                cityId: values.cityId,
                returnUrl: values.returnUrl,
            };
            dispatch(updateAccountProfile(updateAccountPayload));
        },
    });

    const handleChange = (formControl: string, value: string) => {
        formik.setValues({
            ...formik.values,
            [formControl]: value,
        });

        if (formControl === 'country') {
            formik.setFieldTouched('phone', true, false); // Optional: mark phone as touched if you need to show validation errors immediately
            formik.validateField('phone');
        }
    };

    return (
        <HorizontalCard
            heading={<Translation text="profileEdit.title" />}
            content={
                <>
                    <AvatarUploadComponent />
                    <FormikForm
                        fields={profileFormConfig(cityList)}
                        formId="profile-edit-form"
                        initialValues={initialValues}
                        updatedValues={formik.values}
                        theme={theme}
                        shouldValidate={formik.dirty}
                        submitAllowed={setIsSubmitAllowed}
                        customEventChange={handleChange}
                        onSubmit={formik.handleSubmit}
                    />
                </>
            }
            actions={
                <Button
                    type="submit"
                    variant="outlined"
                    color="primary"
                    className="btn-action account-profile-submit-button"
                    form="profile-edit-form"
                    disabled={!isSubmitAllowed}>
                    <Translation text="buttons.update" />
                </Button>
            }
            showLoader={isLoading}
        />
    );
};

export default ProfileTab;
