import {Button, ButtonProps} from '@mui/material';
import React, {useState, useEffect} from 'react';
import {Translation} from 'palipali-panel-common-web';

interface ITimerButtonProps extends ButtonProps {
    countdown: number;
    onPressAction: () => void;
    onFinish: () => void;
    buttonText: string;
    className?: string;
    endIcon?: React.ReactNode;
}

const TimerButton: React.FC<ITimerButtonProps> = ({countdown, onPressAction, onFinish, buttonText, className, endIcon}) => {
    const [countdownValue, setCountdownValue] = useState(countdown);
    let timer: NodeJS.Timer | null;
    useEffect(() => {
        if (countdownValue > 0) {
            timer = setInterval(() => {
                setCountdownValue((prevCountdown) => prevCountdown - 1);
            }, 1000);
        } else {
            onFinish();
        }

        return () => {
            if (timer) {
                clearInterval(timer);
            }
        };
    }, [countdownValue, onFinish]);

    const handleButtonClick = () => {
        if (countdownValue > 0) {
            if (timer) {
                clearInterval(timer);
            }
            onPressAction();
        }
    };

    return (
        <Button
            variant="contained"
            color="primary"
            onClick={handleButtonClick}
            disabled={countdownValue <= 0}
            className={className ? className : undefined}
            endIcon={endIcon}>
            <Translation text={buttonText} config={{time: countdownValue}} />
        </Button>
    );
};

export default TimerButton;
