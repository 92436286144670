import {Box, Checkbox, Divider, Grid, InputAdornment, Stack, TextField, Typography} from '@mui/material';
import {
    CustomTablePagination,
    ILocationOutput,
    ILocationTypeOutput,
    PAGINATION_ITEMS_PER_PAGE,
    Translation,
    accountLocaleSelector,
    dictionaryTranslationName,
    locationTypesSelector,
    MagnifyingGlassIcon,
} from 'palipali-panel-common-web';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {
    CreateJobWizardSteps,
    ICreateJobLocationFilters,
    IPaginationParams,
    changeLocationFilters,
    changeLocationListingPagination,
    resetLocations,
} from '../../../../../../store/reducers/createJobViewSlice';
import {
    createJobGeneralDataSelector,
    createJobLocationMetadataSelector,
    createJobLocationPaginationSelector,
    createJobLocationsSelector,
    currentWizardStepSelector,
} from '../../../../../../store/selectors/createJobViewSelectors';
import {AddressBookType} from '../../index';
import LocationSelectItem from './LocationSelectItem';

export enum LocationTypeCode {
    WAREHOUSE = 'warehouse',
    SHOP = 'shop',
    WHOLESALER = 'wholesaler',
    CONSTRUCTION = 'construction',
}

interface ILocationsSelectProps {
    onSelectLocation: (location: ILocationOutput) => void;
    addressBookTab: AddressBookType;
}

const LocationsSelect: React.FC<ILocationsSelectProps> = ({onSelectLocation, addressBookTab}) => {
    const {t} = useTranslation(),
        dispatch = useDispatch(),
        generalData = useSelector(createJobGeneralDataSelector),
        locations = useSelector(createJobLocationsSelector),
        [locationsArray, setLocationsArray] = useState<ILocationOutput[]>([]),
        locale = useSelector(accountLocaleSelector),
        locationTypes = useSelector(locationTypesSelector),
        locationsPagination = useSelector(createJobLocationPaginationSelector),
        locationsMetadata = useSelector(createJobLocationMetadataSelector),
        currentWizardStep = useSelector(currentWizardStepSelector),
        [locationName, setLocationName] = React.useState<string>(''),
        [selectedLocationTypes, setSelectedLocationTypes] = useState<LocationTypeCode[] | null>(
            currentWizardStep === CreateJobWizardSteps.SENDER_DATA
                ? [LocationTypeCode.SHOP, LocationTypeCode.WAREHOUSE]
                : [LocationTypeCode.CONSTRUCTION]
        ),
        getDefaultLocationFilterValues = (code: LocationTypeCode) => {
            if (!locationTypes) {
                return [];
            }
            return locationTypes?.filter((location) => location.code === code).map((location) => location.id);
        },
        defaultLocationFilterValues =
            currentWizardStep === CreateJobWizardSteps.SENDER_DATA
                ? [...getDefaultLocationFilterValues(LocationTypeCode.SHOP), ...getDefaultLocationFilterValues(LocationTypeCode.WAREHOUSE)]
                : getDefaultLocationFilterValues(LocationTypeCode.CONSTRUCTION),
        [selectedLocationFilterValues, setSelectedLocationFilterValues] = useState<string[] | null>(
            defaultLocationFilterValues.length > 0 ? defaultLocationFilterValues : null
        );

    useEffect(() => {
        const teamId = addressBookTab === AddressBookType.ADDRESS_BOOK && generalData?.teamId ? generalData.teamId : null,
            locationsFilters: ICreateJobLocationFilters = {
                'team.id': String(teamId),
                name: locationName,
                'locationType.id': selectedLocationFilterValues,
            };
        dispatch(changeLocationFilters(locationsFilters));
        return () => {
            dispatch(resetLocations());
        };
    }, []);

    useEffect(() => {
        if (locations) {
            setLocationsArray(locations);
        }
    }, [locations]);

    useEffect(() => {
        if (addressBookTab === AddressBookType.AUTHORIZED_WAREHOUSES) {
            setSelectedLocationFilterValues(
                locationTypes?.filter((location) => !location.accessibleForClients)?.map((location) => location.id) || []
            );
            const selectedLocationTypes = locationTypes
                ?.filter((location) => !location.accessibleForClients)
                ?.map((location) => location.code) as LocationTypeCode[];

            setSelectedLocationTypes(selectedLocationTypes);
        }
    }, [addressBookTab]);

    const setSelectedLocationFilters = (value: boolean, locationType: ILocationTypeOutput) => {
        setSelectedLocationTypes((prevSelectedTypes) => {
            if (prevSelectedTypes) {
                if (value) {
                    return [...prevSelectedTypes, locationType.code as LocationTypeCode];
                } else {
                    return prevSelectedTypes.filter((type) => type !== locationType.code);
                }
            }
            return null;
        });

        setSelectedLocationFilterValues((prevFilterValues) => {
            if (prevFilterValues) {
                if (value) {
                    return [...prevFilterValues, locationType.id];
                } else {
                    return prevFilterValues.filter((filter) => filter !== locationType.id);
                }
            }
            return null;
        });
    };

    useEffect(() => {
        const teamId = addressBookTab === AddressBookType.ADDRESS_BOOK && generalData?.teamId ? generalData.teamId : null;
        const locationsFilters: ICreateJobLocationFilters = {
            'team.id': String(teamId),
            name: locationName,
            'locationType.id': selectedLocationFilterValues,
        };
        dispatch(changeLocationFilters(locationsFilters));
    }, [selectedLocationFilterValues, locationName, selectedLocationTypes]);

    const renderTypeFilters = () => {
        const locationTypesFiltered =
            addressBookTab === AddressBookType.ADDRESS_BOOK
                ? locationTypes?.filter((location) => location.accessibleForClients) || []
                : locationTypes?.filter((location) => !location.accessibleForClients) || [];

        const preferredOrder = ['shop', 'construction', 'warehouse'];
        locationTypesFiltered.sort((a, b) => {
            const indexA = preferredOrder.indexOf(a.code);
            const indexB = preferredOrder.indexOf(b.code);

            if (indexA !== -1 && indexB === -1) {
                return -1;
            }
            if (indexA === -1 && indexB !== -1) {
                return 1;
            }
            if (indexA !== -1 && indexB !== -1) {
                return indexA - indexB;
            }
            return 0;
        });
        return (
            <Stack direction={'row'} justifyContent={'space-between'}>
                {locationTypesFiltered.map((location) => {
                    return (
                        <Stack key={location.id} direction={'row'} alignItems={'center'}>
                            <Checkbox
                                className={`create-job-address-book-location-list-checkbox-${location.code}`}
                                checked={selectedLocationTypes ? selectedLocationTypes?.includes(location.code as LocationTypeCode) : false}
                                onChange={(event, value) => setSelectedLocationFilters(value, location)}
                            />
                            <Typography>{dictionaryTranslationName(location, locale)}</Typography>
                        </Stack>
                    );
                })}
            </Stack>
        );
    };

    return (
        <Stack className="location-search-wrapper">
            <Stack className="location-filters-wrapper">
                {renderTypeFilters()}
                <Box className="location-search-input">
                    <TextField
                        fullWidth
                        className="create-job-address-book-location-list-search-input"
                        value={locationName}
                        label={t('jobs.createJob.senderData.addressBookModal.searchPlaceholder')}
                        onChange={(event) => setLocationName(event.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <MagnifyingGlassIcon />
                                </InputAdornment>
                            ),
                            className: 'courier-search-wrapper',
                        }}
                    />
                    <Divider />
                </Box>
            </Stack>
            <Grid>
                {locationsArray && locationsArray.length ? (
                    <>
                        <Grid className="location-list-container">
                            {locationsArray.map((location) => {
                                return <LocationSelectItem key={location.id} item={location} onPress={onSelectLocation} />;
                            })}
                        </Grid>
                        <CustomTablePagination
                            metadata={locationsMetadata}
                            onChangePage={(event, pageNumber) => {
                                const locationsPagination: IPaginationParams = {
                                    page: pageNumber,
                                    itemsPerPage: PAGINATION_ITEMS_PER_PAGE,
                                };
                                dispatch(changeLocationListingPagination({locationsPagination: locationsPagination}));
                            }}
                            page={locationsPagination.page}
                            rowsPerPage={locationsPagination.itemsPerPage}
                        />
                    </>
                ) : (
                    <Typography>
                        <Translation text={'jobs.createJob.senderData.addressBookModal.noLocations'} />
                    </Typography>
                )}
            </Grid>
        </Stack>
    );
};

export default LocationsSelect;
