import {Button, Stack, Typography, useTheme} from '@mui/material';
import {
    CustomModalComponent,
    FormikForm,
    IMultiselectOption,
    Loader,
    LoaderType,
    MemberOperationType,
    Translation,
    UserRole,
    currentlySelectedModalTypeSelector,
    isModalOpenSelector,
} from 'palipali-panel-common-web';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {isTeamMemberLoadingSelector} from 'src/store/selectors/teamMemberSelectors';
import {inviteTeamMember} from '../../../../../store/reducers/teamMemberSlice';
import {setRoleFilters} from '../utils/roleFilters';
import {teamMemberInviteFormConfig} from './teamMemberInviteFormConfig';
import TeamMemberRoleDescription from '../TeamMemberRoleDescription';

interface ITeamMemberInviteModalProps {
    teamId: string | null | undefined;
    role: UserRole | null;
}

const TeamMemberInviteModal: React.FC<ITeamMemberInviteModalProps> = ({teamId, role}) => {
    const dispatch = useDispatch(),
        theme = useTheme(),
        isOpen = useSelector(isModalOpenSelector),
        isAddModalType = useSelector(currentlySelectedModalTypeSelector) === MemberOperationType.TEAMS_ADD_MEMBER,
        isLoading = useSelector(isTeamMemberLoadingSelector),
        [isAllowed, setIsAllowed] = useState<boolean>(false),
        {t} = useTranslation(),
        isModalOpen = isOpen && isAddModalType,
        selectUserRoles = [UserRole.PURCHASER_TEAM_EMPLOYEE, UserRole.PURCHASER_TEAM_MANAGER, UserRole.PURCHASER_TEAM_OWNER],
        roles: IMultiselectOption[] = Object.values(selectUserRoles).map((role) => ({
            value: role,
            label: t(`roles.${role}`),
        })),
        [adaptedRoles, setAdaptedRole] = useState<IMultiselectOption[]>([
            {
                value: '',
                label: '',
            },
        ]),
        startValues = {
            email: '',
            role: '',
        };

    useEffect(() => {
        if (isModalOpen) {
            setAdaptedRole(setRoleFilters(role, roles));
        }
    }, [isModalOpen]);

    return (
        <CustomModalComponent
            theme={theme}
            customClasses="team-member-invite-modal"
            title={
                <Typography color="text.primary" variant="h6" component="h2">
                    <Translation text={`teams.teamOperation.title.addUser`} />
                </Typography>
            }
            content={
                <Stack sx={{width: '100%'}}>
                    <FormikForm
                        fields={teamMemberInviteFormConfig(adaptedRoles)}
                        formId="team-member-invite"
                        submitAllowed={setIsAllowed}
                        initialValues={startValues}
                        onSubmit={(values) => {
                            const payload = {
                                member: {
                                    role: values.role,
                                    email: values.email,
                                    returnUrl: `${process.env.REACT_APP_URL}/auth/confirm-team-member-invitation`,
                                },
                                id: teamId,
                            };
                            dispatch(inviteTeamMember(payload));
                        }}
                        theme={theme}
                    />
                    <TeamMemberRoleDescription />
                    <Loader showLoader={isLoading} type={LoaderType.Local} />
                </Stack>
            }
            footer={
                <Button type="submit" variant="contained" color="primary" fullWidth form="team-member-invite" disabled={!isAllowed}>
                    <Translation text={`teams.teamOperation.buttons.addUser`} />
                </Button>
            }
        />
    );
};

export default TeamMemberInviteModal;
