import {Button, Divider, Grid, Stack, Typography, useTheme} from '@mui/material';
import {
    ChevronLeftIcon,
    ChevronRight,
    FormikForm,
    IGeoPointInput,
    IJobLocationInput,
    IJobRecipientInput,
    Translation,
} from 'palipali-panel-common-web';
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    CreateJobWizardSteps,
    IAdditionalData,
    createJob,
    replaceJob,
    setCurrentStep,
    setJobAdditionalData,
} from '../../../../store/reducers/createJobViewSlice';
import {
    createJobAdditionalDataSelector,
    createJobGeneralDataSelector,
    createJobReceiverDataSelector,
    createJobRecipientDataSelector,
    createJobSenderDataSelector,
    createdJobSelector,
} from '../../../../store/selectors/createJobViewSelectors';
import additionalDataFormConfig from './additionalDataFormConfig';
import {FormikHelpers} from 'formik';

interface AdditionalDataForm {
    orderNumber: string | undefined;
    description: string | undefined;
}

interface ICreateJobAdditionalDataProps {}

const CreateJobAdditionalData: React.FC<ICreateJobAdditionalDataProps> = () => {
    const theme = useTheme(),
        dispatch = useDispatch(),
        additionalData = useSelector(createJobAdditionalDataSelector),
        generalData = useSelector(createJobGeneralDataSelector),
        senderData = useSelector(createJobSenderDataSelector),
        receiverData = useSelector(createJobReceiverDataSelector),
        recipientData = useSelector(createJobRecipientDataSelector),
        [isValid, setIsValid] = useState<boolean>(true),
        createdJob = useSelector(createdJobSelector),
        initialValues: AdditionalDataForm = {
            orderNumber: additionalData?.orderNumber || '',
            description: additionalData?.description || '',
        };

    const handleSaveData = (values: AdditionalDataForm, formikHelpers?: FormikHelpers<AdditionalDataForm>) => {
        const additionalData: IAdditionalData = {
            orderNumber: values?.orderNumber || '',
            description: values?.description || '',
        };
        dispatch(setJobAdditionalData(additionalData));

        if (generalData?.teamId && senderData && receiverData && recipientData) {
            const senderLocationId = senderData?.locationId || null,
                sender: IJobLocationInput = {
                    locationId: senderLocationId,
                    companyName: senderLocationId ? null : senderData?.companyName || null,
                    phone: senderData?.phone
                        ? {
                              country: senderData.phone.country,
                              phone: senderData.phone.phone,
                          }
                        : null,
                    addressLine: senderLocationId ? null : senderData?.addressLine || null,
                    flatNumber: senderLocationId ? null : senderData?.flatNumber || null,
                    point: senderLocationId ? null : (senderData?.point as IGeoPointInput) || null,
                    street: senderLocationId ? null : senderData.street,
                    houseNumber: senderLocationId ? null : senderData.houseNumber,
                    zip: senderLocationId ? null : senderData.zip,
                    city: senderLocationId ? null : senderData.city,
                    country: senderLocationId ? null : senderData.country,
                },
                receiverLocationId = receiverData?.locationId || null,
                receiver: IJobLocationInput = {
                    locationId: receiverLocationId,
                    companyName: receiverLocationId ? null : receiverData?.companyName || null,
                    phone: null,
                    addressLine: receiverLocationId ? null : receiverData?.addressLine || null,
                    flatNumber: receiverLocationId ? null : receiverData?.flatNumber || null,
                    point: receiverLocationId ? null : (receiverData?.point as IGeoPointInput) || null,
                    street: receiverLocationId ? null : receiverData.street,
                    houseNumber: receiverLocationId ? null : receiverData.houseNumber,
                    zip: receiverLocationId ? null : receiverData.zip,
                    city: receiverLocationId ? null : receiverData.city,
                    country: receiverLocationId ? null : receiverData.country,
                },
                teamMemberId = recipientData?.teamMemberId || null,
                recipientPayload: IJobRecipientInput = {
                    teamMemberId: teamMemberId,
                    firstName: teamMemberId ? null : recipientData?.firstName || null,
                    lastName: teamMemberId ? null : recipientData?.lastName || null,
                    phone: teamMemberId
                        ? null
                        : {
                              country: recipientData?.phone?.country || '',
                              phone: recipientData?.phone?.phone || '',
                          },
                };

            const createJobPayload = {
                teamId: generalData.teamId,
                packageSize: generalData?.packageSize,
                insuranceValue: generalData?.insuranceValue,
                fromLocation: sender,
                toLocation: receiver,
                processAt: generalData.processAt ? new Date(generalData.processAt).toISOString() : null,
                recipient: recipientPayload,
                orderNumber: values?.orderNumber || null,
                description: values?.description || '',
            };

            if (!createdJob) {
                dispatch(createJob(createJobPayload));
            } else {
                const payload = {
                    jobId: createdJob.id,
                    createJobInput: createJobPayload,
                };
                dispatch(replaceJob(payload));
            }
        }
        if (formikHelpers) formikHelpers.setSubmitting(false);
    };

    return (
        <Stack
            className="sender-data-container"
            sx={{
                padding: '2rem',
                gap: '1.6rem',
            }}>
            <Typography variant="body2" color={'text.secondary'} noWrap>
                <Translation text="jobs.createJob.additionalData.orderNumberShare" />
            </Typography>
            <Stack sx={{width: '100%', maxWidth: '50rem'}}>
                <FormikForm
                    fields={additionalDataFormConfig()}
                    formId="job-additional-data"
                    initialValues={initialValues}
                    isDirty={true}
                    theme={theme}
                    submitAllowed={setIsValid}
                    onSubmit={handleSaveData}
                />
            </Stack>
            <Divider />
            <Grid className="navigation-buttons-container">
                <Button
                    key="prev-button"
                    onClick={() => {
                        dispatch(setCurrentStep(CreateJobWizardSteps.RECIPIENT_DATA));
                    }}
                    className="btn-action"
                    startIcon={<ChevronLeftIcon />}>
                    <Translation text="jobs.createJob.generalData.prevButton" />
                </Button>
                <Button
                    key="next-button"
                    type="submit"
                    disabled={!isValid}
                    variant="contained"
                    color="primary"
                    form="job-additional-data"
                    className="navigation-button next-button"
                    endIcon={<ChevronRight />}>
                    <Translation text="jobs.createJob.additionalData.summaryButton" />
                </Button>
            </Grid>
        </Stack>
    );
};

export default CreateJobAdditionalData;
