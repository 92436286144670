import {Button, Typography, useTheme} from '@mui/material';
import {CustomModalComponent, Translation, AlertTriangle} from 'palipali-panel-common-web';
import React from 'react';
import {useDispatch} from 'react-redux';
import {deleteTeam} from '../../../../../../store/reducers/teamViewSlice';

interface IDeleteTeamModalProps {
    id: string | null | undefined;
    companyName: string | null | undefined;
}

const DeleteTeamModal: React.FC<IDeleteTeamModalProps> = ({id, companyName}) => {
    const dispatch = useDispatch(),
        theme = useTheme();

    return (
        <CustomModalComponent
            theme={theme}
            title={
                <Typography color="text.primary" variant="h6" component="h2">
                    <Translation text={`teams.teamOperation.title.removeTeam`} />
                </Typography>
            }
            content={
                <>
                    <AlertTriangle />
                    <Typography className="delete-team-description">
                        <Translation text="teams.teamOperation.description.removeTeam" />
                        <span className="bold-text">{` ${companyName ? companyName : ''}`}</span>
                        <span>?</span>
                    </Typography>
                    <Typography sx={{color: 'error.dark'}}>
                        <Translation text="teams.teamOperation.description.warning" />
                    </Typography>
                </>
            }
            footer={
                <Button type="submit" variant="contained" color="primary" fullWidth onClick={() => dispatch(deleteTeam(id))}>
                    <Translation text={`teams.teamOperation.buttons.removeTeam`} />
                </Button>
            }
        />
    );
};

export default DeleteTeamModal;
