import {Button, Divider, Grid, Stack, Typography, useTheme} from '@mui/material';
import {useFormik} from 'formik';
import 'leaflet-defaulticon-compatibility';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css';
import {
    AddressAutocomplete,
    FormikForm,
    IGeoPointInput,
    IGeoPointOutput,
    IJobLocationInput,
    ILocationOutput,
    MemberOperationType,
    Translation,
    closeModal,
    currentlySelectedModalTypeSelector,
    formatPhoneToPayload,
    isModalOpenSelector,
    locationByCodeSelector,
    openModal,
    ChevronRight,
    ChevronLeftIcon,
    PlusSquareIcon,
    isSameValue,
    isPhoneNumberTypeMobile,
} from 'palipali-panel-common-web';
import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {MapContainer, Marker} from 'react-leaflet';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import {senderMarkerIcon} from 'src/components/shared/mapIcons';
import {
    CreateJobWizardSteps,
    setCurrentStep,
    setIsOutsideNavigation,
    setJobSenderData,
    validateJobSenderData,
} from '../../../../store/reducers/createJobViewSlice';
import {createJobGeneralDataSelector, createJobSenderDataSelector} from '../../../../store/selectors/createJobViewSelectors';
import CustomTileLayer from '../../../shared/CustomTileLayer';
import MapClickHandler from '../../../shared/MapClickHandler';
import LocationsModal from './LocationsModal';
import {LocationTypeCode} from './LocationsModal/LocationsSelect';
import UpdateMapCenter from './UpdateMapCenter';
import senderDataFormConfig from './senderDataFormConfig';
import {IGoogleAddressParts} from 'palipali-panel-common-web/dist/model/location';

export enum AddressBookType {
    ADDRESS_BOOK = 'AddressBook',
    AUTHORIZED_WAREHOUSES = 'AuthorizedWarehouses',
}

interface SenderDataForm {
    locationId: string | null;
    addressLine: string;
    point: IGeoPointOutput | null;
    companyName: string | null;
    flatNumber: string | null;
    dialingCode: string;
    phone: string;
    street: string | null;
    houseNumber: string | null;
    zip: string | null;
    city: string | null;
    country: string | null;
}

interface ICreateJobSenderDataProps {}

const CreateJobSenderData: React.FC<ICreateJobSenderDataProps> = () => {
    const theme = useTheme(),
        dispatch = useDispatch(),
        {t} = useTranslation(),
        navigate = useNavigate(),
        senderData = useSelector(createJobSenderDataSelector),
        generalData = useSelector(createJobGeneralDataSelector),
        isModalOpen = useSelector(isModalOpenSelector),
        modalType = useSelector(currentlySelectedModalTypeSelector),
        selectedLocationType = useSelector((state) => locationByCodeSelector(state, LocationTypeCode.SHOP)),
        [selectedLocation, setSelectedLocation] = useState<ILocationOutput | null>(null),
        [defaultCoordinates, setDefaultCoordinates] = useState<IGeoPointOutput | null>(null),
        [initialValues] = useState<SenderDataForm>({
            locationId: senderData?.locationId || null,
            addressLine: senderData?.addressLine || '',
            point: senderData?.point || defaultCoordinates,
            companyName: senderData?.companyName || '',
            flatNumber: senderData?.flatNumber || '',
            dialingCode: senderData?.phone?.country || '+48',
            phone: senderData?.phone?.phone || '',
            zip: senderData?.zip || '',
            street: senderData?.street || '',
            houseNumber: senderData?.houseNumber || '',
            city: senderData?.city || '',
            country: senderData?.country || '',
        });

    useEffect(() => {
        if (senderData) {
            setDefaultCoordinates(senderData.point);
        }
    }, [senderData]);

    const handleLocationSelection = (value: ILocationOutput) => {
        setSelectedLocation(value);
        const point: IGeoPointInput = {
            latitude: value?.point?.latitude,
            longitude: value?.point?.longitude,
        };
        formik.setValues({
            ...formik.values,
            locationId: value?.id,
            addressLine: value?.addressLine || '',
            point: point,
            companyName: value?.companyName || '',
            flatNumber: value?.flatNumber || '',
            dialingCode: value?.phone?.country || '+48',
            phone: value?.phone?.phone || '',
            zip: value?.zip || '',
            street: value?.street || '',
            houseNumber: value?.houseNumber || '',
            city: value?.city || '',
            country: value?.country || '',
        });
        if (value && value.point) {
            setDefaultCoordinates(point);
        }
        dispatch(closeModal());
    };

    const saveAddress = () => {
        const location = {
            locationId: selectedLocationType?.id,
            companyName: formik.values?.companyName || null,
            phone: {
                country: formik.values?.dialingCode,
                phone: formik.values?.phone,
            },
            addressLine: formik.values?.addressLine,
            flatNumber: formik.values?.flatNumber || null,
            point: {
                latitude: (formik.values.point as IGeoPointInput)?.latitude,
                longitude: (formik.values.point as IGeoPointInput)?.longitude,
            },
            zip: formik.values?.zip || '',
            street: formik.values?.street || '',
            houseNumber: formik.values?.houseNumber || '',
            city: formik.values?.city || '',
            country: formik.values?.country || '',
        };

        const locationId = selectedLocation?.id || null;
        const senderData: IJobLocationInput = {
            ...location,
            locationId: locationId,
        };

        dispatch(setJobSenderData(senderData));
        dispatch(setIsOutsideNavigation(true));
        navigate('/panel/locations/create', {state: {location: location, teamId: generalData?.teamId}});
    };

    const handleSaveData = (values: SenderDataForm) => {
        if ((values.addressLine && values.phone && values.point) || selectedLocation) {
            const isStepValueChanged = !isSameValue(initialValues, values, true),
                isLocationIdSame = initialValues.locationId === values.locationId;
            const senderData: IJobLocationInput = {
                locationId: isStepValueChanged && isLocationIdSame ? null : values?.locationId || null,
                companyName: values?.companyName || '',
                phone: {
                    country: values.dialingCode,
                    phone: formatPhoneToPayload(values.phone),
                },
                addressLine: values?.addressLine || '',
                flatNumber: values?.flatNumber || '',
                point: (values?.point as IGeoPointInput) || null,
                zip: values?.zip || '',
                street: values?.street || '',
                houseNumber: values?.houseNumber || '',
                city: values?.city || '',
                country: values?.country || '',
            };
            dispatch(validateJobSenderData(senderData));
        }
    };

    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: handleSaveData,
    });

    const prevCountryRef = useRef(formik.values.dialingCode),
        [shouldValidate, setShouldValidate] = useState(false);

    useEffect(() => {
        if (formik.values.dialingCode !== prevCountryRef.current) {
            setShouldValidate(true);
            formik.setFieldTouched('phone', true, false);
            prevCountryRef.current = formik.values.dialingCode;
        }
    }, [formik.values.dialingCode]);

    const setAddressAndCoordsFromMapAction = (coords: [number, number], address: string, addressParts: IGoogleAddressParts) => {
        setSelectedLocation(null);
        formik.setValues({
            ...formik.values,
            ...addressParts,
            locationId: null,
            addressLine: address,
            point: {
                latitude: coords[0],
                longitude: coords[1],
            },
        });
    };

    const isValid = () => {
        return (
            formik.values.addressLine &&
            formik.values.point &&
            formik.values.dialingCode &&
            formik.values.phone &&
            formik.values.zip &&
            formik.values.street &&
            formik.values.houseNumber &&
            formik.values.city &&
            formik.values.country
        );
    };

    const isAutocompleteAddressValid = () => {
        return formik.values.zip && formik.values.street && formik.values.houseNumber && formik.values.city && formik.values.country;
    };
    const onAddressClear = () => {
        setSelectedLocation(null);
        formik.setValues({
            ...formik.values,
            locationId: null,
            addressLine: '',
            point: null,
            street: '',
            houseNumber: '',
            zip: '',
            city: '',
            country: '',
        });
        setDefaultCoordinates(null);
    };

    const isFieldDirty = (fieldName: keyof SenderDataForm) => {
        return formik.values[fieldName] !== formik.initialValues[fieldName];
    };

    return (
        <Stack className="sender-data-container">
            <Grid container>
                <Grid item xs={12} md={6} className="sender-data-wrapper">
                    <Typography className="sender-data-description">
                        <Translation text="jobs.createJob.senderData.description" />
                    </Typography>
                    <AddressAutocomplete
                        label={t('jobs.createJob.senderData.formControls.address')}
                        noOptionText={t('jobs.createJob.senderData.noAutocompleteResults')}
                        isAddressBookShown={true}
                        isError={!isAutocompleteAddressValid() && isFieldDirty('addressLine')}
                        onAddressBookAction={() => dispatch(openModal(MemberOperationType.NEW_ORDER_ADDRESS_BOOK))}
                        defaultValue={formik.values.addressLine}
                        isRequired={true}
                        onAddressClear={onAddressClear}
                        onChange={(addressLine, point, addressParts: IGoogleAddressParts) => {
                            formik.setValues({
                                ...formik.values,
                                ...addressParts,
                                addressLine: addressLine,
                                point: point,
                            });
                            setDefaultCoordinates(point);
                        }}
                    />

                    <FormikForm
                        fields={senderDataFormConfig()}
                        formId="job-sender-data"
                        initialValues={initialValues}
                        updatedValues={formik.values}
                        theme={theme}
                        shouldValidate={shouldValidate}
                        customEventChange={(formControl: string, value: string) => {
                            formik.setFieldValue(formControl, value);
                        }}
                        onSubmit={(values) => {
                            handleSaveData(values);
                        }}
                    />
                    <Typography className="sender-data-description sender-phone-description">
                        <Translation text="jobs.createJob.senderData.phoneDetails" />
                    </Typography>
                    <Stack alignItems={'flex-start'}>
                        <Button
                            key="save-address-btn"
                            disabled={!isValid()}
                            className="btn-action"
                            onClick={() => saveAddress()}
                            startIcon={<PlusSquareIcon />}>
                            <Typography>
                                <Translation text="jobs.createJob.senderData.saveAddress" />
                            </Typography>
                        </Button>
                    </Stack>
                </Grid>
                <Grid item xs={12} md={6} className="map-container">
                    <MapContainer
                        center={
                            formik.values?.point && formik.values?.point?.latitude && formik.values?.point?.longitude
                                ? [formik.values.point.latitude, formik.values.point.longitude]
                                : [52.237049, 21.017532]
                        }
                        zoom={10}
                        maxZoom={18}
                        minZoom={2}
                        scrollWheelZoom={false}>
                        <CustomTileLayer />
                        <MapClickHandler onLocationChange={setAddressAndCoordsFromMapAction} />
                        {formik.values?.point && formik.values?.point?.latitude && formik.values?.point?.longitude ? (
                            <Marker
                                icon={senderMarkerIcon}
                                position={[formik.values.point.latitude, formik.values.point.longitude]}></Marker>
                        ) : null}
                        <UpdateMapCenter latitude={defaultCoordinates?.latitude} longitude={defaultCoordinates?.longitude} />
                    </MapContainer>
                </Grid>
            </Grid>

            <Divider />
            <Grid className="navigation-buttons-container">
                <Button
                    key="prev-button"
                    onClick={() => {
                        dispatch(setCurrentStep(CreateJobWizardSteps.GENERAL_DATA));
                    }}
                    className="btn-action"
                    startIcon={<ChevronLeftIcon />}>
                    <Translation text="jobs.createJob.generalData.prevButton" />
                </Button>
                <Button
                    key="next-button"
                    disabled={!isValid() || !isPhoneNumberTypeMobile(formik.values.dialingCode, formik.values.phone)}
                    type="submit"
                    variant="contained"
                    className="navigation-button next-button"
                    form="job-sender-data"
                    endIcon={<ChevronRight />}>
                    <Translation text="jobs.createJob.generalData.nextButton" />
                </Button>
            </Grid>

            {isModalOpen && modalType === MemberOperationType.NEW_ORDER_ADDRESS_BOOK && (
                <LocationsModal activeTab={AddressBookType.ADDRESS_BOOK} onSelectLocation={handleLocationSelection} />
            )}
        </Stack>
    );
};

export default CreateJobSenderData;
